import UserPermission from "../../UserPermission";
import assertDefinedValue from "../../utils/assertDefinedValue";
import useDashboardStore from "../useDashboardStore";

/**
 * Returns whether the user is currently granted the specified permission. If permissions have not yet been set,
 * this will throw an error. This would be unusual since most content isn't rendered until user
 * permissions have been set.
 */
const useUserPermission = (permission: UserPermission) => {
  return useDashboardStore((store) => {
    const permissions = assertDefinedValue(
      "A usage of useUserPermission is expecting permissions to be defined, but they have not yet been set.",
      store.userPermissions
    );

    return permissions[permission];
  });
};

export default useUserPermission;
