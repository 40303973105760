import {
  DashboardPrimaryTemplate,
  UserPermission,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { Box, Button, TrimmedBoxContent } from "@taxbit-private/cosmic";

import FilerSetupModal from "./FilerSetupModal";
import FilerSetupTable from "./table/FilerSetupTable";
import { useFilerSetupModalContext } from "./useFilerSetupModalContext";

export const FilerSetupTrackingId = {
  AddFilerButton: "add-filer-button",
};

const FilerSetup = () => {
  const { handleModalOpen } = useFilerSetupModalContext();
  const canCreatePayers = useUserPermission(UserPermission.CreatePayers);

  return (
    <DashboardPrimaryTemplate
      title="Filer Setup"
      utilityElement={
        canCreatePayers ? (
          <Button
            label="Add Filer"
            onClick={() => {
              handleModalOpen({ isOpen: true });
            }}
            trackingId={FilerSetupTrackingId.AddFilerButton}
          />
        ) : undefined
      }
    >
      <Box>
        <TrimmedBoxContent trim="all">
          <FilerSetupTable />
        </TrimmedBoxContent>
      </Box>
      <FilerSetupModal />
    </DashboardPrimaryTemplate>
  );
};

export default FilerSetup;
