import { Flex, Modal, ModalProps } from "@taxbit-private/cosmic";
import { RhfTextInput } from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";

import {
  EditPersonalInfoModalTrackingId,
  EditPersonalInfoFormFields,
} from "./personalInfoModalTypes";
import useEditPersonalInfoModalForm from "./useEditPersonalInfoModalForm";

type Props = Pick<ModalProps, "isOpen" | "onClose">;

const EDIT_PERSONAL_INFO_FORM_ID = "edit-personal-info-to-organization-form";

const EditPersonalInfoModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const {
    formMethods,
    handleSubmit,
    isLoading: isLoadingUpdateUserName,
  } = useEditPersonalInfoModalForm(onClose);

  return (
    <Modal
      title="Edit Personal Information"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={EditPersonalInfoModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: EditPersonalInfoModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Save Changes",
        trackingId: EditPersonalInfoModalTrackingId.SubmitButton,
        type: "submit",
        form: EDIT_PERSONAL_INFO_FORM_ID,
        isLoading: isLoadingUpdateUserName,
        loadingText: "Saving",
      }}
    >
      <FormProvider {...formMethods}>
        <form
          id={EDIT_PERSONAL_INFO_FORM_ID}
          onSubmit={(e) => {
            void handleSubmit(e);
          }}
        >
          <Flex direction="column">
            <RhfTextInput<EditPersonalInfoFormFields>
              name="newName"
              trackingId={EditPersonalInfoModalTrackingId.NewNameTextInput}
              label="Name"
            />
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditPersonalInfoModal;
