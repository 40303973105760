import { z } from "zod";

import { AccountsUrlParams } from "../../../accountsUrlParams";

const filterDrawerFieldsSchema = AccountsUrlParams.schema
  .pick({
    tinStatuses: true,
    vatStatuses: true,
    taxClassifications: true,
    taxDocumentationTypes: true,
    taxCountryCodes: true,
    filerIds: true,
  })
  .required()
  .extend({
    dateCreated: z.tuple([z.date().optional(), z.date().optional()]),
  });
export type FilterDrawerFields = z.infer<typeof filterDrawerFieldsSchema>;

const fromUrlParams = (params: AccountsUrlParams): FilterDrawerFields => ({
  tinStatuses: params.tinStatuses ?? [],
  vatStatuses: params.vatStatuses ?? [],
  taxClassifications: params.taxClassifications ?? [],
  taxDocumentationTypes: params.taxDocumentationTypes ?? [],
  taxCountryCodes: params.taxCountryCodes ?? [],
  dateCreated: [
    params.dateCreatedStart ? new Date(params.dateCreatedStart) : undefined,
    params.dateCreatedEnd ? new Date(params.dateCreatedEnd) : undefined,
  ],
  filerIds: params.filerIds ?? [],
});

export const FilterDrawerFields = {
  schema: filterDrawerFieldsSchema,
  fromUrlParams,
};

export const DEFAULT_FILTER_DRAWER_FIELDS: FilterDrawerFields = {
  tinStatuses: [],
  vatStatuses: [],
  taxClassifications: [],
  taxDocumentationTypes: [],
  taxCountryCodes: [],
  dateCreated: [undefined, undefined],
  filerIds: [],
};
