import { Outlet } from "@tanstack/react-location";
import { SharedFormYearDropdownParamsContextProvider } from "@taxbit-dashboard/commons";
import {
  DeleteFileUploaderProvider,
  IngestFileUploaderProvider,
} from "@taxbit-dashboard/irw";

import AppLevelSpinner from "./AppLevelSpinner";
import TopNavigation from "./top-navigation/TopNavigation";
import useInitializeVendorUsers from "../../../hooks/useInitializeVendorUsers";
import { useUpdateZendeskUrl } from "../vendors/zendesk";

/**
 * We can only initialize our vendor users when we are confident that
 * we're in an authenticated view. This separate component should only
 * be rendered when we receive an authenticated status from Auth0.
 */
const AuthedRoute = () => {
  const areVendorUsersInitialized = useInitializeVendorUsers();

  /**
   * Ensures we are passing page URL changes to the Zendesk widget for help
   * articles to be properly contextualized.
   */
  useUpdateZendeskUrl();

  if (areVendorUsersInitialized) {
    return (
      <>
        <TopNavigation />
        <IngestFileUploaderProvider>
          <DeleteFileUploaderProvider>
            <SharedFormYearDropdownParamsContextProvider>
              <Outlet />
            </SharedFormYearDropdownParamsContextProvider>
          </DeleteFileUploaderProvider>
        </IngestFileUploaderProvider>
      </>
    );
  } else {
    return <AppLevelSpinner trackingId="authed-route-spinner" />;
  }
};

export default AuthedRoute;
