import { useMemo } from "react";

import { formTypeToLabelMap } from "../../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../../context/useIrForms";

export enum IrFormTypeChipTrackingId {
  Chip = "ir-forms-type-chip",
}

const useFormsTypeChips = () => {
  const { urlParams, setFilterParams } = useIrFormsContext();

  return useMemo(() => {
    return (urlParams.types ?? []).map((type) => {
      const typeLabel = formTypeToLabelMap[type];

      return {
        label: `Type: ${typeLabel}`,
        removeButtonProps: {
          "onClick": () => {
            setFilterParams((draft) => {
              const { types } = draft;
              draft.types = types?.filter((t) => t !== type);
            });
          },
          "aria-label": `Remove filter for ${typeLabel}`,
        },
        trackingId: `${IrFormTypeChipTrackingId.Chip}-${type}`,
      };
    });
  }, [urlParams, setFilterParams]);
};

export default useFormsTypeChips;
