import { z } from "zod";

export enum EditPersonalInfoModalTrackingId {
  SuccessToast = "edit-personal-info-success-toast",
  ErrorToast = "edit-personal-info-error-toast",
  NewNameTextInput = "edit-personal-info-new-name",
  SubmitButton = "edit-personal-info-submit-button",
  CancelButton = "edit-personal-info-cancel-button",
  CloseButton = "edit-personal-info-close-button",
}

export const editPersonalInfoFormFieldSchema = z.object({
  newName: z.string().min(1, "Please enter your name"),
  oldName: z.string(), // TODO: https://taxbit.atlassian.net/browse/TAX-29172
});

export type EditPersonalInfoFormFields = z.infer<
  typeof editPersonalInfoFormFieldSchema
>;
