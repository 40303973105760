import { UrlFilterParams } from "@taxbit-dashboard/commons";

import {
  FilesTableParams,
  filesTableParamsSchema,
} from "../../../api/files/filesApiTypes";

const validateFilesTableUrlParams = (
  params: UrlFilterParams<FilesTableParams>
): FilesTableParams => {
  return filesTableParamsSchema.parse(params);
};

export default validateFilesTableUrlParams;
