import { zodResolver } from "@hookform/resolvers/zod";
import {
  useDashboardStore,
  logError,
  useOrganizationId,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { OrganizationMember } from "@taxbit-dashboard/rest";
import {
  Modal,
  ModalProps,
  Flex,
  ContentSpinner,
  ContentErrorEmptyState,
} from "@taxbit-private/cosmic";
import { RhfTextInput } from "@taxbit-private/cosmic-react-hook-form";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";

import RhfCheckboxGroup from "./RhfCheckboxGroup";
import { useInviteUserToOrganization } from "../api/invitationsApi";
import { useGetOrganizationMembers } from "../api/organizationMembersApi";
import { useGetRoles } from "../api/rolesApi";

type Props = Pick<ModalProps, "isOpen" | "onClose">;

const INVITE_USER_FORM_ID = "invite-user-to-organization-form";

const getInviteUserSchema = (
  organizationMembers: OrganizationMember[] | undefined
) => {
  return z
    .object({
      inviter: z.object({
        name: z.string(),
      }),
      invitee: z.object({
        email: z
          .string()
          .min(1, { message: "Email is required" })
          .email({ message: "Please enter a valid email address" }),
      }),
      roles: z.string().array().nonempty({
        message: "At least one role is required",
      }),
    })
    .refine(
      (data) => {
        return !organizationMembers?.some(
          ({ companyUser: { email } }) => data.invitee.email === email
        );
      },
      {
        message: "A user with this email address has already been added.",
        path: ["invitee.email"],
      }
    );
};

export type InviteUser = z.infer<ReturnType<typeof getInviteUserSchema>>;

const InviteCompanyUserModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const organizationId = useOrganizationId();

  const {
    data: roles,
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
  } = useGetRoles();

  const {
    data: organizationMembers,
    isLoading: isLoadingOrganizationMembers,
    isError: isErrorOrganizationMembers,
  } = useGetOrganizationMembers({ organizationId });

  const currentCompanyUser = useDefinedCurrentCompanyUser();

  const { mutate: inviteUser, isLoading: isLoadingInviteUser } =
    useInviteUserToOrganization(organizationId);

  const addToast = useDashboardStore((store) => store.addToast);

  const formMethods = useForm<InviteUser>({
    resolver: zodResolver(getInviteUserSchema(organizationMembers)),
    defaultValues: {
      inviter: {
        name: currentCompanyUser.name,
      },
      invitee: {
        email: "",
      },
      roles: [],
    },
  });

  const isLoading = isLoadingOrganizationMembers || isLoadingRoles;
  const isError = isErrorOrganizationMembers || isErrorRoles;

  const { handleSubmit, setValue } = formMethods;

  useEffect(() => {
    if (currentCompanyUser) {
      setValue("inviter.name", currentCompanyUser.name);
    }
  }, [setValue, currentCompanyUser]);

  const roleCheckboxOptions =
    roles?.map((role) => ({
      value: role.id,
      label: role.name,
      trackingId: `invite-user-role-${role.id}`,
    })) ?? [];

  return (
    <Modal
      title="Invite User"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId="invite-user-close-button"
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: "invite-user-cancel-button",
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Send Invite",
        trackingId: "invite-user-send-button",
        type: "submit",
        form: INVITE_USER_FORM_ID,
        isLoading: isLoadingInviteUser,
        loadingText: "Sending Invite",
        isDisabled: isError || isLoading,
      }}
    >
      {isError ? (
        <ContentErrorEmptyState entity="form" />
      ) : isLoading ? (
        <ContentSpinner />
      ) : (
        <FormProvider {...formMethods}>
          <form
            id={INVITE_USER_FORM_ID}
            onSubmit={(event) => {
              void handleSubmit((formData: InviteUser) => {
                inviteUser(formData, {
                  onSuccess: () => {
                    onClose();
                    addToast({
                      message:
                        "Your invitation is being processed. The invitee should receive an email shortly.",
                      trackingId: "invite-user-success-toast",
                      variant: "primary",
                      timeoutMs: 10000,
                    });
                  },
                  onError: (error) => {
                    logError({
                      message: "Error processing user invitation request",
                      error,
                    });
                    addToast({
                      message:
                        "Your invitation failed to send. Please try again.",
                      trackingId: "invite-user-error-toast",
                      variant: "danger",
                      timeoutMs: 10000,
                    });
                  },
                  onSettled: () => {
                    onClose();
                    formMethods.reset();
                  },
                });
              })(event);
            }}
          >
            <Flex gap="l" direction="column">
              <RhfTextInput<InviteUser>
                name="invitee.email"
                trackingId="invite-user-email-new"
                label="Email"
              />
              <RhfCheckboxGroup
                label="Roles"
                name="roles"
                options={roleCheckboxOptions}
              />
            </Flex>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default InviteCompanyUserModal;
