import { IrFormStatus } from "@taxbit-dashboard/rest";
import { Body, Flex, Modal } from "@taxbit-private/cosmic";
import pluralize from "pluralize";

import useDeleteFormsConfirmationModal, {
  IrFormDeleteConfirmationModalTrackingId,
} from "./useDeleteFormsConfirmationModal";

type DeleteFormsConfirmationModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const DeleteFormsConfirmationModal = ({
  onClose,
  isOpen,
}: DeleteFormsConfirmationModalProps) => {
  const { handleSubmit, selectedForms, isLoading } =
    useDeleteFormsConfirmationModal({
      onClose,
    });

  const filedFormsLen = selectedForms.filter(
    (form) => form.status === IrFormStatus.Filed
  ).length;

  return (
    <Modal
      title="Delete Forms"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        IrFormDeleteConfirmationModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: IrFormDeleteConfirmationModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Delete",
        trackingId: IrFormDeleteConfirmationModalTrackingId.SubmitButton,
        isLoading,
        loadingText: "Delete",
        variant: "button-primary",
        onClick: handleSubmit,
      }}
    >
      <Flex direction="column" gap="s">
        <Body>{`You are attempting to delete ${selectedForms.length} ${pluralize(
          "form",
          selectedForms.length
        )}. To proceed, click “Delete” below.`}</Body>
        {filedFormsLen > 0 && (
          <>
            <Body>
              Note that only forms in the &quot;generated&quot; state can be
              deleted.
            </Body>
            <ul>
              <li>
                {selectedForms.length - filedFormsLen}{" "}
                {pluralize("form", selectedForms.length - filedFormsLen)} will
                be deleted
              </li>

              <li>
                {filedFormsLen} {pluralize("form", filedFormsLen)}{" "}
                {pluralize("is", filedFormsLen)} unable to be deleted because{" "}
                {filedFormsLen > 1 ? "they" : "it"}{" "}
                {pluralize("has", filedFormsLen)} been &quot;filed&quot;
              </li>
            </ul>
          </>
        )}
      </Flex>
    </Modal>
  );
};

export default DeleteFormsConfirmationModal;
