import {
  cosmicPaginationLimitSchema,
  pageNumberSchema,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  FormItemDocumentType,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

export const formItemsTableParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
});
export type FormItemsTableParams = z.infer<typeof formItemsTableParamsSchema>;

export const dashboardFormTypeToFormItemDocumentTypeMap: Record<
  DashboardFormType,
  FormItemDocumentType | undefined
> = {
  [DashboardFormType.Irs1099B]: FormItemDocumentType.Form1099B,
  [DashboardFormType.Irs1099Misc]: FormItemDocumentType.Form1099Misc,
  [DashboardFormType.Irs1099K]: FormItemDocumentType.Form1099K,
  [DashboardFormType.Irs1099R]: FormItemDocumentType.Form1099R,
  [DashboardFormType.Irs5498]: FormItemDocumentType.Form5498,
  [DashboardFormType.RmdStatement]: FormItemDocumentType.FormRmdStatement,
  [DashboardFormType.Irs1099Nec]: FormItemDocumentType.Form1099Nec,
  [DashboardFormType.Irs1099Int]: FormItemDocumentType.Form1099Int,
  [DashboardFormType.Irs1099Div]: FormItemDocumentType.Form1099Div,
  [DashboardFormType.Irs1042S]: undefined,
  [DashboardFormType.TransactionSummary]: undefined,
  [DashboardFormType.TransactionSummaryPdf]: undefined,
  [DashboardFormType.GainLossSummary]:
    FormItemDocumentType.FormGainLossStatement,
  [DashboardFormType.UkGainLossSummary]:
    FormItemDocumentType.FormGainLossStatement,
  [DashboardFormType.GainLossPdf]: FormItemDocumentType.FormGainLossStatement,
  [DashboardFormType.Cesop]: FormItemDocumentType.Cesop,
  [DashboardFormType.Dac7]: undefined,
};

export const dashboardGainLossTypes = new Set([
  DashboardFormType.GainLossSummary,
  DashboardFormType.UkGainLossSummary,
  DashboardFormType.GainLossPdf,
]);

export const supportedFormItemTypesFromDashboardFormTypes = (() => {
  const supportedTypes = Object.entries(
    dashboardFormTypeToFormItemDocumentTypeMap
  )
    .filter(([, formItemDocumentType]) => formItemDocumentType)
    .map(([dashboardFormType]) => dashboardFormType as DashboardFormType);

  return new Set(supportedTypes);
})();

export const toUpdatedDashboardFormType = (formType: DashboardFormType) => {
  return dashboardGainLossTypes.has(formType)
    ? DashboardFormType.GainLossSummary
    : formType;
};
