import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099DivAggregateValuesSchema = z.object({
  totalOrdinaryDividends: z.number(),
  qualifiedDividends: z.number().optional(),
  totalCapitalGainDistribution: z.number().optional(),
  unrecapSection1250Gains: z.number().optional(),
  section1202Gain: z.number().optional(),
  collectiblesGain: z.number().optional(),
  section897OrdinaryDividends: z.number().optional(),
  section897CapitalGain: z.number().optional(),
  nondividendDistributions: z.number().optional(),
  federalTaxWithheld: z.number().optional(),
  section199aDividends: z.number().optional(),
  investmentExpense: z.number().optional(),
  foreignTaxPaid: z.number().optional(),
  foreignCountryOrUsPossession: z.string().optional(),
  cashLiquidationDistributions: z.number().optional(),
  noncashLiquidationDistributions: z.number().optional(),
  exemptInterestDividends: z.number().optional(),
  specifiedPrivateActivityBondInterestDividends: z.number().optional(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateTaxWithheld: z.number().optional(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099DivFormData
  ),
});

export type FormItem1099DivAggregateValues = z.infer<
  typeof formItem1099DivAggregateValuesSchema
>;
