import {
  PageLimitPaginationParams,
  UrlFilterParams,
  createSingleInstanceHookContext,
  pageLimitPaginationParamsSchema,
  useOrganizationId,
  useUrlFilterParams,
} from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import { useGetOrganizationMembersWithRoles } from "../../../../api/organizationMembersApi";

const useCompanyUserManagementData = () => {
  const { urlParams, setUrlParams } = useUrlFilterParams({
    validateParams: (
      rawUrlParams: UrlFilterParams<PageLimitPaginationParams>
    ) => pageLimitPaginationParamsSchema.parse(rawUrlParams),
  });

  const organizationId = useOrganizationId();
  const { data, ...query } = useGetOrganizationMembersWithRoles({
    organizationId,
    ...urlParams,
  });

  const organizationMembers = useMemo(() => data ?? [], [data]);

  return {
    ...query,
    organizationMembers,
    urlParams,
    setPaginationParams: setUrlParams,
    shouldDisableControls: query.isLoading || query.isPreviousData,
    totalCount: query.meta?.page?.totalCount ?? 0,
  };
};

export const {
  useContextHook: useCompanyUserManagementDataContext,
  Context: CompanyUserManagementDataContext,
  Provider: CompanyUserManagementDataProvider,
} = createSingleInstanceHookContext(
  useCompanyUserManagementData,
  "useCompanyUserManagementData"
);
