// These types are taken directly from the backend
// https://bitbucket.org/taxbit-team/enterprise-api/src/master/src/types/TaxDocumentation.ts

import { z } from "zod";

import { TinReportStatus } from "./accountRestApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum TaxDocumentationTaxIdType {
  Ssn = "SSN",
  Ein = "EIN",
  Itin = "ITIN",
  Atin = "ATIN",
  Empty = "",
  Unknown = "UNKNOWN",
}

export enum TaxDocumentType {
  W8Ben = "W-8BEN",
  W9 = "W9",
  Undetermined = "UNDETERMINED",
}

export enum TaxDocumentationStatus {
  Documented = "DOCUMENTED",
  Undocumented = "UNDOCUMENTED",
}

export enum TaxDocumentationIssueType {
  TinValidationFailure = "TIN_VALIDATION_FAILURE",
  Incomplete = "INCOMPLETE",
  NoData = "NO DATA",
  PoBox = "PO_BOX",
  UsAddress = "US_ADDRESS",
  CareOf = "CARE_OF",
}

const taxDocumentationIssueSchema = z.object({
  type: z.nativeEnum(TaxDocumentationIssueType),
});
export type TaxDocumentationIssue = z.infer<typeof taxDocumentationIssueSchema>;

export const taxDocumentationAddressSchema = z.object({
  firstLine: z.string().optional(),
  secondLine: z.string().optional(),
  thirdLine: z.string().optional(),
  city: z.string().optional(),
  stateOrProvince: z.string().optional(),
  country: z.string().optional(),
  postalCode: z.string().optional(),
});

export const taxDocumentationStatusSchema = z.object({
  documentType: z.nativeEnum(TaxDocumentType),
  tinReportStatus: z.nativeEnum(TinReportStatus).optional(),
  tinValidationDate: z.string().optional(),
  status: z.nativeEnum(TaxDocumentationStatus),
  issues: taxDocumentationIssueSchema.array().optional(),
  expirationDate: z.string().optional(),
});

export type TaxDocumentationStatusType = z.infer<
  typeof taxDocumentationStatusSchema
>;

export const getTaxDocumentationStatusSchema = getPublicApiSuccessSchema(
  taxDocumentationStatusSchema
);
export type GetTaxDocumentationStatusResponse = z.infer<
  typeof getTaxDocumentationStatusSchema
>;

export const w9DataSchema = z.object({
  name: z.string().optional(),
  nameLine2: z.string().optional(),
  address: taxDocumentationAddressSchema.optional(),
  taxId: z.string().optional(),
  taxIdType: z.nativeEnum(TaxDocumentationTaxIdType).optional(),
  signatureDate: z.string().optional(),
  documentType: z.literal(TaxDocumentType.W9),
});

export const w8BENDataSchema = z.object({
  name: z.string().optional(),
  permanentAddress: taxDocumentationAddressSchema.optional(),
  taxId: z.string().optional(),
  signatureDate: z.string().optional(),
  documentType: z.literal(TaxDocumentType.W8Ben),
});

export const undeterminedDataSchema = z.object({
  name: z.string().optional(),
  taxId: z.string().optional(),
  address: taxDocumentationAddressSchema.optional(),
  signatureDate: z.string().optional(),
  documentType: z.literal(TaxDocumentType.Undetermined),
});

export const taxDocumentationSchema = z.discriminatedUnion("documentType", [
  w9DataSchema,
  w8BENDataSchema,
  undeterminedDataSchema,
]);

export type TaxDocumentation = z.infer<typeof taxDocumentationSchema>;
export const getTaxDocumentationSchema = getPublicApiSuccessSchema(
  taxDocumentationSchema
);
export type GetTaxDocumentation = z.infer<typeof getTaxDocumentationSchema>;
