import {
  Row,
  hasDefinedValues,
  renderExcludedSpanDefaultToCosmicPlaceholder,
} from "@taxbit-dashboard/commons";
import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { byIso } from "country-code-lookup";
import { useMemo } from "react";

import {
  AccountOwnerInformation,
  accountTypeMap,
} from "../../../../../api/account/accountApiTypes";
import { taxClassificationMap } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import useFormatUsdValue from "../../../../../utils/useFormatUsdValue";
import AccountAddress from "../AccountAddress";
import CopyData from "../CopyData";
import MaskedData from "../MaskedData";

const useGeneralInformationRows = ({
  generalInformation,
}: {
  generalInformation?: AccountOwnerInformation;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();

  return useMemo(() => {
    if (!generalInformation) {
      return undefined;
    }

    const topSectionRows: Row[] = [
      {
        label: "Name",
        content: renderExcludedSpanDefaultToCosmicPlaceholder(
          generalInformation.accountOwner.pii.name
        ),
      },
    ];

    if (
      generalInformation.accountOwner.pii.address &&
      hasDefinedValues(generalInformation.accountOwner.pii.address)
    ) {
      topSectionRows.push({
        label: "Address",
        content: (
          <AccountAddress
            address={generalInformation.accountOwner.pii.address}
          />
        ),
      });
    }

    topSectionRows.push({
      label: "Account Owner ID",
      content: (
        <CopyData
          data={generalInformation.accountOwner.externalId}
          name="Account Owner ID"
        />
      ),
    });

    if (generalInformation.taxClassification) {
      topSectionRows.push({
        label: "Tax Classification",
        content:
          taxClassificationMap[generalInformation.taxClassification] ?? "Other",
      });
    }

    if (
      generalInformation.documentType === KycTaxDocumentType.Dps &&
      generalInformation.accountOwner.pii.birthCity
    ) {
      topSectionRows.push({
        label: "City of Birth",
        content: renderExcludedSpanDefaultToCosmicPlaceholder(
          generalInformation.accountOwner.pii.birthCity
        ),
      });
    }

    if (
      generalInformation.documentType === KycTaxDocumentType.Dps &&
      generalInformation.accountOwner.pii.birthCountry
    ) {
      topSectionRows.push({
        label: "Country of Birth",
        content: renderExcludedSpanDefaultToCosmicPlaceholder(
          byIso(generalInformation.accountOwner.pii.birthCountry)?.country
        ),
      });
    }

    if (generalInformation.accountOwner.pii.birthDate) {
      topSectionRows.push({
        label: "Date of Birth",
        content: formatDateTime({
          date: generalInformation.accountOwner.pii.birthDate,
        }),
      });
    }

    const secondSectionRows: Row[] = [
      {
        label: "Account ID",
        content: (
          <CopyData
            data={generalInformation.accountOwner.accounts[0].externalId}
            name="Account ID"
          />
        ),
      },
    ];

    if (generalInformation.accountOwner.accounts[0].accountType) {
      secondSectionRows.push({
        label: "Account Type",
        content:
          accountTypeMap[
            generalInformation.accountOwner.accounts[0].accountType
          ],
      });
    }

    if (generalInformation.accountOwner.accounts[0].establishmentDate) {
      secondSectionRows.push({
        label: "Establishment Date",
        content: formatDateTime({
          date: generalInformation.accountOwner.accounts[0].establishmentDate,
        }),
      });
    }

    if (generalInformation.fairMarketValue) {
      secondSectionRows.push({
        label: "Fair Market Value",
        content: formatUsdValue(generalInformation.fairMarketValue),
      });
    }

    if (generalInformation.documentType === KycTaxDocumentType.Dps) {
      if (generalInformation.accountOwner.pii.financialAccountIdMasked) {
        secondSectionRows.push({
          label: "Bank Account Number",
          content: (
            <MaskedData
              data={
                generalInformation.accountOwner.pii.financialAccountIdMasked
              }
              unmaskedData={
                generalInformation.accountOwner.pii.financialAccountId
              }
              dataLabel="Bank Account Number"
            />
          ),
        });
      }

      if (generalInformation.accountOwner.pii.financialAccountHolderName) {
        secondSectionRows.push({
          label: "Bank Account Holder",
          content: renderExcludedSpanDefaultToCosmicPlaceholder(
            generalInformation.accountOwner.pii.financialAccountHolderName
          ),
        });
      }

      if (
        generalInformation.accountOwner.pii.businessRegistrationNumberMasked
      ) {
        secondSectionRows.push({
          label: "Business Registration Number",
          content: (
            <MaskedData
              data={
                generalInformation.accountOwner.pii
                  .businessRegistrationNumberMasked
              }
              unmaskedData={
                generalInformation.accountOwner.pii.businessRegistrationNumber
              }
              dataLabel="Business Registration Number"
            />
          ),
        });
      }

      if (generalInformation.accountOwner.pii.businessRegistrationCountry) {
        secondSectionRows.push({
          label: "BRN Country",
          content: renderExcludedSpanDefaultToCosmicPlaceholder(
            byIso(
              generalInformation.accountOwner.pii.businessRegistrationCountry
            )?.country
          ),
        });
      }
    }

    if (generalInformation.payerName) {
      secondSectionRows.push({
        label: "Filer",
        content: renderExcludedSpanDefaultToCosmicPlaceholder(
          generalInformation.payerName
        ),
      });
    }

    const thirdSectionRows: Row[] = [
      {
        label: "Created Date",
        content: formatDateTime({
          date: generalInformation.accountOwner.accounts[0].dateCreated,
          format: "DateTime",
        }),
      },
      {
        label: "Modified Date",
        content: formatDateTime({
          date: generalInformation.accountOwner.accounts[0].dateModified,
          format: "DateTime",
        }),
      },
    ];

    const bottomSectionRows: Row[] = [
      {
        label: "Physical Mailing",
        content: formatBoolean(
          generalInformation.accountOwner.prefersPhysicalMail ?? false
        ),
      },
    ];

    return [
      topSectionRows,
      secondSectionRows,
      thirdSectionRows,
      bottomSectionRows,
    ];
  }, [formatDateTime, formatUsdValue, generalInformation, formatBoolean]);
};

export default useGeneralInformationRows;
