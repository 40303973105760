import { isTestEnv } from "@taxbit-dashboard/commons";
import { CosmicNumberInput } from "@taxbit-private/cosmic-localization";
import { z } from "zod";

import accountExternalIdsFilterToAccountExternalIds from "../../../utils/accountExternalIdsFilterToAccountExternalIds";

export const getAccountExternalIdsFilterMaxIdsLength = () =>
  // We limit the number of account IDs to 3 in test environment to avoid performance issues
  // Typing in 10k+ account IDs in the filter will be slow
  isTestEnv() ? 3 : 10_000;

// UUID v4 is 36 characters long
const MAX_ACCOUNT_EXTERNAL_ID_LENGTH = 36;

export const accountExternalIdsFilterSchema = z
  .string()
  .transform((val) => val.trim());

export const refineAccountExternalIdsFilterSchema = ({
  accountExternalIdsFilter,
  formatValue,
}: {
  accountExternalIdsFilter: string;
  formatValue: (value: CosmicNumberInput) => string;
}): z.IssueData[] => {
  const errors: z.IssueData[] = [];
  const accountExternalIdsArray = accountExternalIdsFilterToAccountExternalIds(
    accountExternalIdsFilter
  );
  const maxAccountExternalIdsLength = getAccountExternalIdsFilterMaxIdsLength();

  if (accountExternalIdsArray.length > maxAccountExternalIdsLength) {
    errors.push({
      code: z.ZodIssueCode.custom,
      message: `Count of Account IDs cannot exceed ${formatValue(maxAccountExternalIdsLength)}.`,
      path: ["accountExternalIdsFilter"],
    });
  }

  if (
    accountExternalIdsArray.some(
      (id) => id.length > MAX_ACCOUNT_EXTERNAL_ID_LENGTH
    )
  ) {
    errors.push({
      code: z.ZodIssueCode.custom,
      message: `Account ID must be less than or equal to ${MAX_ACCOUNT_EXTERNAL_ID_LENGTH} characters.`,
      path: ["accountExternalIdsFilter"],
    });
  }

  return errors;
};
