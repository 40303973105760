import { Table } from "@taxbit-private/cosmic";

import { useIrFormsContext } from "../context/useIrForms";

const FormsTable = () => {
  const { irFormsTableProps } = useIrFormsContext();

  return <Table {...irFormsTableProps} />;
};

export default FormsTable;
