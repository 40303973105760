import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { InventorySummaryItem } from "@taxbit-dashboard/rest/src/types/api/assetApiTypes";
import { Big } from "big.js";

export const mapInventorySummaryToAssetTableColumns = (
  inventorySummary: InventorySummaryItem[] | undefined
) => {
  return filterToDefinedValues(
    inventorySummary?.map((item) => item.totalAmount)
  )?.filter((item) => !new Big(item.amount).eq(0));
};
