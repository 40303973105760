import { useFlags } from "launchdarkly-react-client-sdk";

import {
  DashboardFeatureFlagSet,
  dashboardFeatureFlagSetSchema,
} from "./dashboardFeatureFlagSetTypes";

const useDashboardFeatureFlags = (): DashboardFeatureFlagSet => {
  const realFeatureFlags = useFlags<DashboardFeatureFlagSet>();

  return dashboardFeatureFlagSetSchema.parse(realFeatureFlags);
};

export default useDashboardFeatureFlags;
