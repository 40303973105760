import { logError, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import {
  AccountsViewType,
  DISABLED_REPORTING_PROFILE_VIEWS,
  VIEWS,
} from "./accountsView";
import { useAccountsUrlParams } from "./useAccountsUrlParams";

const getViewAndTypes = (
  viewType: AccountsViewType,
  {
    hasReportingProfile,
    hasUsAccountsPageView,
    hasGlobalAccountsPageView,
  }: {
    hasReportingProfile: boolean;
    hasUsAccountsPageView: boolean;
    hasGlobalAccountsPageView: boolean;
  }
) => {
  const viewOptions = hasReportingProfile
    ? VIEWS
    : DISABLED_REPORTING_PROFILE_VIEWS;
  const allViews = Object.values(viewOptions)
    .map((v) => v.type)
    .filter(
      (v) =>
        (hasUsAccountsPageView && v === "us") ||
        (hasGlobalAccountsPageView && v === "global")
    );

  if (allViews.length === 0) {
    logError({
      message: `Org does not have any views enabled via feature flag.`,
    });
    throw new Error("No views available");
  }
  const isValidView = allViews.includes(viewType);
  const view = viewOptions[isValidView ? viewType : allViews[0]];
  return { view, allViews };
};

export const useAccountsView = () => {
  const {
    hasReportingProfile,
    hasUsAccountsPageView,
    hasGlobalAccountsPageView,
  } = useDashboardFeatureFlags();

  const {
    urlParams: { view: viewParam },
    setView: setViewParam,
  } = useAccountsUrlParams();

  const { view, allViews } = useMemo(
    () =>
      getViewAndTypes(viewParam, {
        hasReportingProfile,
        hasUsAccountsPageView,
        hasGlobalAccountsPageView,
      }),
    [
      hasReportingProfile,
      viewParam,
      hasUsAccountsPageView,
      hasGlobalAccountsPageView,
    ]
  );

  return { view, allViews, setView: setViewParam };
};
