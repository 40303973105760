import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  TaxReportStatus,
  TaxReportType,
  GenerateTaxReport,
  UnexpectedResponseError,
} from "@taxbit-dashboard/rest";

// 10 seconds
const REFETCH_INTERVAL = 10 * 1000;

export const useGetTaxReports = (
  enabled: boolean,
  reportTypes?: TaxReportType[]
) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.TaxReports, reportTypes],
    () => restSdk.taxReports.all.get({ "filters[report_type]": reportTypes }),
    { ...createQueryMetaObject(restSdk.taxReports.all.buildPath()), enabled }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useGetTaxReportUrl = ({
  reportType,
  onError,
}: {
  reportType?: TaxReportType;
  onError: () => void;
}) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.TaxReportUrl, reportType],
    () => reportType && restSdk.taxReports.all.url.get({ reportType }),
    {
      ...createQueryMetaObject(restSdk.taxReports.all.url.buildPath()),
      enabled: false,
      onError,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateTaxReport = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    UnexpectedResponseError,
    { requestData: GenerateTaxReport },
    void
  >(async ({ requestData }) => {
    await restSdk.taxReports.generate.post({ requestData });

    void queryClient.invalidateQueries([DashboardQueryKey.TaxReportStatus]);
  });
};

export const useGetTaxReportStatuses = () => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.TaxReportStatus],
    () => restSdk.taxReports.all.status.get(),
    {
      ...createQueryMetaObject(restSdk.taxReports.all.status.buildPath()),
      refetchInterval: (data) => {
        const inProgressReports = data?.data.filter(
          ({ status }) => status === TaxReportStatus.InProgress
        );

        if (inProgressReports && inProgressReports.length > 0) {
          return REFETCH_INTERVAL;
        }
        return false;
      },
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
