import { FileAction } from "@taxbit-dashboard/rest";
import { Card, Stepper } from "@taxbit-private/cosmic";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import useFileUploaderFormSteps from "./useFileUploaderFormSteps";
import { FILE_UPLOADER_FORM_ID } from "../context/fileUploaderFormTypes";
import useFileUploaderContext from "../context/useFileUploaderContext";

type Props = {
  action: FileAction;
};

const FileUploaderFormCard: React.FC<Props> = ({ action }) => {
  const { formMethods, onSubmitForm } = useFileUploaderContext(action);

  const steps = useFileUploaderFormSteps({ action });

  return (
    <Card title="Upload Files">
      <FormProvider {...formMethods}>
        <Form id={FILE_UPLOADER_FORM_ID} onSubmit={onSubmitForm}>
          <Stepper steps={steps} />
        </Form>
      </FormProvider>
    </Card>
  );
};

const Form = styled.form(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing.contentPadding}
`
);

export default FileUploaderFormCard;
