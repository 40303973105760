import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { Big } from "big.js";
/**
 * Form Data table helper that formats an asset quantity without trailing zeroes.
 * Specific to the Form Data table that displays CSV data provided by the clients.
 */
const formatFormDataAssetQuantity = (assetAmount?: string) => {
  return assetAmount
    ? new Big(assetAmount).toFixed()
    : COSMIC_VALUE_PLACEHOLDER;
};

export default formatFormDataAssetQuantity;
