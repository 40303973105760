export enum EligibilityDocumentTypeDataSource {
  Irs1099bIngested = "IRS_1099_B_INGESTED",
  Irs1099bFormData = "IRS_1099_B_FORM_DATA",
  Irs1099DivFormData = "IRS_1099_DIV_FORM_DATA",
  Irs1099IntIngested = "IRS_1099_INT_INGESTED",
  Irs1099kIngested = "IRS_1099_K_INGESTED",
  Irs1099MiscIngested = "IRS_1099_MISC_INGESTED",
  Irs1099NecIngested = "IRS_1099_NEC_INGESTED",
  Irs1099IntFormData = "IRS_1099_INT_FORM_DATA",
  Irs1099kFormData = "IRS_1099_K_FORM_DATA",
  Irs1099MiscFormData = "IRS_1099_MISC_FORM_DATA",
  Irs1099NecFormData = "IRS_1099_NEC_FORM_DATA",
  Irs1099rFormData = "IRS_1099_R_FORM_DATA",
  Irs5498FormData = "IRS_5498_FORM_DATA",
  Irs5498MayFormData = "IRS_5498_MAY_FORM_DATA",
  GainLossSummaryIngested = "GENERIC_GAIN_LOSS_INGESTED",
  GainLossSummaryFormData = "GENERIC_GAIN_LOSS_FORM_DATA",
  GainLossPdfIngested = "GAIN_LOSS_PDF_INGESTED",
  GainLossPdfFormData = "GAIN_LOSS_PDF_FORM_DATA",
}
