import { Button, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useState } from "react";
import styled from "styled-components";

import { AccountsUrlParams } from "../../accountsUrlParams";
import { useAccountsUrlParams } from "../../useAccountsUrlParams";

export enum AccountsTableSearchBarTrackingId {
  SearchBar = "accounts-table-search-bar",
  SearchButton = "accounts-table-search-button",
}

type TextSearchParams = Pick<AccountsUrlParams, "name" | "email" | "accountId">;

type SearchParamOption = {
  key: keyof TextSearchParams;
  label: string;
};

type AccountsTableSearchBarProps = {
  isDisabled: boolean;
};

export const TEXT_SEARCH_PARAM_LABELS: Record<keyof TextSearchParams, string> =
  {
    name: "Name",
    email: "Email",
    accountId: "Account ID",
  };

const SEARCH_PARAM_OPTIONS: SearchParamOption[] = [
  {
    key: "accountId",
    label: TEXT_SEARCH_PARAM_LABELS.accountId,
  },
  { key: "name", label: TEXT_SEARCH_PARAM_LABELS.name },
  {
    key: "email",
    label: TEXT_SEARCH_PARAM_LABELS.email,
  },
];

const AccountsTableSearchBar: React.FC<AccountsTableSearchBarProps> = ({
  isDisabled,
}) => {
  const { setFilters } = useAccountsUrlParams();
  const [searchKeyOption, setSearchKeyOption] = useState<SearchParamOption>(
    SEARCH_PARAM_OPTIONS[0]
  );

  const [searchValue, setSearchValue] = useState("");

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFilters({
      [searchKeyOption.key]: searchValue.trim(),
    });
    setSearchValue("");
  };

  const isSearchTooLong = searchValue.length > 100;

  const isSubmitDisabled = !searchValue || isSearchTooLong;

  const tooltipProps = isSearchTooLong
    ? { content: "Search cannot exceed 100 characters." }
    : undefined;

  return (
    <FlexForm onSubmit={handleSearchSubmit}>
      <FullWidthScopedSearchBar
        trackingId={AccountsTableSearchBarTrackingId.SearchBar}
        label="Search by account name, email, or ID"
        isLabelHidden={true}
        dropdownProps={{
          options: SEARCH_PARAM_OPTIONS,
          onChange: setSearchKeyOption,
          value: searchKeyOption,
          getOptionKey: ({ key }: SearchParamOption) => key,
          getOptionLabel: ({ label }: SearchParamOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={isDisabled}
        size="small"
      />
      <Button
        trackingId={AccountsTableSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        size="small"
        isDisabled={isSubmitDisabled}
        tooltipProps={tooltipProps}
      />
    </FlexForm>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.m};
  align-items: center;
  flex-grow: 1;
`
);

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchParamOption>)`
  flex-grow: 1;
`;

export default AccountsTableSearchBar;
