import {
  useSavedScrollPosition,
  DashboardPrimaryTemplate,
} from "@taxbit-dashboard/commons";
import { Box } from "@taxbit-private/cosmic";

import AccountsContent from "./AccountsContent";
import DownloadAccountsCsvButton from "./table/DownloadAccountsCsvButton";

const Accounts: React.FC = () => {
  useSavedScrollPosition({ key: "accounts" });

  return (
    <DashboardPrimaryTemplate
      title="Accounts"
      utilityElement={<DownloadAccountsCsvButton />}
    >
      <Box>
        <AccountsContent />
      </Box>
    </DashboardPrimaryTemplate>
  );
};

export default Accounts;
