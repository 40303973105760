import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const form1099IntAggregateValuesSchema = z.object({
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099IntIngested
  ),
  taxYearTotalTransactionCount: z.number(),
  taxYearTotalFeesQuantity: z.number(),
  taxYearTotalReceivedQuantity: z.number(),
  taxYearTotalAdjustedReceivedQuantity: z.number(),
  taxYearTotalSentQuantity: z.number(),
});

export type Form1099IntAggregateValues = z.infer<
  typeof form1099IntAggregateValuesSchema
>;
