import React, { PropsWithChildren, createContext, useContext } from "react";

import useIngestFileUploader from "./useIngestFileUploader";

type IngestFileUploaderContext =
  | ReturnType<typeof useIngestFileUploader>
  | undefined;

const IngestFileUploaderContext =
  createContext<IngestFileUploaderContext>(undefined);

export const useIngestFileUploaderContext = () => {
  const context = useContext(IngestFileUploaderContext);
  if (!context) {
    throw new Error(
      "useIngestFileUploaderContext must be used within a IngestFileUploaderProvider"
    );
  }
  return context;
};

export const IngestFileUploaderProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const ingestFileUploader = useIngestFileUploader();

  return (
    <IngestFileUploaderContext.Provider value={ingestFileUploader}>
      {children}
    </IngestFileUploaderContext.Provider>
  );
};
