import { TransferOutLotItem } from "@taxbit-dashboard/rest";
import { Big } from "big.js";

export default (transferOutLotItems: TransferOutLotItem[]) => {
  let hasMissingCostBasis = false;
  let costBasisCurrency: string | undefined;

  const totalCostBasis = transferOutLotItems.reduce(
    (acc: Big | undefined, lot) => {
      // If we have missing cost basis, return a placeholder
      if (hasMissingCostBasis) {
        return undefined;
      }

      const costBasisAmount = lot.cost ? new Big(lot.cost.amount) : undefined;

      // If the costBasisAmount is undefined, we have missing cost basis
      if (costBasisAmount === undefined) {
        hasMissingCostBasis = true;
        return undefined;
      }

      // If the cost basis currency is not the same as the first defined lot, we have a mismatch
      // Return undefined to prevent summing of different currencies
      if (
        costBasisCurrency &&
        lot.cost &&
        lot.cost.asset.id !== costBasisCurrency
      ) {
        hasMissingCostBasis = true;
        return undefined;
      }

      // If costBasisCurrency is undefined, set it to the first defined lot's currency
      if (costBasisCurrency === undefined && lot.cost) {
        costBasisCurrency = lot.cost.asset.id;
      }

      // If this is our first defined lot, start the sum
      if (acc === undefined) {
        return costBasisAmount;
      }

      // Otherwise, keep summing
      return acc.add(costBasisAmount);
    },
    undefined
  );

  return totalCostBasis && transferOutLotItems[0].cost
    ? {
        amount: totalCostBasis.toString(),
        asset: transferOutLotItems[0].cost.asset,
      }
    : undefined;
};
