import { getPageLimitApiParams, mapObject } from "@taxbit-dashboard/commons";
import { GetIrFormsParams } from "@taxbit-dashboard/rest";

import { FormsTableParams } from "./formsApiTypes";
import { IrFormTypeDateParams } from "../irApiTypes";

export type FormsParams = FormsTableParams & IrFormTypeDateParams;

const getFormsParams = ({
  limit,
  page,
  formDate,
  formType,
  sort,
  statuses,
  types,
  startDate,
  endDate,
  ...searches
}: FormsParams): GetIrFormsParams => {
  const searchParams = mapObject(searches, (key, value) => [
    `filters[${key}]`,
    value,
  ]);

  return {
    "sort": sort,
    "filters[document_date]": formDate,
    "filters[document_type]": formType,
    "filters[status]": statuses,
    "filters[type]": types,
    "filters[date_generated][$gte]": startDate,
    "filters[date_generated][$lte]": endDate,
    ...getPageLimitApiParams({ page, limit }),
    ...searchParams,
  };
};

export default getFormsParams;
