import { MakeGenerics } from "@tanstack/react-location";

import {
  FormItemsTableParams,
  formItemsTableParamsSchema,
} from "../../../api/form-items/formItemsApiTypes";

export type FormItemsTableGenerics = MakeGenerics<{
  Search: {
    // All keys become strings or string arrays because
    // we use qs to deserialize url query params.
    [key in keyof FormItemsTableParams]?: string | string[];
  };
}>;

/**
 * URL params can be manually edited or out of date according
 * to the current data if from a saved link. To simplify the number
 * of edge cases we need to dive into, we use a zod schema that coerces
 * the given params into a set that could be used to fetch data, i.e. it
 * strips out incorrect optional params and defaults required ones.
 */
const validateFormItemsParams = (
  rawParams: FormItemsTableGenerics["Search"]
): FormItemsTableParams => {
  return formItemsTableParamsSchema.parse(rawParams);
};

export default validateFormItemsParams;
