import { isDefined, renderExcludedSpan, Row } from "@taxbit-dashboard/commons";
import {
  KycTinVerificationStatus,
  IrsTinMatchValidationResult,
} from "@taxbit-dashboard/rest";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { byIso } from "country-code-lookup";
import { useMemo } from "react";

import { TinInformationResult } from "./useGetTinInformation";
import InvalidCountry from "../InvalidCountry";
import MaskedData from "../MaskedData";
import TinBadge from "../TinBadge";

const useTinInformationRows = ({
  tinInformation,
}: {
  tinInformation?: TinInformationResult[];
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!tinInformation) {
      return undefined;
    }

    const tinStatusesWithoutTinVerificationDate = new Set([
      IrsTinMatchValidationResult.Foreign,
      KycTinVerificationStatus.ForeignIndicia,
      IrsTinMatchValidationResult.InvalidData,
      KycTinVerificationStatus.InvalidData,
    ]);

    return tinInformation.reduce<Row[][]>((acc, tinInfo) => {
      const isoCountryName = (() => {
        try {
          return tinInfo.country && byIso(tinInfo.country)?.country;
        } catch {
          return undefined;
        }
      })();

      if (
        !tinInfo.isDac7 &&
        (tinInfo.maskedTin || tinInfo.unmaskedTin || tinInfo.country)
      ) {
        return [
          ...acc,
          [
            {
              label: "TIN",
              content: (
                <MaskedData
                  data={tinInfo.maskedTin}
                  unmaskedData={tinInfo.unmaskedTin}
                  dataLabel="TIN"
                />
              ),
              id: tinInfo.id,
            },
            {
              label: "Country",
              content: isDefined(isoCountryName) ? (
                renderExcludedSpan(isoCountryName)
              ) : (
                <InvalidCountry country={tinInfo.country} />
              ),
            },
            ...(tinInfo.tinVerificationStatus || tinInfo.country === "US"
              ? [
                  {
                    label: "US TIN Status",
                    content: (
                      <TinBadge
                        tinValidationStatus={tinInfo.tinVerificationStatus}
                      />
                    ),
                  },
                  {
                    label: "Last Verified",
                    content:
                      isDefined(tinInfo.tinVerificationStatus) &&
                      !tinStatusesWithoutTinVerificationDate.has(
                        tinInfo.tinVerificationStatus
                      )
                        ? formatDateTime({
                            date: tinInfo.tinVerificationDate,
                          })
                        : COSMIC_VALUE_PLACEHOLDER,
                  },
                ]
              : []),
          ],
        ];
      } else if (tinInfo.isDac7) {
        return [
          ...acc,
          [
            {
              label: "TIN",
              content: tinInfo.tinNotRequired ? (
                "Not Required"
              ) : (
                <MaskedData
                  data={tinInfo.tin}
                  unmaskedData={tinInfo.unmaskedTin}
                  dataLabel="TIN"
                />
              ),
              id: tinInfo.id,
            },
            {
              label: "Country",
              content: isDefined(isoCountryName) ? (
                renderExcludedSpan(isoCountryName)
              ) : (
                <InvalidCountry country={tinInfo.country} />
              ),
            },
          ],
        ];
      }

      return acc;
    }, []);
  }, [formatDateTime, tinInformation]);
};

export default useTinInformationRows;
