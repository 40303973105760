import {
  ToastConfig,
  irFormTypeToDisplayNameMap,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";

import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import { useIrSummaryDataContext } from "../context/IrSummaryContext";

export enum RecalculateEligibilityTrackingIds {
  SuccessToast = "ir-recalculate-eligibility-success-toast",
  ErrorToast = "ir-recalculate-eligibility-error-toast",
}

export const recalculateEligibilitySuccessToastContent = (
  formDate: FourDigitYear,
  formType: DashboardFormType
): ToastConfig => {
  return {
    message: `Recalculating eligibility analysis for ${formDate} ${irFormTypeToDisplayNameMap[formType]}. This may take a few minutes.`,
    trackingId: RecalculateEligibilityTrackingIds.SuccessToast,
    timeoutMs: TOAST_TIMEOUT,
  };
};

export const recalculateEligibilityErrorToastContent: ToastConfig = {
  message:
    "There was an error recalculating eligibility analysis. Please try again.",
  variant: "danger",
  trackingId: RecalculateEligibilityTrackingIds.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useRecalculateEligibility = () => {
  const { recalculateEligibility, currentYear, currentForm } =
    useIrSummaryDataContext();
  const addToast = useDashboardStore((state) => state.addToast);

  return {
    onRecalculateEligibility: () => {
      recalculateEligibility(undefined, {
        onSuccess: () => {
          addToast(
            recalculateEligibilitySuccessToastContent(currentYear, currentForm)
          );
        },
        onError: () => {
          addToast(recalculateEligibilityErrorToastContent);
        },
      });
    },
  };
};

export default useRecalculateEligibility;
