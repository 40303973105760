import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
} from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useFormatUsdValue from "../../../../utils/useFormatUsdValue";

const useForm1099REligibilityTableColumns = () => {
  const formatUsdValue = useFormatUsdValue();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        label: "Distribution Code",
        id: "formSpecificFieldsDistributionCode",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099rFormData
          ) {
            if (formSpecificFields.taxYearDistributionsCodes.length === 0) {
              return COSMIC_VALUE_PLACEHOLDER;
            } else if (
              formSpecificFields.taxYearDistributionsCodes.length > 1
            ) {
              return "Multiple";
            } else {
              return formSpecificFields.taxYearDistributionsCodes[0];
            }
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
      {
        key: "formSpecificFields",
        label: "Amount",
        id: "formSpecificFieldsDistributionAmount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099rFormData
          ) {
            if (formSpecificFields.taxYearGrossDistribution.length === 0) {
              return COSMIC_VALUE_PLACEHOLDER;
            } else if (formSpecificFields.taxYearGrossDistribution.length > 1) {
              return "See Export";
            } else {
              return formatUsdValue(
                formSpecificFields.taxYearGrossDistribution[0]
              );
            }
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
    ],
    [formatUsdValue]
  );
};

export default useForm1099REligibilityTableColumns;
