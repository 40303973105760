import { CenteredDiv } from "@taxbit-dashboard/commons";
import { Card, EmptyState, Flex, Timeline } from "@taxbit-private/cosmic";

import useDeadlineSlots from "./useDeadlineSlots";

const Deadlines: React.FC = () => {
  const slots = useDeadlineSlots();

  return (
    <Card title="Deadlines">
      {slots.length === 0 ? (
        <CenteredDiv>
          <EmptyState
            title="Not Applicable"
            subtitleElement="There are no deadlines for this form type."
            avatarProps={{
              iconName: "file-text",
              variant: "secondary",
            }}
          />
        </CenteredDiv>
      ) : (
        <Flex padding="contentPadding">
          <Timeline slots={slots} />
        </Flex>
      )}
    </Card>
  );
};

export default Deadlines;
