import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  createQueryMetaObject,
  filterToDefinedValues,
} from "@taxbit-dashboard/commons";
import { AccountId, TransactionId } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

const getGainsQueryKeys = (
  accountId?: AccountId,
  transactionId?: TransactionId,
  limit?: number
) =>
  [
    {
      key: DashboardQueryKey.Gains,
      accountId,
      transactionId,
      gainsLimit: limit,
    },
  ] as const;

export const useGetGains = ({
  accountId: accountUuid,
  transactionId: transactionUuid,
  limit,
}: {
  accountId: AccountId;
  transactionId?: TransactionId;
  limit: number;
}) => {
  const restSdk = useTaxBitRest();

  const fetchGains = ({
    queryKey: [{ accountId, transactionId, gainsLimit }],
    pageParam,
  }: QueryFunctionContext<ReturnType<typeof getGainsQueryKeys>, string>) => {
    if (accountId && transactionId) {
      return restSdk.gains.get({
        accountId,
        transactionId,
        next: pageParam,
        limit: gainsLimit,
      });
    } else {
      return undefined;
    }
  };

  const query = useInfiniteQuery(
    getGainsQueryKeys(accountUuid, transactionUuid, limit),
    fetchGains,
    {
      ...createQueryMetaObject(
        restSdk.gains.buildPath(accountUuid?.toString())
      ),
      enabled: !!accountUuid && !!transactionUuid,
      getNextPageParam: (lastPage) => lastPage?.meta?.page?.next,
    }
  );

  const data = useMemo(
    () =>
      filterToDefinedValues(query.data?.pages)?.flatMap((page, pageIndex) =>
        page.data.map((item, itemIndex) => ({
          ...item,
          key: `${pageIndex}-${itemIndex}`,
        }))
      ),
    [query]
  );

  return { ...query, data };
};
