import {
  UmcApiReport,
  UmcApiReportSubtype,
  UmcApiReportType,
} from "@taxbit-dashboard/rest";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import { UmcReport, UmcReportDisplayType } from "../types/umcReportTypes";

const TAX_DOCUMENTATION_NAME = "Tax Documentation";
const TIN_VERIFICATION_NAME = "TIN Verification";
const ACTION_NEEDED_NAME = "Actions Needed";
const FULL_DESCRIPTION = "for all accounts";
const ACTION_NEEDED_DESCRIPTION = "for accounts needing remediation";
const UNKNOWN_NAME = "Other";

const mapKeyToFileName = (key: string) => {
  // key from S3 bucket will have a folder structure
  // "/custom/custom.csv"
  const splitSlash = key.split("/");
  const splitFileExt = splitSlash.at(-1)?.split(".");
  return splitFileExt?.[0] ?? "";
};

const useMapApiReport = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  return useCallback(
    (report: UmcApiReport): UmcReport => {
      const defaultReportValues = {
        date: formatDateTime({
          date: report.date,
          format: "DateTime",
        }),
        key: report.key,
      };

      if (report.type === UmcApiReportType.TaxDocumentationStatus) {
        switch (report.subtype) {
          case UmcApiReportSubtype.Full: {
            return {
              ...defaultReportValues,
              type: UmcReportDisplayType.Accounts,
              name: `${TAX_DOCUMENTATION_NAME} Status`,
              description: `${TAX_DOCUMENTATION_NAME} Status ${FULL_DESCRIPTION}`,
            };
          }
          case UmcApiReportSubtype.ActionNeeded: {
            return {
              ...defaultReportValues,
              type: UmcReportDisplayType.Accounts,
              name: `${ACTION_NEEDED_NAME} ${TAX_DOCUMENTATION_NAME}`,
              description: `${TAX_DOCUMENTATION_NAME} Status ${ACTION_NEEDED_DESCRIPTION}`,
            };
          }
          default: {
            return {
              ...defaultReportValues,
              type: UmcReportDisplayType.Accounts,
              name: `${UNKNOWN_NAME} ${TAX_DOCUMENTATION_NAME}`,
              description: COSMIC_VALUE_PLACEHOLDER,
            };
          }
        }
      }

      if (report.type === UmcApiReportType.TinVerification) {
        switch (report.subtype) {
          case UmcApiReportSubtype.Full: {
            return {
              ...defaultReportValues,
              type: UmcReportDisplayType.Accounts,
              name: `${TIN_VERIFICATION_NAME} Status`,
              description: `${TIN_VERIFICATION_NAME} Status ${FULL_DESCRIPTION}`,
            };
          }
          case UmcApiReportSubtype.ActionNeeded: {
            return {
              ...defaultReportValues,
              type: UmcReportDisplayType.Accounts,
              name: `${ACTION_NEEDED_NAME} ${TIN_VERIFICATION_NAME}`,
              description: `${TIN_VERIFICATION_NAME} Status ${ACTION_NEEDED_DESCRIPTION}`,
            };
          }
          default: {
            return {
              ...defaultReportValues,
              type: UmcReportDisplayType.Accounts,
              name: `${UNKNOWN_NAME} ${TIN_VERIFICATION_NAME}`,
              description: COSMIC_VALUE_PLACEHOLDER,
            };
          }
        }
      }

      return {
        ...defaultReportValues,
        type: UmcReportDisplayType.Custom,
        description: COSMIC_VALUE_PLACEHOLDER,
        name: mapKeyToFileName(report.key),
      };
    },
    [formatDateTime]
  );
};

export default useMapApiReport;
