import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";

import GeneralInformation from "./general-information/GeneralInformation";
import TinInformationCards from "./tin-information-cards/TinInformationCards";
import VatInformation from "./vat-information/VatInformation";

export enum DetailsTabTrackingId {
  DetailsTabSpinner = "details-tab-spinner",
}

const DetailsTab = () => {
  const { hasDac7AccountAccess } = useDashboardFeatureFlags();
  return (
    <>
      <GeneralInformation />
      <TinInformationCards />
      {hasDac7AccountAccess && <VatInformation />}
    </>
  );
};

export default DetailsTab;
