import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { RhfTextArea } from "@taxbit-private/cosmic-react-hook-form";

import { FormsFiltersFormFields } from "./useFormsFiltersFormFieldSchema";
import { getAccountExternalIdsFilterMaxIdsLength } from "../../shared/accountExternalIdsFilterSchema";

export enum FormsAccountIdsFilterTrackingId {
  TextArea = "ir-forms-account-ids-filter-textarea",
  HelpIcon = "ir-forms-account-ids-filter-help-icon",
}
const FormsAccountIdsFilter = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <RhfTextArea<Pick<FormsFiltersFormFields, "accountExternalIdsFilter">>
      name="accountExternalIdsFilter"
      label="Filter by Account ID"
      trackingId={FormsAccountIdsFilterTrackingId.TextArea}
      descriptionText="Enter account IDs on separate lines"
      helpIconProps={{
        tooltipProps: {
          content: `Manually enter up to ${formatWholeQuantity(getAccountExternalIdsFilterMaxIdsLength())} account IDs, with each ID on a separate line.`,
        },
        trackingId: FormsAccountIdsFilterTrackingId.HelpIcon,
      }}
    />
  );
};

export default FormsAccountIdsFilter;
