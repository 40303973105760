import { Form1099DivLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItem1099DivColumns = () => {
  const { formatQuantity } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form1099DivLineItem> => [
      {
        key: "totalOrdinaryDividends",
        label: "Total Ordinary Dividends",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (totalOrdinaryDividends) =>
          formatQuantity(totalOrdinaryDividends),
      },
      {
        key: "qualifiedDividends",
        label: "Qualified Dividends",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (qualifiedDividends) => formatQuantity(qualifiedDividends),
      },
      {
        key: "totalCapitalGainDistribution",
        label: "Total Capital Gain Distribution",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (totalCapitalGainDistribution) =>
          formatQuantity(totalCapitalGainDistribution),
      },
      {
        key: "unrecapSection1250Gains",
        label: "Unrecaptured Section 1250 Gain",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (unrecapSection1250Gains) =>
          formatQuantity(unrecapSection1250Gains),
      },
      {
        key: "section1202Gain",
        label: "Section 1202 Gain",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (section1202Gain) => formatQuantity(section1202Gain),
      },
      {
        key: "collectiblesGain",
        label: "Collectibles Gain",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (collectiblesGain) => formatQuantity(collectiblesGain),
      },
      {
        key: "section897OrdinaryDividends",
        label: "Section 897 Ordinary Dividends",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (section897OrdinaryDividends) =>
          formatQuantity(section897OrdinaryDividends),
      },
      {
        key: "section897CapitalGain",
        label: "Section 897 Capital Gain",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (section897CapitalGain) =>
          formatQuantity(section897CapitalGain),
      },
      {
        key: "nondividendDistributions",
        label: "Nondividend Distributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (nondividendDistributions) =>
          formatQuantity(nondividendDistributions),
      },
      {
        key: "federalTaxWithheld",
        label: "Federal Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "section199ADividends",
        label: "Section 199A Dividends",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (section199ADividends) =>
          formatQuantity(section199ADividends),
      },
      {
        key: "investmentExpense",
        label: "Investment Expense",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (investmentExpense) => formatQuantity(investmentExpense),
      },
      {
        key: "foreignTaxPaid",
        label: "Foreign Tax Paid",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (foreignTaxPaid) => formatQuantity(foreignTaxPaid),
      },
      {
        key: "foreignCountryOrUsPossession",
        label: "Foreign Country Or US Possession",
        isContentFullWidth: true,
      },
      {
        key: "cashLiquidationDistributions",
        label: "Cash Liquidation Distributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (cashLiquidationDistributions) =>
          formatQuantity(cashLiquidationDistributions),
      },
      {
        key: "noncashLiquidationDistributions",
        label: "Noncash Liquidation Distributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (noncashLiquidationDistributions) =>
          formatQuantity(noncashLiquidationDistributions),
      },
      {
        key: "exemptInterestDividends",
        label: "Exempt Interest Dividends",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (exemptInterestDividends) =>
          formatQuantity(exemptInterestDividends),
      },
      {
        key: "specifiedPrivateActivityBondInterestDividends",
        label: "Specified Private Activity Bond Interest Dividends",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (specifiedPrivateActivityBondInterestDividends) =>
          formatQuantity(specifiedPrivateActivityBondInterestDividends),
      },
      {
        key: "state",
        label: "State",
        isContentFullWidth: true,
      },
      {
        key: "payersStateNumber",
        label: "Payers State Number",
        isContentFullWidth: true,
      },
      {
        key: "stateTaxWithheld",
        label: "State Tax Withheld",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (
          stateTaxWithheld: Form1099DivLineItem["stateTaxWithheld"]
        ) => formatQuantity(stateTaxWithheld),
      },
    ],
    [formatQuantity]
  );
};

export default useFormItem1099DivColumns;
