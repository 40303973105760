const accountExternalIdsFilterToAccountExternalIds = (
  filter: string
): string[] => [
  ...new Set(
    filter
      .split("\n")
      .map((id) => id.trim())
      .filter(Boolean)
  ),
];

export default accountExternalIdsFilterToAccountExternalIds;
