import {
  GetAccountsSearchKey,
  getAccountsSearchKeySchema,
} from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import { accountsSearchKeyLabelMap } from "../../../../../api/accounts/accountsApiTypes";
import useAccountsTableData from "../../useAccountsTableData";

const useSearchFilterChips = () => {
  const { urlParams, setFilterParams } = useAccountsTableData();

  const getSearchChip = useCallback(
    (key: GetAccountsSearchKey, value: string) => {
      if (value) {
        const label = `${accountsSearchKeyLabelMap[key]}: "${value}"`;
        return {
          label,
          removeButtonProps: {
            "onClick": () =>
              setFilterParams((draft) => {
                draft[key] = undefined;
              }),
            "aria-label": `Remove filter for ${label}`,
          },
          trackingId: `account-table-chip-search-${key}`,
        };
      } else {
        return undefined;
      }
    },
    [setFilterParams]
  );

  const searchChipEntries = Object.entries(urlParams).filter(
    (entry): entry is [GetAccountsSearchKey, string] =>
      getAccountsSearchKeySchema.options.includes(
        entry[0] as GetAccountsSearchKey
      ) && !!entry[1]
  );

  return searchChipEntries.map(([key, value]) => getSearchChip(key, value));
};

export default useSearchFilterChips;
