import {
  AssetAmount,
  Transaction,
  TransactionType,
} from "@taxbit-dashboard/rest";
import { Big } from "big.js";

import calculateAssetFiatValue from "./calculateAssetFiatValue";
import calculateFeeFiatValue from "./calculateFeeFiatValue";

const calculateBuyTransactionCostBasis = (transaction: Transaction) => {
  const transactionInAssetCode =
    transaction.received?.[0]?.assetAmount.asset.code;
  const transactionOutFiatValue = calculateTransactionOutFiatValue(transaction);
  const feeFiatValue = calculateFeeFiatValue(transaction.fees);
  const feeAssetCode = transaction.fees?.[0]?.assetAmount.asset.code;

  const shouldAddFeeToTransaction =
    transactionInAssetCode &&
    feeAssetCode &&
    feeFiatValue &&
    transactionOutFiatValue &&
    feeAssetCode !== transactionInAssetCode;

  if (shouldAddFeeToTransaction) {
    return {
      asset: transactionOutFiatValue.asset,
      amount: new Big(transactionOutFiatValue.amount)
        .add(feeFiatValue.amount)
        .toString(),
    };
  }

  return transactionOutFiatValue;
};

const calculateIncomeTransactionCostBasis = (transaction: Transaction) => {
  const transactionInAssetCode =
    transaction.received?.[0]?.assetAmount.asset.code;
  const transactionInFiatValue = calculateTransactionInFiatValue(transaction);
  const feeFiatValue = calculateFeeFiatValue(transaction.fees);
  const feeAssetCode = transaction.fees?.[0]?.assetAmount.asset.code;

  const shouldAddFeeToTransaction =
    feeAssetCode &&
    feeFiatValue &&
    transactionInFiatValue &&
    feeAssetCode !== transactionInAssetCode;

  if (shouldAddFeeToTransaction) {
    return {
      asset: transactionInFiatValue.asset,
      amount: new Big(transactionInFiatValue.amount)
        .add(feeFiatValue.amount)
        .toString(),
    };
  }

  return transactionInFiatValue;
};

const calculateRewardTransactionCostBasis = (transaction: Transaction) => {
  const transactionInAssetCode =
    transaction.received?.[0]?.assetAmount.asset.code;
  const feeFiatValue = calculateFeeFiatValue(transaction.fees);
  const feeAssetCode = transaction.fees?.[0]?.assetAmount.asset.code;

  if (
    transactionInAssetCode &&
    feeAssetCode &&
    feeAssetCode !== transactionInAssetCode
  ) {
    return feeFiatValue;
  }

  const asset =
    transaction.received?.[0]?.rates?.[0]?.asset ??
    transaction.fees?.[0]?.rates?.[0]?.asset;

  if (!asset) {
    return undefined;
  }

  return {
    amount: "0.00",
    asset,
  };
};

const calculateTransactionInFiatValue = (
  transaction: Transaction
): AssetAmount | undefined => {
  const assetIn = transaction.received?.[0];
  return calculateAssetFiatValue(assetIn);
};

const calculateTransactionOutFiatValue = (
  transaction: Transaction
): AssetAmount | undefined => {
  const assetOut = transaction.sent?.[0];
  return calculateAssetFiatValue(assetOut);
};

// calculating cost basis based on this doc
// https://docs.google.com/spreadsheets/d/1yymzLxlFkOJqGSL0EyDLgkROi8SGU9sBLzFggmwuHf4/edit#gid=0
const calculateTransactionCostBasis = (
  transaction: Transaction
): AssetAmount | undefined => {
  const transactionType = transaction.type;

  if (transactionType === TransactionType.Buy) {
    return calculateBuyTransactionCostBasis(transaction);
  }

  if (transactionType === TransactionType.Income) {
    return calculateIncomeTransactionCostBasis(transaction);
  }

  if (transactionType === TransactionType.Reward) {
    return calculateRewardTransactionCostBasis(transaction);
  }

  return undefined;
};

export default calculateTransactionCostBasis;
