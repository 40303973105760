import { DashboardFormType } from "@taxbit-dashboard/rest";
import { formatDateTime } from "@taxbit-private/cosmic-localization";
import { FourDigitYear, fourDigitYearSchema } from "@taxbit-private/datetime";
import {
  addDays,
  isWeekend,
  nextMonday,
  isMonday,
  previousMonday,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

type FormDeadline = {
  recipientCopyDate: Date;
  recipientCopyExtendedDate?: Date;
  eFileDate?: Date;
  eFileExtendedDate?: Date;
};

/**
 * Returns UTC date time adjusted to Eastern Time.
 */
const toEtAdjustedUtcDateTime = (date: Date) => {
  return zonedTimeToUtc(date, "America/New_York");
};

const extendTo30Days = (date: Date): Date => addDays(date, 30);

// We can use any formatting locale here as we are just formatting to simplify
// checks for the same date without worrying about timezoning.
const formatForDateCheck = (date: Date) =>
  formatDateTime({ date, locale: "en-US" });

// IRS Deadlines start on 1/31 and the latest deadline is 5/31 + 30 days
// The only holiday that can affect deadlines is Memorial Day
// IRS doesn't recognize Presidents' Day as a holiday (details https://taxbit.atlassian.net/browse/TAX-41079)
// We can add more holidays if needed
const getIrsDeadlinesUsFederalHolidaysForYear = (year: number) => {
  const lastDayOfMay = new Date(year, 4, 31);
  const memorialDay = isMonday(lastDayOfMay)
    ? lastDayOfMay
    : previousMonday(lastDayOfMay);

  return new Set([formatForDateCheck(memorialDay)]);
};

/**
 * Helper method to check if the current date is a holiday or weekend.
 * If it is, we adjust the date to the next weekday.
 */
export const adjustDateToFallOnWeekday = (date: Date): Date => {
  const year = date.getFullYear();
  const holidays = getIrsDeadlinesUsFederalHolidaysForYear(year);

  if (holidays.has(formatForDateCheck(date))) {
    return adjustDateToFallOnWeekday(addDays(date, 1));
  }

  if (isWeekend(date)) {
    return adjustDateToFallOnWeekday(nextMonday(date));
  }

  return date;
};

/**
 * Returns the IRS deadlines for a given form type and year.
 * Deadlines are provided by SME team and should not be changed without their approval.
 * https://docs.google.com/presentation/d/1GQ_IG62flBoDEpnKx-9mHJ-uAgYLzJz_YzYidAaAahs/edit#slide=id.g1387d14dca0_0_252
 */
const getFormDeadlinesByFormTypeDate = (
  formYear: FourDigitYear,
  formType: DashboardFormType
): FormDeadline | undefined => {
  const adjustedYear = fourDigitYearSchema.parse(formYear + 1);

  const jan31Deadline = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(new Date(adjustedYear, 0, 31))
  );
  const jan31DeadlineExtended = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(extendTo30Days(new Date(adjustedYear, 0, 31)))
  );

  const feb15Deadline = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(new Date(adjustedYear, 1, 15))
  );
  const feb15DeadlineExtended = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(extendTo30Days(new Date(adjustedYear, 1, 15)))
  );

  const mar15Deadline = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(new Date(adjustedYear, 2, 15))
  );

  const mar31Deadline = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(new Date(adjustedYear, 2, 31))
  );
  const mar31DeadlineExtended = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(extendTo30Days(new Date(adjustedYear, 2, 31)))
  );

  const may31Deadline = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(new Date(adjustedYear, 4, 31))
  );
  const may31DeadlineExtended = toEtAdjustedUtcDateTime(
    adjustDateToFallOnWeekday(extendTo30Days(new Date(adjustedYear, 4, 31)))
  );

  const deadlinesMap = {
    [DashboardFormType.Irs1099B]: {
      recipientCopyDate: feb15Deadline,
      recipientCopyExtendedDate: feb15DeadlineExtended,
      eFileDate: mar31Deadline,
      eFileExtendedDate: mar31DeadlineExtended,
    },
    [DashboardFormType.Irs1099Misc]: {
      recipientCopyDate: jan31Deadline,
      recipientCopyExtendedDate: jan31DeadlineExtended,
      eFileDate: mar31Deadline,
      eFileExtendedDate: mar31DeadlineExtended,
    },
    [DashboardFormType.Irs1099Div]: {
      recipientCopyDate: jan31Deadline,
      recipientCopyExtendedDate: jan31DeadlineExtended,
      eFileDate: mar31Deadline,
      eFileExtendedDate: mar31DeadlineExtended,
    },
    [DashboardFormType.Irs1099Int]: {
      recipientCopyDate: jan31Deadline,
      recipientCopyExtendedDate: jan31DeadlineExtended,
      eFileDate: mar31Deadline,
      eFileExtendedDate: mar31DeadlineExtended,
    },
    [DashboardFormType.Irs1099K]: {
      recipientCopyDate: jan31Deadline,
      recipientCopyExtendedDate: jan31DeadlineExtended,
      eFileDate: mar31Deadline,
      eFileExtendedDate: mar31DeadlineExtended,
    },
    [DashboardFormType.Irs1099Nec]: {
      recipientCopyDate: jan31Deadline,
      eFileDate: jan31Deadline,
    },
    [DashboardFormType.Irs1099R]: {
      recipientCopyDate: jan31Deadline,
      recipientCopyExtendedDate: jan31DeadlineExtended,
      eFileDate: mar31Deadline,
      eFileExtendedDate: mar31DeadlineExtended,
    },
    [DashboardFormType.Irs5498]: {
      recipientCopyDate: may31Deadline,
      recipientCopyExtendedDate: may31DeadlineExtended,
      eFileDate: may31Deadline,
      eFileExtendedDate: may31DeadlineExtended,
    },
    [DashboardFormType.RmdStatement]: {
      recipientCopyDate: jan31Deadline,
    },
    [DashboardFormType.Irs1042S]: {
      recipientCopyDate: mar15Deadline,
      eFileDate: mar15Deadline,
    },
    [DashboardFormType.TransactionSummary]: undefined,
    [DashboardFormType.TransactionSummaryPdf]: undefined,
    [DashboardFormType.UkGainLossSummary]: undefined,
    [DashboardFormType.GainLossPdf]: undefined,
    [DashboardFormType.GainLossSummary]: undefined,
    [DashboardFormType.Cesop]: undefined,
    [DashboardFormType.Dac7]: undefined,
  };

  return deadlinesMap[formType];
};

export default getFormDeadlinesByFormTypeDate;
