import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  useDashboardFeatureFlags,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  ExportAccountsCsvSchema,
  GetExportAccountsCsvParams,
  ExportAccountsCsvResponseType,
  DownloadAccountsCsvStatus,
} from "@taxbit-dashboard/rest";

import { AccountsTableParams } from "../accounts/accountsApiTypes";
import getAccountsApiParams from "../accounts/getAccountsApiParams";

export const useInitiateAccountsCsvExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    [DashboardQueryKey.AccountsExport],
    (params?: AccountsTableParams) => {
      if (params) {
        const {
          "page[offset]": offset,
          "page[limit]": limit,
          ...restParams
        } = getAccountsApiParams(params);
        return restSdk.accountsExport.post({
          ...restParams,
        });
      } else {
        return restSdk.accountsExport.post({});
      }
    },
    {
      ...createQueryMetaObject(restSdk.accountsExport.buildPath()),
    }
  );
};

const isFinishedProcessing = (response?: ExportAccountsCsvSchema) => {
  return response?.status === DownloadAccountsCsvStatus.Finished;
};

export const useGetAccountsCsvUrl = ({
  params,
  onError,
  onSuccess,
}: {
  params: GetExportAccountsCsvParams;
  onError: () => void;
  onSuccess: (response: ExportAccountsCsvResponseType) => void;
}) => {
  const restSdk = useTaxBitRest();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const query = useQuery(
    [DashboardQueryKey.AccountsExport, { ...params }],
    () => restSdk.accountsExport.get(params),
    {
      ...createQueryMetaObject(
        restSdk.accountsExport.buildPath(params.exportId)
      ),
      refetchInterval: hasNotificationCenterAccess
        ? false
        : (response) => (isFinishedProcessing(response?.data) ? false : 2000),
      refetchIntervalInBackground: !hasNotificationCenterAccess,
      enabled: !!params?.exportId,
      onSuccess: (response) => onSuccess(response),
      onError,
    }
  );

  return query;
};
