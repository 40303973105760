import FallbackView from "./fallback-view/FallbackView";
import { DashboardFeatureFlagSet } from "../feature-flags/dashboardFeatureFlagSetTypes";
import useDashboardFeatureFlags from "../feature-flags/useDashboardFeatureFlags";
import useDashboardStore from "../store/useDashboardStore";
import UserPermission from "../UserPermission";

type Props = React.PropsWithChildren<{
  /**
   * The required permissions to view this page. The user will be able to view
   * the given page contents if they have _ANY_ of the permissions listed.
   */
  permissions?: readonly UserPermission[];
  /**
   * The required feature flags to view this page. The user will be able to view
   * the given page contents if they have _ANY_ of the feature flags listed.
   */
  flags?: readonly (keyof DashboardFeatureFlagSet)[];
}>;

/**
 * A wrapper that asserts that the current user has at least one of the required permissions
 * and at least one of the required flags to view the given contents. We do not require all
 * permissions or all flags because in the event that multiple are needed for a single page,
 * that page should still be able to be navigated to and individual sections should be hidden
 * or disabled as-needed.
 *
 * If the user cannot view this page's contents, they will be rerouted to the /unauthorized route.
 */
const AuthorizedView: React.FC<Props> = ({ permissions, flags, children }) => {
  const userFlags = useDashboardFeatureFlags();
  const userPermissions = useDashboardStore((store) => store.userPermissions);

  // This navigation component is meant to be used only in authenticated views,
  // so we shouldn't run across a situation where permissions are undefined.
  // We are (mostly) just doing this to appease Typescript.
  if (!userPermissions) {
    throw new Error("User permissions have not yet been set.");
  }

  const hasRequiredPermission = permissions
    ? permissions.some((permission) => userPermissions[permission])
    : true;

  const hasRequiredFlag = flags ? flags.some((flag) => userFlags[flag]) : true;

  if (!hasRequiredFlag || !hasRequiredPermission) {
    return (
      <FallbackView
        trackingId="authorized-view-fallback"
        subtitle="You don't have permission to access this page. Check your URL or contact your organization administrator to gain access."
      />
    );
  }

  return <>{children}</>;
};

export default AuthorizedView;
