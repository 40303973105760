import {
  AccountOwnerIssue,
  KycTaxDocumentType,
  KycTaxDocumentationIssueStatus,
} from "@taxbit-dashboard/rest";
import { Divider, Flex, Snippet } from "@taxbit-private/cosmic";
import { useMemo } from "react";

import { accountOwnerIssueTypeToDisplayNameMap } from "./accountOwnerIssueTypeToDisplayNameMap";
import KycBadge from "./KycBadge";
import { getIssueTextsByIssueType } from "./texts/curingDocumentationTexts";
import { useCreateAccountOwnerCuringArchive } from "../../../../api/account-owner-curing-archives/accountOwnerCuringArchivesApi";
import { useCreateAccountOwnerCuring } from "../../../../api/account-owner-curings/accountOwnerCuringsApi";
import { accountOwnerDetailsCuringIssueTypeToAccountOwnerCuringsIssueType } from "../../../../api/account-owner-curings/accountOwnerCuringsApiTypes";
import {
  AccountOwnerDetailsCuringIssueType,
  isAccountOwnerDetailsCuringIssueType,
} from "../../../../api/account-owners/accountOwnersApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";

export enum IssueDetailsModalTrackingId {
  FooterCloseBtn = "footer-issue-details-modal-close-btn",
  ResolvePrimaryBtn = "primary-issue-details-modal-resolve-btn",
  ReopenPrimaryBtn = "primary-issue-details-modal-reopen-btn",
  CloseBtn = "issue-details-modal-close-btn",
  Checklist = "issue-details-modal-checklist",
}

const useGetIssuesDetailsModalInfo = (
  taxDocumentType: KycTaxDocumentType,
  issue: AccountOwnerIssue,
  onModalClose: () => void,
  addressString?: string
) => {
  const { accountOwner } = useAccountOwnerTaxDocumentContext();
  const { issueType, resolvedAt, accountOwnerCuring } = issue;

  const {
    mutate: createAccountOwnerCuring,
    isLoading: isCreateAccountOwnerCuringLoading,
    isError: isCuringError,
    reset: resetCreateCuringState,
  } = useCreateAccountOwnerCuring();

  const {
    mutate: createAccountOwnerCuringArchive,
    isLoading: isCreateAccountOwnerCuringArchiveLoading,
    isError: isCuringArchiveError,
    reset: resetCreateCuringArchiveState,
  } = useCreateAccountOwnerCuringArchive();

  const issueStatus = resolvedAt
    ? KycTaxDocumentationIssueStatus.Resolved
    : KycTaxDocumentationIssueStatus.Open;

  const issueTypeDescription = (
    <Flex gap="s">
      {accountOwnerIssueTypeToDisplayNameMap[issueType]}
      <KycBadge taxStatus={issueStatus} />
    </Flex>
  );

  const closeBtnProps = useMemo(() => {
    return {
      label: "Close",
      trackingId: IssueDetailsModalTrackingId.FooterCloseBtn,
      onClick: () => {
        resetCreateCuringState();
        resetCreateCuringArchiveState();
        onModalClose();
      },
    };
  }, [onModalClose, resetCreateCuringArchiveState, resetCreateCuringState]);

  const getOpenCuringStatusPrimaryBtnProps = (
    curingIssueType: AccountOwnerDetailsCuringIssueType
  ) => ({
    label: "Resolve",
    trackingId: IssueDetailsModalTrackingId.ResolvePrimaryBtn,
    isLoading: isCreateAccountOwnerCuringLoading,
    onClick: () => {
      createAccountOwnerCuring({
        accountOwnerId: accountOwner?.accountOwnerId,
        issueType:
          accountOwnerDetailsCuringIssueTypeToAccountOwnerCuringsIssueType[
            curingIssueType
          ],
        target: addressString,
      });
    },
  });

  const getResolvedCuringStatusPrimaryBtnProps = (
    isResolvedByCuring: boolean
  ) => {
    if (!isResolvedByCuring) {
      return closeBtnProps;
    }

    return {
      label: "Re-Open",
      trackingId: IssueDetailsModalTrackingId.ReopenPrimaryBtn,
      isLoading: isCreateAccountOwnerCuringArchiveLoading,
      onClick: () => {
        createAccountOwnerCuringArchive({
          accountOwnerCuringId: accountOwnerCuring?.id,
        });
      },
    };
  };

  const primaryBtnProps = (() => {
    if (
      issueStatus === KycTaxDocumentationIssueStatus.Open &&
      isAccountOwnerDetailsCuringIssueType(issueType)
    ) {
      return getOpenCuringStatusPrimaryBtnProps(issueType);
    }

    if (
      issueStatus === KycTaxDocumentationIssueStatus.Resolved &&
      isAccountOwnerDetailsCuringIssueType(issueType)
    ) {
      return getResolvedCuringStatusPrimaryBtnProps(!!accountOwnerCuring);
    }

    return closeBtnProps;
  })();

  const secondaryBtnProps = (() => {
    const isOpenCuringIssue =
      isAccountOwnerDetailsCuringIssueType(issueType) &&
      issueStatus === KycTaxDocumentationIssueStatus.Open;

    const isCuringIssueResolvedByCuring =
      isAccountOwnerDetailsCuringIssueType(issueType) &&
      issueStatus === KycTaxDocumentationIssueStatus.Resolved &&
      !!accountOwnerCuring;

    if (isOpenCuringIssue || isCuringIssueResolvedByCuring) {
      return { secondaryButtonProps: closeBtnProps };
    }

    return {};
  })();

  const issueTexts =
    taxDocumentType === KycTaxDocumentType.W8BenE ||
    taxDocumentType === KycTaxDocumentType.W8Ben
      ? getIssueTextsByIssueType(taxDocumentType, !!accountOwnerCuring)[
          issueType
        ]
      : undefined;

  const footerContent = (() => {
    const footerText =
      issueStatus === KycTaxDocumentationIssueStatus.Resolved
        ? issueTexts?.resolutionText
        : issueTexts?.actionRequired;

    if (!footerText) {
      return undefined;
    }

    return (
      <>
        <Divider />
        <Flex direction="column" gap="s">
          {footerText}
        </Flex>
      </>
    );
  })();

  const content = (
    <>
      <Snippet label="Tax Documentation" content={taxDocumentType} />
      <Snippet label="Issue" content={issueTypeDescription} />
      {issueTexts && (
        <Snippet label="Description" content={issueTexts?.descriptionText} />
      )}
      {footerContent && footerContent}
    </>
  );

  return {
    primaryBtnProps,
    secondaryBtnProps,
    content,
    isCuringError,
    isCuringArchiveError,
    resetCreateCuringState,
    resetCreateCuringArchiveState,
  };
};

export default useGetIssuesDetailsModalInfo;
