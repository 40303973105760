import { useGetDateRangeChipLabel } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import useAccountsTableData from "../../useAccountsTableData";

const useDateRangeFilterChip = () => {
  const {
    urlParams: { startDate, endDate },
    setFilterParams,
  } = useAccountsTableData();

  const getChipLabel = useGetDateRangeChipLabel();

  return useMemo(() => {
    if (!startDate) {
      return undefined;
    }

    const dateLabel = getChipLabel({ startDate, endDate });

    return {
      label: `Account Creation Date: ${dateLabel}`,
      removeButtonProps: {
        "onClick": () =>
          setFilterParams((draft) => {
            draft.startDate = undefined;
            draft.endDate = undefined;
          }),
        "label": "Remove filter",
        "aria-label": "Remove filter for account creation date",
      },
      trackingId: "accounts-table-chip-date-range",
    };
  }, [endDate, getChipLabel, setFilterParams, startDate]);
};

export default useDateRangeFilterChip;
