import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
} from "@taxbit-dashboard/commons";
import { Transfer, TransferMetadata } from "@taxbit-dashboard/rest";
import { useEffect, useState } from "react";

import getTransfersApiUrlParams from "./getTransfersApiUrlParams";
import { TransfersTableParams } from "./transfersApiTypes";
import useTransferInfo from "../../utils/useTransferInfo";

type TransfersData = {
  transfers?: Transfer[];
  metadata?: TransferMetadata;
};

const useTransfersInApiQuery = (
  params: TransfersTableParams,
  enabled = true
) => {
  const restSdk = useTaxBitRest();
  const { transfersApiParams, transfersMetadataApiParams } =
    getTransfersApiUrlParams(params);

  const transfersDataQuery = useQuery({
    queryKey: [DashboardQueryKey.TransfersIn, transfersApiParams],
    queryFn: () => restSdk.transfersIn.get(transfersApiParams),
    meta: createQueryMetaObject(restSdk.transfersIn.buildPath()),
    keepPreviousData: true,
    enabled,
  });

  const transfersMetadataQuery = useQuery({
    queryKey: [
      DashboardQueryKey.TransfersInMetadata,
      transfersMetadataApiParams,
    ],
    queryFn: () => restSdk.transfersIn.metadata.get(transfersMetadataApiParams),
    meta: createQueryMetaObject(restSdk.transfersIn.metadata.buildPath()),
    keepPreviousData: true,
    enabled,
  });

  return { transfersDataQuery, transfersMetadataQuery };
};

const useTransfersOutApiQuery = (
  params: TransfersTableParams,
  enabled = true
) => {
  const restSdk = useTaxBitRest();
  const { transfersApiParams, transfersMetadataApiParams } =
    getTransfersApiUrlParams(params);

  const transfersDataQuery = useQuery({
    queryKey: [DashboardQueryKey.TransfersOut, transfersApiParams],
    queryFn: () => restSdk.transfersOut.get(transfersApiParams),
    meta: createQueryMetaObject(restSdk.transfersOut.buildPath()),
    keepPreviousData: true,
    enabled,
  });

  const transfersMetadataQuery = useQuery({
    queryKey: [
      DashboardQueryKey.TransfersOutMetadata,
      transfersMetadataApiParams,
    ],
    queryFn: () =>
      restSdk.transfersOut.metadata.get(transfersMetadataApiParams),
    meta: createQueryMetaObject(restSdk.transfersOut.metadata.buildPath()),
    keepPreviousData: true,
    enabled,
  });

  return { transfersDataQuery, transfersMetadataQuery };
};

const useTransfersInformation = (
  transfersQuery:
    | ReturnType<typeof useTransfersInApiQuery>
    | ReturnType<typeof useTransfersOutApiQuery>
) => {
  const { transfersDataQuery, transfersMetadataQuery } = transfersQuery;

  const [transfersData, setTransfersData] = useState<TransfersData>({
    transfers: transfersDataQuery.data?.data,
    metadata: transfersMetadataQuery.data?.data,
  });

  const areQueriesLoading =
    transfersDataQuery.isLoading || transfersMetadataQuery.isLoading;
  const isError = transfersDataQuery.isError || transfersMetadataQuery.isError;
  const isPreviousData =
    transfersDataQuery.isPreviousData || transfersMetadataQuery.isPreviousData;

  // Because we store transfer data in a local state we need to keep track
  // of the loading state ourselves. Otherwise we can get a situation
  // when the data is already loaded and the loading state is false
  // but transfersData is undefined because it will be updated only
  // on the next render
  const [isLoading, setIsLoading] = useState(
    areQueriesLoading || isPreviousData
  );

  // Transfers metadata and transfers should be used together in the table.
  // Due to BE limitations we have to make two separate queries to get the data.
  // To avoid weird behavior (transfers are shown behind the loading state
  // when metadata request failed or vice versa) we will return undefined
  // for both if one of the queries didn't return data
  useEffect(() => {
    if (!areQueriesLoading && !isPreviousData) {
      if (transfersDataQuery.data?.data && transfersMetadataQuery.data?.data) {
        setTransfersData({
          transfers: transfersDataQuery.data.data,
          metadata: transfersMetadataQuery.data.data,
        });
      } else {
        setTransfersData({
          transfers: undefined,
          metadata: undefined,
        });
      }

      setIsLoading(false);
    }
  }, [
    transfersDataQuery.data?.data,
    transfersMetadataQuery.data?.data,
    areQueriesLoading,
    isPreviousData,
    setTransfersData,
  ]);

  useEffect(() => {
    if (areQueriesLoading || isPreviousData) {
      setIsLoading(true);
    }
  }, [areQueriesLoading, isPreviousData]);

  return {
    isError,
    isLoading,
    ...transfersData,
  };
};

export const useGetTransfersIn = (
  params: TransfersTableParams,
  enabled = true
) => {
  const queries = useTransfersInApiQuery(params, enabled);
  return useTransfersInformation(queries);
};

export const useGetTransfersOut = (
  params: TransfersTableParams,
  enabled = true
) => {
  const queries = useTransfersOutApiQuery(params, enabled);
  return useTransfersInformation(queries);
};

export const useGetTransferIn = (enabled = true) => {
  const restSdk = useTaxBitRest();
  const { transactionId, accountId } = useTransferInfo();
  const query = useQuery(
    [DashboardQueryKey.TransfersIn, transactionId, accountId],
    () =>
      transactionId && accountId
        ? restSdk.transferIn.get({ transactionId, accountId })
        : undefined,
    {
      meta: createQueryMetaObject(
        restSdk.transferIn.buildPath(transactionId, accountId)
      ),
      enabled,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetTransferOut = (enabled = true) => {
  const restSdk = useTaxBitRest();
  const { transactionId, accountId } = useTransferInfo();
  const query = useQuery(
    [DashboardQueryKey.TransfersOut, transactionId, accountId],
    () =>
      transactionId && accountId
        ? restSdk.transferOut.get({ transactionId, accountId })
        : undefined,
    {
      enabled,
      meta: createQueryMetaObject(
        restSdk.transferOut.buildPath(transactionId, accountId)
      ),
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
