import { z } from "zod";

const detailedErrorSchema = z.object({
  type: z.string(),
  detail: z.string(),
});

type DetailedError = z.infer<typeof detailedErrorSchema>;

const taxBitErrorBodySchema = z.object({
  errors: z.array(detailedErrorSchema),
});

const getDetailedErrors = (body: string) => {
  try {
    const bodyObject = JSON.parse(body) as unknown;
    const result = taxBitErrorBodySchema.safeParse(bodyObject);

    if (result.success) {
      return result.data.errors;
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};

/**
 * A simple extension of the standard error class that contains
 * the message and status code of a failed fetch response. This
 * can be checked with `instanceof UnexpectedResponseError` to
 * typecheck accesses on the `message`, `status`, and `detailedErrors` fields.
 */
export class UnexpectedResponseError extends Error {
  status: number;

  body: string;

  detailedErrors?: DetailedError[];

  constructor({
    message,
    status,
    body,
    detailedErrors,
  }: {
    message: string;
    status: number;
    body: string;
    detailedErrors?: DetailedError[];
  }) {
    super(message);
    this.status = status;
    this.body = body;
    this.detailedErrors = detailedErrors;
  }
}

/**
 * Returns a new `UnexpectedResponseError` instance containing the
 * text response and status code attached to the given fetch response.
 */
const getUnexpectedResponseError = async (response: Response) => {
  const body = await response.text();

  const message = `Server returned unexpected response.\nStatus code: ${response.status}\nBody: ${body}`;

  return new UnexpectedResponseError({
    message,
    status: response.status,
    body,
    detailedErrors: getDetailedErrors(body),
  });
};

export default getUnexpectedResponseError;
