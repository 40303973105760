import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import {
  Box,
  TrimmedBoxContent,
  ContentSpinner,
  ContentErrorEmptyState,
  ContentEntityEmptyState,
  Flex,
  Icon,
  Body,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import ReportsTable from "./ReportsTable";
import useGetAllReports from "./useGetAllReports";
import { useGetTaxReportStatuses } from "../../../api/taxReportsApi";

export enum ReportsTrackingId {
  ReportsSpinner = "reportsPageSpinner",
}

const Reports: React.FC = () => {
  const { data: taxReportStatuses } = useGetTaxReportStatuses();
  const { isLoading, reports, isError } = useGetAllReports(taxReportStatuses);
  const hasNoReports = !reports?.length;

  return (
    <PageWrapper direction="column">
      <DashboardPrimaryTemplate title="Reports">
        <Box>
          <TrimmedBoxContent trim="all">
            {isLoading ? (
              <ContentSpinner trackingId={ReportsTrackingId.ReportsSpinner} />
            ) : isError ? (
              <ContentErrorEmptyState entity="reports" />
            ) : hasNoReports ? (
              <ContentEntityEmptyState entity="reports" />
            ) : (
              <ReportsTable reports={reports} />
            )}
          </TrimmedBoxContent>
        </Box>
      </DashboardPrimaryTemplate>
      {!isLoading && !isError && !hasNoReports && (
        <CenteredFlex gap="xs" alignItems="center">
          <Icon name="info" />
          <Body>
            Reports typically have a fifteen minute lag between data ingestion
            and that data being available.
          </Body>
        </CenteredFlex>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled(Flex)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.m};
`
);

const CenteredFlex = styled(Flex)`
  margin: 0 auto;
`;

export default Reports;
