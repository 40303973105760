import {
  useNavigateBackWithFallback,
  DashboardPrimaryTemplate,
  useSavedScrollPosition,
  doesResponseErrorMatchStatusCode,
} from "@taxbit-dashboard/commons";
import {
  Box,
  ContentSpinner,
  ContentErrorEmptyState,
} from "@taxbit-private/cosmic";

import DispositionTable from "./table/DispositionTable";
import TransferInformation from "./TransferInformation";
import useTransferQuery from "./useTransferQuery";
import EntityNotFound from "../../accounts/EntityNotFound";

export enum TransferDetailsTrackingIds {
  LoadingSpinner = "transfer-details-loading-spinner",
  BackBtn = "transfer-details-back-btn",
}

const TransferDetails: React.FC = () => {
  const { isLoading, isError, error, data: transfer } = useTransferQuery();
  const isNotFoundError = doesResponseErrorMatchStatusCode(error, 404);

  useSavedScrollPosition({ key: "transfer-details" });

  const navigateBackWithFallback = useNavigateBackWithFallback();

  return (
    <DashboardPrimaryTemplate
      title="Transfer Details"
      backButtonLabel="Back to transfers"
      onBack={() => navigateBackWithFallback("transfer-details", "../../../")}
      backButtonTrackingId={TransferDetailsTrackingIds.BackBtn}
    >
      {isLoading ? (
        <ContentSpinner
          trackingId={TransferDetailsTrackingIds.LoadingSpinner}
        />
      ) : isNotFoundError ? (
        <EntityNotFound entity="Transfer" />
      ) : isError || !transfer ? (
        <Box>
          <ContentErrorEmptyState entity="transfer" />
        </Box>
      ) : (
        <>
          <TransferInformation transfer={transfer} />
          <DispositionTable transfer={transfer} />
        </>
      )}
    </DashboardPrimaryTemplate>
  );
};

export default TransferDetails;
