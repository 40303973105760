import { AssetAmount, AssetType } from "@taxbit-dashboard/rest";
import {
  COSMIC_VALUE_PLACEHOLDER,
  CosmicSupportedCurrency,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

const useFormatAsset = () => {
  const { formatNumber, formatTokenQuantity } = useCosmicLocalizationContext();

  return useCallback(
    ({
      assetAmount,
      shouldShowPlusSign,
    }: {
      assetAmount?: AssetAmount;
      shouldShowPlusSign?: boolean;
    }) => {
      if (assetAmount?.asset.type === AssetType.Fiat) {
        return formatNumber({
          value: assetAmount?.amount,
          format: "FiatCurrency",
          signFormat: shouldShowPlusSign ? "change" : "default",
          currency: assetAmount?.asset.code as CosmicSupportedCurrency,
        });
      } else if (assetAmount?.asset?.code) {
        return formatTokenQuantity(
          assetAmount?.amount,
          assetAmount?.asset.code
        );
      } else {
        return COSMIC_VALUE_PLACEHOLDER;
      }
    },
    [formatNumber, formatTokenQuantity]
  );
};

export default useFormatAsset;
