import {
  ToastConfig,
  navigateToUrl,
  useDashboardFeatureFlags,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { useEffect, useState } from "react";

import {
  useGenerateFormsExport,
  useGetFormsExport,
} from "../../../../../api/information-reporting/forms/formsApi";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import { useIrFormsContext } from "../../context/useIrForms";

export enum FormsTableExportTrackingIds {
  ErrorToast = "ir-forms-table-export-error-toast",
  SuccessToast = "ir-forms-table-export-success-toast",
  InfoToast = "ir-forms-table-export-info-toast",
  ExportButton = "ir-forms-table-export-button",
}

export const exportErroredToastContent: ToastConfig = {
  message: "Forms table failed to export. Please try again.",
  trackingId: FormsTableExportTrackingIds.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
};

export const exportSucceededToastContent: ToastConfig = {
  message: "Your forms table has been exported.",
  trackingId: FormsTableExportTrackingIds.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const exportStartedNotificationsToastContent: ToastConfig = {
  message:
    "Your forms table is being exported. The CSV file will appear in the notifications tab when ready to download.",
  trackingId: FormsTableExportTrackingIds.InfoToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const exportStartedToastContent: ToastConfig = {
  message: "Your forms table is being exported.",
  trackingId: FormsTableExportTrackingIds.InfoToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useFormsTableExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useDashboardStore((store) => store.addToast);
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const {
    accountExternalIdsFilter,
    urlParams,
    currentForm,
    currentYear,
    shouldDisableControls,
    isFormsError,
  } = useIrFormsContext();

  const { mutate: generateFormsExport, data: formsExportResponse } =
    useGenerateFormsExport();

  const {
    isSuccess: isGetFormsExportSuccess,
    isError: isFormsExportError,
    data: formsExportData,
  } = useGetFormsExport(formsExportResponse?.data?.exportId);

  useEffect(() => {
    if (isFormsExportError && !hasNotificationCenterAccess) {
      setIsLoading(false);
      addToast(exportErroredToastContent);
    }
  }, [isFormsExportError, addToast, hasNotificationCenterAccess]);

  useEffect(() => {
    if (
      isGetFormsExportSuccess &&
      formsExportData?.presignedUrl &&
      !hasNotificationCenterAccess
    ) {
      setIsLoading(false);
      addToast(exportSucceededToastContent);
      navigateToUrl(formsExportData.presignedUrl);
    }
  }, [
    isGetFormsExportSuccess,
    formsExportData?.presignedUrl,
    addToast,
    hasNotificationCenterAccess,
  ]);

  const handleSubmit = () => {
    setIsLoading(true);
    addToast(
      hasNotificationCenterAccess
        ? exportStartedNotificationsToastContent
        : exportStartedToastContent
    );

    generateFormsExport(
      {
        ...urlParams,
        formDate: currentYear,
        formType: currentForm,
        accountExternalIdsFilter,
      },
      {
        onError: () => {
          setIsLoading(false);
          addToast(exportErroredToastContent);
        },
        onSuccess: hasNotificationCenterAccess
          ? () => {
              setIsLoading(false);
            }
          : undefined,
      }
    );
  };

  return {
    isLoading,
    isDisabled: isLoading || shouldDisableControls || isFormsError,
    createExport: handleSubmit,
  };
};

export default useFormsTableExport;
