import { doesResponseErrorMatchStatusCode } from "@taxbit-dashboard/commons";
import { AccountId } from "@taxbit-dashboard/rest";
import { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useGetTransactions } from "../../../../api/transactionsApi";
import useAccountId from "../../../../utils/useAccountId";
import { mapTransactionToTableTransaction } from "../transactions/useTransactionsTableColumns";

const TRANSACTIONS_LIMIT = 100;

const useGetTransactionsTab = () => {
  const accountId = useAccountId();

  const {
    data: transactionsData,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isError,
    error,
  } = useGetTransactions(accountId, TRANSACTIONS_LIMIT);

  const transactions = useMemo(
    () =>
      transactionsData?.map((transaction) =>
        mapTransactionToTableTransaction(transaction)
      ),
    [transactionsData]
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: () => {
      void fetchNextPage();
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 10px 0px",
  });

  const isAccountNotFoundError = doesResponseErrorMatchStatusCode(error, 401);

  return {
    isLoading,
    transactions,
    isAccountNotFoundError,
    sentryRef,
    isError,
    accountId: accountId as AccountId,
    isFetchingNextPage,
  };
};

export default useGetTransactionsTab;
