import { zodResolver } from "@hookform/resolvers/zod";
import { useDashboardStore } from "@taxbit-dashboard/commons";
import { FilesApiFile } from "@taxbit-dashboard/rest";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useUpdateFile } from "../../../../../api/files/filesApi";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";

export enum EditFileDescriptionModalTrackingId {
  SuccessToast = "edit-file-description-success-toast",
  ErrorToast = "edit-file-description-error-toast",
  TextInput = "edit-file-description-input",
  ClearDescriptionButton = "edit-file-description-clear-description-button",
  SubmitButton = "edit-file-description-submit-button",
  CancelButton = "edit-file-description-cancel-button",
  CloseButton = "edit-file-description-close-button",
}

const editFileDescriptionFormFieldSchema = z.object({
  description: z.string(),
});

export type EditFileDescriptionFormFields = z.infer<
  typeof editFileDescriptionFormFieldSchema
>;

const useEditFileDescriptionModalForm = (
  onClose: () => void,
  file: FilesApiFile
) => {
  const { mutate: updateFile, isLoading: isUpdatingFile } = useUpdateFile();

  const addToast = useDashboardStore((store) => store.addToast);

  const formMethods = useForm<EditFileDescriptionFormFields>({
    resolver: zodResolver(editFileDescriptionFormFieldSchema),
    defaultValues: {
      description: file.description,
    },
  });

  const clearDescription = () => {
    formMethods.setValue("description", "", {
      shouldDirty: true,
    });
  };

  const isDescriptionEmpty = formMethods.watch("description") === "";

  const handleSubmit = formMethods.handleSubmit(
    (formData: EditFileDescriptionFormFields) => {
      updateFile(
        {
          fileId: file.fileId,
          requestData: { ...formData },
        },
        {
          onSuccess: () => {
            addToast({
              message: `The description of ${file.fileName} was successfully updated.`,
              timeoutMs: TOAST_TIMEOUT,
              trackingId: EditFileDescriptionModalTrackingId.SuccessToast,
            });
            onClose();
          },
          onError: () => {
            formMethods.setError("description", {
              type: "custom",
              message:
                "We were unable to save your description. Please try again later.",
            });
          },
        }
      );
    }
  );

  return {
    formMethods,
    handleSubmit,
    isDescriptionEmpty,
    clearDescription,
    isUpdatingFile,
  };
};

export default useEditFileDescriptionModalForm;
