import { Divider } from "@taxbit-private/cosmic";
import styled from "styled-components";

const TopNavigationActionMenuDivider = styled(Divider)(
  ({ theme }) => `
  margin: ${theme.spacing.s} ${theme.spacing.m};
  max-width: 100%;
  width: unset;
`
);

export default TopNavigationActionMenuDivider;
