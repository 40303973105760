import { RhfDateRangePicker } from "@taxbit-private/cosmic-react-hook-form";

import { TransfersFilterDrawerFormFields } from "./transfersFilterDrawerFormFieldTypes";

export enum TransfersDateRangeFilterTrackingId {
  DateRangePicker = "transfers-filters-drawer-date-range-picker",
}

const TransfersDateRangeFilter: React.FC = () => {
  return (
    <RhfDateRangePicker<Pick<TransfersFilterDrawerFormFields, "dateRange">>
      name="dateRange"
      label="Date"
      trackingId={TransfersDateRangeFilterTrackingId.DateRangePicker}
      shouldAlignMenuToTargetWidth={true}
      placeholder="All Time"
      maxDate={new Date()}
    />
  );
};

export default TransfersDateRangeFilter;
