import { useMatchRoute } from "@tanstack/react-location";
import { useCallback } from "react";

import { buildOrganizationPathPrefix } from "./useOrganizationPrefixedNavigation";

const useIsHrefActive = () => {
  const matchRoute = useMatchRoute();
  /**
   * The React Location matchRoute call below will return the organizationId if
   * the rest of the given route is a parent of the current route), and undefined if not.
   */
  return useCallback(
    (href: string) =>
      !!matchRoute({
        to: `${buildOrganizationPathPrefix(":organizationId")}/${href}`,
        fuzzy: true,
      }),
    [matchRoute]
  );
};

export default useIsHrefActive;
