import { Navigate } from "@tanstack/react-location";
import {
  UserPermission,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  AuthorizedView,
} from "@taxbit-dashboard/commons";

import DeleteFileUploader from "./file-uploader/DeleteFileUploader";
import IngestFileUploader from "./file-uploader/IngestFileUploader";

const ingestFileUploaderNavigationItem: DashboardNavigationItemChild = {
  href: "/manage-data/ingest",
  label: "Ingest",
  trackingId: "sidebar-navigation-ingest-file-uploader-anchor",
  key: "ingest-file-uploader",
} as const;

const deleteFileUploaderNavigationItem: DashboardNavigationItemChild = {
  href: "/manage-data/delete",
  label: "Delete",
  trackingId: "sidebar-navigation-delete-file-uploader-anchor",
  key: "delete-file-uploader",
  shouldShowRoute: ({ flags }) => flags.hasIngestionModuleDeleteAccess,
} as const;

export const navigationItem: DashboardNavigationItemWithChildren = {
  iconName: "settings",
  label: "Manage Data",
  trackingId: "sidebar-navigation-manage-data-anchor",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.UploadFiles] && flags.hasIngestionModuleAccess,
  children: [
    ingestFileUploaderNavigationItem,
    deleteFileUploaderNavigationItem,
  ],
} as const;

export const route = {
  path: "manage-data",
  children: [
    {
      path: "/",
      element: <Navigate to="./ingest" />,
    },
    {
      path: "/ingest",
      children: [
        {
          path: "/",
          element: (
            <AuthorizedView
              flags={["hasIngestionModuleAccess"]}
              permissions={[UserPermission.UploadFiles]}
            >
              <IngestFileUploader />
            </AuthorizedView>
          ),
        },
      ],
    },
    {
      path: "/delete",
      children: [
        {
          path: "/",
          element: (
            <AuthorizedView
              flags={["hasIngestionModuleDeleteAccess"]}
              permissions={[UserPermission.UploadFiles]}
            >
              <DeleteFileUploader />
            </AuthorizedView>
          ),
        },
      ],
    },
  ],
};
