import { IrFormStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";

import { FormsFiltersFormFields } from "./useFormsFiltersFormFieldSchema";
import { formStatusToLabelMap } from "../../../../api/information-reporting/forms/formsApiTypes";

export enum FormsStatusFilterTrackingIds {
  Multiselect = "ir-forms-status-filter-multiselect",
}

export const FORM_STATUS_OPTIONS = Object.values(IrFormStatus).map(
  (status) => ({
    key: status,
    label: formStatusToLabelMap[status],
  })
);

const FormsStatusFilter = () => {
  return (
    <RhfMultiselect<
      IrFormStatus,
      Pick<FormsFiltersFormFields, "statuses">,
      "statuses",
      (typeof FORM_STATUS_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="statuses"
      options={FORM_STATUS_OPTIONS}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Status"
      placeholder="All"
      trackingId={FormsStatusFilterTrackingIds.Multiselect}
    />
  );
};

export default FormsStatusFilter;
