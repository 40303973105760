import { groupBy } from "@taxbit-dashboard/commons";
import { ReleasedDocument } from "@taxbit-dashboard/rest";
import { useEffect, useMemo, useState } from "react";

import { useGetTaxForms } from "../../../../api/tax-forms/taxFormsApi";

const useGetTaxFormsTab = () => {
  const { data: taxForms, isLoading, isError } = useGetTaxForms();
  const [currentYear, setCurrentYear] = useState<string>();

  const documentsByYearMap = useMemo(() => {
    if (!taxForms) {
      return undefined;
    }

    const sortedByTypeTaxForms = taxForms.sort((taxFormA, taxFormB) =>
      taxFormA.type.localeCompare(taxFormB.type)
    );

    return groupBy(sortedByTypeTaxForms, (document: ReleasedDocument) =>
      document.year.toString()
    );
  }, [taxForms]);

  const years = useMemo<string[]>(() => {
    if (!documentsByYearMap) {
      return [];
    }

    return Object.keys(documentsByYearMap).sort().reverse();
  }, [documentsByYearMap]);

  useEffect(() => {
    if (years.length > 0) {
      setCurrentYear(years[0]);
    }
  }, [years]);

  const currentForms: ReleasedDocument[] | undefined = useMemo(() => {
    if (!documentsByYearMap || !currentYear) return undefined;
    return documentsByYearMap[currentYear];
  }, [currentYear, documentsByYearMap]);

  return {
    isError,
    isLoading,
    setCurrentYear,
    currentYear,
    years,
    currentForms,
  };
};

export default useGetTaxFormsTab;
