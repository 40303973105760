import { getPageLimitApiParams } from "@taxbit-dashboard/commons";
import { GetFilesParams } from "@taxbit-dashboard/rest";

import { FilesTableParams } from "./filesApiTypes";

const getFilesParams = ({
  fileType,
  page,
  limit,
}: FilesTableParams): GetFilesParams => {
  return {
    "filters[type]": fileType,
    ...getPageLimitApiParams({ page, limit }),
  };
};

export default getFilesParams;
