import { TransactionType } from "@taxbit-dashboard/rest";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

const formatTransactionType = (
  type?: TransactionType,
  placeholder = COSMIC_VALUE_PLACEHOLDER
): string => {
  if (!type) {
    return placeholder;
  }

  switch (type) {
    case TransactionType.Buy: {
      return "Buy";
    }
    case TransactionType.Income: {
      return "Income";
    }
    case TransactionType.TransferIn: {
      return "Transfer In";
    }
    case TransactionType.TransferOut: {
      return "Transfer Out";
    }
    case TransactionType.Sell: {
      return "Sell";
    }
    case TransactionType.Trade: {
      return "Trade";
    }
    case TransactionType.Expense: {
      return "Expense";
    }
    case TransactionType.Forex: {
      return "Forex";
    }
    case TransactionType.GiftReceived: {
      return "Gift Received";
    }
    case TransactionType.GiftSent: {
      return "Gift Sent";
    }
    case TransactionType.Reward: {
      return "Reward";
    }
    default: {
      return "Other";
    }
  }
};
export default formatTransactionType;
