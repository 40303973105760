import { useRouter } from "@tanstack/react-location";
import { useCallback } from "react";

import { buildOrganizationPathPrefix } from "./useOrganizationPrefixedNavigation";
import useOrganizationRouteMatch from "./useOrganizationRouteMatch";

/**
 * Creates a `returnTo` path for use in the Auth0 redirect flow. This returnTo
 * will be the current page if we're not swapping the organization, or will be
 * the current page prefixed with the new organization if one is provided.
 */
const useGetReturnTo = () => {
  const { state } = useRouter();
  const { subroute } = useOrganizationRouteMatch();

  return useCallback(
    (organizationId?: string) => {
      if (!organizationId) {
        return state.location.pathname;
      }

      return `${buildOrganizationPathPrefix(organizationId)}${
        subroute ? `/${subroute}` : ""
      }`;
    },
    [state.location.pathname, subroute]
  );
};

export default useGetReturnTo;
