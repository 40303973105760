import { GridGroup } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import useGetVatInformation from "./useGetVatInformation";
import useVatInformationRows from "./useVatInformationRows";

export enum VatInformationTrackingId {
  VatInformationSpinner = "vat-information-spinner",
}

const VatInformation: React.FC = () => {
  const { isLoading, vatInformation, isError } = useGetVatInformation();
  const vatInfoRows = useVatInformationRows({
    vatInformation,
  });

  return (
    <Card title="VAT Information">
      {isLoading ? (
        <ContentSpinner
          trackingId={VatInformationTrackingId.VatInformationSpinner}
        />
      ) : vatInfoRows && vatInfoRows.length > 0 ? (
        <GridGroup data={vatInfoRows} />
      ) : isError ? (
        <ContentErrorEmptyState entity="VAT data" />
      ) : (
        <ContentEntityEmptyState entity="VAT data" />
      )}
    </Card>
  );
};

export default VatInformation;
