import { TRACKING_EXCLUDE_CLASS } from "./trackingExcludeClasses";

/**
 * Given potential string or number contents, renders defined contents in
 * a span with our tracking exclusion class added to ensure that the contents
 * are excluded from tracking vendors. If the given contents are undefined,
 * this helper returns undefined so that its consumers can leverage built in
 * fallback behavior of other components.
 */
const renderExcludedSpan = (contents?: string | number) =>
  contents && <span className={TRACKING_EXCLUDE_CLASS}>{contents}</span>;

export default renderExcludedSpan;
