import {
  Box,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import DownloadTaxForms from "./download-tax-forms/DownloadTaxForms";
import YearDropdown from "./download-tax-forms/YearDropdown";
import TaxFormDetails from "./tax-form-details/TaxFormDetails";
import useGetTaxFormsTab from "./useGetTaxFormsTab";

export enum TaxFormsTabTrackingId {
  TaxFormsTabSpinner = "tax-forms-tab-spinner",
}

const TaxFormsTab = () => {
  const {
    years,
    currentYear,
    isLoading,
    isError,
    setCurrentYear,
    currentForms,
  } = useGetTaxFormsTab();

  return isLoading ? (
    <ContentSpinner trackingId={TaxFormsTabTrackingId.TaxFormsTabSpinner} />
  ) : isError ? (
    <Box>
      <ContentErrorEmptyState entity="tax forms" />
    </Box>
  ) : years.length > 0 && currentYear && currentForms ? (
    <>
      <YearDropdown
        years={years}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />
      <DownloadTaxForms currentForms={currentForms} />
      <TaxFormDetails currentForms={currentForms} />
    </>
  ) : (
    <Box>
      <ContentEntityEmptyState entity="tax forms" />
    </Box>
  );
};

export default TaxFormsTab;
