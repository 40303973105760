import {
  EligibilityDataItem,
  EligibilityDocumentTypeDataSource,
  EligibilityFormSpecificFields,
  EligibilityStatus,
  Form1099BAggregateValues,
  FormGainLossPdfAggregateValues,
  FormGainLossSummaryAggregateValues,
  FormItem1099BAggregateValues,
  FormItemGainLossPdfAggregateValues,
  FormItemGainLossSummaryAggregateValues,
} from "@taxbit-dashboard/rest";
import { Badge, TableColumns } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import defaultToZeroOrPlaceholder from "./defaultToZeroOrPlaceholder";

export enum FormSpecificEligibilityTableTrackingIds {
  GainItemsTooltip = "irEligibilityTableGainItemsTooltip",
}

const GAIN_ITEMS_TOOLTIP_CONTENT =
  "A 'gain item' refers to each lot disposed of as part of a reportable transaction. To the extent multiple lots are disposed of as part of a single reportable transaction, each individual lot disposal is a separate gain item.";

const formEligibilityTypesWithGainsCount =
  new Set<EligibilityDocumentTypeDataSource>([
    EligibilityDocumentTypeDataSource.GainLossPdfFormData,
    EligibilityDocumentTypeDataSource.GainLossPdfIngested,
    EligibilityDocumentTypeDataSource.GainLossSummaryFormData,
    EligibilityDocumentTypeDataSource.GainLossSummaryIngested,
    EligibilityDocumentTypeDataSource.Irs1099bFormData,
    EligibilityDocumentTypeDataSource.Irs1099bIngested,
  ]);

const hasFormEligibilityGainsCount = (
  formSpecificFields: EligibilityFormSpecificFields
): formSpecificFields is
  | Form1099BAggregateValues
  | FormGainLossPdfAggregateValues
  | FormGainLossSummaryAggregateValues
  | FormItem1099BAggregateValues
  | FormItemGainLossPdfAggregateValues
  | FormItemGainLossSummaryAggregateValues => {
  return formEligibilityTypesWithGainsCount.has(
    formSpecificFields.documentTypeDataSource
  );
};

const useFormSpecificEligibilityColumns = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const repTransactionsColumn = useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "taxYearTotalTaxableTransactionCount",
        label: "Rep. Trans.",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (taxYearTotalTaxableTransactionCount, eligibilityData) => {
          if (eligibilityData.isFormData) {
            return <Badge variant="secondary" label="Form Data" />;
          } else if (eligibilityData.status === EligibilityStatus.Pending) {
            return COSMIC_VALUE_PLACEHOLDER;
          } else {
            return formatWholeQuantity(
              taxYearTotalTaxableTransactionCount ?? 0
            );
          }
        },
      },
    ],
    [formatWholeQuantity]
  );

  const gainsCountColumn = useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        label: "Gain Items",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields, eligibilityData) => {
          if (
            formSpecificFields &&
            hasFormEligibilityGainsCount(formSpecificFields)
          ) {
            return formatWholeQuantity(
              formSpecificFields.taxYearTotalGainsItems
            );
          }

          return defaultToZeroOrPlaceholder(eligibilityData.status);
        },
        helpIconProps: {
          trackingId: FormSpecificEligibilityTableTrackingIds.GainItemsTooltip,
          tooltipProps: {
            content: GAIN_ITEMS_TOOLTIP_CONTENT,
            appendTo: "body",
            isInteractive: false,
          },
        },
      },
    ],
    [formatWholeQuantity]
  );

  return { repTransactionsColumn, gainsCountColumn };
};

export default useFormSpecificEligibilityColumns;
