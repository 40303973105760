import { Divider, Flex } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { Fragment } from "react";

import GridRow from "./GridRow";
import Grid from "../BaseGrid";

export type Row = {
  label: string;
  content?: React.ReactNode;
  id?: string;
};

const GridGroup = ({ data }: { data: Row[][] }) => {
  const filteredData = data.filter((section) => section.length > 0);
  return (
    <Flex direction="column" padding="l" gap="l">
      {filteredData.map((section, index) => (
        <Fragment key={`cardSection-${section[0]?.label}`}>
          {index !== 0 && <Divider />}
          <Grid>
            {section.map(({ label, content }) => (
              <GridRow
                label={label}
                content={content ?? COSMIC_VALUE_PLACEHOLDER}
                key={`cardSection-${label}`}
              />
            ))}
          </Grid>
        </Fragment>
      ))}
    </Flex>
  );
};

export default GridGroup;
