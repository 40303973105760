import { z } from "zod";

import { companyUserIdSchema } from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const createAccountOwnerCuringArchiveRequestSchema = z.object({
  accountOwnerCuringId: z.number(),
  note: z.string().optional(),
});

const accountOwnerCuringArchiveSchema =
  createAccountOwnerCuringArchiveRequestSchema.extend({
    id: z.number(),
    companyUserId: companyUserIdSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
  });

export const accountOwnerCuringArchiveResponseSchema =
  getPublicApiSuccessSchema(accountOwnerCuringArchiveSchema);

export type CreateAccountOwnerCuringArchiveRequest = z.infer<
  typeof createAccountOwnerCuringArchiveRequestSchema
>;

export type AccountOwnerCuringArchiveResponse = z.infer<
  typeof accountOwnerCuringArchiveResponseSchema
>;
