import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  KycDocumentGenerationParams,
  UserId,
  KycDocumentId,
  GetKycDocument,
  AccountOwnerId,
} from "@taxbit-dashboard/rest";

export const useGetKycTaxDocumentationStatus = (
  userId?: UserId | AccountOwnerId
) => {
  const { hasTaxDocumentationAccess } = useDashboardFeatureFlags();
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.KycTaxDocumentationStatus, userId],
    () =>
      userId ? restSdk.kycTaxDocumentation.status.get({ userId }) : undefined,
    {
      ...createQueryMetaObject(
        restSdk.kycTaxDocumentation.status.buildPath(userId)
      ),
      enabled: !!userId && hasTaxDocumentationAccess,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateKycDocument = () => {
  const restSdk = useTaxBitRest();
  return useMutation(
    ({
      userId,
      requestParams,
    }: {
      userId: UserId | AccountOwnerId;
      requestParams: KycDocumentGenerationParams;
    }) => restSdk.kycTaxDocumentation.document.post({ userId, requestParams })
  );
};

export const useGetKycDocument = ({
  userId,
  documentId,
  refetchInterval,
  onError,
  onSuccess,
}: {
  userId?: UserId | AccountOwnerId;
  documentId?: KycDocumentId;
  refetchInterval?: number;
  onError?: () => void;
  onSuccess?: (data: GetKycDocument) => void;
}) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.KycTaxDocumentationDocument, userId, documentId],
    () =>
      userId && documentId
        ? restSdk.kycTaxDocumentation.document.get({ userId, documentId })
        : undefined,
    {
      ...createQueryMetaObject(
        restSdk.kycTaxDocumentation.document.buildPath(userId, documentId)
      ),
      enabled: !!documentId && !!userId,
      refetchInterval,
      cacheTime: 0,
      onError,
      onSuccess,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
