import { useDashboardStore } from "@taxbit-dashboard/commons";
import { useEffect } from "react";
import styled from "styled-components";

import ZeroHashLogo from "../assets/zeroHashLogo.svg?react";

/**
 * The parent company ids for all ZeroHash tenants, as of 12/11/2023.
 * In order to display the correct logo for ZeroHash tenants, all new tenants
 * must be created under one of these ids.
 */
const zeroHashCompanyIds = new Set([
  "45d89bbf-ea80-46fe-88e8-8ac39b9acea4",
  "7b9bfa38-2f24-4a7a-bf02-0d16f2010fbe",
  "5e80e9a7-7c22-4df5-833e-a5aa1e0bfe1a",
]);

/**
 * The logo elements, page titles, and favicon paths for tenants that require
 * logo overrides. Any new favicons must be placed in apps/dashboard/public.
 */
const getOverridesForCompanyId = (companyId?: string) => {
  if (companyId && zeroHashCompanyIds.has(companyId)) {
    return {
      logo: <SizedZeroHashLogo />,
      favicon: "/zh-favicon.png",
      title: "Zero Hash",
    };
  } else {
    return undefined;
  }
};

const useTenantBrandingOverrides = () => {
  const companyId = useDashboardStore((state) => state.companyId);
  const overrides = getOverridesForCompanyId(companyId);

  /**
   * The favicon and titles are set in the index.html file, so in order to
   * update them we must manually find and mutate the existing elements.
   */
  useEffect(() => {
    if (overrides) {
      const favicon = document.querySelector("#favicon") as HTMLLinkElement;
      favicon.href = overrides.favicon;
      document.title = overrides.title;
    }
  }, [overrides]);

  return { logoElement: overrides?.logo };
};

const SizedZeroHashLogo = styled(ZeroHashLogo)(
  ({ theme }) => `
  height: ${theme.sizing.xs};
`
);

export default useTenantBrandingOverrides;
