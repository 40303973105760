import { logError, useDashboardStore } from "@taxbit-dashboard/commons";
import { Button } from "@taxbit-private/cosmic";

import { UsernamePasswordSettingsTrackingId } from "./usernamePasswordSettingsTrackingId";
import { useChangeCurrentCompanyPassword as useChangeCurrentCompanyUserPassword } from "../../api/companyUsersApi";
import { TOAST_TIMEOUT } from "../../utils/toastTimeout";

const PasswordChangeButton = () => {
  const { mutate: changePassword, isLoading } =
    useChangeCurrentCompanyUserPassword();

  const addToast = useDashboardStore((store) => store.addToast);

  const onSuccess = () => {
    addToast({
      message: "A link to change your password has been sent to your email.",
      trackingId: UsernamePasswordSettingsTrackingId.PasswordChangeSuccessToast,
      timeoutMs: TOAST_TIMEOUT,
    });
  };

  const onError = (error: unknown) => {
    logError({
      message: "Error changing user's password.",
      error,
    });
    addToast({
      message: "The change password request failed. Please try again.",
      trackingId: UsernamePasswordSettingsTrackingId.PasswordChangeErrorToast,
      variant: "danger",
      timeoutMs: TOAST_TIMEOUT,
    });
  };

  return (
    <Button
      label="Send Password Change Email"
      isDisabled={isLoading}
      trackingId={UsernamePasswordSettingsTrackingId.PasswordChangeButton}
      variant="anchor-primary-inline"
      onClick={() => {
        changePassword(undefined, {
          onSuccess,
          onError,
        });
      }}
    />
  );
};

export default PasswordChangeButton;
