import camelCaseKeys from "camelcase-keys";

const options = {
  deep: true,
};

/**
 * Recursively camel cases keys of an object. Returns a new object.
 */
export default (data: Parameters<typeof camelCaseKeys>[0]) => {
  return camelCaseKeys(data, options);
};
