// Listed permissions will be requested on behalf of the user.
// If the user has a permission that is not listed here,
// this permission will be missing from a token
enum UserPermission {
  ReadUsers = "read:users",
  CreateUsers = "create:users",
  ReadOrganizationMembers = "read:organization_members",
  DeleteOrganizationMembers = "delete:organization_members",
  UpdateOrganizationMemberRoles = "update:organization_member_roles",
  ReadOrganizationInvitations = "read:organization_invitations",
  CreateOrganizationInvitations = "create:organization_invitations",
  ReadAccounts = "read:accounts",
  CreateAccounts = "create:accounts",
  ReadTins = "read:tins",
  ReadAccountAssets = "read:account_assets",
  ReadAccountDocuments = "read:account_documents",
  CreateAccountDocuments = "create:account_documents",
  ReadAccountTransactions = "read:account_transactions",
  ReadTaxReports = "read:tax_reports",
  ReadCompanyUsers = "read:company_users",
  ReadRoles = "read:roles",
  ResetMfaForCompanyUser = "delete:guardian_enrollments",
  UploadFiles = "create:upload_files",
  ReadFiles = "read:files",
  UpdateFiles = "update:files",
  ReadPayers = "read:payers",
  CreatePayers = "create:payers",
  UpdatePayers = "update:payers",
  UpdateAccounts = "update:accounts",
  DeleteTaxForms = "delete:tax_forms",
  CreateTaxForms = "create:tax_forms",
}

export default UserPermission;
