import { calculatePageNumberForUpdatedPageSize } from "@taxbit-dashboard/commons";
import { CosmicPageSize, PaginationFooter } from "@taxbit-private/cosmic";

import { FormItemsTableParams } from "../../api/form-items/formItemsApiTypes";

export enum FormItemsTablePaginationTrackingIds {
  Pagination = "accountFormItemsTablePagination",
}

type FormItemsTablePaginationProps = {
  currentPage: number;
  currentPageSize: CosmicPageSize;
  isDisabled: boolean;
  totalCount: number;
  setPaginationParams: (
    setter: (draft: Pick<FormItemsTableParams, "page" | "limit">) => void
  ) => void;
};

const FormItemsTablePagination: React.FC<FormItemsTablePaginationProps> = ({
  currentPage,
  currentPageSize,
  isDisabled,
  setPaginationParams,
  totalCount,
}) => {
  const setCurrentPage = (nextPage: number) => {
    setPaginationParams((draft) => {
      draft.page = nextPage;
    });
  };

  const setPageSize = (nextPageSize: CosmicPageSize) => {
    const page = calculatePageNumberForUpdatedPageSize({
      currentPageSize,
      currentPage,
      nextPageSize,
    });

    setPaginationParams((draft) => {
      draft.page = page;
      draft.limit = nextPageSize;
    });
  };

  return (
    <PaginationFooter
      currentPage={currentPage}
      isDisabled={isDisabled}
      pageSize={currentPageSize}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      totalCount={totalCount}
      trackingId={FormItemsTablePaginationTrackingIds.Pagination}
    />
  );
};

export default FormItemsTablePagination;
