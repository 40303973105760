import { GridGroup } from "@taxbit-dashboard/commons";
import { ReleasedDocument } from "@taxbit-dashboard/rest";
import { Card } from "@taxbit-private/cosmic";

import useGetTaxFormsDetailsRows from "./useGetTaxFormDetailsRows";
import { documentTitleMap } from "../../../../../api/tax-forms/taxFormsApiTypes";

type TaxFormDetailsProps = {
  currentForms: ReleasedDocument[];
};

const TaxFormDetails: React.FC<TaxFormDetailsProps> = ({ currentForms }) => {
  const getTaxFormDetailsRows = useGetTaxFormsDetailsRows();

  return currentForms.map((form) => {
    const cardTitle = `Form ${documentTitleMap[form.type]} Details`;

    const taxFormDetailsRows = getTaxFormDetailsRows({ taxFormDetails: form });

    return (
      <Card title={cardTitle}>
        <GridGroup data={taxFormDetailsRows} />
      </Card>
    );
  });
};

export default TaxFormDetails;
