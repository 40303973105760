import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  UserPermission,
  useTaxBitRest,
  useUserPermission,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
} from "@taxbit-dashboard/commons";
import { AccountOwnerId } from "@taxbit-dashboard/rest";

export const useGetAccountOwnerDetails = (
  accountOwnerId?: AccountOwnerId,
  unmaskTaxId?: boolean
) => {
  const restSdk = useTaxBitRest();
  const canUnmaskTaxId = useUserPermission(UserPermission.ReadTins);

  const query = useQuery(
    [DashboardQueryKey.AccountOwnerDetails, accountOwnerId, !!canUnmaskTaxId],
    () =>
      accountOwnerId
        ? restSdk.accountOwnerDetails.get({ accountOwnerId, unmaskTaxId })
        : undefined,
    {
      ...createQueryMetaObject(
        restSdk.accountOwnerDetails.buildPath(accountOwnerId)
      ),
      enabled: !!accountOwnerId,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
