import { Form1099NecLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItem1099NecColumns = (): TableColumns<Form1099NecLineItem> => {
  const { formatQuantity, formatBoolean } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form1099NecLineItem> => [
      {
        key: "nonemployeeComp",
        label: "Nonemployee Comp",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (nonemployeeComp) => formatQuantity(nonemployeeComp),
      },
      {
        key: "directSales5000ConsumerProducts",
        label: "Direct Sales 5000 Consumer Products",
        isContentFullWidth: true,
        renderCell: (directSales5000ConsumerProducts) =>
          formatBoolean(directSales5000ConsumerProducts),
      },
      {
        key: "federalTaxWithheld",
        label: "Federal Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "stateTaxWithheld",
        label: "State Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (stateTaxWithheld) => formatQuantity(stateTaxWithheld),
      },
      {
        key: "state",
        label: "State",
        isContentFullWidth: true,
      },
      {
        key: "payersStateNumber",
        label: "Payers State Number",
        isContentFullWidth: true,
      },
      {
        key: "stateIncome",
        label: "State Income",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (stateIncome) => formatQuantity(stateIncome),
      },
    ],
    [formatBoolean, formatQuantity]
  );
};

export default useFormItem1099NecColumns;
