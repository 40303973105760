import { Body, Flex } from "@taxbit-private/cosmic";
import { getChartColor, CosmicChartColor } from "@taxbit-private/cosmic-charts";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import styled from "styled-components";

export enum EligibleUsersCountTrackingIds {
  CompleteAccountData = "irEligibleUsersCountCompletePii",
  IncompleteAccountData = "irEligibleUsersCountIncompletePii",
  Total = "irEligibleUsersCountTotal",
}

export type PieSection = {
  key: "CompleteAccountData" | "IncompleteAccountData";
  label: string;
  value: number;
  color: CosmicChartColor;
};

type EligibleUsersCountRowsProps = {
  sections: PieSection[];
  total: number;
};

const EligibleUsersCountRows: React.FC<EligibleUsersCountRowsProps> = ({
  sections,
  total,
}) => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <Flex direction="column" gap="m">
      {sections.map((section) => (
        <FlexWithSpaceBetween gap="m" key={section.key}>
          <LegendFlexContainer>
            {total > 0 && (
              <ColoredSquare color={getChartColor(section.color)} />
            )}
            <Body>{section.label}</Body>
          </LegendFlexContainer>

          <BoldBody trackingId={EligibleUsersCountTrackingIds[section.key]}>
            {formatWholeQuantity(section.value)}
          </BoldBody>
        </FlexWithSpaceBetween>
      ))}
    </Flex>
  );
};

const ColoredSquare = styled.div.withConfig<{
  color: string;
}>({
  shouldForwardProp: (propName) => propName !== "color",
})(
  ({ theme, color }) => `
  width: ${theme.sizing.xxxs};
  height: ${theme.sizing.xxxs};
  background-color: ${color};
`
);

const LegendFlexContainer = styled(Flex)(
  ({ theme }) => `
    gap: ${theme.spacing.s};
    align-items: center;
  `
);

const BoldBody = styled(Body)(
  ({ theme }) => `
  font-weight: ${theme.fontWeight.bold};`
);

const FlexWithSpaceBetween = styled(Flex)`
  justify-content: space-between;
`;

export default EligibleUsersCountRows;
