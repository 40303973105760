import {
  Flex,
  Checkbox,
  TrackingProps,
  Body,
  BodyDangerSmall,
} from "@taxbit-private/cosmic";
import { Controller, FieldPath, FieldValues } from "react-hook-form";

export type CheckboxOptionValue = boolean | string | number;

export type CheckboxOption = {
  value: CheckboxOptionValue;
  label: string;
} & TrackingProps;

type Props<
  TFieldValues extends FieldValues,
  TCheckboxOption extends CheckboxOption,
> = {
  name: FieldPath<TFieldValues>;
  label?: string;
  options: TCheckboxOption[];
};

// FIXME: Move to Cosmic repo
// CheckboxGroup https://taxbit.atlassian.net/browse/TAX-16540
// RHF adapter https://taxbit.atlassian.net/browse/TAX-21718
/**
 * A react-hook-form adapter for presenting and controlling form state for a group of checkboxes
 * that modify a single array. As checkboxes are checked, values are added to the array. As they
 * are unchecked, values are removed from the array.
 */
const RhfCheckboxGroup = <
  TFieldValues extends FieldValues,
  TCheckboxOption extends CheckboxOption,
>({
  name,
  label,
  options,
}: Props<TFieldValues, TCheckboxOption>) => {
  return (
    <Controller<TFieldValues>
      name={name}
      render={({ field, fieldState: { error } }) => {
        // FIXME: Can we use TypeScript better to avoid some of this?
        if (!Array.isArray(field.value)) {
          throw new TypeError("CheckboxArray field type must be an array");
        }

        const value = field.value as unknown[];

        return (
          <Flex direction="column" gap="s">
            {label && <Body>{label}</Body>}
            <Flex direction="column" gap="m">
              {options.map((option) => {
                return (
                  <Checkbox
                    key={String(option.value)}
                    isChecked={value.includes(option.value)}
                    label={option.label}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        field.onChange([...field.value, option.value]);
                      } else {
                        field.onChange(
                          value.filter(
                            (selectedRoleValue) =>
                              selectedRoleValue !== option.value
                          )
                        );
                      }
                    }}
                    trackingId={option.trackingId}
                  />
                );
              })}
              {error && <BodyDangerSmall>{error.message}</BodyDangerSmall>}
            </Flex>
          </Flex>
        );
      }}
    />
  );
};

export default RhfCheckboxGroup;
