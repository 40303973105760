import styled from "styled-components";

const Grid = styled.div(
  ({ theme }) => `
    display: grid;
    grid-template-columns: 0.8fr 2fr;
    row-gap: ${theme.spacing.s};
    column-gap: ${theme.spacing.l};
    word-wrap: break-word;
  `
);

export default Grid;
