import { Link as ReactRouterLink } from "@tanstack/react-location";
import {
  TrackingProps,
  withTracking,
  ClickableStyleProps,
  getClickableCss,
  ClickableContent,
  ClickableContentProps,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

export type LinkProps = ClickableStyleProps &
  ClickableContentProps &
  TrackingProps &
  React.ComponentProps<typeof ReactRouterLink>;

/**
 * A styled wrapper for the React-Location `Link` component that allows
 * it to be styled and used like our clickable (`Button` and `Anchor`)
 * atoms. This should be used for all clickable elements meant to
 * trigger a navigation to other pages of the dashboard.
 *
 * For clickable elements that navigate externally, see the Cosmic `Anchor` atom.
 * For clickable elements that perform an action on click, see the Cosmic `Button` atom.
 */
const Link: React.FC<LinkProps> = ({
  iconName,
  iconPlacement,
  label,
  ...linkProps
}) => {
  const contentProps = {
    iconName,
    iconPlacement,
    label,
  } as ClickableContentProps;

  return (
    <StyledLink {...linkProps}>
      <ClickableContent {...contentProps} />
    </StyledLink>
  );
};

const StyledLink = withTracking(
  styled(ReactRouterLink)<ClickableStyleProps>(
    ({ theme, variant = "anchor-primary", size }) => `
  ${getClickableCss(theme, { variant, size })}
`
  )
);

export default Link;
