import { AccountOwnerDetailsTaxDocumentationIssueType } from "@taxbit-dashboard/rest";

export type AccountOwnerDetailsCuringIssueType =
  | AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf
  | AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress
  | AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia;

const curingIssueTypes = new Set([
  AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf,
  AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress,
  AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia,
]);

export const isAccountOwnerDetailsCuringIssueType = (
  issueType: AccountOwnerDetailsTaxDocumentationIssueType
): issueType is AccountOwnerDetailsCuringIssueType =>
  curingIssueTypes.has(issueType as AccountOwnerDetailsCuringIssueType);
