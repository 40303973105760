import { DownloadItem } from "@taxbit-dashboard/rest";
import {
  BodySmall,
  Flex,
  getRgbaForHex,
  invisibleClickableStyles,
  withTracking,
  BodyExtraSmall,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import styled from "styled-components";

import CsvSvg from "../../../../../../assets/csvFileIcon.svg";
import GenericFileSvg from "../../../../../../assets/genericFileIcon.svg";
import PdfSvg from "../../../../../../assets/pdfFileIcon.svg";
import ZipSvg from "../../../../../../assets/zipFileIcon.svg";

export enum DownloadListItemTrackingId {
  Wrapper = "download-list-item-wrapper",
}

export type DownloadListItemProps = {
  downloadItem: DownloadItem;
};

const DownloadListItem: React.FC<DownloadListItemProps> = ({
  downloadItem: {
    downloadUrl,
    lastModified,
    properties: { fileDownloadName, fileDescription },
  },
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();

  const downloadExtension =
    fileDownloadName.split(".").pop()?.toLowerCase() ?? "Unknown File Type";
  const downloadIconSrc = (() => {
    switch (downloadExtension) {
      case "pdf": {
        return PdfSvg;
      }
      case "csv": {
        return CsvSvg;
      }
      case "zip": {
        return ZipSvg;
      }
      default: {
        return GenericFileSvg;
      }
    }
  })();

  return (
    <Wrapper href={downloadUrl} trackingId={DownloadListItemTrackingId.Wrapper}>
      <DownloadListIcon
        src={downloadIconSrc}
        alt={downloadExtension.toUpperCase()}
      />
      <Flex direction="column">
        <DownloadName>{fileDownloadName}</DownloadName>
        <Flex gap="xs">
          <DownloadDescription>
            {formatDateTime({
              date: new Date(lastModified),
              format: "DateTimeShortened",
              tz: "Browser",
            })}
          </DownloadDescription>
          <DownloadDescription>|</DownloadDescription>
          <DownloadDescription shouldTruncate={true}>
            {fileDescription}
          </DownloadDescription>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = withTracking(
  styled.a(
    ({ theme }) => `
  ${invisibleClickableStyles}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.m};
  width: 100%;
  padding: ${theme.spacing.m} ${theme.spacing.l};
  
  :hover {
    background-color: ${getRgbaForHex(theme.color.primary, 0.05)};
  }
`
  )
);

const DownloadName = styled(BodySmall)(
  ({ theme }) => `
  font-weight: ${theme.fontWeight.bold};
`
);

const DownloadDescription = styled(BodyExtraSmall)`
  flex-shrink: 0;
`;

const DownloadListIcon = styled.img(
  ({ theme }) => `
  width: ${theme.sizing.xs};
  height: ${theme.sizing.xs};
`
);

export default DownloadListItem;
