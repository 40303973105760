import {
  DashboardQueryKey,
  UserPermission,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { AccountId } from "@taxbit-dashboard/rest";

import useAccountIdDependentQuery from "../useAccountIdDependentQuery";

export const useGetAccount = (unmaskTaxId?: boolean) => {
  const restSdk = useTaxBitRest();
  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.Accounts, !!unmaskTaxId],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.account.get({ accountId, unmaskTaxId }),
    pathBuilder: (accountId?: AccountId) =>
      restSdk.account.buildPath(accountId),
  });
  return unwrapPublicApiWrappedQuery(query);
};

export const useGetAccountV2 = (unmaskTaxId?: boolean) => {
  const restSdk = useTaxBitRest();
  const canUnmaskTaxId = useUserPermission(UserPermission.ReadTins);

  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.AccountsV2, !!canUnmaskTaxId],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.accountV2.get({ accountId, unmaskTaxId }),
    pathBuilder: (accountId?: AccountId) =>
      restSdk.accountV2.buildPath(accountId),
  });

  return unwrapPublicApiWrappedQuery(query);
};
