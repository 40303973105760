import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  GetAccountsSearchKey,
  getAccountsSearchKeySchema,
} from "@taxbit-dashboard/rest";
import { Button, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useState } from "react";
import styled from "styled-components";

import {
  accountsSearchKeyLabelMap,
  tinSchema,
} from "../../../../api/accounts/accountsApiTypes";
import useAccountsTableData from "../useAccountsTableData";

const SEARCH_KEY_OPTIONS = getAccountsSearchKeySchema.options.map((key) => ({
  key,
  label: accountsSearchKeyLabelMap[key],
}));
const DEFAULT_OPTION = SEARCH_KEY_OPTIONS[0];

type SearchKeyOption = (typeof SEARCH_KEY_OPTIONS)[number];

enum AccountsScopedSearchBarTrackingId {
  SearchBar = "accounts-scoped-search-bar",
  SearchButton = "accounts-scoped-search-button",
}

const getRepeatSearchText = (key: GetAccountsSearchKey) => {
  const label = accountsSearchKeyLabelMap[key];
  const casedKey =
    key === "masked_tax_id"
      ? "TIN"
      : label[0].toLocaleLowerCase() + label.slice(1);
  return `You can only search for one ${casedKey} at a time. Please clear your existing search to search by another ${casedKey}.`;
};

const AccountsScopedSearchBar: React.FC = () => {
  const { shouldHideAccountsTableTinUi } = useDashboardFeatureFlags();
  const { setFilterParams, urlParams, shouldDisableControls } =
    useAccountsTableData();

  const [searchKeyOption, setSearchKeyOption] =
    useState<SearchKeyOption>(DEFAULT_OPTION);

  // Typescript is struggling to infer this enum type at compile time, even though
  // it works fine in VSCode. TODO: https://taxbit.atlassian.net/browse/TAX-28283
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const searchKey = searchKeyOption.key as GetAccountsSearchKey;

  const [searchValue, setSearchValue] = useState("");

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFilterParams((draft) => {
      draft[searchKey] = searchValue.trim();
    });
    setSearchValue("");
  };

  const isInvalidTin =
    searchKey === "masked_tax_id" && !tinSchema.safeParse(searchValue).success;
  const hasAlreadyAddedFilter = !!urlParams[searchKey];

  const isSubmitDisabled =
    !searchValue ||
    hasAlreadyAddedFilter ||
    shouldDisableControls ||
    isInvalidTin;

  const tooltipProps = (() => {
    if (hasAlreadyAddedFilter) {
      return {
        content: getRepeatSearchText(searchKey),
      };
    } else if (isInvalidTin) {
      return {
        content: "Please enter the last 4 digits of a TIN.",
      };
    } else {
      return undefined;
    }
  })();

  const options = shouldHideAccountsTableTinUi
    ? SEARCH_KEY_OPTIONS.filter((option) => option.key !== "masked_tax_id")
    : SEARCH_KEY_OPTIONS;

  return (
    <FlexForm onSubmit={handleSearchSubmit}>
      <FullWidthScopedSearchBar
        trackingId={AccountsScopedSearchBarTrackingId.SearchBar}
        label="Search by account name, email, ID, or tax ID"
        isLabelHidden={true}
        dropdownProps={{
          options,
          onChange: setSearchKeyOption,
          value: searchKeyOption,
          getOptionKey: ({ key }: SearchKeyOption) => key,
          getOptionLabel: ({ label }: SearchKeyOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={shouldDisableControls}
      />
      <Button
        trackingId={AccountsScopedSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        isDisabled={isSubmitDisabled}
        tooltipProps={tooltipProps}
      />
    </FlexForm>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.m};
  align-items: center;
  flex-grow: 1;
`
);

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchKeyOption>)`
  flex-grow: 1;
`;

export default AccountsScopedSearchBar;
