import { ToastConfig } from "@taxbit-dashboard/commons";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";

export enum IrFormActionsToastTrackingId {
  DeleteSuccessToast = "ir-forms-delete-confirmation-modal-success-toast",
  DeleteErrorToast = "ir-forms-delete-confirmation-modal-error-toast",
}

export const successDeleteToastContent: ToastConfig = {
  message: "Forms have been successfully deleted.",
  trackingId: IrFormActionsToastTrackingId.DeleteSuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const getDeleteErrorToastContent = ({
  errorCount,
  totalCount,
}: {
  errorCount: number;
  totalCount: number;
}): ToastConfig => ({
  message:
    totalCount > 1
      ? `There was an error and ${errorCount}/${totalCount} of the requested forms were not deleted. Please try again.`
      : `Failed to delete the form. Please try again.`,
  trackingId: IrFormActionsToastTrackingId.DeleteErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
});
