import { RhfDateRangePicker } from "@taxbit-private/cosmic-react-hook-form";

import { AccountsFilterDrawerFormFields } from "../accountsFilterDrawerFormFieldSchema";

const AccountsDateRangeFilter: React.FC = () => {
  return (
    <RhfDateRangePicker<Pick<AccountsFilterDrawerFormFields, "dateRange">>
      name="dateRange"
      label="Account Creation Date"
      trackingId="accounts-filters-drawer-date-range-picker"
      shouldAlignMenuToTargetWidth={true}
      placeholder="All Time"
      maxDate={new Date()}
    />
  );
};

export default AccountsDateRangeFilter;
