import styled from "styled-components";

import FixedPositionNotificationsWrapper from "./FixedPositionNotificationsWrapper";

const ActionBarWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <TableExtensionDiv />
      <FixedPositionNotificationsWrapper
        padding="none l l l"
        gap="s"
        direction="column"
        alignItems="center"
      >
        {children}
      </FixedPositionNotificationsWrapper>
    </>
  );
};

// Used to provide space between the BulkActionBar and the table.
const TableExtensionDiv = styled.div(
  ({ theme }) => `height: ${theme.spacing.l};`
);

export default ActionBarWrapper;
