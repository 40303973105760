import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const form1099MiscAggregateValuesSchema = z.object({
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099MiscIngested
  ),
  taxYearTotalOtherIncome: z.number(),
  taxYearTotalRentIncome: z.number(),
  taxYearTotalRoyaltiesIncome: z.number(),
  taxYearTotalMedicalPaymentIncome: z.number(),
  taxYearTotalGrossProceedsPaidToAnAttorneyIncome: z.number(),
});
export type Form1099MiscAggregateValues = z.infer<
  typeof form1099MiscAggregateValuesSchema
>;
