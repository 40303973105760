import { zodResolver } from "@hookform/resolvers/zod";
import {
  getBrowserDateFromUtcDateString,
  getUtcDateStringFromBrowserDate,
  hasDefinedValues,
} from "@taxbit-dashboard/commons";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import {
  TransfersFilterDrawerFormFields,
  transfersFilterDrawerFormFieldsSchema,
} from "./transfersFilterDrawerFormFieldTypes";
import { TransfersTableParams } from "../../../../api/transfers/transfersApiTypes";
import useTransfersTableData from "../table/useTransfersTableData";

const getTransfersFormDataFromUrlParams = ({
  transferStatuses,
  endDate,
  startDate,
}: TransfersTableParams): TransfersFilterDrawerFormFields => ({
  transferStatuses: transferStatuses ?? [],
  dateRange: [
    getBrowserDateFromUtcDateString(startDate),
    getBrowserDateFromUtcDateString(endDate),
  ],
});

const transfersFormDataEmptyValues: TransfersFilterDrawerFormFields = {
  transferStatuses: [],
  dateRange: [undefined, undefined],
};

const useTransfersFilterDrawerForm = () => {
  const { urlParams, setFilterParams } = useTransfersTableData();

  const defaultValues = useMemo(
    () => getTransfersFormDataFromUrlParams(urlParams),
    [urlParams]
  );

  const formMethods = useForm<TransfersFilterDrawerFormFields>({
    resolver: zodResolver(transfersFilterDrawerFormFieldsSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(
        ({ transferStatuses, dateRange: [startDate, endDate] }) => {
          setFilterParams((draft) => {
            draft.startDate =
              startDate && getUtcDateStringFromBrowserDate(startDate);
            draft.endDate =
              endDate && getUtcDateStringFromBrowserDate(endDate, false);
            draft.transferStatuses = transferStatuses;
          });
        }
      )(e);
    },
    [formMethods, setFilterParams]
  );

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset(getTransfersFormDataFromUrlParams(urlParams));
  }, [urlParams, formMethods]);

  /**
   * Clears all form filters. We must use the `setValue` form methods to individually
   * mark each state as dirty, in order to not mess with RHF's state handling.
   */
  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof TransfersFilterDrawerFormFields;

      if (value !== transfersFormDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, transfersFormDataEmptyValues[typedKey], {
          shouldDirty: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useTransfersFilterDrawerForm;
