import { AssetAmount } from "@taxbit-dashboard/rest";
import { SummaryGrid, Card } from "@taxbit-private/cosmic";
import { useMemo } from "react";

import useFormatAsset from "../../../../utils/useFormatAsset";

type DisposalSummaryProps = {
  totalCost?: AssetAmount;
  totalProceeds?: AssetAmount;
  totalGain?: AssetAmount;
  totalDisposals?: number;
};

const DisposalSummary: React.FC<DisposalSummaryProps> = ({
  totalCost,
  totalProceeds,
  totalGain,
  totalDisposals = 0,
}) => {
  const formatAsset = useFormatAsset();

  const summaryGridContents = useMemo(
    () => ({
      summaries: [
        {
          trackingId: "total-disposals-grid-block",
          value: totalDisposals.toString(),
          title: "Total Disposals",
          helpIconProps: {
            tooltipProps: {
              content:
                "The total count of individual tax lots disposed of in this transaction.",
            },
            trackingId: "total-disposals-transaction-tooltip",
          },
        },
        {
          trackingId: "total-proceeds-grid-block",
          value: formatAsset({ assetAmount: totalProceeds }),
          title: "Total Proceeds",
          helpIconProps: {
            tooltipProps: {
              content:
                "The total amount of money generated from this transaction, after all expenses and fees have been considered.",
            },
            trackingId: "total-proceeds-tooltip",
          },
        },
        {
          trackingId: "total-cost-basis-grid-block",
          value: formatAsset({ assetAmount: totalCost }),
          title: "Total Cost Basis",
          helpIconProps: {
            tooltipProps: {
              content:
                "The total cost basis of all inventory disposed of in this transaction.",
            },
            trackingId: "total-cost-basis-tooltip",
          },
        },
        {
          trackingId: "total-gain-loss-grid-block",
          value: formatAsset({ assetAmount: totalGain }),
          title: "Total Gain/Loss",
          helpIconProps: {
            tooltipProps: {
              content:
                "The amount by which the total proceeds of this transaction exceed (are less than) the total cost basis.",
            },
            trackingId: "total-gain-loss-tooltip",
          },
        },
      ],
    }),
    [formatAsset, totalCost, totalDisposals, totalGain, totalProceeds]
  );

  return (
    <Card title="Disposal Summary">
      <SummaryGrid {...summaryGridContents} />
    </Card>
  );
};

export default DisposalSummary;
