import { useAuth0 } from "@auth0/auth0-react";
import {
  useDashboardStore,
  useGetAccessTokenAndUpdateAuthState,
} from "@taxbit-dashboard/commons";
import { useEffectOnceWhen } from "rooks";

const useInitializeUserPermissions = () => {
  const { isAuthenticated } = useAuth0();
  const userPermissions = useDashboardStore((store) => store.userPermissions);
  const getAccessTokenAndUpdateAuthState =
    useGetAccessTokenAndUpdateAuthState();

  const isInitializationNeeded = isAuthenticated && !userPermissions;

  useEffectOnceWhen(() => {
    void getAccessTokenAndUpdateAuthState();
  }, isInitializationNeeded);
};

export default useInitializeUserPermissions;
