import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099KAggregateValuesSchema = z.object({
  januaryAmount: z.number(),
  februaryAmount: z.number(),
  marchAmount: z.number(),
  aprilAmount: z.number(),
  mayAmount: z.number(),
  juneAmount: z.number(),
  julyAmount: z.number(),
  augustAmount: z.number(),
  septemberAmount: z.number(),
  octoberAmount: z.number(),
  novemberAmount: z.number(),
  decemberAmount: z.number(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateTaxWithheld: z.number(),
  federalTaxWithheld: z.number(),
  merchantCategoryCode: z.string().optional(),
  grossAmountOfPaymentsTransactions: z.number(),
  numberOfPaymentTransactions: z.number(),
  cardNotPresentTransactions: z.number(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099kFormData
  ),
});

export type FormItem1099KAggregateValues = z.infer<
  typeof formItem1099KAggregateValuesSchema
>;
