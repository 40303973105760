import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import { Box, Button, TrimmedBoxContent } from "@taxbit-private/cosmic";
import { useState } from "react";

import InvitationManagementTable from "./InvitationManagementTable";
import InviteCompanyUserModal from "../../../../components/InviteCompanyUserModal";

enum InvitationManagementTrackingId {
  InviteUserButton = "invite-user-button",
}

const InvitationManagement = () => {
  const [shouldShowInviteUserModal, setShouldShowInviteUserModal] =
    useState(false);

  return (
    <DashboardPrimaryTemplate
      title="Invitation Management"
      utilityElement={
        <Button
          label="Invite User"
          trackingId={InvitationManagementTrackingId.InviteUserButton}
          onClick={() => setShouldShowInviteUserModal(true)}
        />
      }
    >
      <Box>
        <TrimmedBoxContent trim="all">
          <InvitationManagementTable />
        </TrimmedBoxContent>
      </Box>
      <InviteCompanyUserModal
        isOpen={shouldShowInviteUserModal}
        onClose={() => setShouldShowInviteUserModal(false)}
      />
    </DashboardPrimaryTemplate>
  );
};

export default InvitationManagement;
