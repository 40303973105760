import { Avatar, Flex, H4 } from "@taxbit-private/cosmic";
import styled from "styled-components";

import TopNavigationActionMenu from "./OrganizationAndCompanyPicker";
import TopNavigationActionMenuDivider from "./TopNavigationActionMenuDivider";
import TopNavigationActionMenuItem from "./TopNavigationActionMenuItem";
import TopNavigationActionMenuItems from "./TopNavigationActionMenuItems";
import { useTopNavigationActionMenuContext } from "./useTopNavigationActionMenu";
import getInitialsForTopNavDropdown from "../shared/getInitialsForTopNavDropdown";

export enum TopNavigationActionMenuContentsTrackingId {
  OpenCompanySwitcherButton = "top-navigation-open-company-switcher-button",
}

const TopNavigationActionMenuContents: React.FC = () => {
  const {
    currentCompanyUser,
    isLoading,
    isOrgAndCompanyPickerOpen,
    setIsOrgAndCompanyPickerOpen,
    hasAccessToMoreThanOneOrganization,
  } = useTopNavigationActionMenuContext();

  return isOrgAndCompanyPickerOpen ? (
    <PanelWrapper direction="column">
      <TopNavigationActionMenu />
    </PanelWrapper>
  ) : (
    <PanelWrapper direction="column">
      <AvatarSectionWrapper gap="s">
        <Flex gap="s" alignItems="center">
          <Avatar
            label={getInitialsForTopNavDropdown(currentCompanyUser.name)}
            variant="secondary"
            size="small"
          />
          <Flex direction="column">
            <H4 forwardedAs="div">{currentCompanyUser.name}</H4>
            {currentCompanyUser.email}
          </Flex>
        </Flex>
      </AvatarSectionWrapper>
      <TopNavigationActionMenuDivider />
      {!isLoading && hasAccessToMoreThanOneOrganization && (
        <>
          <TopNavigationActionMenuItem
            onClick={() => setIsOrgAndCompanyPickerOpen(true)}
            trackingId={
              TopNavigationActionMenuContentsTrackingId.OpenCompanySwitcherButton
            }
            label="Switch organization"
            rightIcon="chevron-right"
            leftIcon="shuffle"
            shouldCloseOnClick={false}
          />
          <TopNavigationActionMenuDivider />
        </>
      )}
      <TopNavigationActionMenuItems />
    </PanelWrapper>
  );
};

const PanelWrapper = styled(Flex)(
  ({ theme }) => `
  width: ${theme.measure.m};
`
);

const AvatarSectionWrapper = styled(Flex)(
  ({ theme }) => `
  padding: 20px ${theme.spacing.m} 12px;
`
);

export default TopNavigationActionMenuContents;
