import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
} from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useFormatUsdValue from "../../../../utils/useFormatUsdValue";

const useForm1099NecEligibilityTableColumns = () => {
  const formatUsdValue = useFormatUsdValue();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        label: "Nec Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099NecIngested
          ) {
            return formatUsdValue(
              formSpecificFields.taxYearTotalAdjustedReceivedQuantity.toString()
            );
          }

          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099NecFormData
          ) {
            return formatUsdValue(
              formSpecificFields.nonemployeeComp.toString()
            );
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
    ],
    [formatUsdValue]
  );
};

export default useForm1099NecEligibilityTableColumns;
