import { TinValidationStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";

import { AccountsFilterDrawerFormFields } from "./accountsFilterDrawerFormFieldSchema";
import { tinStatusLabelMap } from "../../../../../api/accounts/accountsApiTypes";

const TIN_VALIDATION_OPTIONS = Object.values(TinValidationStatus).map(
  (status) => ({
    key: status,
    label: tinStatusLabelMap[status],
  })
);

const AccountsTinValidationStatusFilter: React.FC = () => {
  return (
    <RhfMultiselect<
      TinValidationStatus,
      Pick<AccountsFilterDrawerFormFields, "tinValidationStatuses">,
      "tinValidationStatuses",
      (typeof TIN_VALIDATION_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="tinValidationStatuses"
      options={TIN_VALIDATION_OPTIONS}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="TIN Validation Status"
      placeholder="All"
      trackingId="accounts-filters-drawer-tin-validation-status-filter"
    />
  );
};

export default AccountsTinValidationStatusFilter;
