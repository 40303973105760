import {
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
  Flex,
} from "@taxbit-private/cosmic";

import AccountsPagination from "./AccountsPagination";
import AccountsScopedSearchBar from "./filters/AccountsScopedSearchBar";
import AccountsFilterChips from "./filters/chips/AccountsFilterChips";
import AccountsFilterDrawerToggle from "./filters/drawer/AccountsFilterDrawerToggle";
import AccountsTable from "./table/AccountsTable";
import useAccountsTableData from "./useAccountsTableData";

export const AccountsContentTrackingId = {
  LoadingSpinner: "accounts-content-loading-spinner",
};

const AccountsContent: React.FC = () => {
  const { isLoading, isError, hasUrlParams, data } = useAccountsTableData();

  const tableContent = (
    <Flex direction="column">
      <Flex padding="m" direction="column" gap="m">
        <Flex gap="m">
          <AccountsFilterDrawerToggle />
          <AccountsScopedSearchBar />
        </Flex>
        <AccountsFilterChips />
      </Flex>
      <Divider />
      <AccountsTable />
      {/* If we are in an error or initial loading state, we can't accurately show pagination info. */}
      {!isError && !isLoading && (
        <>
          <Divider />
          <AccountsPagination />
        </>
      )}
    </Flex>
  );

  // If we load the page with any params set, we must show
  // the table view so that the param controls are visible.
  if (hasUrlParams) {
    return tableContent;
  } else if (isLoading) {
    return (
      <ContentSpinner trackingId={AccountsContentTrackingId.LoadingSpinner} />
    );
  } else if (isError) {
    return <ContentErrorEmptyState entity="accounts" />;
  } else if (data?.length === 0) {
    return <ContentEntityEmptyState entity="accounts" />;
  } else {
    return tableContent;
  }
};

export default AccountsContent;
