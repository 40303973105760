import { doesResponseErrorMatchStatusCode } from "@taxbit-dashboard/commons";

import { mapInventorySummaryToAssetTableColumns } from "./inventoryTableColumns";
import { useGetInventorySummary } from "../../../../api/inventorySummaryApi";
import useAccountId from "../../../../utils/useAccountId";

const useGetInventory = () => {
  const accountId = useAccountId();

  const {
    data: inventorySummary,
    isFetching: isLoading,
    isError,
    error,
  } = useGetInventorySummary(accountId);

  const inventoryItems =
    mapInventorySummaryToAssetTableColumns(inventorySummary);

  const isAccountNotFoundError = doesResponseErrorMatchStatusCode(error, 401);

  return {
    isLoading,
    inventoryItems,
    isError,
    isAccountNotFoundError,
  };
};

export default useGetInventory;
