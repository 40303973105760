import { PrimaryTemplate } from "@taxbit-private/cosmic";

type PrimaryTemplateProps = React.ComponentProps<typeof PrimaryTemplate>;

const DashboardPrimaryTemplate: React.FC<PrimaryTemplateProps> = (props) => {
  const { children, ...componentProps } = props;

  return (
    <PrimaryTemplate {...componentProps} shouldHaveMaxWidth={true}>
      {children}
    </PrimaryTemplate>
  );
};

export default DashboardPrimaryTemplate;
