import { AssetType, Asset } from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import useFormatAsset from "./useFormatAsset";

/**
 * A hook that returns a helper method to format value that we know is a USD
 * value, but we don't know the asset details for.
 */
const useFormatUsdValue = () => {
  const formatAsset = useFormatAsset();
  return useCallback(
    (value?: string) =>
      formatAsset({
        assetAmount: value
          ? {
              amount: value,
              asset: { code: "USD", type: AssetType.Fiat, id: "" } as Asset,
            }
          : undefined,
      }),
    [formatAsset]
  );
};

export default useFormatUsdValue;
