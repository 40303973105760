import { useNavigate } from "@tanstack/react-location";
import { useCallback } from "react";

import useOrganizationPrefixedNavigation from "./useOrganizationPrefixedNavigation";
import { PageContextPageKey } from "../../store/page-context/pageContextSliceModel";
import useDashboardStore from "../../store/useDashboardStore";

/**
 * Returns a helper to navigate back to the previous href stored in global state
 * for the back button on the given page. In the event that the href is undefined
 * (for example, if the page has just been refreshed) then this helper will trigger
 * a prefixed navigation to the fallbackHref.
 */
const useNavigateBackWithFallback = () => {
  const backHrefs = useDashboardStore((store) => store.backHrefs);
  const navigate = useNavigate();
  const { prefixedNavigate } = useOrganizationPrefixedNavigation();

  return useCallback(
    (key: PageContextPageKey, fallbackHref: string) => {
      const backHref = backHrefs[key];
      // The previous hrefs stored in global state already include organization
      // prefixes, so there's no need to use our prefixed navigate.
      if (backHref) {
        navigate({
          to: backHref,
        });
      } else {
        prefixedNavigate({
          to: fallbackHref,
        });
      }
    },
    [navigate, prefixedNavigate, backHrefs]
  );
};

export default useNavigateBackWithFallback;
