import {
  TRACKING_EXCLUDE_CLASS,
  useCopyToClipboard,
} from "@taxbit-dashboard/commons";
import { ActionButton, Body, Flex } from "@taxbit-private/cosmic";

export enum CopyDataTrackingId {
  CopyDataButton = "copyDataBtn",
}

type CopyDataProps = {
  data: string;
  name: string;
};

const CopyData: React.FC<CopyDataProps> = ({ data, name }) => {
  const copyToClipboard = useCopyToClipboard();
  return (
    <Flex gap="s">
      <Body className={TRACKING_EXCLUDE_CLASS}>{data}</Body>
      <ActionButton
        iconName="copy"
        trackingId={CopyDataTrackingId.CopyDataButton}
        onClick={() => {
          void copyToClipboard(name, data);
        }}
        label={`Copy ${name}`}
      />
    </Flex>
  );
};

export default CopyData;
