/**
 * Uses a browser date delivered from the date range picker to determine
 * the correct date (day/month/year) and then creates an ISO date string
 * that represents the start or end of that given date in UTC.
 */
const getUtcDateStringFromBrowserDate = (date: Date, isStartOfDay = true) => {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  return new Date(
    Date.UTC(
      year,
      monthIndex,
      day,
      ...(isStartOfDay ? [0, 0, 0, 0] : [23, 59, 59, 999])
    )
  ).toISOString();
};

export default getUtcDateStringFromBrowserDate;
