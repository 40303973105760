import { Navigate } from "@tanstack/react-location";
import {
  UserPermission,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  AuthorizedView,
} from "@taxbit-dashboard/commons";

import { EligibilityDataProvider } from "./eligible-users/context/useEligibilityData";
import EligibleUsers from "./eligible-users/EligibleUsers";
import { IrFormsContextProvider } from "./forms/context/useIrForms";
import Forms from "./forms/Forms";
import { IrSummaryDataProvider } from "./summary/context/IrSummaryContext";
import IrSummary from "./summary/IrSummary";

export const irSummaryNavigationItem: DashboardNavigationItemChild = {
  href: "/ir/summary",
  label: "Summary",
  trackingId: "sidebar-navigation-ir-summary-anchor",
  key: "summary",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountDocuments] &&
    (flags.hasIrSummaryPageAccess || flags.hasIrItemsPreviewAccess),
  shouldDisableRoute: ({ flags }) => !flags.hasIrSummaryPageAccess,
} as const;

export const eligibilityNavigationItem: DashboardNavigationItemChild = {
  href: "/ir/eligibility",
  label: "Eligibility",
  trackingId: "sidebar-navigation-ir-eligibility-anchor",
  key: "eligibility",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountDocuments] &&
    (flags.hasEligibilityPageAccess || flags.hasIrItemsPreviewAccess),
  shouldDisableRoute: ({ flags }) => !flags.hasEligibilityPageAccess,
} as const;

export const formsNavigationItem: DashboardNavigationItemChild = {
  href: "/ir/forms",
  label: "Forms",
  trackingId: "sidebar-navigation-ir-forms-anchor",
  key: "forms",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountDocuments] &&
    (flags.hasIrFormsPageAccess || flags.hasIrItemsPreviewAccess),
  shouldDisableRoute: ({ flags }) => !flags.hasIrFormsPageAccess,
} as const;

export const navigationItem: DashboardNavigationItemWithChildren = {
  iconName: "file-text",
  label: "Information Reporting",
  trackingId: "sidebar-navigation-ir-anchor",
  children: [
    irSummaryNavigationItem,
    eligibilityNavigationItem,
    formsNavigationItem,
  ],
} as const;

const authorizedViewProps = {
  flags: ["hasIrSummaryPageAccess"],
  permissions: [UserPermission.ReadAccountDocuments],
} as const;

export const route = {
  path: "ir",
  children: [
    {
      path: "/",
      element: <Navigate to="./summary" />,
    },
    {
      path: "summary",
      element: (
        <AuthorizedView {...authorizedViewProps}>
          <IrSummaryDataProvider>
            <IrSummary />
          </IrSummaryDataProvider>
        </AuthorizedView>
      ),
    },
    {
      path: "eligibility",
      element: (
        <AuthorizedView
          {...authorizedViewProps}
          flags={["hasEligibilityPageAccess"]}
        >
          <EligibilityDataProvider>
            <EligibleUsers />
          </EligibilityDataProvider>
        </AuthorizedView>
      ),
    },
    {
      path: "forms",
      element: (
        <AuthorizedView
          {...authorizedViewProps}
          flags={["hasIrFormsPageAccess"]}
        >
          <IrFormsContextProvider>
            <Forms />
          </IrFormsContextProvider>
        </AuthorizedView>
      ),
    },
  ],
};
