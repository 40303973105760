/**
 * Groups an array of items together given a specified criterion.
 * @param arr an array of items
 * @param getGroupingKey a function that accepts an item, and makes the key used
 * to group the items equal to the return value of the getGroupingKey function
 * @returns an object of the provided items, grouped by the key
 */
export const groupBy = <T>(arr: T[], getGroupingKey: (item: T) => string) => {
  // eslint-disable-next-line unicorn/prefer-object-from-entries
  return arr.reduce<Record<string, T[]>>((obj, item) => {
    const key = getGroupingKey(item);
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = [];
    }
    obj[key].push(item);
    return obj;
  }, {});
};
