import { z } from "zod";

export const DOWNLOAD_FORMS_FORM_ID = "download-forms-form";

export const downloadFormsModalFormSchema = z.object({
  population: z.enum(["all", "filtered"]).optional(),
});
export type DownloadFormsModalForm = z.infer<
  typeof downloadFormsModalFormSchema
>;

export const downloadFormsModalFormDefaultValues = {
  population: "filtered",
};
