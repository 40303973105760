import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  KycTaxDocumentType,
  KycTaxdocumentationVatStatus,
} from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import maskData from "../../../../../utils/maskData";

export type VatInformation = {
  vatin?: string;
  unmaskedVatin?: string;
  vatinCountry?: string;
  vatStatus: KycTaxdocumentationVatStatus;
  vatValidationDate?: string;
};

const useGetVatInformation = () => {
  const {
    shouldUnmaskValue,
    isLoading,
    taxDocumentations,
    taxDocumentationStatus,
    isError: isAccountDetailsError,
    isTaxDocumentationStatusError,
  } = useAccountOwnerTaxDocumentContext();

  const { shouldUseNewDpsStatus } = useDashboardFeatureFlags();
  const taxDocumentationData = useMemo(() => {
    const dps = taxDocumentations?.find(
      (doc) => doc.documentType === KycTaxDocumentType.Dps
    );

    if (dps?.documentType !== KycTaxDocumentType.Dps) {
      return undefined;
    }

    return {
      ...dps,
      vatin: maskData(dps.vatin),
      unmaskedVatin: shouldUnmaskValue ? dps.vatin : undefined,
    };
  }, [shouldUnmaskValue, taxDocumentations]);

  const vatInformation: VatInformation | undefined = useMemo(() => {
    if (
      shouldUseNewDpsStatus &&
      taxDocumentationStatus?.dpsQuestionnaire?.vatStatus &&
      taxDocumentationData
    ) {
      return {
        vatin: taxDocumentationData.vatin,
        unmaskedVatin: shouldUnmaskValue
          ? taxDocumentationData.unmaskedVatin
          : undefined,
        vatinCountry: taxDocumentationData.vatinCountry,
        vatStatus: taxDocumentationStatus.dpsQuestionnaire.vatStatus,
        vatValidationDate:
          taxDocumentationStatus.dpsQuestionnaire.vatValidationDate,
      };
    }

    if (
      !shouldUseNewDpsStatus &&
      taxDocumentationStatus?.dac7Interview?.vatStatus &&
      taxDocumentationData
    ) {
      return {
        vatin: taxDocumentationData.vatin,
        unmaskedVatin: shouldUnmaskValue
          ? taxDocumentationData.unmaskedVatin
          : undefined,
        vatinCountry: taxDocumentationData.vatinCountry,
        vatStatus: taxDocumentationStatus.dac7Interview.vatStatus,
        vatValidationDate:
          taxDocumentationStatus.dac7Interview.vatValidationDate,
      };
    }

    return undefined;
  }, [
    shouldUnmaskValue,
    taxDocumentationData,
    taxDocumentationStatus,
    shouldUseNewDpsStatus,
  ]);

  const isError = isAccountDetailsError || isTaxDocumentationStatusError;

  return {
    isLoading,
    isError,
    vatInformation,
  };
};

export default useGetVatInformation;
