import { Flex, Modal } from "@taxbit-private/cosmic";
import { RhfRadioGroup } from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";

import useDownloadFormsConfirmationModal from "./useDownloadFormsConfirmationModal";
import {
  DOWNLOAD_FORMS_FORM_ID,
  DownloadFormsModalForm,
} from "../../context/downloadFormsModalFormTypes";

type DownloadFormsConfirmationModalProps = {
  onClose: () => void;
  isOpen: boolean;
  isBulkDownload?: boolean;
};

export enum IrFormsDownloadConfirmationModalTrackingId {
  CloseButton = "ir-forms-download-confirmation-modal-close-btn",
  CancelButton = "ir-forms-download-confirmation-modal-cancel-btn",
  SubmitButton = "ir-forms-download-confirmation-modal-submit-btn",
  PopulationRadioGroup = "ir-forms-download-confirmation-modal-population-radio-group",
}

const DownloadFormsConfirmationModal = ({
  onClose,
  isOpen,
  isBulkDownload,
}: DownloadFormsConfirmationModalProps) => {
  const {
    downloadFormsModalFormMethods,
    getDownloadFormsModalPopulationOptions,
    handleDownloadForms,
    hasFiltersApplied,
    modalContent,
    modalNote,
  } = useDownloadFormsConfirmationModal({ isBulkDownload, onClose });

  return (
    <Modal
      title="Download Forms"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        IrFormsDownloadConfirmationModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: IrFormsDownloadConfirmationModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        form: DOWNLOAD_FORMS_FORM_ID,
        type: "submit",
        label: "Download",
        trackingId: IrFormsDownloadConfirmationModalTrackingId.SubmitButton,
        variant: "button-primary",
      }}
    >
      <FormProvider {...downloadFormsModalFormMethods}>
        <form id={DOWNLOAD_FORMS_FORM_ID} onSubmit={handleDownloadForms}>
          <Flex direction="column" gap="l">
            {modalContent}
            {isBulkDownload && hasFiltersApplied && (
              <RhfRadioGroup<DownloadFormsModalForm, "population">
                name="population"
                options={getDownloadFormsModalPopulationOptions()}
                trackingId={
                  IrFormsDownloadConfirmationModalTrackingId.PopulationRadioGroup
                }
              />
            )}
            {modalNote}
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default DownloadFormsConfirmationModal;
