import JsonData from "./types/JsonData";
import JsonDataZodSchema from "./types/JsonDataZodSchema";

export default <TResponseDataSchema extends JsonDataZodSchema>(
  data: JsonData | FormData,
  schema: TResponseDataSchema,
  errorMessagePrefix: string
) => {
  try {
    return schema.parse(data);
  } catch (e) {
    const schemaErrorMessage = e instanceof Error ? `\n${e.message}` : "";
    throw new Error(`${errorMessagePrefix}${schemaErrorMessage}`);
  }
};
