import {
  Link,
  TRACKING_EXCLUDE_CLASS,
  irFormTypeToDisplayNameMap,
  renderExcludedSpan,
} from "@taxbit-dashboard/commons";
import {
  AccountValidationsEligibilityStatus,
  DashboardEligibilityFormStatus,
  DashboardFormType,
  EligibilityDataItem,
  EligibilityStatus,
} from "@taxbit-dashboard/rest";
import {
  Badge,
  Body,
  Flex,
  Icon,
  TableColumns,
  Tooltip,
} from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import styled from "styled-components";

import {
  dashboardEligibilityFormStatusLabelMap,
  dashboardEligibilityFormStatusVariantMap,
  getEligibilityStatusLabelMap,
  toDashboardEligibilityFormStatus,
} from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

export enum EligibilityTableTrackingIds {
  AccountLink = "irEligibilityTableAccountLink",
  AccountValidationTooltip = "irEligibilityTableAccountValidationTooltip",
}

const PII_VALIDATION_TOOLTIP_CONTENT =
  "To generate a deliverable, all required account data must be present and valid. 'Incomplete' means some data is missing or invalid. Click the Account ID link to view details on the Account Overview page.";

type GetBaseEligibilityTableColumnsProps = {
  onAccountLinkClick: () => void;
  getAccountLink: (accountId: EligibilityDataItem["accountId"]) => string;
  formType: DashboardFormType;
};

const getBaseEligibilityTableColumns = ({
  onAccountLinkClick,
  getAccountLink,
  formType,
}: GetBaseEligibilityTableColumnsProps): TableColumns<EligibilityDataItem> => [
  {
    key: "accountExternalId",
    label: "Account ID",
    isContentFullWidth: true,
    renderCell: (accountExternalId, eligibilityData) => (
      <Link
        to={getAccountLink(eligibilityData.accountId)}
        trackingId={EligibilityTableTrackingIds.AccountLink}
        onClick={onAccountLinkClick}
        className={TRACKING_EXCLUDE_CLASS}
        label={accountExternalId}
        variant="anchor-primary-inline"
      />
    ),
  },
  {
    key: "name",
    label: "Name",
    shouldTruncate: true,
    minWidth: 128,
    renderCell: renderExcludedSpan,
  },
  {
    key: "status",
    label: "Status",
    isContentFullWidth: true,
    renderCell: (status) => {
      return (
        <Badge
          variant={
            status === EligibilityStatus.Eligible ||
            status === EligibilityStatus.Added
              ? "success"
              : "secondary"
          }
          label={getEligibilityStatusLabelMap(formType)[status]}
          shouldTransformLabel={false}
        />
      );
    },
  },
  {
    key: "accountValidationsEligibilityStatus",
    label:
      formType === DashboardFormType.Dac7
        ? "Data Validations"
        : "Account Validation",
    isContentFullWidth: true,
    renderCell: (accountValidationsEligibilityStatus) => {
      if (!accountValidationsEligibilityStatus)
        return (
          <Badge
            variant="secondary"
            label="Pending"
            shouldTransformLabel={false}
          />
        );

      return (
        <>
          <Badge
            key={accountValidationsEligibilityStatus}
            variant={
              accountValidationsEligibilityStatus ===
              AccountValidationsEligibilityStatus.Incomplete
                ? "danger"
                : "success"
            }
            label={
              accountValidationsEligibilityStatus ===
              AccountValidationsEligibilityStatus.Incomplete
                ? "Incomplete"
                : "Complete"
            }
            shouldTransformLabel={false}
          />
          <WarningIconWrapper>
            {accountValidationsEligibilityStatus ===
              AccountValidationsEligibilityStatus.CompleteWithWarnings && (
              <Tooltip
                arrow={false}
                content={
                  <Flex direction="column" gap="m">
                    <Body>
                      This account has all valid data points that are required
                      to generate a {irFormTypeToDisplayNameMap[formType]} (TIN,
                      Name, Address, and IRA Type).
                    </Body>
                    <Body>
                      However, it is missing optional data (Date of Birth and/or
                      Established Date), which may limit validation we are able
                      to perform.
                    </Body>
                    <Body>
                      Click into the account to view additional details.
                    </Body>
                  </Flex>
                }
                target={<Icon name="alert-triangle" />}
              />
            )}
          </WarningIconWrapper>
        </>
      );
    },
    helpIconProps: {
      trackingId: EligibilityTableTrackingIds.AccountValidationTooltip,
      tooltipProps: {
        content: PII_VALIDATION_TOOLTIP_CONTENT,
        appendTo: "body",
        isInteractive: false,
      },
    },
  },
  {
    key: "formStatus",
    label: "Form Status",
    isContentFullWidth: true,
    renderCell: (formStatus, { status }) => {
      const dashboardFormStatus = toDashboardEligibilityFormStatus(formStatus);
      const shouldHideNotGeneratedFormStatus =
        dashboardFormStatus === DashboardEligibilityFormStatus.NotGenerated &&
        status !== EligibilityStatus.Eligible &&
        status !== EligibilityStatus.Added;

      return shouldHideNotGeneratedFormStatus ? (
        COSMIC_VALUE_PLACEHOLDER
      ) : (
        <Badge
          variant={
            dashboardEligibilityFormStatusVariantMap[dashboardFormStatus]
          }
          label={dashboardEligibilityFormStatusLabelMap[dashboardFormStatus]}
          shouldTransformLabel={false}
        />
      );
    },
  },
];

const WarningIconWrapper = styled.div`
  float: right;
  margin-left: 8px;
  top: 50%;
  -ms-transform: translateY(25%);
  transform: translateY(25%);
`;

export default getBaseEligibilityTableColumns;
