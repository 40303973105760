import { useMatchRoute } from "@tanstack/react-location";

import { buildOrganizationPathPrefix } from "./useOrganizationPrefixedNavigation";

type OrganizationRouteMatch = {
  authOrganizationId?: string;
  subroute?: string;
};

const useOrganizationRouteMatch = (): OrganizationRouteMatch => {
  const matchRoute = useMatchRoute();
  const match =
    matchRoute({
      to: `${buildOrganizationPathPrefix(":organizationId")}/*`,
    }) ?? {};

  return {
    authOrganizationId: match.organizationId,
    subroute: match["*"],
  };
};

export default useOrganizationRouteMatch;
