import BannerNotification from "./BannerNotification";
import ToastNotifications from "./ToastNotifications";

/**
 * All components that contain top-level notifications should be surfaced in this
 * component. This component will appear on top of every page in the shell, and
 * the contents of these components depend on the global state.
 */
const AppNotifications: React.FC = () => {
  return (
    <>
      <ToastNotifications />
      <BannerNotification />
    </>
  );
};

export default AppNotifications;
