import { useDashboardStore } from "@taxbit-dashboard/commons";
import { FilesApiFile } from "@taxbit-dashboard/rest";
import { Alert, Body, Flex, Modal, ModalProps } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import {
  useDeleteFile,
  useRemoveUpload,
} from "../../../../../api/files/filesApi";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";

type Props = Pick<ModalProps, "isOpen" | "onClose"> & { file: FilesApiFile };

export enum RemoveUploadModalTrackingId {
  CloseButton = "remove-download-modal-close-button",
  CancelButton = "remove-download-modal-cancel-button",
  RemoveButton = "remove-download-modal-remove-button",
  SuccessToast = "remove-download-modal-success-toast",
}

const RemoveUploadModal: React.FC<Props> = ({ onClose, isOpen, file }) => {
  const {
    mutate: removeUpload,
    isLoading: isRemovingUpload,
    isError: didRemovalFail,
  } = useRemoveUpload();
  const {
    mutate: deleteFile,
    isLoading: isDeletingFile,
    isError: didDeletionFail,
  } = useDeleteFile();

  const addToast = useDashboardStore((store) => store.addToast);

  const onSuccess = useCallback(() => {
    addToast({
      message: `${file.fileName} was removed successfully!`,
      timeoutMs: TOAST_TIMEOUT,
      trackingId: RemoveUploadModalTrackingId.SuccessToast,
    });
    onClose();
  }, [addToast, file.fileName, onClose]);

  const onClick = useCallback(() => {
    if (file.uploadId) {
      removeUpload({ uploadId: file.uploadId }, { onSuccess });
    } else {
      deleteFile({ fileId: file.fileId }, { onSuccess });
    }
  }, [onSuccess, file.fileId, file.uploadId, removeUpload, deleteFile]);

  return (
    <Modal
      title="Remove File"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={RemoveUploadModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: RemoveUploadModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Remove",
        variant: "button-danger",
        trackingId: RemoveUploadModalTrackingId.RemoveButton,
        loadingText: "Removing",
        isLoading: isRemovingUpload || isDeletingFile,
        onClick,
      }}
    >
      <Flex direction="column" gap="m">
        <Body>Would you like to remove this file from the list?</Body>
        <Body>
          Note: This data has not been ingested. Nothing will be deleted from
          TaxBit&apos;s system.
        </Body>
        {(didRemovalFail || didDeletionFail) && (
          <Alert variant="danger">
            Failed to remove {file.fileName}. Please try again later.
          </Alert>
        )}
      </Flex>
    </Modal>
  );
};

export default RemoveUploadModal;
