import { TaxReportType } from "@taxbit-dashboard/rest";

export enum UmcReportDisplayType {
  Custom = "Custom",
  Accounts = "Accounts",
  Ingestion = "Ingestion",
  Reconciliation = "Reconciliation",
  TaxForms = "Tax Forms",
}

export type UmcReport = {
  key: string;
  name: string;
  description: string;
  date: string;
  type: UmcReportDisplayType;
};

export type UmcTaxReport = UmcReport & { key: TaxReportType };
