import { Box, Divider, Flex } from "@taxbit-private/cosmic";

import FormsActionBar from "./actions/FormsActionBar";
import FormsTableExportButton from "./export/FormsTableExportButton";
import FormsTable from "./FormsTable";
import FormsTablePagination from "./FormsTablePagination";
import FormsFiltersDrawerToggle from "../drawer/FormsFiltersDrawerToggle";
import FormsFilterChips from "../filters/chips/FormsFilterChips";
import FormsScopedSearchBar from "../filters/FormsScopedSearchBar";

const FormsTableContent = () => {
  return (
    <>
      <Box>
        <Flex direction="column">
          <Flex padding="m" direction="column" gap="m">
            <Flex gap="s">
              <FormsFiltersDrawerToggle />
              <FormsScopedSearchBar />
              <Flex>
                <Divider direction="vertical" />
              </Flex>
              <FormsTableExportButton label="Export" />
            </Flex>
            <FormsFilterChips />
          </Flex>
          <Divider />
          <FormsTable />
          <Divider />
          <FormsTablePagination />
        </Flex>
      </Box>
      <FormsActionBar />
    </>
  );
};

export default FormsTableContent;
