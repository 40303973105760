import { FormGainLossLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import formatFormDataAssetQuantity from "./formatFormDataAssetQuantity";

const useFormItemsGainLossColumns = () => {
  const { formatDateTime, formatQuantity } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<FormGainLossLineItem> => [
      {
        key: "assetName",
        label: "Asset Name",
        isContentFullWidth: true,
      },
      {
        key: "assetSymbol",
        label: "Asset Symbol",
        isContentFullWidth: true,
      },
      {
        key: "assetQuantity",
        label: "Asset Quantity",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (assetQuantity) =>
          formatFormDataAssetQuantity(assetQuantity),
      },
      {
        key: "acquiredDate",
        label: "Acquired Date",
        isContentFullWidth: true,
        renderCell: (acquiredDate) =>
          formatDateTime({
            date: acquiredDate,
          }),
      },
      {
        key: "disposedDate",
        label: "Disposed Date",
        isContentFullWidth: true,
        renderCell: (disposedDate) =>
          formatDateTime({
            date: disposedDate,
          }),
      },
      {
        key: "proceeds",
        label: "Proceeds",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (proceeds) => formatQuantity(proceeds),
      },
      {
        key: "costBasis",
        label: "Cost Basis",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (costBasis) => formatQuantity(costBasis),
      },
      {
        key: "gainLossClassification",
        label: "Gain Loss Classification",
        isContentFullWidth: true,
      },
    ],
    [formatDateTime, formatQuantity]
  );
};

export default useFormItemsGainLossColumns;
