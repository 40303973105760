import { z } from "zod";

import {
  accountOwnerCuringSchema,
  createAccountOwnerCuringRequestSchema,
} from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const accountOwnerCuringResponseSchema = getPublicApiSuccessSchema(
  accountOwnerCuringSchema
);

export type CreateAccountOwnerCuringRequest = z.infer<
  typeof createAccountOwnerCuringRequestSchema
>;

export type AccountOwnerCuringResponse = z.infer<
  typeof accountOwnerCuringResponseSchema
>;
