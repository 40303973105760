import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import {
  ContentSpinner,
  Flex,
  getMediaQueryForBelowBreakpoint,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import { useIrSummaryDataContext } from "./context/IrSummaryContext";
import Deadlines from "./deadlines/Deadlines";
import FormEligibilityCard from "./eligibility/FormEligibilityCard";
import DataIngestionTimestamps from "./eligibility-timestamps/DataIngestionTimestamps";
import FormCountInformation, {
  FormCountInformationType,
} from "./FormCountInformation";
import IrFormYearUtilityDropdowns from "../shared/IrFormYearUtilityDropdowns";

export enum IrSummaryTrackingId {
  Spinner = "ir-summary-spinner",
}

const FORM_GENERATION_TOOLTIP_CONTENT =
  "The total number of eligible accounts with complete data, excluding those who have been manually removed from scope, for which an Original form has not been generated. This figure does not include eligible accounts with incomplete data, as a form can not yet be generated for those accounts.";
const FORM_FILING_TOOLTIP_CONTENT =
  "The total number of active forms that have been generated and delivered to end-users that have not been e-Filed with the IRS. These forms may be awaiting final review or approval, or may be scheduled for future filing. Monitor this count regularly to keep track of your remaining filing obligations and to ensure all necessary returns are filed with the IRS in accordance with applicable deadlines.";

const IrSummary: React.FC = () => {
  const {
    formMetadataCounts,
    hasEligibilityPageAccess,
    isFormMetadataError,
    isEligibilityCountsError,
    isLoading,
    shouldFileForm,
    ...props
  } = useIrSummaryDataContext();

  const isFormsCountError = isFormMetadataError || isEligibilityCountsError;

  const content = (
    <Flex gap="l" direction="column">
      {hasEligibilityPageAccess && (
        <SectionWrapper gap="l" growItems={true}>
          <Deadlines />
          <FormEligibilityCard />
        </SectionWrapper>
      )}
      <SectionWrapper gap="l" growItems={true}>
        <FormCountInformation
          isError={isFormsCountError}
          formInformation={formMetadataCounts?.generated}
          tooltipContent={FORM_GENERATION_TOOLTIP_CONTENT}
          type={FormCountInformationType.Generated}
        />
        <FormCountInformation
          isError={isFormsCountError}
          isEmpty={!shouldFileForm}
          type={FormCountInformationType.Filed}
          formInformation={formMetadataCounts?.filed}
          tooltipContent={FORM_FILING_TOOLTIP_CONTENT}
        />
      </SectionWrapper>
    </Flex>
  );

  return (
    <PageWrapper gap="l" direction="column">
      <DashboardPrimaryTemplate
        title="IR Summary"
        utilityElement={
          <IrFormYearUtilityDropdowns {...props} isDisabled={isLoading} />
        }
      >
        {isLoading ? (
          <ContentSpinner trackingId={IrSummaryTrackingId.Spinner} />
        ) : (
          content
        )}
      </DashboardPrimaryTemplate>
      {hasEligibilityPageAccess && !isLoading && <DataIngestionTimestamps />}
    </PageWrapper>
  );
};

const PageWrapper = styled(Flex)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.m};
`
);

const SectionWrapper = styled(Flex)(
  ({ theme }) => `
  flex-direction: row;

  ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
    flex-direction: column;
  }
`
);

export default IrSummary;
