import { z } from "zod";

/**
 * Due to an outstanding issue in zod, anything with a "catch" clause
 * is inferred as non-optional even if the catch is for an undefined value.
 * This can be fixed by adding an additional .optional(), which this
 * helper does for us to avoid unnecessary schema cruft.
 */
const withOptionalCatch = <T extends z.ZodTypeAny>(schema: z.ZodOptional<T>) =>
  schema.catch(undefined).optional();

export default withOptionalCatch;
