import { Button, ButtonProps } from "@taxbit-private/cosmic";

import useFormsTableExport, {
  FormsTableExportTrackingIds,
} from "./useFormsTableExport";

type FormsTableExportButtonProps = {
  isAnchor?: boolean;
  label: string;
};

const FormsTableExportButton: React.FC<FormsTableExportButtonProps> = ({
  isAnchor = false,
  label,
}) => {
  const { isLoading, createExport, isDisabled } = useFormsTableExport();
  const buttonProps:
    | Pick<ButtonProps, "variant">
    | Required<
        Pick<
          ButtonProps,
          "size" | "variant" | "loadingText" | "isLoading" | "iconName"
        >
      > = isAnchor
    ? {
        variant: "anchor-primary",
      }
    : {
        size: "small",
        variant: "button-tertiary",
        iconName: "upload",
        loadingText: "Exporting",
        isLoading,
      };

  return (
    <Button
      {...buttonProps}
      label={label}
      onClick={createExport}
      trackingId={FormsTableExportTrackingIds.ExportButton}
      isDisabled={isDisabled}
    />
  );
};

export default FormsTableExportButton;
