import { useMatch, MakeGenerics } from "@tanstack/react-location";
import { AccountId, TransactionId } from "@taxbit-dashboard/rest";

type RouteMatchGeneric = MakeGenerics<{
  Params: {
    transactionId?: TransactionId;
    accountId?: AccountId;
  };
}>;

/**
 * Pulls the `transactionId` and `accountId` params from the current route. If
 * used outside a transfer-activity route, the values will be undefined.
 */
const useTransferInfo = () => {
  const {
    params: { transactionId, accountId },
  } = useMatch<RouteMatchGeneric>();

  return {
    transactionId,
    accountId,
  };
};

export default useTransferInfo;
