import { identify, init, setUserVars } from "@fullstory/browser";
import { logError } from "@taxbit-dashboard/commons";
import { CompanyUser } from "@taxbit-dashboard/rest";

let isUserIdentified = false;

export const initializeFullStory = () => {
  try {
    init({ orgId: "NF3HD" });
  } catch (error) {
    logError({ message: "Unable to initialize FullStory", error });
  }
};

export const updateFullStoryUser = (
  { id, email, name }: CompanyUser,
  organizationId: string,
  organizationName: string
) => {
  const fullStoryUser = {
    uid: id,
    email,
    displayName: name,
    isUmcUser: true,
    organizationId,
    organizationName,
  };

  try {
    if (isUserIdentified) {
      setUserVars(fullStoryUser);
    } else {
      identify(fullStoryUser.uid, fullStoryUser);
      isUserIdentified = true;
    }
  } catch (error) {
    logError({ message: "Unable to update FullStory user", error });
  }
};
