import {
  ToastConfig,
  useDashboardStore,
  navigateToUrl,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { DownloadAccountsCsvStatus } from "@taxbit-dashboard/rest";
import { useState } from "react";

import {
  useGetAccountsCsvUrl,
  useInitiateAccountsCsvExport,
} from "../../../../api/accounts-exports/accountsExportApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import useAccountsTableData from "../useAccountsTableData";

export enum DownloadAccountsTrackingId {
  ErrorToast = "accountsCsvErrorToast",
  SuccessToast = "accountsCsvSuccessToast",
  InfoToast = "accountsCsvInfoToast",
}

const errorToastContent: ToastConfig = {
  message: "Accounts failed to export. Please try again.",
  trackingId: DownloadAccountsTrackingId.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
};

const successToastContent: ToastConfig = {
  message: "Your accounts have been exported.",
  trackingId: DownloadAccountsTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useGetAccountsCsv = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [exportId, setExportId] = useState("");

  const addToast = useDashboardStore((store) => store.addToast);
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const { urlParams } = useAccountsTableData();

  const { mutate: generateAccountsCsvUrl } = useInitiateAccountsCsvExport();

  useGetAccountsCsvUrl({
    params: { exportId },
    onSuccess: (response) => {
      const { data } = response;
      if (
        data?.status === DownloadAccountsCsvStatus.Finished &&
        data.presignedUrl
      ) {
        setExportId("");
        if (!hasNotificationCenterAccess) {
          setIsLoading(false);
          navigateToUrl(data.presignedUrl);
          addToast(successToastContent);
        }
      }
    },
    onError: () => {
      addToast(errorToastContent);
    },
  });

  const generateAccountsCsv = (shouldExportAllAccounts = false) => {
    setIsLoading(true);
    const toastMessage = hasNotificationCenterAccess
      ? "Your accounts are being exported. The CSV file will appear in the notifications tab when ready to download."
      : "Your accounts are being exported.";
    addToast({
      message: toastMessage,
      trackingId: DownloadAccountsTrackingId.InfoToast,
      timeoutMs: TOAST_TIMEOUT,
    });
    generateAccountsCsvUrl(shouldExportAllAccounts ? undefined : urlParams, {
      onSuccess: (response) => {
        setExportId(response.data.exportId);
        if (hasNotificationCenterAccess) {
          setIsLoading(false);
        }
      },
      onError: () => {
        setIsLoading(false);
        addToast(errorToastContent);
      },
    });
  };

  return {
    isLoading,
    generateAccountsCsv,
  };
};

export default useGetAccountsCsv;
