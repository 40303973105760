import {
  useGetPayers,
  useSetBackHrefAndNavigate,
} from "@taxbit-dashboard/commons";
import {
  getEnUsErrorEmptyStateProps,
  useCosmicTable,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useMemo, useCallback } from "react";

import { AccountsTableRow } from "./accountsTableRow";
import { useAccountsTableColumns } from "./useAccountsTableColumns";
import { useGetHydratedAccounts } from "../../../../api/hydrated-accounts/hydratedAccountsApi";
import { AccountsUrlParams } from "../accountsUrlParams";
import { useAccountsUrlParams } from "../useAccountsUrlParams";
import { useAccountsView } from "../useAccountsView";

const getSortProps = (sort: AccountsUrlParams["sort"]) => {
  return {
    initialSortColumnKey: sort?.field === "name" ? "name" : "dateCreated",
    isInitialSortDescending: sort?.dir === undefined || sort.dir === "desc",
  } as const;
};

enum AccountsTableTrackingId {
  CosmicTable = "accounts-table",
}

export const useAccountsTable = () => {
  const { urlParams, setPageControls } = useAccountsUrlParams();
  const { view } = useAccountsView();
  const columns = useAccountsTableColumns();

  const {
    data: payers,
    isLoading: isLoadingPayers,
    isError: isErrorPayers,
  } = useGetPayers();

  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isError: isErrorAccounts,
    meta: accountsMeta,
    isFetching: isFetchingAccounts,
  } = useGetHydratedAccounts(
    AccountsUrlParams.toSearchHydratedAccountsParams(urlParams, view, payers)
  );

  const memoizedGetRowKey = useCallback(
    (row: AccountsTableRow) => row.externalId,
    []
  );

  const rows = useMemo(() => {
    if (!payers) return [];
    return (
      accounts?.map((a) => AccountsTableRow.fromHydratedAccount(a, payers)) ??
      []
    );
  }, [accounts, payers]);

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const setBackHrefAndNavigate = useSetBackHrefAndNavigate();

  const isLoading = isLoadingAccounts || isLoadingPayers || isFetchingAccounts;
  const isError = isErrorAccounts || isErrorPayers;

  const sortProps = useMemo(() => getSortProps(urlParams.sort), [urlParams]);

  const onSortChange = useCallback(
    (key: keyof AccountsTableRow, isDescending?: boolean) => {
      setPageControls({
        sort: {
          field: key === "name" ? "name" : "dateCreated",
          dir: isDescending || isDescending === undefined ? "desc" : "asc",
        },
      });
    },
    [setPageControls]
  );

  const tableProps = useCosmicTable({
    trackingId: AccountsTableTrackingId.CosmicTable,
    isManualSortable: false,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    density: "compact",
    rows,
    columns,
    isLoading,
    isSortable: true,
    onSortChange,
    emptyStateProps: isError
      ? getEnUsErrorEmptyStateProps({ entity: "accounts" })
      : searchEmptyStateProps,
    onRowClick: ({ id }) => {
      setBackHrefAndNavigate({
        to: `./${id}`,
        backHrefKey: "account-details",
        reset: ["transactions", "transaction-details"],
      });
    },
    ...sortProps,
  });

  const shouldDisableControls = isLoading || isError;

  return {
    tableProps,
    shouldDisableControls,
    totalCount: accountsMeta?.page?.totalCount ?? 0,
  };
};
