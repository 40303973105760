import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

import getIrParams from "../../../api/information-reporting/getIrParams";
import { IrFormTypeDateParams } from "../../../api/information-reporting/irApiTypes";

const useGetFormMetadata = ({ params }: { params: IrFormTypeDateParams }) => {
  const restSdk = useTaxBitRest();
  const apiParams = getIrParams(params);

  const query = useQuery(
    [DashboardQueryKey.FormMetadata, { ...apiParams }],
    () => restSdk.formMetadata.get(apiParams),
    {
      ...createQueryMetaObject(restSdk.formMetadata.buildPath()),
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export default useGetFormMetadata;
