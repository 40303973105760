import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const formRmdLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.FormRmdStatement),
  rmdDate: z.string(),
  rmdAmount: z.string(),
});

export const formRmdLineItemWithIdsSchema = formRmdLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type FormRmdLineItem = z.infer<typeof formRmdLineItemWithIdsSchema>;
