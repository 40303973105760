/**
 * When applied to an element, the contents of that element as well as any interactions
 * with it will be excluded from all FullStory & Pendo tracking. This should be applied to any
 * element containing PII that we do not need to record interaction metrics for. This will
 * ensure that we are fully excluding as much PII as possible from our tracking tooling.
 */
export const TRACKING_EXCLUDE_CLASS = "fs-exclude pendo-ignore";
/**
 * When applied to an element, the contents of that element will be hidden in FullStory tracking
 * and the entire element will be ignored by Pendo. Clicks and other interactions on that element
 * will still be recorded in FullStory. This should be applied to any element containing PII that
 * product dictates we should still track for usage metrics.
 */
export const TRACKING_MASK_CLASS = "fs-mask pendo-ignore";
