import {
  UsTaxIdType,
  TaxDocumentationTaxIdType,
  TaxIdType,
  AccountServiceTinType,
} from "@taxbit-dashboard/rest";

const formatTaxId = (
  taxId?: string,
  taxIdType?:
    | TaxIdType
    | TaxDocumentationTaxIdType
    | UsTaxIdType
    | AccountServiceTinType
): string | undefined => {
  if (!taxId) {
    return undefined;
  }

  const taxIdWithoutDashes = taxId.replaceAll("-", "");

  switch (taxIdType) {
    case TaxIdType.UsEin:
    case TaxDocumentationTaxIdType.Ein: {
      return `${taxIdWithoutDashes.slice(0, 2)}-${taxIdWithoutDashes.slice(2)}`;
    }
    case TaxIdType.UsSsn:
    case TaxIdType.UsItin:
    case TaxDocumentationTaxIdType.Ssn:
    case TaxDocumentationTaxIdType.Itin: {
      return `${taxIdWithoutDashes.slice(0, 3)}-${taxIdWithoutDashes.slice(
        3,
        5
      )}-${taxIdWithoutDashes.slice(5)}`;
    }
    default: {
      return taxId;
    }
  }
};

export default formatTaxId;
