import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099MiscAggregateValuesSchema = z.object({
  otherIncome: z.number(),
  rents: z.number(),
  royalties: z.number(),
  substitutePayments: z.number(),
  fishingBoatProceeds: z.number(),
  fishPurchasedForResale: z.number(),
  section409ADeferrals: z.number(),
  cropInsuranceProceeds: z.number(),
  excessGoldenParachutePayments: z.number(),
  medicalAndHealthCarePayments: z.number(),
  grossProceedsPaidToAnAttorney: z.number(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099MiscFormData
  ),
});

export type FormItem1099MiscAggregateValues = z.infer<
  typeof formItem1099MiscAggregateValuesSchema
>;
