import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  DenseRowGroup,
} from "@taxbit-private/cosmic";

import useDeliveryDeveloperSettingsRows from "./useDeliveryDeveloperSettingsRows";
import useDeveloperSettings from "./useDeveloperSettings";
import useGeneralDeveloperSettingsRows from "./useGeneralDeveloperSettingsRows";

export enum DeveloperSettingsTrackingId {
  DeveloperSettingsSpinner = "developer-settings-spinner",
  DeliveryConfigurationSettingsHelpIcon = "delivery-configuration-settings-help-icon",
}

const DeveloperSettings = () => {
  const { isError, isLoading } = useDeveloperSettings();
  const generalSettingsSections = useGeneralDeveloperSettingsRows();
  const deliveryConfigurationSettingsSections =
    useDeliveryDeveloperSettingsRows();

  return isLoading ? (
    <ContentSpinner
      trackingId={DeveloperSettingsTrackingId.DeveloperSettingsSpinner}
    />
  ) : isError ? (
    <ContentErrorEmptyState entity="developer settings" />
  ) : (
    <DashboardPrimaryTemplate title="Developer Settings">
      <Card title="General Settings">
        <DenseRowGroup sections={generalSettingsSections} />
      </Card>
      <Card
        title="Delivery Configuration Settings"
        helpIconProps={{
          tooltipProps: {
            content: "Your forms are always available on the Form page.",
          },
          trackingId:
            DeveloperSettingsTrackingId.DeliveryConfigurationSettingsHelpIcon,
        }}
      >
        <DenseRowGroup sections={deliveryConfigurationSettingsSections} />
      </Card>
    </DashboardPrimaryTemplate>
  );
};

export default DeveloperSettings;
