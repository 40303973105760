import { Button, Chip, ChipGroup } from "@taxbit-private/cosmic";

import useTransfersFilterChips from "./useTransfersFilterChips";
import useTransfersTableData from "../../table/useTransfersTableData";

export enum TransfersFiltersChipsTrackingIds {
  ClearAllBtn = "transfers-filters-chips-clear-all-btn",
}

const TransfersFiltersChips: React.FC = () => {
  const { clearAllFilters } = useTransfersTableData();
  const chips = useTransfersFilterChips();

  return chips.length > 0 ? (
    <ChipGroup>
      {chips.map((props) => (
        <Chip key={props.trackingId} {...props} />
      ))}
      <Button
        onClick={clearAllFilters}
        label="Clear All"
        trackingId={TransfersFiltersChipsTrackingIds.ClearAllBtn}
        variant="anchor-primary-inline"
      />
    </ChipGroup>
  ) : undefined;
};

export default TransfersFiltersChips;
