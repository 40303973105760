import { z } from "zod";

export const FORM_GENERATION_FORM_ID = "form-generation-form";

export const formGenerationModalFormSchema = z.object({
  shouldIncludeFilters: z.boolean().optional(),
  shouldSkipCompareData: z.boolean().optional(),
});
export type FormGenerationModalForm = z.infer<
  typeof formGenerationModalFormSchema
>;

export const formGenerationModalFormDefaultValues = {
  shouldIncludeFilters: false,
  shouldSkipCompareData: false,
};
