import { Route } from "@tanstack/react-location";
import {
  AuthorizedView,
  buildOrganizationPathPrefix,
  DashboardAppRoutePath,
} from "@taxbit-dashboard/commons";
import { route as irwRoute } from "@taxbit-dashboard/irw";
import { route as settingsRoute } from "@taxbit-dashboard/settings";

import AuthProtectedRoute from "./AuthProtectedRoute";
import getRoutesWithErrorAndRoutingFallbacks from "./getRoutesWithErrorAndRoutingFallbacks";
import Callback from "../../callback/Callback";
import NotificationsPage from "../../notifications/page/NotificationsPage";
import Launch from "../Launch";

const routes: Route[] = getRoutesWithErrorAndRoutingFallbacks([
  {
    path: DashboardAppRoutePath.Callback,
    element: <Callback />,
  },
  {
    element: <AuthProtectedRoute />,
    children: [
      // Routes owned by the Dashboard Foundations team.
      {
        path: DashboardAppRoutePath.Launch,
        element: <Launch />,
      },
      {
        path: buildOrganizationPathPrefix(":organizationId"),
        children: [
          {
            path: "/",
            element: <Launch />,
          },
          {
            path: DashboardAppRoutePath.Notifications,
            element: (
              <AuthorizedView flags={["hasNotificationCenterAccess"]}>
                <NotificationsPage />
              </AuthorizedView>
            ),
          },
          settingsRoute,
          irwRoute,
        ],
        meta: {
          shouldIgnoreFallback: true,
        },
      },
    ],
  },
]);

export default routes;
