import { z } from "zod";

/**
 * Check that the string is a valid UTC date.
 * .datetime() doesn't validate that the Date is valid
 * that's why we need to pipe it with .coerce.date()
 * and back to string with .transform()
 * https://github.com/colinhacks/zod/issues/2357
 */
const utcDateStringSchema = z
  .string()
  .datetime()
  .pipe(z.coerce.date())
  .transform((date) => date.toISOString());

export type UtcDateStringSchema = z.infer<typeof utcDateStringSchema>;

export default utcDateStringSchema;
