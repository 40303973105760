import {
  getOffsetFromPage,
  pageLimitPaginationParamsSchema,
  searchValueStringSchema,
  utcDateStringSchema,
  withOptionalCatch,
} from "@taxbit-dashboard/commons";
import {
  GetFormsSearchKey,
  GetFormsSort,
  IrFormStatus,
  IrFormType,
  SearchIrFormsParams as ApiSearchIrFormsParams,
  getFormsSearchKeySchema,
  getFormsSortSchema,
  irFormStatusSchema,
  irFormTypeSchema,
  GenerateTaxFormsRequest,
  EligibilityDataItem,
  DashboardFormType,
  BaseIrFormsFilters,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

import accountExternalIdsFilterToAccountExternalIds from "../../../utils/accountExternalIdsFilterToAccountExternalIds";
import { EligibilityDataParams } from "../eligible-users/eligibleUsersApiTypes";
import { AccountExternalIdsFilter, IrFormTypeDateParams } from "../irApiTypes";

export const DEFAULT_FORMS_SORT: GetFormsSort = "-date_generated";
const sortSchema = getFormsSortSchema.catch(DEFAULT_FORMS_SORT);

const searchKeyStringParams = Object.fromEntries(
  getFormsSearchKeySchema.options.map((key) => [key, searchValueStringSchema])
) as Record<GetFormsSearchKey, typeof searchValueStringSchema>;

export const formsSearchKeyLabelMap: Record<GetFormsSearchKey, string> = {
  account_external_id: "Account ID",
  name: "Name",
};

const formsEnumParamsSchema = z.object({
  statuses: withOptionalCatch(z.array(irFormStatusSchema).optional()),
  types: withOptionalCatch(z.array(irFormTypeSchema).optional()),
});

export const formsEnumParamKeys = Object.keys(
  formsEnumParamsSchema.shape
) as (keyof z.infer<typeof formsEnumParamsSchema>)[];

export const formsTableParams = pageLimitPaginationParamsSchema
  .extend({
    sort: sortSchema,
    startDate: withOptionalCatch(utcDateStringSchema.optional()),
    endDate: withOptionalCatch(utcDateStringSchema.optional()),
    ...searchKeyStringParams,
  })
  .merge(formsEnumParamsSchema);

export type FormsTableParams = z.infer<typeof formsTableParams>;

export const formTypeToLabelMap: Record<IrFormType, string> = {
  [IrFormType.Original]: "Original",
  [IrFormType.Correction]: "Correction",
  [IrFormType.Void]: "Void",
};

export const formStatusToLabelMap: Record<IrFormStatus, string> = {
  [IrFormStatus.Generated]: "Generated",
  [IrFormStatus.Filed]: "Filed",
};

export type SearchIrFormsParams = FormsTableParams &
  IrFormTypeDateParams &
  AccountExternalIdsFilter;

export const toBaseApiFormsFilters = ({
  accountExternalIdsFilter,
  account_external_id: accountExternalId,
  startDate,
  endDate,
  statuses,
  types,
  name,
}: Omit<FormsTableParams, "sort" | "page" | "limit"> &
  AccountExternalIdsFilter): BaseIrFormsFilters => {
  const accountExternalIds = accountExternalIdsFilterToAccountExternalIds(
    accountExternalIdsFilter
  );
  return {
    accountExternalId,
    accountExternalIds:
      accountExternalIds.length === 0 ? undefined : accountExternalIds,
    dateGenerated:
      startDate || endDate
        ? {
            gte: startDate,
            lte: endDate,
          }
        : undefined,
    name,
    statuses,
    types,
  };
};

export const toApiSearchIrFormsParams = ({
  sort,
  page,
  limit,
  formDate,
  formType,
  statuses,
  types,
  startDate,
  endDate,
  accountExternalIdsFilter,
  account_external_id,
  name,
}: SearchIrFormsParams): ApiSearchIrFormsParams => {
  const baseFilters = toBaseApiFormsFilters({
    accountExternalIdsFilter,
    account_external_id,
    startDate,
    endDate,
    statuses,
    types,
    name,
  });

  return {
    filters: {
      ...baseFilters,
      documentDate: formDate,
      documentType: formType,
    },
    sort,
    page: {
      limit,
      offset: getOffsetFromPage({ page, limit }),
    },
  };
};

export type GenerateTaxFormsMutationParams = {
  shouldIncludeFilters: boolean;
  shouldCompareData: boolean;
  eligibilityItems?: EligibilityDataItem[];
} & AccountExternalIdsFilter;

export const toGenerateTaxFormsRequest = ({
  accountExternalIdsFilter,
  accountValidationsEligibilityStatuses,
  eligibilityItems,
  external_id: externalId,
  formDate,
  formStatuses,
  formType,
  name,
  shouldCompareData,
  shouldForm1099BIncludeCostBasis,
  shouldIncludeFilters,
  statuses,
}: GenerateTaxFormsMutationParams &
  EligibilityDataParams & {
    shouldForm1099BIncludeCostBasis: boolean;
  }): GenerateTaxFormsRequest => {
  const externalIds = accountExternalIdsFilterToAccountExternalIds(
    accountExternalIdsFilter
  );

  const filters = shouldIncludeFilters
    ? {
        accountValidationsEligibilityStatus:
          accountValidationsEligibilityStatuses,
        externalId,
        externalIds: externalIds.length > 0 ? externalIds : undefined,
        formStatus: formStatuses,
        name,
        status: statuses,
      }
    : {};

  return {
    data: {
      accountIds: eligibilityItems?.map(({ accountId }) => accountId),
      documentDate: formDate,
      documentType: formType,
      should1099bIncludeCostBasis:
        formType === DashboardFormType.Irs1099B
          ? shouldForm1099BIncludeCostBasis
          : undefined,
      shouldCompareSnapshots: shouldCompareData,
      ...filters,
    },
  };
};
