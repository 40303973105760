import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099RAggregateValuesSchema = z.object({
  taxYearDistributionsCodes: z.string().array(),
  taxYearGrossDistribution: z.string().array(),
  totalTaxYearGrossDistribution: z.number().optional(),
  totalTaxYearTaxableAmount: z.number().optional(),
  totalTaxYearCapitalGain: z.number().optional(),
  totalTaxYearEmployeeContributionsDesignatedRothContributionsInsurancePremiums:
    z.number().optional(),
  totalTaxYearNetUnrealizedAppreciation: z.number().optional(),
  totalTaxYearOther: z.number().optional(),
  totalTaxYearOtherPercentage: z.number().optional(),
  totalTaxYearPercentageOfTotalDistribution: z.number().optional(),
  totalTaxYearTotalEmployeeContributions: z.number().optional(),
  totalTaxYearAllocableToIrrWithin5Years: z.number().optional(),
  taxYearFirstYearDesignatedRothContribution: z.string().optional(),
  taxYearDateOfPayment: z.string().optional(),
  taxYearState: z.string().optional(),
  taxYearPayersStateNumber: z.string().optional(),
  totalTaxYearStateDistribution: z.number().optional(),
  totalTaxYearLocalTaxWithheld: z.number().optional(),
  totalTaxYearFederalTaxWithheld: z.number().optional(),
  taxYearFederalTaxWithheld: z.string().array(),
  totalTaxYearLocalDistribution: z.number().optional(),
  taxYearNameOfLocality: z.string().optional(),
  totalTaxYearStateTaxWithheld: z.number().optional(),
  taxYearStateTaxWithheld: z.string().array(),
  isTotalDistribution: z.boolean().optional(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099rFormData
  ),
});

export type FormItem1099RAggregateValues = z.infer<
  typeof formItem1099RAggregateValuesSchema
>;
