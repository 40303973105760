import { useDashboardStore } from "@taxbit-dashboard/commons";
import { FilesApiFile } from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import { useGetFileErrorReportUrl } from "../../../../api/files/filesApi";
import { fileTypeLabelMap } from "../../../../api/files/filesApiTypes";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";

export enum DownloadErrorReportTrackingId {
  DownloadErrorReportsFailed = "download-error-report-failed",
}

const useDownloadErrorReports = (file: FilesApiFile) => {
  const addToast = useDashboardStore((store) => store.addToast);
  const { getFileErrorReportUrl } = useGetFileErrorReportUrl({
    fileId: file.fileId,
  });

  const languageForFormType = fileTypeLabelMap[file.fileType].toLowerCase();

  return useCallback(async () => {
    const { data, isError } = await getFileErrorReportUrl();

    if (data) {
      window.open(data.errorReportS3Url, "_blank");
    } else if (isError) {
      addToast({
        message: `Failed to download the invalid ${languageForFormType} reports for file ${file.fileName}. Please try again later.`,
        trackingId: DownloadErrorReportTrackingId.DownloadErrorReportsFailed,
        variant: "danger",
        timeoutMs: TOAST_TIMEOUT,
      });
    }
  }, [addToast, file.fileName, getFileErrorReportUrl, languageForFormType]);
};

export default useDownloadErrorReports;
