import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099IntAggregateValuesSchema = z.object({
  state: z.string().optional(),
  bondPremium: z.number(),
  interestIncome: z.number(),
  marketDiscount: z.number(),
  foreignTaxPaid: z.number(),
  investmentExpense: z.number(),
  stateTaxWithheld: z.number(),
  payersStateNumber: z.string().optional(),
  taxExemptInterest: z.number(),
  earlyWithdrawalPenalty: z.number(),
  bondPremiumOnTaxExemptBond: z.number(),
  bondPremiumOnTreasuryObligations: z.number(),
  specifiedPrivateActivityBondInterest: z.number(),
  interestOnUsSavingsBondsAndTreasuryObligations: z.number(),
  foreignCountryOrUsPossession: z.string().optional(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099IntFormData
  ),
});

export type FormItem1099IntAggregateValues = z.infer<
  typeof formItem1099IntAggregateValuesSchema
>;
