import {
  useTaxBitRest,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
} from "@taxbit-dashboard/commons";
import { AccountId, ReleasedDocumentId } from "@taxbit-dashboard/rest";

import useAccountIdDependentQuery from "../useAccountIdDependentQuery";

export const useGetTaxForms = () => {
  const restSdk = useTaxBitRest();
  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.TaxForms],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.taxForms.get({ accountId }),
    pathBuilder: (accountId?: AccountId) =>
      restSdk.taxForms.buildPath(accountId),
  });
  return unwrapPublicApiWrappedQuery(query);
};

export const useGetTaxFormUrl = (
  documentId: ReleasedDocumentId,
  refetchInterval?: number
) => {
  const restSdk = useTaxBitRest();
  const query = useAccountIdDependentQuery({
    queryKey: [DashboardQueryKey.TaxForms, documentId],
    makeQueryFn: (accountId: AccountId) => () =>
      restSdk.taxForms.url.get({ accountId, documentId }),
    refetchInterval,
    pathBuilder: (accountId?: AccountId) =>
      restSdk.taxForms.url.buildPath(accountId, documentId),
  });
  return unwrapPublicApiWrappedQuery(query);
};
