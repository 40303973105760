import { pageNumberSchema } from "@taxbit-dashboard/commons";
import {
  hydratedAccountVatStatusSchema,
  hydratedAccountTaxClassificationSchema,
  SearchHydratedAccountsParams,
  Payer,
  ExportHydratedAccountsParams,
  HydratedAccountTinStatus,
} from "@taxbit-dashboard/rest";
import { cosmicPaginationLimitSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import { AccountsView } from "./accountsView";

const sortSchema = z.object({
  field: z.enum(["name", "dateCreated"]),
  dir: z.enum(["asc", "desc"]),
});

export const POSSIBLE_TIN_STATUSES = [
  "PENDING",
  "FOREIGN",
  "INVALID_DATA",
  "VALID_MATCH",
  "MISMATCH",
  "TIN_NOT_ISSUED",
  "ERROR",
  "UNHANDLED",
] as const;
const tinStatusesSchema = z.enum(POSSIBLE_TIN_STATUSES);

const accountsUrlParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
  view: AccountsView.viewTypeSchema,
  name: z.string().optional(),
  email: z.string().optional(),
  accountId: z.string().optional(),
  tinStatuses: tinStatusesSchema.array().optional(),
  vatStatuses: hydratedAccountVatStatusSchema.array().optional(),
  taxClassifications: hydratedAccountTaxClassificationSchema.array().optional(),
  taxDocumentationTypes: z.string().array().optional(),
  taxCountryCodes: z.string().array().optional(),
  dateCreatedStart: z.string().optional(),
  dateCreatedEnd: z.string().optional(),
  filerIds: z.string().array().optional(),
  sort: sortSchema.optional(),
});
export type AccountsUrlParams = z.infer<typeof accountsUrlParamsSchema>;

const encodedAccountsUrlParamsSchema = z.object({
  p: accountsUrlParamsSchema.shape.page,
  l: accountsUrlParamsSchema.shape.limit,
  v: accountsUrlParamsSchema.shape.view,
  n: accountsUrlParamsSchema.shape.name,
  e: accountsUrlParamsSchema.shape.email,
  s: accountsUrlParamsSchema.shape.sort,
  id: accountsUrlParamsSchema.shape.accountId,
  ts: accountsUrlParamsSchema.shape.tinStatuses,
  vs: accountsUrlParamsSchema.shape.vatStatuses,
  tc: accountsUrlParamsSchema.shape.taxClassifications,
  td: accountsUrlParamsSchema.shape.taxDocumentationTypes,
  tcc: accountsUrlParamsSchema.shape.taxCountryCodes,
  dcs: accountsUrlParamsSchema.shape.dateCreatedStart,
  dce: accountsUrlParamsSchema.shape.dateCreatedEnd,
  fi: accountsUrlParamsSchema.shape.filerIds,
});
export type EncodedAccountsUrlParams = z.infer<
  typeof encodedAccountsUrlParamsSchema
>;

const encode = (params: AccountsUrlParams): EncodedAccountsUrlParams => ({
  p: params.page,
  l: params.limit,
  v: params.view,
  n: params.name,
  e: params.email,
  s: params.sort,
  id: params.accountId,
  ts: params.tinStatuses,
  vs: params.vatStatuses,
  tc: params.taxClassifications,
  td: params.taxDocumentationTypes,
  tcc: params.taxCountryCodes,
  dcs: params.dateCreatedStart,
  dce: params.dateCreatedEnd,
  fi: params.filerIds,
});

const decode = (params: EncodedAccountsUrlParams): AccountsUrlParams => ({
  page: params.p,
  limit: params.l,
  view: params.v,
  name: params.n,
  email: params.e,
  sort: params.s,
  accountId: params.id,
  tinStatuses: params.ts,
  vatStatuses: params.vs,
  taxClassifications: params.tc,
  taxDocumentationTypes: params.td,
  taxCountryCodes: params.tcc,
  dateCreatedStart: params.dcs,
  dateCreatedEnd: params.dce,
  filerIds: params.fi,
});

const expandTinStatuses = (
  statuses: AccountsUrlParams["tinStatuses"]
): HydratedAccountTinStatus[] | undefined => {
  if (!statuses || statuses.length === 0) {
    return undefined;
  }
  const tinStatuses: HydratedAccountTinStatus[] = [];
  for (const status of statuses) {
    if (status === "VALID_MATCH") {
      tinStatuses.push(
        "VALID_SSN_MATCH",
        "VALID_EIN_MATCH",
        "VALID_SSN_EIN_MATCH"
      );
    } else {
      tinStatuses.push(status);
    }
  }
  return tinStatuses;
};

const toHydratedAccountFilters = (
  params: AccountsUrlParams,
  view: AccountsView,
  payers?: Payer[]
): SearchHydratedAccountsParams["filters"] => {
  const defaultPayer = payers?.find((payer) => payer.isDefault);
  return {
    account: {
      name: AccountsView.includes("name", view) ? params.name : undefined,
      email: params.email,
      externalId: params.accountId,
      dateCreated: {
        gte: params.dateCreatedStart,
        lte: params.dateCreatedEnd,
      },
      tinStatuses: AccountsView.includes("tinStatus", view)
        ? expandTinStatuses(params.tinStatuses)
        : undefined,
      vatStatuses: AccountsView.includes("vatStatus", view)
        ? params.vatStatuses
        : undefined,
      types: AccountsView.includes("taxClassification", view)
        ? params.taxClassifications
        : undefined,
      taxCountryCodes: AccountsView.includes("taxCountryCodes", view)
        ? params.taxCountryCodes
        : undefined,
      filerIds: AccountsView.includes("filer", view)
        ? params.filerIds
        : undefined,
      matchMissingFiler:
        defaultPayer?.payerId &&
        params.filerIds?.includes(defaultPayer.payerId),
    },
    profile: {
      us: {
        name: AccountsView.includes("usProfileName", view)
          ? params.name
          : undefined,
        taxClassifications: AccountsView.includes(
          "usProfileTaxClassification",
          view
        )
          ? params.taxClassifications
          : undefined,
        taxCountryCodes: AccountsView.includes("usProfileTaxCountryCodes", view)
          ? params.taxCountryCodes
          : undefined,
        tinStatuses: AccountsView.includes("usProfileTinStatus", view)
          ? expandTinStatuses(params.tinStatuses)
          : undefined,
        taxDocumentationTypes: AccountsView.includes(
          "usProfileTaxDocumentationType",
          view
        )
          ? params.taxDocumentationTypes
          : undefined,
      },
      global: {
        name: AccountsView.includes("globalProfileName", view)
          ? params.name
          : undefined,
        vatStatuses: AccountsView.includes("globalProfileVatStatus", view)
          ? params.vatStatuses
          : undefined,
        taxClassifications: AccountsView.includes(
          "globalProfileTaxClassification",
          view
        )
          ? params.taxClassifications
          : undefined,
        taxCountryCodes: AccountsView.includes(
          "globalProfileTaxCountryCodes",
          view
        )
          ? params.taxCountryCodes
          : undefined,
        taxDocumentationTypes: AccountsView.includes(
          "globalProfileTaxDocumentationType",
          view
        )
          ? params.taxDocumentationTypes
          : undefined,
      },
    },
  };
};

const toHydratedAccountSort = (
  params: AccountsUrlParams
): SearchHydratedAccountsParams["sort"] => {
  if (!params.sort) {
    return undefined;
  }

  const dir = params.sort.dir === "desc" ? "-" : ("" as const);
  return `${dir}account.${params.sort.field}` as const;
};

const toHydratedAccountPage = (
  params: AccountsUrlParams
): SearchHydratedAccountsParams["page"] => {
  return {
    limit: params.limit,
    offset: (params.page - 1) * params.limit,
  };
};

const toSearchHydratedAccountsParams = (
  params: AccountsUrlParams,
  view: AccountsView,
  payers?: Payer[]
): SearchHydratedAccountsParams => {
  return {
    filters: toHydratedAccountFilters(params, view, payers),
    page: toHydratedAccountPage(params),
    sort: toHydratedAccountSort(params),
  };
};

const toExportHydratedAccountsParams = (
  params: AccountsUrlParams,
  view: AccountsView,
  payers?: Payer[]
): ExportHydratedAccountsParams => {
  return {
    filters: toHydratedAccountFilters(params, view, payers),
    mappings: AccountsView.toExportMappings(view),
    sort: toHydratedAccountSort(params),
  };
};

export const AccountsUrlParams = {
  schema: accountsUrlParamsSchema,
  encodedSchema: encodedAccountsUrlParamsSchema,
  encode,
  decode,
  toSearchHydratedAccountsParams,
  toExportHydratedAccountsParams,
};

export const DEFAULT_ACCOUNTS_URL_PARAMS: AccountsUrlParams = {
  page: 1,
  limit: 25,
  view: "us",
  sort: {
    field: "dateCreated",
    dir: "desc",
  },
};

export const DEFAULT_ENCODED_ACCOUNTS_URL_PARAMS = encode(
  DEFAULT_ACCOUNTS_URL_PARAMS
);
