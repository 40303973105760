import { Toast, Clickable, ClickableProps } from "@taxbit-private/cosmic";
import { useEffect, useRef } from "react";

import Link from "../navigation/Link";
import { Toast as ToastType } from "../store/notification/notificationSliceModels";
import useDashboardStore from "../store/useDashboardStore";

const TimedToast: React.FC<ToastType> = ({
  ctaProps,
  message,
  timeoutMs,
  trackingId,
  uuid,
  variant,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const removeToast = useDashboardStore((state) => state.removeToast);

  useEffect(() => {
    if (timeoutMs) {
      timeoutRef.current = setTimeout(() => removeToast(uuid), timeoutMs);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutMs, removeToast, uuid]);

  const ctaElement = (() => {
    if (ctaProps?.to) {
      return <Link variant="anchor-primary" {...ctaProps} />;
    } else if (ctaProps) {
      return (
        // Have to cast the prop type here because TS can't properly infer that
        // the `if` statements here have checked that these props are correct ClickableProps
        <Clickable variant="anchor-primary" {...(ctaProps as ClickableProps)} />
      );
    } else {
      return undefined;
    }
  })();

  return (
    <Toast
      key={uuid}
      onClose={() => removeToast(uuid)}
      variant={variant}
      trackingId={trackingId}
    >
      {message} {ctaElement}
      {/* Per design guidelines, toast messages are complete sentences. */}
      {ctaElement ? "." : ""}
    </Toast>
  );
};

export default TimedToast;
