import { AccountValidationsEligibilityStatus } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { accountValidationsEligibilityStatusLabelMap } from "../../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export const getAccountValidationsEligibilityStatusChipTrackingId = (
  accountValidationsEligibilityStatus: AccountValidationsEligibilityStatus
) => {
  return `irEligibilityFilters-${accountValidationsEligibilityStatus}-filter-chip`;
};

const useAccountValidationsEligibilityStatusChip = () => {
  const { urlParams, setFilterParams } = useEligibilityDataContext();

  return useMemo(() => {
    return (urlParams.accountValidationsEligibilityStatuses ?? []).map(
      (accountValidationsEligibilityStatus) => {
        const label =
          accountValidationsEligibilityStatusLabelMap[
            accountValidationsEligibilityStatus
          ];

        return {
          label: `Account Validation: ${label}`,
          removeButtonProps: {
            "onClick": () => {
              setFilterParams((draft) => {
                const { accountValidationsEligibilityStatuses } = draft;
                draft.accountValidationsEligibilityStatuses =
                  accountValidationsEligibilityStatuses?.filter(
                    (s) => s !== accountValidationsEligibilityStatus
                  );
              });
            },
            "aria-label": `Remove filter for ${label}`,
          },
          trackingId: getAccountValidationsEligibilityStatusChipTrackingId(
            accountValidationsEligibilityStatus
          ),
        };
      }
    );
  }, [urlParams, setFilterParams]);
};

export default useAccountValidationsEligibilityStatusChip;
