import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const downloadItemSchema = z.object({
  lastModified: z.string(),
  downloadUrl: z.string(),
  properties: z.object({
    fileDisplayName: z.string(),
    fileDownloadName: z.string(),
    fileDescription: z.string(),
  }),
});
export type DownloadItem = z.infer<typeof downloadItemSchema>;

export const getDownloadsResponseSchema = getPublicApiSuccessSchema(
  z.object({
    items: downloadItemSchema.array(),
  })
);
