import {
  hasMultipleValuesGreaterThanZero,
  filterToDefinedValues,
} from "@taxbit-dashboard/commons";
import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
  FormItem5498AggregateValues,
} from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import useFormatUsdValue from "../../../../utils/useFormatUsdValue";

type ContributionTypeKeys = keyof Pick<
  FormItem5498AggregateValues,
  | "totalTaxYearIraContributions"
  | "totalTaxYearRolloverContributions"
  | "totalTaxYearRothIraConversionAmount"
  | "totalTaxYearRecharacterizedContributions"
  | "totalTaxYearSepContributions"
  | "totalTaxYearSimpleContributions"
  | "totalTaxYearRothIraContributions"
  | "totalTaxYearPostponedLateContribution"
>;

const mapFormDataFieldToLabel: Record<ContributionTypeKeys, string> = {
  totalTaxYearIraContributions: "Standard",
  totalTaxYearRolloverContributions: "Rollover",
  totalTaxYearRothIraConversionAmount: "Conversion",
  totalTaxYearRecharacterizedContributions: "Recharacterized",
  totalTaxYearSepContributions: "SEP",
  totalTaxYearSimpleContributions: "SIMPLE",
  totalTaxYearRothIraContributions: "Roth",
  totalTaxYearPostponedLateContribution: "Postponed / Late Rollover",
};

const getFormDataFieldLabel = (fields: FormItem5498AggregateValues): string => {
  const {
    totalTaxYearIraContributions,
    totalTaxYearRolloverContributions,
    totalTaxYearRothIraConversionAmount,
    totalTaxYearRecharacterizedContributions,
    totalTaxYearSepContributions,
    totalTaxYearSimpleContributions,
    totalTaxYearRothIraContributions,
    totalTaxYearPostponedLateContribution,
  } = fields;

  const contributionFields: Record<ContributionTypeKeys, number | undefined> = {
    totalTaxYearIraContributions,
    totalTaxYearRolloverContributions,
    totalTaxYearRothIraConversionAmount,
    totalTaxYearRecharacterizedContributions,
    totalTaxYearSepContributions,
    totalTaxYearSimpleContributions,
    totalTaxYearRothIraContributions,
    totalTaxYearPostponedLateContribution,
  };

  const hasMultipleFields = hasMultipleValuesGreaterThanZero(
    filterToDefinedValues(Object.values(contributionFields))
  );

  if (hasMultipleFields) {
    return "Multiple";
  }

  for (const [fieldKey, field] of Object.entries(contributionFields)) {
    if (field && field > 0) {
      return mapFormDataFieldToLabel[fieldKey as ContributionTypeKeys];
    }
  }

  return COSMIC_VALUE_PLACEHOLDER;
};

const useGetFormDataFieldAmount = () => {
  const formatUsdValue = useFormatUsdValue();

  return useCallback(
    (fields: FormItem5498AggregateValues): string => {
      const {
        totalTaxYearIraContributions,
        totalTaxYearRolloverContributions,
        totalTaxYearRothIraConversionAmount,
        totalTaxYearRecharacterizedContributions,
        totalTaxYearSepContributions,
        totalTaxYearSimpleContributions,
        totalTaxYearRothIraContributions,
        totalTaxYearPostponedLateContribution,
      } = fields;

      const contributionFields: Record<
        ContributionTypeKeys,
        number | undefined
      > = {
        totalTaxYearIraContributions,
        totalTaxYearRolloverContributions,
        totalTaxYearRothIraConversionAmount,
        totalTaxYearRecharacterizedContributions,
        totalTaxYearSepContributions,
        totalTaxYearSimpleContributions,
        totalTaxYearRothIraContributions,
        totalTaxYearPostponedLateContribution,
      };

      const hasMultipleFields = hasMultipleValuesGreaterThanZero(
        filterToDefinedValues(Object.values(contributionFields))
      );

      if (hasMultipleFields) {
        return "See Export";
      }

      for (const field of Object.values(contributionFields)) {
        if (field && field > 0) {
          return formatUsdValue(field.toString());
        }
      }

      return COSMIC_VALUE_PLACEHOLDER;
    },
    [formatUsdValue]
  );
};

const useForm5498EligibilityTableColumns = () => {
  const getFormDataFieldAmount = useGetFormDataFieldAmount();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        label: "Contribution Type",
        id: "formSpecificFieldsContributionType",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs5498MayFormData
          ) {
            return getFormDataFieldLabel(formSpecificFields);
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
      {
        key: "formSpecificFields",
        label: "Amount",
        id: "formSpecificFieldsAmount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs5498MayFormData
          ) {
            return getFormDataFieldAmount(formSpecificFields);
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
    ],
    [getFormDataFieldAmount]
  );
};

export default useForm5498EligibilityTableColumns;
