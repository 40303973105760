import { isDefined } from "@taxbit-dashboard/commons";
import { Form1099BLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import formatFormDataAssetQuantity from "./formatFormDataAssetQuantity";

const useFormItem1099BColumns = () => {
  const { formatDateTime, formatQuantity, formatBoolean } =
    useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form1099BLineItem> => [
      {
        key: "assetName",
        label: "Asset Name",
        isContentFullWidth: true,
      },
      {
        key: "assetSymbol",
        label: "Asset Symbol",
        isContentFullWidth: true,
      },
      {
        key: "assetQuantity",
        label: "Asset Quantity",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (assetQuantity) =>
          formatFormDataAssetQuantity(assetQuantity),
      },
      {
        key: "acquiredDate",
        label: "Acquired Date",
        isContentFullWidth: true,
        renderCell: (acquiredDate) =>
          formatDateTime({
            date: acquiredDate,
          }),
      },
      {
        key: "disposedDate",
        label: "Disposed Date",
        isContentFullWidth: true,
        renderCell: (disposedDate) =>
          formatDateTime({
            date: disposedDate,
          }),
      },
      {
        key: "proceeds",
        label: "Proceeds",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (proceeds) => formatQuantity(proceeds),
      },
      {
        key: "costBasis",
        textAlign: "right",
        label: "Cost Basis",
        isContentFullWidth: true,
        renderCell: (costBasis) => formatQuantity(costBasis),
      },
      {
        key: "gainLoss",
        textAlign: "right",
        label: "Gain Loss",
        isContentFullWidth: true,
        renderCell: (gainLoss) => formatQuantity(gainLoss),
      },
      {
        key: "accruedMarketDiscount",
        textAlign: "right",
        label: "Accrued Market Discount",
        isContentFullWidth: true,
        renderCell: (accruedMarketDiscount) =>
          formatQuantity(accruedMarketDiscount),
      },
      {
        key: "washSale",
        textAlign: "right",
        label: "Wash Sale",
        isContentFullWidth: true,
        renderCell: (washSale) => formatQuantity(washSale),
      },
      {
        key: "gainLossClassification",
        label: "Gain Loss Classification",
        isContentFullWidth: true,
      },
      {
        key: "collectiblesQofClassification",
        label: "Collectibles QOF Classification",
        isContentFullWidth: true,
      },
      {
        key: "federalTaxWithheld",
        textAlign: "right",
        label: "Federal Tax Withheld",
        isContentFullWidth: true,
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "isNoncoveredSecurity",
        label: "Noncovered Security",
        isContentFullWidth: true,
        renderCell: (isNoncoveredSecurity) =>
          isDefined(isNoncoveredSecurity)
            ? formatBoolean(isNoncoveredSecurity)
            : COSMIC_VALUE_PLACEHOLDER,
      },
      {
        key: "cusipNumber",
        label: "CUSIP Number",
        isContentFullWidth: true,
      },
      {
        key: "proceedsType",
        label: "Proceeds Type",
        isContentFullWidth: true,
      },
      {
        key: "isLossNotAllowedBasedOnProceeds",
        label: "Loss Not Allowed Based On Proceeds",
        isContentFullWidth: true,
        renderCell: (isLossNotAllowedBasedOnProceeds) =>
          isDefined(isLossNotAllowedBasedOnProceeds)
            ? formatBoolean(isLossNotAllowedBasedOnProceeds)
            : COSMIC_VALUE_PLACEHOLDER,
      },
      {
        key: "isBasisReported",
        label: "Basis Reported",
        isContentFullWidth: true,
        renderCell: (isBasisReported) =>
          isDefined(isBasisReported)
            ? formatBoolean(isBasisReported)
            : COSMIC_VALUE_PLACEHOLDER,
      },
      {
        key: "bartering",
        label: "Bartering",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (bartering) => formatQuantity(bartering),
      },
      {
        key: "stateCode",
        label: "State Code",
        isContentFullWidth: true,
      },
      {
        key: "stateId",
        label: "State ID",
        isContentFullWidth: true,
      },
      {
        key: "stateTaxWithheld",
        label: "State Tax Withheld",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (stateTaxWithheld) => formatQuantity(stateTaxWithheld),
      },
      {
        key: "isDigitalAsset",
        label: "Digital Asset",
        isContentFullWidth: true,
        renderCell: (isDigitalAsset: Form1099BLineItem["isDigitalAsset"]) =>
          isDefined(isDigitalAsset)
            ? formatBoolean(isDigitalAsset)
            : COSMIC_VALUE_PLACEHOLDER,
      },
      {
        key: "gainsOnClosedContracts",
        label: "Gains On Closed Contracts",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (gainsOnClosedContracts) =>
          formatQuantity(gainsOnClosedContracts),
      },
      {
        key: "unrealizedGainsOnOpenContractsPriorYear",
        label: "Unrealized Gains On Open Contracts Prior Year",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (unrealizedGainsOnOpenContractsPriorYear) =>
          formatQuantity(unrealizedGainsOnOpenContractsPriorYear),
      },
      {
        key: "unrealizedGainsOnOpenContractsCurrentYear",
        label: "Unrealized Gains On Open Contracts Current Year",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (unrealizedGainsOnOpenContractsCurrentYear) =>
          formatQuantity(unrealizedGainsOnOpenContractsCurrentYear),
      },
      {
        key: "aggregateGainsOnContracts",
        label: "Aggregate Gains On Contracts",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (aggregateGainsOnContracts) =>
          formatQuantity(aggregateGainsOnContracts),
      },
    ],
    [formatDateTime, formatQuantity, formatBoolean]
  );
};

export default useFormItem1099BColumns;
