import { Flex, Checkbox, Modal, Body } from "@taxbit-private/cosmic";

export enum TransactionsCsvModalTrackingId {
  TransactionCsvExportModalCloseBtn = "transactionsCSVExportModalCloseBtn",
  TransactionCsvExportModalPrimaryBtn = "transactionsCSVExportModalPrimaryBtn",
  TransactionCsvExportModalSecondaryBtn = "transactionsCSVExportModalSecondaryBtn",
  TransactionCsvExportModalCheckbox = "transactionsCSVExportModalCheckbox",
}

type TransactionsCsvExportModalProps = {
  isModalOpen: boolean;
  onModalContinueClick: () => void;
  onModalClose: () => void;
  isCheckboxChecked: boolean;
  onCheckboxChange: () => void;
};

const TransactionsCsvExportModal: React.FC<TransactionsCsvExportModalProps> = ({
  onModalContinueClick,
  onModalClose,
  onCheckboxChange,
  isCheckboxChecked,
  isModalOpen,
}) => {
  return (
    <Modal
      title="Transaction Limit Message"
      isOpen={isModalOpen}
      onClose={onModalClose}
      primaryButtonProps={{
        label: "Continue",
        variant: "button-primary",
        trackingId:
          TransactionsCsvModalTrackingId.TransactionCsvExportModalPrimaryBtn,
        onClick: onModalContinueClick,
      }}
      secondaryButtonProps={{
        label: "Close",
        trackingId:
          TransactionsCsvModalTrackingId.TransactionCsvExportModalSecondaryBtn,
        onClick: onModalClose,
      }}
      closeButtonTrackingId={
        TransactionsCsvModalTrackingId.TransactionCsvExportModalCloseBtn
      }
    >
      <Flex direction="column" gap="m">
        <Body>
          If you have more than 3,000 transactions in an export, some of your
          data will not be included. We currently do not support exports larger
          than 3,000 transactions. Continue to download the allowable
          transactions.
        </Body>
        <Checkbox
          isChecked={isCheckboxChecked}
          label="Don’t show this message again."
          onChange={onCheckboxChange}
          trackingId={
            TransactionsCsvModalTrackingId.TransactionCsvExportModalCheckbox
          }
        />
      </Flex>
    </Modal>
  );
};

export default TransactionsCsvExportModal;
