import {
  Flex,
  Icon,
  IconName,
  TrackingProps,
  getListOptionCss,
  getSharedAtomFocusCss,
  withTracking,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import { useTopNavigationActionMenuContext } from "./useTopNavigationActionMenu";

type Props = {
  label: string;
  leftIcon: IconName;
  onClick: () => void;
  rightIcon?: IconName;
  shouldCloseOnClick?: boolean;
} & TrackingProps;

const TopNavigationActionMenuItem: React.FC<Props> = ({
  label,
  leftIcon,
  onClick,
  rightIcon,
  trackingId,
  shouldCloseOnClick = true,
}) => {
  const { closeActionMenu } = useTopNavigationActionMenuContext();

  return (
    <MultipleIconClickableOption
      onClick={() => {
        onClick();

        if (shouldCloseOnClick) {
          closeActionMenu();
        }
      }}
      trackingId={trackingId}
    >
      <Flex alignItems="center" gap="s">
        {leftIcon && <Icon name={leftIcon} />}
        {label}
      </Flex>
      {rightIcon && <Icon name={rightIcon} />}
    </MultipleIconClickableOption>
  );
};

export default TopNavigationActionMenuItem;

export const MultipleIconClickableOption = withTracking(
  styled.button(
    ({ theme, disabled }) => `
  ${getListOptionCss({ theme, isFocused: false, isDisabled: disabled })}
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${theme.color.gray1};
  }

  &:focus-visible {
    ${getSharedAtomFocusCss(theme)}
  }
`
  )
);
