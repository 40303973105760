import { z } from "zod";

import { formItemGainLossAggregateValuesSchema } from "./formItemFormGainLossAggregateValues";
import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099BAggregateValuesSchema =
  formItemGainLossAggregateValuesSchema.extend({
    documentTypeDataSource: z.literal(
      EligibilityDocumentTypeDataSource.Irs1099bFormData
    ),
  });

export type FormItem1099BAggregateValues = z.infer<
  typeof formItem1099BAggregateValuesSchema
>;
