import { TransferIntention } from "@taxbit-dashboard/rest";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

const getTransferIntentionLabel = (intention?: TransferIntention) => {
  if (!intention) return COSMIC_VALUE_PLACEHOLDER;

  switch (intention) {
    case TransferIntention.Personal: {
      return "Personal";
    }
    default: {
      return "Other";
    }
  }
};

export default getTransferIntentionLabel;
