import { z } from "zod";

import cosmicPaginationLimitSchema from "./cosmicPaginationLimitSchema";
import pageNumberSchema from "./pageNumberSchema";

const pageLimitPaginationParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
});

export type PageLimitPaginationParams = z.infer<
  typeof pageLimitPaginationParamsSchema
>;

export default pageLimitPaginationParamsSchema;
