import { Chip, ChipGroup, Button } from "@taxbit-private/cosmic";

import useEligibilityFilterChips from "./useEligibilityFilterChips";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum EligibilityFilterChipsTrackingId {
  ClearAllButton = "irEligibilityChipsClearAllBtn",
}

const EligibilityFilterChips = () => {
  const { clearAllFilters } = useEligibilityDataContext();
  const filterChipProps = useEligibilityFilterChips();

  return filterChipProps.length > 0 ? (
    <ChipGroup>
      {filterChipProps.map((props) => (
        <Chip key={props.trackingId} {...props} />
      ))}
      <Button
        onClick={clearAllFilters}
        label="Clear All"
        trackingId={EligibilityFilterChipsTrackingId.ClearAllButton}
        variant="anchor-primary-inline"
      />
    </ChipGroup>
  ) : undefined;
};

export default EligibilityFilterChips;
