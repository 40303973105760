import { Flex } from "@taxbit-private/cosmic";

import EligibleUsersCount from "./EligibleUsersCount";
import IneligibleUsersCount from "./IneligibleUsersCounts";

export enum EligibilityCountsTrackingIds {
  EligibilityBreakdown = "irEligibleUsersEligibilityBreakdown",
}

const EligibilityCounts = () => {
  return (
    <Flex
      gap="l"
      growItems={true}
      trackingId={EligibilityCountsTrackingIds.EligibilityBreakdown}
    >
      <EligibleUsersCount />
      <IneligibleUsersCount />
    </Flex>
  );
};

export default EligibilityCounts;
