import { Alert } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";

import { getEligibilityStatusLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export const FORM_DATA_FOR_WRONG_COUNTRY_CODE_ALERT_TRACKING_ID =
  "irEligibilityFormDataForWrongCountryCodeAlert";

const FormDataForWrongCountryCodeUsersAlert = () => {
  const { eligibilityAlerts, urlParams } = useEligibilityDataContext();
  const [isWarningPresent, setIsWarningPresent] = useState(
    !!eligibilityAlerts?.hasFormDataForNonValidTaxCountryCode
  );

  useEffect(() => {
    setIsWarningPresent(
      !!eligibilityAlerts?.hasFormDataForNonValidTaxCountryCode
    );
  }, [eligibilityAlerts]);

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const warningMessage = useMemo(() => {
    const count = eligibilityAlerts?.hasFormDataForNonValidTaxCountryCode ?? 0;

    return `Form data has been loaded for ${formatWholeQuantity(count)} ${pluralize("account", count)} that ${pluralize(
      "has",
      count
    )} been deemed a ${
      getEligibilityStatusLabelMap(urlParams.formType).NON_VALID_TAX_COUNTRY
    } person.`;
  }, [
    eligibilityAlerts?.hasFormDataForNonValidTaxCountryCode,
    formatWholeQuantity,
    urlParams.formType,
  ]);

  if (isWarningPresent) {
    return (
      <Alert
        onClose={() => setIsWarningPresent(false)}
        variant="warning"
        trackingId={FORM_DATA_FOR_WRONG_COUNTRY_CODE_ALERT_TRACKING_ID}
      >
        {warningMessage}
      </Alert>
    );
  } else {
    return undefined;
  }
};

export default FormDataForWrongCountryCodeUsersAlert;
