import { GridGroup } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import useFormDetailsRows from "./useFormDetailsRows";
import useGetFormDetails from "./useGetFormDetails";

export enum FormDetailsTrackingId {
  FormDetailsSpinner = "form-details-spinner",
}

const FormDetails: React.FC = () => {
  const { isLoading, formDetails } = useGetFormDetails();

  const formDetailsRows = useFormDetailsRows({
    formDetails,
  });

  const cardTitle = `${
    formDetails ? formDetails.documentType : "Form"
  } Details`;

  return (
    <Card title={cardTitle}>
      {isLoading ? (
        <ContentSpinner trackingId={FormDetailsTrackingId.FormDetailsSpinner} />
      ) : formDetailsRows ? (
        <GridGroup data={formDetailsRows} />
      ) : (
        <ContentErrorEmptyState entity="form details" />
      )}
    </Card>
  );
};

export default FormDetails;
