import {
  CosmicPageSize,
  PaginationFooter,
  PaginationFooterProps,
} from "@taxbit-private/cosmic";
import { O } from "ts-toolbelt";

import { PageLimitPaginationParams } from "../api/common/pageLimitPaginationSchema";
import { UseFilterParams } from "../navigation/hooks/useUrlFilterParams";
import calculatePageNumberForUpdatedPageSize from "../utils/calculatePageNumberForUpdatedPageSize";

export type UrlParamPaginationControlsProps = {
  urlParams: PageLimitPaginationParams;
  setUrlParams: UseFilterParams<PageLimitPaginationParams>["setUrlParams"];
} & O.Omit<
  PaginationFooterProps,
  "currentPage" | "setCurrentPage" | "setPageSize" | "pageSize"
>;

const UrlParamPaginationControls: React.FC<UrlParamPaginationControlsProps> = ({
  urlParams,
  setUrlParams,
  ...paginationFooterProps
}) => {
  const { page: currentPage, limit: currentPageSize } = urlParams;

  const setCurrentPage = (nextPage: number) => {
    setUrlParams((draft) => {
      draft.page = nextPage;
    });
  };

  const setPageSize = (nextPageSize: CosmicPageSize) => {
    const page = calculatePageNumberForUpdatedPageSize({
      currentPageSize,
      currentPage,
      nextPageSize,
    });

    setUrlParams((draft) => {
      draft.page = page;
      draft.limit = nextPageSize;
    });
  };

  return (
    <PaginationFooter
      {...paginationFooterProps}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      pageSize={currentPageSize}
    />
  );
};

export default UrlParamPaginationControls;
