import { QueryKey, useQuery } from "@tanstack/react-query";
import { createQueryMetaObject } from "@taxbit-dashboard/commons";
import { AccountId } from "@taxbit-dashboard/rest";

import useAccountId from "../utils/useAccountId";

type UseAccountDependantQueryParams<TReturn> = {
  enabled?: boolean;
  makeQueryFn: (accountId: AccountId) => () => Promise<TReturn>;
  onError?: () => void;
  pathBuilder: (accountId?: AccountId) => string;
  queryKey: QueryKey;
  refetchInterval?: number;
};

/**
 * Query helper for the accountId based queries
 * @param {boolean} enabled - by default query will be enabled based on the accountId, this param allows to disable query manaully
 */

const useAccountIdDependentQuery = <TReturn>({
  enabled,
  makeQueryFn,
  onError,
  pathBuilder,
  queryKey,
  refetchInterval = undefined,
}: UseAccountDependantQueryParams<TReturn>) => {
  const accountId = useAccountId();

  return useQuery(
    [...queryKey, accountId],
    accountId ? makeQueryFn(accountId) : () => undefined,
    {
      ...createQueryMetaObject(pathBuilder(accountId)),
      enabled: enabled === undefined ? !!accountId : !!accountId && enabled,
      onError,
      refetchInterval,
    }
  );
};

export default useAccountIdDependentQuery;
