import { useDashboardStore } from "@taxbit-dashboard/commons";
import { Notification } from "@taxbit-dashboard/rest";
import {
  ActionButton,
  Avatar,
  BodySmall,
  Flex,
  H4,
  getRgbaForHex,
  withTracking,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";
import styled from "styled-components";

import NotificationsListItemCtas from "./NotificationsListItemCtas";
import { useMarkNotificationAsRead } from "../../../api/notifications/notificationsApi";
import { notificationCategoryToUiConfigMap } from "../../../api/notifications/notificationsApiTypes";
import { NotificationToastTrackingId } from "../useNotifications";
import { NotificationsContext } from "../useNotificationsContext";

type Props = {
  notification: Notification;
  context: NotificationsContext;
};

export enum NotificationsListItemTrackingId {
  PrimaryCta = "primary-cta-anchor",
  SecondaryCta = "secondary-cta-anchor",
  MarkAsRead = "mark-as-read-button",
  Dot = "unread-dot",
}

const NotificationsListItem: React.FC<Props> = ({
  notification: {
    actions,
    category,
    description,
    isRead,
    notificationId,
    occurredAt,
  },
  context,
}) => {
  const { formatRelativeDateTime } = useCosmicLocalizationContext();
  const timestamp = formatRelativeDateTime({ date: occurredAt });

  const { mutate: markAsRead, isLoading: isMarkingAsRead } =
    useMarkNotificationAsRead();

  const addToast = useDashboardStore((store) => store.addToast);

  const markNotificationAsRead = useCallback(() => {
    markAsRead(notificationId, {
      onError: () => {
        addToast({
          message: `Failed to mark notification as read. Please try again.`,
          trackingId: NotificationToastTrackingId.MarkAsReadError,
          timeoutMs: 5000,
          variant: "danger",
        });
      },
    });
  }, [addToast, markAsRead, notificationId]);

  const { label, avatarVariant, icon } =
    notificationCategoryToUiConfigMap[category];

  return (
    <Wrapper isRead={isRead}>
      <Avatar variant={avatarVariant} iconName={icon} />
      <FullWidthFlex direction="column" gap="xs">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="s">
            <H4>{label}</H4>
            {!isRead && (
              <Dot trackingId={NotificationsListItemTrackingId.Dot} />
            )}
          </Flex>
          <ActionButton
            iconName="check"
            trackingId={NotificationsListItemTrackingId.MarkAsRead}
            label="Mark as read"
            onClick={markNotificationAsRead}
            isDisabled={isRead}
            isLoading={isMarkingAsRead}
          />
        </Flex>
        <BodySmall>
          <span>{timestamp}</span> | <span>{description}</span>
        </BodySmall>
        <NotificationsListItemCtas
          actions={actions}
          notificationId={notificationId}
          context={context}
          markNotificationAsRead={markNotificationAsRead}
        />
      </FullWidthFlex>
    </Wrapper>
  );
};

export default NotificationsListItem;

const Wrapper = styled.div<{ isRead: boolean }>(
  ({ theme, isRead }) => `
  display: flex;
  width: 100%;
  padding: ${theme.spacing.l};
  background-color: ${isRead ? theme.color.white : getRgbaForHex(theme.color.primary, 0.05)};
  gap: ${theme.spacing.m};
`
);

const FullWidthFlex = styled(Flex)`
  width: 100%;
`;

const Dot = withTracking(
  styled.div(
    ({ theme }) => `
  width: ${theme.sizing.xxxs};
  height: ${theme.sizing.xxxs};
  background-color: ${theme.color.primary};
  border-radius: ${theme.borderRadius.round};
`
  )
);
