import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const form1099KAggregateValuesSchema = z.object({
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099kIngested
  ),
  janTotalAdjustedReceivedQuantity: z.number(),
  febTotalAdjustedReceivedQuantity: z.number(),
  marTotalAdjustedReceivedQuantity: z.number(),
  aprTotalAdjustedReceivedQuantity: z.number(),
  mayTotalAdjustedReceivedQuantity: z.number(),
  junTotalAdjustedReceivedQuantity: z.number(),
  julTotalAdjustedReceivedQuantity: z.number(),
  augTotalAdjustedReceivedQuantity: z.number(),
  sepTotalAdjustedReceivedQuantity: z.number(),
  octTotalAdjustedReceivedQuantity: z.number(),
  novTotalAdjustedReceivedQuantity: z.number(),
  decTotalAdjustedReceivedQuantity: z.number(),
  taxYearTotalAdjustedReceivedQuantity: z.number(),
});
export type Form1099KAggregateValues = z.infer<
  typeof form1099KAggregateValuesSchema
>;
