import { TransferStatus } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { transferStatusesMap } from "../../../../../api/transfers/transfersApiTypes";
import useTransfersTableData from "../../table/useTransfersTableData";

export const getTransferStatusChipTrackingId = (status: TransferStatus) => {
  return `transfers-table-transfer-status-${status}-filter-chip`;
};

const useTransferStatusFilterChips = () => {
  const { urlParams, setFilterParams } = useTransfersTableData();

  return useMemo(() => {
    return (urlParams.transferStatuses ?? []).map((status) => {
      return {
        label: transferStatusesMap[status],
        removeButtonProps: {
          "onClick": () => {
            setFilterParams((draft) => {
              const statuses = draft.transferStatuses;
              draft.transferStatuses = statuses?.filter((s) => s !== status);
            });
          },
          "aria-label": "Remove filter for transfer status",
        },
        trackingId: getTransferStatusChipTrackingId(status),
      };
    });
  }, [urlParams, setFilterParams]);
};

export default useTransferStatusFilterChips;
