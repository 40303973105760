import { getPageLimitApiParams } from "@taxbit-dashboard/commons";
import { GetNotificationsParams } from "@taxbit-dashboard/rest";

import {
  NotificationsPageParams,
  NotificationsTab,
} from "./notificationsApiTypes";

const getNotificationsParams = ({
  limit,
  page,
  tab,
}: NotificationsPageParams): GetNotificationsParams => {
  return {
    "filters[is_read]":
      tab === NotificationsTab.All ? undefined : tab === NotificationsTab.Read,
    ...getPageLimitApiParams({ page, limit }),
  };
};

export default getNotificationsParams;
