import {
  AccountOwnerDetailsTaxDocumentationIssueType,
  KycTaxDocumentType,
} from "@taxbit-dashboard/rest";

import {
  resubmissionOrCuringText,
  resubmissionText,
  usIndiciaText,
} from "./actionRequiredTexts";
import {
  addressResubmissionOrCuringDescriptionText,
  expiredFormDescriptionText,
  getAddressDescriptionText,
  usIndiciaDescriptionText,
} from "./descriptionTexts";
import {
  curingDocumentationObtainedText,
  newTaxDocumentationSubmittedText,
} from "./resolutionTexts";

export const getIssueTextsByIssueType = (
  documentType: KycTaxDocumentType,
  hasCuring: boolean
) => ({
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress]: {
    descriptionText: addressResubmissionOrCuringDescriptionText,
    actionRequired: resubmissionOrCuringText,
    resolutionText: hasCuring
      ? curingDocumentationObtainedText
      : newTaxDocumentationSubmittedText,
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.ExpiredForm]: {
    descriptionText: expiredFormDescriptionText,
    actionRequired: resubmissionText,
    resolutionText: newTaxDocumentationSubmittedText,
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.UsPermanentAddress]: {
    descriptionText: getAddressDescriptionText(documentType),
    actionRequired: resubmissionText,
    resolutionText: newTaxDocumentationSubmittedText,
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf]: {
    descriptionText: addressResubmissionOrCuringDescriptionText,
    actionRequired: resubmissionOrCuringText,
    resolutionText: hasCuring
      ? curingDocumentationObtainedText
      : newTaxDocumentationSubmittedText,
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia]: {
    descriptionText: usIndiciaDescriptionText,
    actionRequired: usIndiciaText,
    resolutionText: curingDocumentationObtainedText,
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benPoBoxPermanentAddress]: {
    descriptionText: getAddressDescriptionText(KycTaxDocumentType.W8Ben),
    actionRequired: resubmissionText,
    resolutionText: newTaxDocumentationSubmittedText,
  },
  [AccountOwnerDetailsTaxDocumentationIssueType.W8benCareOf]: {
    descriptionText: getAddressDescriptionText(KycTaxDocumentType.W8Ben),
    actionRequired: resubmissionText,
    resolutionText: newTaxDocumentationSubmittedText,
  },
});
