import {
  readonlyIncludes,
  UrlFilterParams,
  useDashboardFeatureFlags,
  useUrlFilterParams,
} from "@taxbit-dashboard/commons";
import {
  getAccountsSearchKeySchema,
  accountsEnumSearchKeys,
} from "@taxbit-dashboard/rest";
import { useCallback, useMemo } from "react";

import validateAccountsUrlParams from "./validateAccountsUrlParams";
import { useGetAccounts } from "../../../api/accounts/accountsApi";
import {
  accountsEnumParamKeys,
  AccountsTableParams,
} from "../../../api/accounts/accountsApiTypes";

// This type def is just to add comments to the hook's return object.
type UseAccountsTableData = {
  /**
   * The validated and defaulted set of URL params derived from the current URL.
   * These params may not match the URL exactly, but represents the set of params
   * that will be used to fetch accounts.
   */
  urlParams: AccountsTableParams;
  /**
   * A setter for pagination url params. Takes in a setter fn that uses Immer to
   * update a draft of the current parsed url params. The param name for the setter
   * function MUST begin with draft to avoid eslint errors about mutating function params.
   *
   * ex: (draft) => { draft.page += 1; }
   */
  setPaginationParams: (
    setter: (draft: Pick<AccountsTableParams, "page" | "limit">) => void
  ) => void;
  /**
   * A setter for filter url params. Takes in a setter fn that uses Immer to update a draft of
   * the current parsed url params, and resets the current page so that the user will immediately
   * view the first page of the new results. The param name for the setter function MUST begin with
   * draft to avoid eslint errors about mutating function params.
   *
   * ex: (draft) => { draft.searchKey = "email"; }
   */
  setFilterParams: (
    setter: (draft: Omit<AccountsTableParams, "page" | "limit">) => void
  ) => void;
  /**
   * Clears all filter params and resets the current page.
   */
  clearAllFilters: () => void;
  /**
   * Indicates whether we should disable the controls because we are loading new results or
   * loading a new view that displays controls (ex: we already have filter params in the URL).
   */
  shouldDisableControls: boolean;
  /**
   * Whether there are currently any params in the URL. We will only lack params on a fresh
   * load of the accounts view, prior to interacting with any pagination or filter controls.
   */
  hasUrlParams: boolean;
  /**
   * The total count of results for the given set of filter params.
   */
  totalCount: number;
  /**
   * The total count of filters applied via the filter drawer.
   */
  filterDrawerFilterCount: number;
  /**
   * The total count of filters applied via the filter drawer and search.
   */
  filterCount: number;
} & Omit<ReturnType<typeof useGetAccounts>, "isPreviousData">;

const useAccountsTableData = (): UseAccountsTableData => {
  const { shouldHideAccountsTableTinUi } = useDashboardFeatureFlags();

  const validateParams = useCallback(
    (rawParams: UrlFilterParams<AccountsTableParams>) =>
      validateAccountsUrlParams({ rawParams, shouldHideAccountsTableTinUi }),
    [shouldHideAccountsTableTinUi]
  );

  const {
    urlParams,
    hasUrlParams,
    setUrlParams,
    setUrlParamsAndResetPagination: setFilterParams,
  } = useUrlFilterParams<AccountsTableParams>({
    validateParams,
  });

  const clearAllFilters = useCallback(
    () =>
      setFilterParams((draft) => {
        for (const key of getAccountsSearchKeySchema.options) {
          draft[key] = undefined;
        }

        for (const key of accountsEnumParamKeys) {
          draft[key] = [];
        }

        draft.startDate = undefined;
        draft.endDate = undefined;
      }),
    [setFilterParams]
  );

  const { isPreviousData: isLoadingNewResults, ...query } = useGetAccounts({
    params: urlParams,
  });

  const filterDrawerFilterCount = useMemo(() => {
    return Object.keys(urlParams).reduce((count, key) => {
      // Our date range picker allows for just a start date to
      // be selected, but not just an end date. So, just use the
      // start date as an indicator of a date filter.
      if (key === "startDate" && urlParams.startDate) {
        return count + 1;
      } else if (
        readonlyIncludes(accountsEnumParamKeys, key) &&
        urlParams[key]
      ) {
        const selectedOptionCount = urlParams[key]?.length ?? 0;
        return count + selectedOptionCount;
      } else {
        return count;
      }
    }, 0);
  }, [urlParams]);

  const searchFilterCount = useMemo(() => {
    return Object.keys(urlParams).reduce((count, key) => {
      if (readonlyIncludes(accountsEnumSearchKeys, key) && urlParams[key]) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
  }, [urlParams]);

  return {
    ...query,
    hasUrlParams,
    shouldDisableControls: isLoadingNewResults || query.isLoading,
    setFilterParams,
    setPaginationParams: setUrlParams,
    urlParams,
    clearAllFilters,
    totalCount: query.meta?.page?.totalCount ?? 0,
    filterDrawerFilterCount,
    filterCount: filterDrawerFilterCount + searchFilterCount,
  };
};

export default useAccountsTableData;
