import { useGetPayers } from "@taxbit-dashboard/commons";
import {
  HYDRATED_ACCOUNT_POSSIBLE_TAX_CLASSIFICATIONS,
  HYDRATED_ACCOUNT_POSSIBLE_VAT_STATUSES,
} from "@taxbit-dashboard/rest";
import { Button, Drawer, NumericalBadge } from "@taxbit-private/cosmic";
import { RhfDateRangePicker } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import { countryCodes } from "./countryCodes";
import MultiselectFilter from "./MultiselectFilter";
import { useAccountsFilterDrawerForm } from "./useAccountsFilterDrawerForm";
import { POSSIBLE_TIN_STATUSES } from "../../../accountsUrlParams";
import { AccountsView } from "../../../accountsView";
import { useAccountsView } from "../../../useAccountsView";
import {
  TAX_CLASSIFICATION_LABELS,
  TIN_STATUS_LABELS,
  VAT_STATUS_LABELS,
} from "../../accountsLabels";

export enum AccountsFilterDrawerTrackingIds {
  CloseButton = "AccountsFilterDrawerCloseButton",
  ApplyButton = "AccountsFilterDrawerApplyButton",
  CancelButton = "AccountsFilterDrawerCancelButton",
  ClearAllFiltersButton = "AccountsFilterDrawerClearFiltersButton",
  ToggleButton = "AccountsFilterDrawerToggleButton",
  CountBadge = "AccountsFilterDrawerCountBadge",
  DateRangePicker = "AccountsFilterDrawerDateRangePicker",
}

const ACCOUNTS_FILTER_DRAWER_FORM_ID = "accounts-filter-drawer-form";

const TIN_STATUS_OPTIONS = POSSIBLE_TIN_STATUSES.filter(
  (s) => s !== "UNHANDLED"
).map((status) => ({ label: TIN_STATUS_LABELS[status], value: status }));

const VAT_STATUS_OPTIONS = HYDRATED_ACCOUNT_POSSIBLE_VAT_STATUSES.filter(
  (s) => s !== "UNHANDLED"
).map((status) => ({ label: VAT_STATUS_LABELS[status], value: status }));

const TAX_CLASSIFICATION_OPTIONS =
  HYDRATED_ACCOUNT_POSSIBLE_TAX_CLASSIFICATIONS.filter(
    (c) => c !== "UNHANDLED"
  ).map((classification) => ({
    label: TAX_CLASSIFICATION_LABELS[classification],
    value: classification,
  }));

const TAX_DOCUMENTATION_TYPE_OPTIONS = ["W-9", "W-8BEN", "W-8BEN-E", "DPS"].map(
  (type) => ({ label: type, value: type })
);

const COUNTRY_CODE_OPTIONS = countryCodes.map((c) => ({
  label: `[${c.code}] ${c.name}`,
  value: c.code,
}));

const AccountsFilterDrawer: React.FC = () => {
  const { view } = useAccountsView();
  const [isOpen, setIsOpen] = useState(false);
  const {
    clearAllFormFilters,
    formMethods,
    handleSubmit,
    isFormDirty,
    hasFiltersApplied,
    resetForm,
    filterCount,
  } = useAccountsFilterDrawerForm();

  const {
    data: payers,
    isLoading: isPayersLoading,
    isError: isPayersError,
  } = useGetPayers();

  const closeAndReset = () => {
    setIsOpen(false);
    resetForm();
  };

  const payerOptions = useMemo(() => {
    return (
      payers?.map((payer) => ({
        label: payer.payerName,
        value: payer.payerId,
      })) ?? []
    );
  }, [payers]);

  const isLoading = isPayersLoading;
  const isError = isPayersError;

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        size="small"
        trackingId={AccountsFilterDrawerTrackingIds.ToggleButton}
        onClick={() => setIsOpen(true)}
        isDisabled={isLoading || isError}
        utilityElement={
          filterCount > 0 ? (
            <NumericalBadge
              trackingId={AccountsFilterDrawerTrackingIds.CountBadge}
              value={filterCount}
            />
          ) : undefined
        }
      />
      <Drawer
        onClose={closeAndReset}
        isOpen={isOpen}
        title="Filters"
        closeButtonTrackingId={AccountsFilterDrawerTrackingIds.CloseButton}
        primaryButtonProps={{
          label: "Apply",
          isDisabled: !isFormDirty,
          type: "submit",
          form: ACCOUNTS_FILTER_DRAWER_FORM_ID,
          trackingId: AccountsFilterDrawerTrackingIds.ApplyButton,
        }}
        secondaryButtonProps={{
          label: "Cancel",
          onClick: closeAndReset,
          trackingId: AccountsFilterDrawerTrackingIds.CancelButton,
        }}
      >
        <FormProvider {...formMethods}>
          <FlexForm
            id={ACCOUNTS_FILTER_DRAWER_FORM_ID}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e);
              setIsOpen(false);
            }}
          >
            {AccountsView.includes("dateCreated", view) && (
              <RhfDateRangePicker
                name="dateCreated"
                label="Date Created"
                trackingId={AccountsFilterDrawerTrackingIds.DateRangePicker}
                placeholder="Select Date Range"
              />
            )}
            {AccountsView.includesAny("tinStatus", view) && (
              <MultiselectFilter
                options={TIN_STATUS_OPTIONS}
                name="tinStatuses"
              />
            )}
            {AccountsView.includesAny("vatStatus", view) && (
              <MultiselectFilter
                options={VAT_STATUS_OPTIONS}
                name="vatStatuses"
              />
            )}
            {AccountsView.includesAny("taxClassification", view) && (
              <MultiselectFilter
                options={TAX_CLASSIFICATION_OPTIONS}
                hasSearchBar={true}
                name="taxClassifications"
              />
            )}
            {AccountsView.includesAny("taxDocumentationType", view) && (
              <MultiselectFilter
                options={TAX_DOCUMENTATION_TYPE_OPTIONS}
                name="taxDocumentationTypes"
              />
            )}
            {AccountsView.includesAny("taxCountryCodes", view) && (
              <MultiselectFilter
                options={COUNTRY_CODE_OPTIONS}
                hasSearchBar={true}
                name="taxCountryCodes"
              />
            )}
            {AccountsView.includes("filer", view) && payerOptions && (
              <MultiselectFilter options={payerOptions} name="filerIds" />
            )}

            {hasFiltersApplied && (
              <Button
                onClick={clearAllFormFilters}
                label="Clear All Filters"
                variant="anchor-primary-inline"
                trackingId={
                  AccountsFilterDrawerTrackingIds.ClearAllFiltersButton
                }
              />
            )}
          </FlexForm>
        </FormProvider>
      </Drawer>
    </>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

export default AccountsFilterDrawer;
