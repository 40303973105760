import { ToastConfig } from "@taxbit-dashboard/commons";

import { OverrideEligibilityAction } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";

export const getEligibilityOverrideToastTrackingIds = (
  action: OverrideEligibilityAction
) => ({
  SuccessToast: `ir-eligibility-override-${action}-success-toast`,
  ErrorToast: `ir-eligibility-override-${action}-error-toast`,
});

const actionToToastVerbMap: Record<OverrideEligibilityAction, string> = {
  [OverrideEligibilityAction.Add]: "added",
  [OverrideEligibilityAction.Remove]: "removed",
};

export const actionToPrepositionMap: Record<OverrideEligibilityAction, string> =
  {
    [OverrideEligibilityAction.Add]: "to",
    [OverrideEligibilityAction.Remove]: "from",
  };

export const actionToBannerVerbMap: Record<OverrideEligibilityAction, string> =
  {
    [OverrideEligibilityAction.Add]: "adding",
    [OverrideEligibilityAction.Remove]: "removing",
  };

export const getOverrideErroredToastContent = (
  errorCount: number,
  total: number,
  action: OverrideEligibilityAction
): ToastConfig => ({
  message:
    total > 1
      ? `There was an error and ${errorCount}/${total} of the requested accounts were not ${actionToToastVerbMap[action]} ${actionToPrepositionMap[action]} scope. Please try again.`
      : `Failed to ${action} account ${actionToPrepositionMap[action]} scope. Please try again.`,
  trackingId: getEligibilityOverrideToastTrackingIds(action).ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
});

export const getOverrideSucceededToastContent = (
  action: OverrideEligibilityAction
): ToastConfig => ({
  message: `Accounts have been ${actionToToastVerbMap[action]} ${actionToPrepositionMap[action]} scope.`,
  trackingId: getEligibilityOverrideToastTrackingIds(action).SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
});
