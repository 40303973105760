/**
 * A helper method to calculate the page number for the next page size.
 * Should be used to calculate the page number when we update per page size
 * in a table.
 */
const calculatePageNumberForUpdatedPageSize = ({
  currentPageSize,
  currentPage,
  nextPageSize,
}: {
  currentPageSize: number;
  currentPage: number;
  nextPageSize: number;
}) => {
  const currentStartItem = (currentPage - 1) * currentPageSize + 1;
  const nextPage = Math.ceil(currentStartItem / nextPageSize);

  return nextPage;
};

export default calculatePageNumberForUpdatedPageSize;
