import { IrFormType } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";

import { FormsFiltersFormFields } from "./useFormsFiltersFormFieldSchema";
import { formTypeToLabelMap } from "../../../../api/information-reporting/forms/formsApiTypes";

export enum FormsTypeFilterTrackingIds {
  Multiselect = "ir-forms-type-filter-multiselect",
}

export const FORM_TYPE_OPTIONS = Object.values(IrFormType).map((status) => ({
  key: status,
  label: formTypeToLabelMap[status],
}));

const FormsTypeFilter = () => {
  return (
    <RhfMultiselect<
      IrFormType,
      Pick<FormsFiltersFormFields, "types">,
      "types",
      (typeof FORM_TYPE_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="types"
      options={FORM_TYPE_OPTIONS}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Type"
      placeholder="All"
      trackingId={FormsTypeFilterTrackingIds.Multiselect}
    />
  );
};

export default FormsTypeFilter;
