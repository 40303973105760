import { logError, useDashboardStore } from "@taxbit-dashboard/commons";
import { useCallback } from "react";

import { useCreateHydratedAccountsExport } from "../../../../api/hydrated-accounts/hydratedAccountsApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import { AccountsUrlParams } from "../accountsUrlParams";
import { useAccountsUrlParams } from "../useAccountsUrlParams";
import { useAccountsView } from "../useAccountsView";

enum ExportAccountsTableTrackingId {
  ExportButton = "AccountsTableExportButton",
}

export const useExportAccountsTable = () => {
  const { urlParams } = useAccountsUrlParams();
  const { view } = useAccountsView();
  const addToast = useDashboardStore((store) => store.addToast);

  const { mutate, isLoading } = useCreateHydratedAccountsExport();

  const startExport = useCallback(() => {
    mutate(AccountsUrlParams.toExportHydratedAccountsParams(urlParams, view), {
      onError: (error) => {
        logError({
          message: "Failed to export accounts table",
          error,
        });
      },
    });
    addToast({
      message: "Your export has started.",
      trackingId: ExportAccountsTableTrackingId.ExportButton,
      timeoutMs: TOAST_TIMEOUT,
    });
  }, [urlParams, view, mutate, addToast]);

  return { startExport, isLoading };
};
