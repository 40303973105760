import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { useState, useMemo } from "react";

import sortDashboardForms from "./sortDashboardForms";
import { calculateFormTypeToYearsMap } from "../../../utils/calculateFormTypeToYearsMap";

type SharedFormDropdownDataOptions = {
  shouldUseFormItemsForms: boolean;
  shouldIncludeCesop?: boolean;
};

/**
 * @deprecated This hook provides parsed data from the shared `irFormTypesByYear` feature flag. All
 * new form flagging should use individual feature flags and `useFormFeatureFlagData` instead.
 */
const useSharedFormDropdownData = ({
  shouldUseFormItemsForms,
  shouldIncludeCesop = false,
}: SharedFormDropdownDataOptions) => {
  const { irFormTypesByYear } = useDashboardFeatureFlags();
  const [formTypeToYearsMap] = useState(
    calculateFormTypeToYearsMap({
      irFormTypesByYear,
      shouldUseFormItemsForms,
      shouldIncludeCesop,
    })
  );

  const forms = useMemo(() => {
    return sortDashboardForms(
      Object.keys(formTypeToYearsMap) as DashboardFormType[]
    );
  }, [formTypeToYearsMap]);

  return {
    forms,
    formTypeToYearsMap,
    irFormTypesByYear,
  };
};

export default useSharedFormDropdownData;
