import { transferStatusSchema } from "@taxbit-dashboard/rest";
import { z } from "zod";

export const transfersFilterDrawerFormFieldsSchema = z.object({
  transferStatuses: z.array(transferStatusSchema),
  dateRange: z.tuple([z.date().optional(), z.date().optional()]),
});

export type TransfersFilterDrawerFormFields = z.infer<
  typeof transfersFilterDrawerFormFieldsSchema
>;
