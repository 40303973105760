import { Box, EmptyState, Flex } from "@taxbit-private/cosmic";

type EntityNotFoundProps = {
  entity: string;
};

const EntityNotFound: React.FC<EntityNotFoundProps> = ({ entity }) => {
  return (
    <Box>
      <Flex justifyContent="center" padding="xl">
        <EmptyState
          title={`${entity} not found`}
          avatarProps={{
            iconName: "file-text",
            variant: "secondary",
          }}
        />
      </Flex>
    </Box>
  );
};

export default EntityNotFound;
