import {
  AccountsTemplateType,
  FileAction,
  FileType,
  TransactionsTemplateType,
} from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { fileTypesWithTemplates } from "./fileUploaderFormTypes";
import useTemplatesFeatureFlagData from "./useTemplatesFeatureFlagData";
import { fileActionFileTypesMap } from "../../../api/files/filesApiTypes";

const useFileUploaderFormDefaults = ({ action }: { action: FileAction }) => {
  const {
    getTemplatesForFileType,
    hasMultipleTemplatesForFileType,
    forms,
    getYearsForForm,
    accountsTemplates,
    transactionsTemplates,
  } = useTemplatesFeatureFlagData();

  /**
   * We only want to allow a user to select a file type if there are templates available for that file type.
   */
  const filteredFileTypes = useMemo(() => {
    const baseTypesForAction = fileActionFileTypesMap[action];
    return baseTypesForAction.filter((fileType) => {
      if (fileTypesWithTemplates.has(fileType)) {
        return getTemplatesForFileType(fileType).length > 0;
      } else {
        return true;
      }
    });
  }, [action, getTemplatesForFileType]);

  const { formTaxYear, formDocumentType } = useMemo(() => {
    if (hasMultipleTemplatesForFileType(FileType.Forms)) {
      return {
        formTaxYear: undefined,
        formDocumentType: undefined,
      };
    } else {
      return {
        formDocumentType: forms[0],
        formTaxYear: getYearsForForm(forms[0])[0],
      };
    }
  }, [forms, getYearsForForm, hasMultipleTemplatesForFileType]);

  const accountsTemplateType = useMemo(
    () =>
      hasMultipleTemplatesForFileType(FileType.Accounts)
        ? accountsTemplates.includes(AccountsTemplateType.Us)
          ? AccountsTemplateType.Us
          : undefined
        : accountsTemplates[0],
    [accountsTemplates, hasMultipleTemplatesForFileType]
  );

  const transactionsTemplateType = useMemo(
    () =>
      hasMultipleTemplatesForFileType(FileType.Transactions)
        ? transactionsTemplates.includes(TransactionsTemplateType.Default)
          ? TransactionsTemplateType.Default
          : undefined
        : transactionsTemplates[0],
    [hasMultipleTemplatesForFileType, transactionsTemplates]
  );

  const defaultValues = useMemo(
    () => ({
      fileType: filteredFileTypes[0],
      files: [] as File[],
      formTaxYear,
      formDocumentType,
      accountsTemplateType,
      transactionsTemplateType,
    }),
    [
      accountsTemplateType,
      filteredFileTypes,
      formDocumentType,
      formTaxYear,
      transactionsTemplateType,
    ]
  );

  return {
    filteredFileTypes,
    defaultValues,
  };
};

export default useFileUploaderFormDefaults;
