import { useMatch } from "@tanstack/react-location";
import { useDashboardStore } from "@taxbit-dashboard/commons";
import { AccountId } from "@taxbit-dashboard/rest";
import { Button } from "@taxbit-private/cosmic";
import { useCallback, useEffect, useState } from "react";

import useGetTransactionsCsv from "./csv/useGetTransactionsCsv";
import TransactionsCsvExportModal from "./TransactionsCsvExportModal";
import { useGetTransactions } from "../../../../api/transactionsApi";
import downloadFile from "../../../../utils/downloadFile";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import useAccountId from "../../../../utils/useAccountId";

export enum DownloadTransactionsCsvButtonTrackingId {
  ExportCsvButton = "topTransactionsExportTransactionsCsvButton",
  TransactionCsvErrorToast = "transactionsCSVErrorToast",
  TransactionCsvInfoToast = "transactionsCSVInfoToast",
  TransactionCsvSuccessToast = "transactionsCSVSuccessToast",
}

const LOCAL_STORAGE_MODAL_DISSMISSED_KEY =
  "_umc-dashboard_transaction-csv-export-modal-dismissed";

type DownloadTransactionsCsvButtonProps = {
  size?: "default" | "small" | "large";
};

const DownloadTransactionsCsvButton: React.FC<
  DownloadTransactionsCsvButtonProps
> = ({ size = "default" }) => {
  const {
    params: { accountId: accountIdFromUrl },
  } = useMatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const [isModalCheckboxChecked, setIsModalCheckboxChecked] = useState(false);
  const addToast = useDashboardStore((store) => store.addToast);

  const accountIdFromStore = useAccountId();
  const accountId = accountIdFromStore ?? (accountIdFromUrl as AccountId);

  const {
    isLoading: isTransactionsLoading,
    isLoadingError: isTransactionsLoadingError,
  } = useGetTransactions(accountId);

  const onError = useCallback(() => {
    addToast({
      message: "Transactions CSV failed to generate. Please try again.",
      trackingId:
        DownloadTransactionsCsvButtonTrackingId.TransactionCsvErrorToast,
      variant: "danger",
      timeoutMs: TOAST_TIMEOUT,
    });
  }, [addToast]);

  const {
    generateTransactionsCsv,
    isLoading: isCsvGenerating,
    csv,
  } = useGetTransactionsCsv({
    accountId,
    onError,
  });

  useEffect(() => {
    const isModalDismissed = localStorage.getItem(
      LOCAL_STORAGE_MODAL_DISSMISSED_KEY
    );
    if (isModalDismissed === "true") {
      setShouldShowModal(false);
    }
  }, []);

  useEffect(() => {
    if (csv && !isCsvGenerating) {
      addToast({
        message: "Transactions CSV download successful!",
        trackingId:
          DownloadTransactionsCsvButtonTrackingId.TransactionCsvSuccessToast,
        variant: "primary",
        timeoutMs: TOAST_TIMEOUT,
      });
      downloadFile(`transactionsCsv_${accountId}`, csv);
    }
  }, [csv, addToast, accountId, isCsvGenerating]);

  const handleTransactionCsvGeneration = () => {
    addToast({
      message: "Transactions CSV download started.",
      trackingId:
        DownloadTransactionsCsvButtonTrackingId.TransactionCsvInfoToast,
      variant: "primary",
      timeoutMs: TOAST_TIMEOUT,
    });
    generateTransactionsCsv();
  };

  return (
    <>
      <Button
        label="Export CSV"
        trackingId={DownloadTransactionsCsvButtonTrackingId.ExportCsvButton}
        variant="button-secondary"
        size={size}
        isLoading={isCsvGenerating}
        loadingText="Exporting"
        isDisabled={
          !accountId || isTransactionsLoadingError || isTransactionsLoading
        }
        onClick={() => {
          if (shouldShowModal) {
            setIsModalOpen(true);
          } else {
            handleTransactionCsvGeneration();
          }
        }}
      />
      <TransactionsCsvExportModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        onModalContinueClick={() => {
          if (isModalCheckboxChecked) {
            localStorage.setItem(LOCAL_STORAGE_MODAL_DISSMISSED_KEY, "true");
            setShouldShowModal(false);
          }
          setIsModalOpen(false);
          handleTransactionCsvGeneration();
        }}
        isCheckboxChecked={isModalCheckboxChecked}
        onCheckboxChange={() => setIsModalCheckboxChecked((prev) => !prev)}
      />
    </>
  );
};

export default DownloadTransactionsCsvButton;
