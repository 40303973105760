import { GetFormItemsParams } from "@taxbit-dashboard/rest";
import { O } from "ts-toolbelt";

import {
  FormItemsTableParams,
  dashboardFormTypeToFormItemDocumentTypeMap,
} from "./formItemsApiTypes";
import { IrFormTypeDateParams } from "../information-reporting/irApiTypes";

export type FormItemsParams = IrFormTypeDateParams &
  O.Merge<{ limit: number }, FormItemsTableParams>;

const getFormItemsParams = ({
  limit,
  page,
  formDate,
  formType,
}: FormItemsParams): GetFormItemsParams => {
  const offset = (page - 1) * limit;
  const mappedDocumentType =
    dashboardFormTypeToFormItemDocumentTypeMap[formType];

  if (!mappedDocumentType) {
    throw new Error(`Invalid form item type: ${formType}`);
  }

  return {
    "filters[document_date]": formDate,
    "filters[document_type]": mappedDocumentType,
    "page[limit]": limit,
    "page[offset]": offset,
  };
};

export default getFormItemsParams;
