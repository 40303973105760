import {
  useSavedScrollPosition,
  DashboardPrimaryTemplate,
} from "@taxbit-dashboard/commons";
import { TransferType } from "@taxbit-dashboard/rest";
import {
  Anchor,
  Flex,
  EmptyState,
  Box,
  ContentSpinner,
  ContentErrorEmptyState,
} from "@taxbit-private/cosmic";

import useTransfersTableData from "./table/useTransfersTableData";
import TransfersView from "./TransfersView";

export const LEARN_MORE_URL =
  "https://apidocs.taxbit.com/docs/cost-basis-interchange-requirements";

export enum TransfersTrackingIds {
  LearnMoreLink = "transfer-activity-learn-more-link",
  LoadingSpinner = "transfer-activity-loading-spinner",
  Pagination = "transfer-activity-pagination",
}

const Transfers: React.FC = () => {
  const {
    hasUrlParams,
    transferType,
    isError,
    isLoading,
    transfers = [],
  } = useTransfersTableData();

  const title =
    transferType === TransferType.TransferIn ? "Incoming" : "Outgoing";

  useSavedScrollPosition({ key: "transfer-activity" });

  const renderLayout = () => {
    if (hasUrlParams) {
      return <TransfersView />;
    }

    if (isLoading) {
      return (
        <ContentSpinner trackingId={TransfersTrackingIds.LoadingSpinner} />
      );
    }

    if (isError) {
      return (
        <Box>
          <ContentErrorEmptyState entity="transfer data" />
        </Box>
      );
    }

    if (transfers.length === 0) {
      return (
        <Box>
          <Flex justifyContent="center" padding="xl">
            <EmptyState
              title="No transfer data"
              subtitleElement={
                <>
                  Set up an integration with the TaxBit API.{" "}
                  <Anchor
                    label="Learn More"
                    variant="anchor-primary-inline"
                    href={LEARN_MORE_URL}
                    trackingId={TransfersTrackingIds.LearnMoreLink}
                  />
                </>
              }
              avatarProps={{
                iconName: "file-text",
                variant: "secondary",
              }}
            />
          </Flex>
        </Box>
      );
    }

    return <TransfersView />;
  };

  return (
    <DashboardPrimaryTemplate title={title}>
      {renderLayout()}
    </DashboardPrimaryTemplate>
  );
};

export default Transfers;
