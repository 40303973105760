import {
  AuthorizedView,
  DashboardAppRoutePath,
  DashboardNavigationItem,
  UserPermission,
} from "@taxbit-dashboard/commons";

import FilerSetup from "./FilerSetup";
import { FilerSetupModalProvider } from "./useFilerSetupModalContext";

export const navigationItem: DashboardNavigationItem = {
  href: DashboardAppRoutePath.FilerSettings,
  iconName: "tool",
  label: "Filer Setup",
  key: "filer-setup",
  trackingId: "sidebar-navigation-filer-setup",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadPayers] && flags.hasFilerSetupAccess,
  shouldDisableRoute: ({ flags }) => !flags.hasFilerSetupAccess,
} as const;

export const route = {
  path: "/filer-settings",
  element: (
    <AuthorizedView flags={["hasFilerSetupAccess"]}>
      <FilerSetupModalProvider>
        <FilerSetup />
      </FilerSetupModalProvider>
    </AuthorizedView>
  ),
};
