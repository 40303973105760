import { isDefined } from "@taxbit-dashboard/commons";
import { FileAction, FilesApiFile, FileType } from "@taxbit-dashboard/rest";
import { NumericalRowGroup } from "@taxbit-private/cosmic";
import { useMemo } from "react";

import { fileTypeLabelMap } from "../../../../../api/files/filesApiTypes";
import {
  getFileMetadataSucceeded,
  getFileMetadataFailed,
  getFileMetadataTotal,
  getFileMetadataNewAndUpdated,
  getFileMetadataSecondaryTotal,
  getFileMetadataWarnings,
} from "../../../../../api/files/filesApiUtils";

type Props = {
  action: FileAction;
  file: FilesApiFile;
};

const shouldShowDetailedRows = (file: FilesApiFile) =>
  [FileType.Accounts, FileType.Transactions].includes(file.fileType);

const shouldShowSecondaryTotalRow = (file: FilesApiFile) =>
  [
    FileType.DeleteTransactions,
    FileType.DeleteAccountOwners,
    FileType.DeleteForms,
  ].includes(file.fileType);

const FileMetadataGrid: React.FC<Props> = ({ action, file }) => {
  const languageForFormType =
    file.fileType === FileType.DeleteForms
      ? "Accounts with Form Data"
      : fileTypeLabelMap[file.fileType];

  const totalRow = useMemo(
    () =>
      ({
        label: `Total ${languageForFormType}`,
        value: getFileMetadataTotal(file),
        key: "total",
        hasDivider: true,
        variant: "bold",
      }) as const,
    [file, languageForFormType]
  );

  const validRows = useMemo(
    () =>
      [
        {
          label: `Valid ${languageForFormType}`,
          value: getFileMetadataSucceeded(file),
          key: "succeeded",
          variant: "bold",
          hasDivider: !shouldShowDetailedRows(file),
        },
        ...(shouldShowDetailedRows(file)
          ? ([
              {
                label: `New ${languageForFormType}`,
                value: getFileMetadataNewAndUpdated(file)?.new,
                key: "new",
                variant: "indented",
              },
              {
                label: `Updated ${languageForFormType}`,
                value: getFileMetadataNewAndUpdated(file)?.updated,
                key: "updated",
                variant: "indented",
                hasDivider: true,
              },
            ] as const)
          : []),
      ] as const,
    [file, languageForFormType]
  );

  const invalidRow = useMemo(
    () =>
      ({
        label: `Invalid ${languageForFormType}`,
        value: getFileMetadataFailed(file),
        key: "failed",
        variant: "bold-danger",
        hasDivider: true,
      }) as const,
    [file, languageForFormType]
  );

  const ingestedFormDataAccountRows = useMemo(() => {
    const newAndUpdatedCounts = getFileMetadataNewAndUpdated(file);
    const shouldShowImpactedAccounts =
      file.fileType === FileType.Forms &&
      (isDefined(newAndUpdatedCounts?.new) ||
        isDefined(newAndUpdatedCounts?.updated));

    const totalImpactedAccounts =
      (newAndUpdatedCounts?.new ?? 0) + (newAndUpdatedCounts?.updated ?? 0);

    return shouldShowImpactedAccounts
      ? ([
          {
            label: "Impacted Accounts",
            value: totalImpactedAccounts,
            key: "impactedFormDataAccounts",
            variant: "bold",
          },
          {
            label: "New Form Data",
            value: newAndUpdatedCounts?.new ?? 0,
            key: "new",
            variant: "indented",
          },
          {
            label: "Updated Form Data",
            value: newAndUpdatedCounts?.updated ?? 0,
            key: "updated",
            variant: "indented",
          },
        ] as const)
      : [];
  }, [file]);

  const warningsRow = useMemo(
    () =>
      ({
        label: "Rows with Warnings",
        value: getFileMetadataWarnings(file),
        key: "warnings",
        variant: "bold-danger",
        hasDivider: ingestedFormDataAccountRows.length > 0,
      }) as const,
    [file, ingestedFormDataAccountRows.length]
  );

  const deleteSharedRows = useMemo(
    () =>
      [
        {
          label: `Total ${languageForFormType}`,
          value: getFileMetadataTotal(file),
          key: "total",
          variant: "bold",
        },
        {
          label: `Valid ${languageForFormType}`,
          value: getFileMetadataSucceeded(file),
          key: "succeeded",
          variant: "indented",
        },
        {
          label: `Invalid ${languageForFormType}`,
          value: getFileMetadataFailed(file),
          key: "failed",
          variant: "indented-danger",
          hasDivider: shouldShowSecondaryTotalRow(file),
        },
      ] as const,
    [file, languageForFormType]
  );

  const secondaryTotalRow = useMemo(
    () =>
      ({
        label:
          file.fileType === FileType.DeleteForms
            ? "Total Form Data Items"
            : "Impacted Accounts",
        value: getFileMetadataSecondaryTotal(file),
        key: "secondaryTotal",
        variant: "bold",
      }) as const,
    [file]
  );

  const ingestRows = [
    totalRow,
    ...validRows,
    invalidRow,
    warningsRow,
    ...ingestedFormDataAccountRows,
  ];
  const deleteRows = [
    ...deleteSharedRows,
    ...(shouldShowSecondaryTotalRow(file) ? [secondaryTotalRow] : []),
  ];

  return (
    <NumericalRowGroup
      rows={action === FileAction.Ingest ? ingestRows : deleteRows}
    />
  );
};

export default FileMetadataGrid;
