import { calculatePageNumberForUpdatedPageSize } from "@taxbit-dashboard/commons";
import { Body, CosmicPageSize, PaginationFooter } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import AccountsTableExportButton from "./AccountsTableExportButton";
import { useAccountsUrlParams } from "../useAccountsUrlParams";

export enum AccountsTablePaginationTrackingId {
  Pagination = "accounts-table-pagination",
}

type AccountsTablePaginationProps = {
  isDisabled: boolean;
  totalCount: number;
};

const MAX_ROWS = 10_000;

const AccountsTablePagination: React.FC<AccountsTablePaginationProps> = ({
  isDisabled,
  totalCount,
}) => {
  const {
    urlParams: { page, limit },
    setPageControls,
  } = useAccountsUrlParams();

  const setPage = useCallback(
    (nextPage: number) => {
      setPageControls({
        page: nextPage,
      });
    },
    [setPageControls]
  );

  const setLimit = useCallback(
    (nextLimit: CosmicPageSize) => {
      const newPage = calculatePageNumberForUpdatedPageSize({
        currentPageSize: limit,
        currentPage: page,
        nextPageSize: nextLimit,
      });

      setPageControls({
        page: newPage,
        limit: nextLimit,
      });
    },
    [limit, page, setPageControls]
  );

  const limitTooltipProps = {
    content: (
      <Body>
        Visible accounts are limited to 10,000 rows. Please{" "}
        <AccountsTableExportButton label="export a CSV" isAnchor={true} /> to
        see all accounts.
      </Body>
    ),
    isInteractive: true,
  } as const;

  return (
    <PaginationFooter
      itemsLimit={MAX_ROWS}
      tooltipProps={limitTooltipProps}
      currentPage={page}
      isDisabled={isDisabled}
      pageSize={limit}
      setCurrentPage={setPage}
      setPageSize={setLimit}
      totalCount={totalCount}
      trackingId={AccountsTablePaginationTrackingId.Pagination}
    />
  );
};

export default AccountsTablePagination;
