import { Card, Flex, Snippet } from "@taxbit-private/cosmic";

type Props = {
  name: string;
  email: string;
};

const IdentityProviderSettingsView: React.FC<Props> = ({ name, email }) => {
  return (
    <Card title="Personal Information">
      <Flex padding="l" direction="column" gap="xl">
        <Snippet label="Name" content={name} />
        <Snippet label="Email" content={email} />
        <Snippet label="Login Method" content="Identity Provider (IdP)" />
      </Flex>
    </Card>
  );
};

export default IdentityProviderSettingsView;
