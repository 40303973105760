import { ActionSnippet, Anchor } from "@taxbit-private/cosmic";
import { useMemo } from "react";

const HELP_CENTER_LINK =
  "https://taxbitsupport.zendesk.com/hc/en-us/categories/25452765315085-Enterprise-Tax";

export enum DeliveryDeveloperSettingsTrackingId {
  FileNameFormatHelpIcon = "file-name-format-help-icon",
  FileFormatHelpIcon = "file-format-help-icon",
  EncryptionPublicKeyMaskedData = "encryption-public-key-masked-data",
  HelpPageButton = "help-page-button",
}

const useDeliveryDeveloperSettingsRows = () => {
  return useMemo(() => {
    const deliveryConfigurationSettingsSections = [
      {
        key: "s3Settings",
        rows: [
          {
            label: "Delivery To S3",
            key: "deliveryToS3",
            content: "test7",
          },
          {
            label: "Folder Path",
            key: "folderPath",
            content: "test8",
          },
          {
            label: "File Name Format",
            key: "fileNameFormat",
            content: "test9",
            helpIconProps: {
              tooltipProps: {
                content: (
                  <div>
                    Go to our{" "}
                    <Anchor
                      trackingId={
                        DeliveryDeveloperSettingsTrackingId.HelpPageButton
                      }
                      label="Help Page"
                      href={HELP_CENTER_LINK}
                      variant="anchor-primary-inline"
                    />{" "}
                    for more information on file name formats.
                  </div>
                ),
              },
              trackingId:
                DeliveryDeveloperSettingsTrackingId.FileNameFormatHelpIcon,
            },
          },
        ],
      },
      {
        key: "encryptionSettings",
        rows: [
          {
            label: "Encryption Status",
            key: "encryptionStatus",
            content: "test10",
          },
          {
            label: "File Format",
            key: "fileFormat",
            content: "test11",
            helpIconProps: {
              tooltipProps: {
                content: (
                  <div>
                    Go to our{" "}
                    <Anchor
                      trackingId={
                        DeliveryDeveloperSettingsTrackingId.HelpPageButton
                      }
                      label="Help Page"
                      href={HELP_CENTER_LINK}
                      variant="anchor-primary-inline"
                    />{" "}
                    for more information on file name formats.
                  </div>
                ),
              },
              trackingId:
                DeliveryDeveloperSettingsTrackingId.FileFormatHelpIcon,
            },
          },
          {
            label: "Encryption Public Key",
            key: "encryptionPublicKey",
            content: (
              <ActionSnippet
                contents="test12"
                trackingId={
                  DeliveryDeveloperSettingsTrackingId.EncryptionPublicKeyMaskedData
                }
                shouldMask={true}
                shouldCopy={true}
              />
            ),
          },
        ],
      },
    ];

    return deliveryConfigurationSettingsSections;
  }, []);
};

export default useDeliveryDeveloperSettingsRows;
