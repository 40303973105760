import { Button, NumericalBadge } from "@taxbit-private/cosmic";
import { useState } from "react";

import AccountsFilterDrawer from "./AccountsFilterDrawer";
import useAccountsTableData from "../../useAccountsTableData";

export enum AccountsFilterDrawerToggleTrackingId {
  FilterToggle = "accounts-filter-toggle-button",
}

const AccountsFilterDrawerToggle: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { filterDrawerFilterCount, shouldDisableControls } =
    useAccountsTableData();

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        trackingId={AccountsFilterDrawerToggleTrackingId.FilterToggle}
        utilityElement={
          filterDrawerFilterCount > 0 ? (
            <NumericalBadge value={filterDrawerFilterCount} />
          ) : undefined
        }
        onClick={() => setIsDrawerOpen(true)}
        isDisabled={shouldDisableControls}
      />
      <AccountsFilterDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default AccountsFilterDrawerToggle;
