import React, { PropsWithChildren, createContext, useContext } from "react";

import useIrSummaryData from "./useIrSummaryData";

type IrSummaryDataContext = ReturnType<typeof useIrSummaryData> | undefined;

const IrSummaryDataContext = createContext<IrSummaryDataContext>(undefined);

export const useIrSummaryDataContext = () => {
  const context = useContext(IrSummaryDataContext);
  if (!context) {
    throw new Error(
      "useIrSummaryDataContext must be used within a IrSummaryDataProvider"
    );
  }
  return context;
};

export const IrSummaryDataProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const irSummaryData = useIrSummaryData();

  return (
    <IrSummaryDataContext.Provider value={irSummaryData}>
      {children}
    </IrSummaryDataContext.Provider>
  );
};
