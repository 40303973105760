import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  createQueryMetaObject,
} from "@taxbit-dashboard/commons";
import { AccountId, TransactionId } from "@taxbit-dashboard/rest";

const getGainsSummaryQueryKeys = (
  accountId?: AccountId,
  transactionId?: TransactionId
) =>
  [
    {
      key: DashboardQueryKey.GainsSummary,
      accountId,
      transactionId,
    },
  ] as const;

export const useGetGainsSummary = ({
  accountId: accountUuid,
  transactionId: transactionUuid,
}: {
  accountId: AccountId;
  transactionId?: TransactionId;
}) => {
  const restSdk = useTaxBitRest();

  const fetchGainsSummary = ({
    queryKey: [{ accountId, transactionId }],
  }: QueryFunctionContext<
    ReturnType<typeof getGainsSummaryQueryKeys>,
    string
  >) => {
    if (accountId && transactionId) {
      return restSdk.gains.summary.get({
        accountId,
        transactionId,
      });
    } else {
      return undefined;
    }
  };

  return useQuery(
    getGainsSummaryQueryKeys(accountUuid, transactionUuid),
    fetchGainsSummary,
    {
      ...createQueryMetaObject(restSdk.gains.summary.buildPath(accountUuid)),
      enabled: !!accountUuid && !!transactionUuid,
    }
  );
};
