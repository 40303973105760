import {
  UrlFilterParams,
  filterObject,
  isDefined,
} from "@taxbit-dashboard/commons";
import { z } from "zod";

import {
  AccountsTableParams,
  accountsTableParamsSchema,
} from "../../../api/accounts/accountsApiTypes";

/**
 * URL params can be manually edited or out of date according
 * to the current data if from a saved link. To simplify the number
 * of edge cases we need to dive into, we use a zod schema that coerces
 * the given params into a set that could be used to fetch data, i.e. it
 * strips out incorrect optional params and defaults required ones.
 */
const validateAccountsUrlParams = ({
  rawParams,
  shouldHideAccountsTableTinUi = false,
}: {
  rawParams: UrlFilterParams<AccountsTableParams>;
  shouldHideAccountsTableTinUi?: boolean;
}): AccountsTableParams => {
  const schema = shouldHideAccountsTableTinUi
    ? accountsTableParamsSchema.merge(
        // Explicitly wipe out TIN-related params.
        z.object({
          masked_tax_id: z.undefined().catch(undefined),
          tinValidationStatuses: z.undefined().catch(undefined),
        })
      )
    : accountsTableParamsSchema;

  const params = schema.parse(rawParams);
  return filterObject(params, (val) => isDefined(val));
};

export default validateAccountsUrlParams;
