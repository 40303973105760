import { FourDigitYear } from "@taxbit-private/datetime";
import { z } from "zod";

import { form1099BLineItemWithIdsSchema } from "./formItem1099BRestApiTypes";
import { form1099DivLineItemWithIdsSchema } from "./formItem1099DivRestApiTypes";
import { form1099IntLineItemWithIdsSchema } from "./formItem1099IntRestApiTypes";
import { form1099KLineItemWithIdsSchema } from "./formItem1099KRestApiTypes";
import { form1099MiscLineItemWithIdsSchema } from "./formItem1099MiscRestApiTypes";
import { form1099NecLineItemWithIdsSchema } from "./formItem1099NecRestApiTypes";
import { form1099RLineItemWithIdsSchema } from "./formItem1099RRestApiTypes";
import { form5498LineItemWithIdsSchema } from "./formItem5498RestApiTypes";
import { formGainLossLineItemWithIdsSchema } from "./formItemGainLossRestApiTypes";
import { formRmdLineItemWithIdsSchema } from "./formItemRmdRestApiTypes";
import { FormItemDocumentType } from "./formItemsSharedRestApiTypes";
import getPublicApiSuccessSchema from "../../getPublicApiSuccessSchema";

export type GetFormItemsParams = {
  "page[offset]"?: number;
  "page[limit]"?: number;
  "filters[document_type]": FormItemDocumentType;
  "filters[document_date]": FourDigitYear;
};

// Form Items types and schemas are copied from the BE and modified to be in camelCase.
// Some fields are simplified to be strings.
// https://github.com/taxbit-private/form-item-service
const genericFormLineItemWithIdsSchema = z.discriminatedUnion("documentType", [
  form1099BLineItemWithIdsSchema,
  form1099DivLineItemWithIdsSchema,
  form1099IntLineItemWithIdsSchema,
  form1099KLineItemWithIdsSchema,
  form1099MiscLineItemWithIdsSchema,
  form1099NecLineItemWithIdsSchema,
  form1099RLineItemWithIdsSchema,
  form5498LineItemWithIdsSchema,
  formGainLossLineItemWithIdsSchema,
  formRmdLineItemWithIdsSchema,
]);
export type GenericFormItem = z.infer<typeof genericFormLineItemWithIdsSchema>;

const formItemsSchema = z.object({
  formItems: genericFormLineItemWithIdsSchema.array(),
});

export const getFormItemsResponseSchema =
  getPublicApiSuccessSchema(formItemsSchema);
export type GetFormItemsResponse = z.infer<typeof getFormItemsResponseSchema>;
