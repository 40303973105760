import { Button, Drawer } from "@taxbit-private/cosmic";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import TransfersDateRangeFilter from "./TransfersDateRangeFilter";
import TransfersStatusFilter from "./TransfersStatusFilter";
import useTransfersFilterDrawerForm from "./useTransfersFilterDrawerForm";

export enum TransfersFilterDrawerTrackingId {
  ApplyBtn = "transfers-filter-drawer-apply-btn",
  CancelBtn = "transfers-filter-drawer-cancel-btn",
  CloseBtn = "transfers-filter-drawer-close-btn",
  ClearAllFiltersBtn = "transfers-filter-drawer-clear-filters-button",
}

const TRANSFER_FILTER_DRAWER_FORM_ID = "transfers-filter-drawer-form";

type TransfersFilterDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TransfersFilterDrawer: React.FC<TransfersFilterDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    isFormDirty,
    formMethods,
    resetForm,
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  } = useTransfersFilterDrawerForm();

  const closeAndReset = () => {
    onClose();
    resetForm();
  };

  return (
    <Drawer
      title="Filter Transfers"
      isOpen={isOpen}
      onClose={closeAndReset}
      primaryButtonProps={{
        label: "Apply",
        trackingId: TransfersFilterDrawerTrackingId.ApplyBtn,
        isDisabled: !isFormDirty,
        type: "submit",
        form: TRANSFER_FILTER_DRAWER_FORM_ID,
      }}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: TransfersFilterDrawerTrackingId.CancelBtn,
        onClick: closeAndReset,
      }}
      closeButtonTrackingId={TransfersFilterDrawerTrackingId.CloseBtn}
    >
      <FormProvider {...formMethods}>
        <FlexForm
          id={TRANSFER_FILTER_DRAWER_FORM_ID}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(e);
            onClose();
          }}
        >
          <TransfersDateRangeFilter />
          <TransfersStatusFilter />
          {hasFiltersApplied && (
            <Button
              onClick={clearAllFormFilters}
              label="Clear All Filters"
              variant="anchor-primary-inline"
              trackingId={TransfersFilterDrawerTrackingId.ClearAllFiltersBtn}
            />
          )}
        </FlexForm>
      </FormProvider>
    </Drawer>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

export default TransfersFilterDrawer;
