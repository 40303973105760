/**
 * MSW currently does not support Node18, but we opted to lock the
 * dashboard repo to this version anyway to be the most future proof.
 * This cross-fetch package fills the gap so that we can have both.
 *
 * TODO: Remove this as soon as MSW supports Node 18.
 * https://github.com/mswjs/msw/issues/1388
 */
import { Headers } from "cross-fetch";

export default ({
  accessToken,
  body,
}: {
  accessToken: string;
  body: FormData | string | undefined;
}) => {
  const headers = new Headers({
    Authorization: `Bearer ${accessToken}`,
  });

  // If our request has a string body, indicate that we are sending a JSON object as that body.
  if (typeof body === "string") {
    headers.set("Content-Type", "application/json");
  }

  return headers;
};
