import { DateTimeString } from "@taxbit-private/data-models-v2";
import { StringType } from "@taxbit-private/type-wrappers";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import { assetAmountSchema, lineItemSchema } from "./assetApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export type TransactionId = StringType<"TransactionId">;
export const transactionIdSchema = z
  .string()
  .refine((transactionId) => uuidSchema.safeParse(transactionId).success)
  .transform((transactionId) => transactionId as TransactionId);

export const dateTimeStringSchema = z
  .string()
  .transform((dateTimeString) => dateTimeString as DateTimeString);

export type ExternalTransactionId = StringType<"ExternalId">;

export const externalIdSchema = z
  .string()
  .transform(
    (externalTransactionId) => externalTransactionId as ExternalTransactionId
  );

export enum TransactionType {
  Sell = "SELL",
  Buy = "BUY",
  Forex = "FOREX",
  TransferIn = "TRANSFER-IN",
  TransferOut = "TRANSFER-OUT",
  GiftSent = "GIFT-SENT",
  GiftReceived = "GIFT-RECEIVED",
  Trade = "TRADE",
  Income = "INCOME",
  Expense = "EXPENSE",
  Reward = "REWARD",
  Unknown = "UNKNOWN",
}

export const platformSchema = z.object({
  transactionHash: z.string().optional(),
});

export const metaDataSchema = z.object({
  platform: platformSchema.optional(),
  hasTransferLotData: z.boolean().optional(),
});

export const transactionSchema = z.object({
  id: transactionIdSchema,
  accountId: uuidSchema,
  externalId: externalIdSchema,
  datetime: dateTimeStringSchema,
  updatedDatetime: dateTimeStringSchema.optional(),
  createdDatetime: dateTimeStringSchema.optional(),
  type: z.nativeEnum(TransactionType).optional(),
  received: lineItemSchema.array().optional(),
  sent: lineItemSchema.array().optional(),
  fees: lineItemSchema.array().optional(),
  prices: assetAmountSchema.array().optional(),
  metadata: metaDataSchema.optional(),
});

export type Transaction = z.infer<typeof transactionSchema>;

export const getTransactionsSchema = getPublicApiSuccessSchema(
  transactionSchema.array()
);

export type GetTransactionsResponse = z.infer<typeof getTransactionsSchema>;

export const getTransactionSchema =
  getPublicApiSuccessSchema(transactionSchema);

export type GetTransactionResponse = z.infer<typeof getTransactionSchema>;
