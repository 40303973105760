import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const formGainLossLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.FormGainLossStatement),
  assetName: z.string(),
  assetSymbol: z.string(),
  assetQuantity: z.string(),
  acquiredDate: z.string().optional(),
  disposedDate: z.string().optional(),
  proceeds: z.string(),
  costBasis: z.string().optional(),
  gainLossClassification: z.string().optional(),
});

export const formGainLossLineItemWithIdsSchema =
  formGainLossLineItemSchema.merge(baseFormLineItemWithIdsSchema);
export type FormGainLossLineItem = z.infer<
  typeof formGainLossLineItemWithIdsSchema
>;
