import { useNavigate, useSearch } from "@tanstack/react-location";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useMemo } from "react";

import useFormFeatureFlagData, {
  YearsByFormFeatureFlag,
} from "./useFormFeatureFlagData";
import validateFormFeatureFlagParams, {
  IrFormFeatureFlagSearchLocationParams,
} from "./validateFormFeatureFlagParams";

const useFormFeatureFlagDropdowns = ({
  featureFlag,
  onFormChangeCallback,
  onYearChangeCallback,
}: {
  featureFlag: YearsByFormFeatureFlag;
  onFormChangeCallback?: (form: DashboardFormType) => void;
  onYearChangeCallback?: (year: FourDigitYear) => void;
}) => {
  const { forms, yearsByFormType, getYearsForForm, hasForms } =
    useFormFeatureFlagData({
      featureFlag,
    });

  const navigate = useNavigate<IrFormFeatureFlagSearchLocationParams>();

  const rawUrlParams = useSearch<IrFormFeatureFlagSearchLocationParams>();
  const validatedUrlParams = useMemo(() => {
    return validateFormFeatureFlagParams(rawUrlParams, yearsByFormType);
  }, [rawUrlParams, yearsByFormType]);

  const currentForm = (() => {
    if (validatedUrlParams?.formType) return validatedUrlParams.formType;

    if (hasForms) return forms[0];

    return undefined;
  })();

  const currentYear = (() => {
    if (validatedUrlParams?.formDate) return validatedUrlParams.formDate;

    if (hasForms) return getYearsForForm(currentForm)[0];

    return undefined;
  })();

  const onFormChange = (form: DashboardFormType) => {
    navigate({
      search: {
        formType: form,
        formDate: `${
          yearsByFormType[form]?.find(
            (formYear) => formYear === validatedUrlParams?.formDate
          ) ?? getYearsForForm(currentForm)[0]
        }`,
      },
    });
    onFormChangeCallback?.(form);
  };

  const onYearChange = (year: FourDigitYear) => {
    navigate({
      search: {
        formType: currentForm,
        formDate: `${year}`,
      },
    });
    onYearChangeCallback?.(year);
  };

  return {
    forms,
    currentForm,
    onFormChange,
    years: getYearsForForm(currentForm),
    currentYear,
    onYearChange,
  };
};

export default useFormFeatureFlagDropdowns;
