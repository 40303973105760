import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { Body, Flex, Icon } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { max, parseISO } from "date-fns";
import { useMemo } from "react";
import styled from "styled-components";

import TimestampsTooltip from "./TimestampsTooltip";
import { useIrSummaryDataContext } from "../context/IrSummaryContext";

const DataIngestionTimestamps: React.FC = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const { timestamps } = useIrSummaryDataContext();
  const {
    accountsSyncTimestamp,
    transactionsSyncTimestamp,
    formItemsSyncTimestamp,
  } = timestamps || {};

  const definedTimestamps = useMemo(
    () =>
      filterToDefinedValues(
        [
          accountsSyncTimestamp,
          transactionsSyncTimestamp,
          formItemsSyncTimestamp,
        ].map((timestamp) => (timestamp ? parseISO(timestamp) : undefined))
      ),
    [accountsSyncTimestamp, transactionsSyncTimestamp, formItemsSyncTimestamp]
  );

  const latestTimestamp = (() => {
    if (definedTimestamps.length === 0) {
      return COSMIC_VALUE_PLACEHOLDER;
    }

    return formatDateTime({
      date: max(definedTimestamps),
      format: "DateTime",
    });
  })();

  return (
    <CenteredFlex gap="xs" alignItems="center">
      <Icon name="info" />
      <Body>Most Recent Data Ingestion: {latestTimestamp}</Body>
      {definedTimestamps.length > 0 && (
        <>
          {" "}
          <TimestampsTooltip />
        </>
      )}
    </CenteredFlex>
  );
};

const CenteredFlex = styled(Flex)`
  margin: 0 auto;
`;

export default DataIngestionTimestamps;
