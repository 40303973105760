import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
} from "@taxbit-private/cosmic";

import FormItemsTablePagination from "../../../form-items/FormItemsTablePagination";
import FormItemsTable from "../../../form-items/table/FormItemsTable";
import useFormItems from "../../../form-items/useFormItems";
import IrFormYearUtilityDropdowns from "../../../information-reporting/shared/IrFormYearUtilityDropdowns";

export enum FormDataTabTrackingIds {
  LoadingSpinner = "form-data-tab-spinner",
}

const FormDataTab: React.FC = () => {
  const {
    error,
    formItems,
    isError,
    isLoading,
    isNewDataLoading,
    setPaginationParams,
    shouldDisableControls,
    totalCount,
    urlParams,
    ...props
  } = useFormItems();

  const renderLayout = (() => {
    if (isLoading || (isNewDataLoading && urlParams.page === 1)) {
      return (
        <ContentSpinner trackingId={FormDataTabTrackingIds.LoadingSpinner} />
      );
    }

    if (isError && urlParams.page === 1) {
      return <ContentErrorEmptyState entity="form data" />;
    }

    if (!isError && formItems?.length === 0) {
      return <ContentEntityEmptyState entity="form data" />;
    }

    return (
      <>
        <FormItemsTable
          isLoading={isNewDataLoading}
          isError={isError}
          formItems={formItems ?? []}
          currentForm={props.currentForm}
        />
        <Divider />
        <FormItemsTablePagination
          isDisabled={shouldDisableControls}
          totalCount={totalCount}
          currentPageSize={urlParams.limit}
          currentPage={urlParams.page}
          setPaginationParams={setPaginationParams}
        />
      </>
    );
  })();

  return (
    <Card
      title="Form Data"
      utilityElement={
        <IrFormYearUtilityDropdowns
          {...props}
          isDisabled={isLoading}
          size="small"
        />
      }
    >
      {renderLayout}
    </Card>
  );
};

export default FormDataTab;
