import {
  AccountsTableEnumParamKey,
  AccountsTableParams,
} from "../../../../../api/accounts/accountsApiTypes";
import useAccountsTableData from "../../useAccountsTableData";

const useEnumFilterChips = <
  TKey extends AccountsTableEnumParamKey,
  TEnum extends NonNullable<AccountsTableParams[TKey]>,
>(
  key: TKey,
  labelMap: Record<TEnum[number], string>,
  filterLabel: string
) => {
  const { urlParams, setFilterParams } = useAccountsTableData();

  const values = urlParams[key];

  if (values) {
    return values.map((val) => {
      // TS is having a hard time with this access despite the type
      // working as designed when the function is called, so we just cast it.
      const label = labelMap[val as keyof typeof labelMap];
      return {
        label: `${filterLabel}: ${label}`,
        removeButtonProps: {
          "onClick": () =>
            setFilterParams((draft) => {
              const currentFilterSet = draft[key];

              // Casting due to issues with Array type inference for methods
              // https://github.com/microsoft/TypeScript/issues/44373
              draft[key] = (currentFilterSet as Array<TEnum[number]>)?.filter(
                (currentVal) => currentVal !== val
              ) as typeof currentFilterSet;
            }),
          "aria-label": `Remove filter for ${label}`,
        },
        trackingId: `account-table-chip-search-${key}-${val}`,
      };
    });
  } else {
    return [];
  }
};

export default useEnumFilterChips;
