import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form1099KLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form1099K),
  grossAmountOfPaymentsTransactions: z.string(),
  cardNotPresentTransactions: z.string().optional(),
  merchantCategoryCode: z.string().optional(),
  numberOfPaymentTransactions: z.number(),
  federalTaxWithheld: z.string().optional(),
  january5A: z.string(),
  february5B: z.string(),
  march5C: z.string(),
  april5D: z.string(),
  may5E: z.string(),
  june5F: z.string(),
  july5G: z.string(),
  august5H: z.string(),
  september5I: z.string(),
  october5J: z.string(),
  november5K: z.string(),
  december5L: z.string(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
});

export const form1099KLineItemWithIdsSchema = form1099KLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form1099KLineItem = z.infer<typeof form1099KLineItemWithIdsSchema>;
