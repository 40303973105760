import { useAuth0 } from "@auth0/auth0-react";
import { MakeGenerics, useSearch } from "@tanstack/react-location";
import { useEffect } from "react";

type LoginSearchParams = MakeGenerics<{
  Search: {
    invitation: string;
    organization: string;
  };
}>;

/**
 * Determines if a user has arrived from an invitation link. If so,
 * redirects the user through Auth0's invitation acceptance auth flow.
 * Returns whether the user is being redirected.
 */
const useHandleArrivalFromInvitation = () => {
  const { loginWithRedirect } = useAuth0();
  const { invitation, organization } = useSearch<LoginSearchParams>();
  const hasArrivedFromInvitation = !!(invitation && organization);

  useEffect(() => {
    if (hasArrivedFromInvitation) {
      void loginWithRedirect({
        authorizationParams: {
          invitation,
          organization,
        },
      });
    }
  }, [hasArrivedFromInvitation, invitation, organization, loginWithRedirect]);

  return hasArrivedFromInvitation;
};

export default useHandleArrivalFromInvitation;
