import { KycTaxDocumentationIssueType } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";

import { AccountsFilterDrawerFormFields } from "./accountsFilterDrawerFormFieldSchema";
import { issueTypesMap } from "../../../account-details/kyc-tax-documentation/issuesTypesMap";

export const AccountsTaxDocumentationOpenIssueFilterTrackingId = {
  Filter: "accounts-filters-drawer-tax-documentation-open-issue-filter",
};

const ISSUE_TYPE_OPTIONS = Object.values(KycTaxDocumentationIssueType).map(
  (type) => ({
    key: type,
    label: issueTypesMap[type],
  })
);

const AccountsTaxDocumentationOpenIssueFilter: React.FC = () => {
  return (
    <RhfMultiselect<
      KycTaxDocumentationIssueType,
      Pick<AccountsFilterDrawerFormFields, "taxDocumentationOpenIssues">,
      "taxDocumentationOpenIssues",
      (typeof ISSUE_TYPE_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="taxDocumentationOpenIssues"
      options={ISSUE_TYPE_OPTIONS}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Open Documentation Issues"
      placeholder="All"
      trackingId={AccountsTaxDocumentationOpenIssueFilterTrackingId.Filter}
    />
  );
};

export default AccountsTaxDocumentationOpenIssueFilter;
