import {
  DashboardAppRoutePath,
  useOrganizationPrefixedNavigate,
} from "@taxbit-dashboard/commons";
import { ActionSnippet, Anchor, Button } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useDeveloperSettings from "./useDeveloperSettings";

const TAX_API_DOCS_URL = "https://apidocs.taxbit.com/docs/getting-started";

export enum GeneralDeveloperSettingsTrackingId {
  TenantIdCopyBtn = "tenant-id-copy-btn",
  ClientIdCopyBtn = "client-id-copy-btn",
  ClientSecretMaskedData = "client-secret-masked-data",
  FilerDetailsBtn = "filer-details-btn",
  S3BucketCopyBtn = "s3-bucket-copy-btn",
  AccessKeyCopyBtn = "access-key-copy-btn",
  SecretKeyMaskedData = "secret-key-masked-data",
  ApiDocumentationLink = "api-documentation-link",
}

const useGeneralDeveloperSettingsRows = () => {
  const { currentOrganization, currentOrganizationId, devSettings } =
    useDeveloperSettings();
  const { prefixedNavigate } = useOrganizationPrefixedNavigate();
  return useMemo(() => {
    const generalSettingsSections = [
      {
        key: "General",
        rows: [
          {
            label: "Tenant Name",
            key: "tenantName",
            content: currentOrganization?.name || COSMIC_VALUE_PLACEHOLDER,
          },
          {
            label: "Tenant ID",
            key: "tenantId",
            content: (
              <ActionSnippet
                contents={currentOrganizationId}
                trackingId={GeneralDeveloperSettingsTrackingId.TenantIdCopyBtn}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "Parent Name",
            key: "parentName",
            content:
              currentOrganization?.companyName || COSMIC_VALUE_PLACEHOLDER,
          },
          {
            label: "Filer Details",
            key: "filerDetails",
            content: (
              <Button
                trackingId={GeneralDeveloperSettingsTrackingId.FilerDetailsBtn}
                label="Filer Setup"
                onClick={() => {
                  prefixedNavigate({ to: DashboardAppRoutePath.FilerSettings });
                }}
                variant="anchor-primary-inline"
              />
            ),
          },
        ],
      },
      {
        key: "API",
        rows: [
          {
            label: "Client ID",
            key: "clientId",
            content: (
              <ActionSnippet
                contents={devSettings?.auth0ClientId}
                trackingId={GeneralDeveloperSettingsTrackingId.ClientIdCopyBtn}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "Client Secret",
            key: "clientSecret",
            content: (
              <ActionSnippet
                contents={devSettings?.auth0ClientSecret}
                trackingId={
                  GeneralDeveloperSettingsTrackingId.ClientSecretMaskedData
                }
                shouldMask={true}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "API Documentation",
            key: "apiDocumentation",
            content: (
              <Anchor
                trackingId={
                  GeneralDeveloperSettingsTrackingId.ApiDocumentationLink
                }
                label={TAX_API_DOCS_URL}
                href={TAX_API_DOCS_URL}
                variant="anchor-primary-inline"
              />
            ),
          },
        ],
      },
      {
        key: "AwsS3",
        rows: [
          {
            label: "S3 Bucket",
            key: "s3Bucket",
            content: (
              <ActionSnippet
                contents={devSettings?.s3DropzoneBucket}
                trackingId={GeneralDeveloperSettingsTrackingId.S3BucketCopyBtn}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "Access Key",
            key: "accessKey",
            content: (
              <ActionSnippet
                contents={devSettings?.s3DropzoneBucketAccessKey}
                trackingId={GeneralDeveloperSettingsTrackingId.AccessKeyCopyBtn}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "Secret Key",
            key: "secretKey",
            content: (
              <ActionSnippet
                contents={devSettings?.s3DropzoneBucketSecretKey}
                trackingId={
                  GeneralDeveloperSettingsTrackingId.SecretKeyMaskedData
                }
                shouldMask={true}
                shouldCopy={true}
              />
            ),
          },
        ],
      },
    ];
    return generalSettingsSections;
  }, [
    currentOrganization,
    currentOrganizationId,
    devSettings,
    prefixedNavigate,
  ]);
};

export default useGeneralDeveloperSettingsRows;
