import { FilesApiFile } from "@taxbit-dashboard/rest";
import { Flex, Modal, ModalProps, Button } from "@taxbit-private/cosmic";
import { RhfTextInput } from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";

import useEditFileDescriptionModalForm, {
  EditFileDescriptionFormFields,
  EditFileDescriptionModalTrackingId,
} from "./useEditFileDescriptionModalForm";

type Props = Pick<ModalProps, "isOpen" | "onClose"> & { file: FilesApiFile };

const EDIT_FILE_DESCRIPTION_FORM_ID = "edit-file-description-form";

const EditFileDescriptionModal: React.FC<Props> = ({
  onClose,
  isOpen,
  file,
}) => {
  const {
    clearDescription,
    formMethods,
    handleSubmit,
    isDescriptionEmpty,
    isUpdatingFile,
  } = useEditFileDescriptionModalForm(onClose, file);

  return (
    <Modal
      title="Edit Description"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={EditFileDescriptionModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: EditFileDescriptionModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Save Changes",
        trackingId: EditFileDescriptionModalTrackingId.SubmitButton,
        type: "submit",
        form: EDIT_FILE_DESCRIPTION_FORM_ID,
        isDisabled: !formMethods.getFieldState("description").isDirty,
        loadingText: "Saving",
        isLoading: isUpdatingFile,
      }}
    >
      <FormProvider {...formMethods}>
        <form
          id={EDIT_FILE_DESCRIPTION_FORM_ID}
          onSubmit={(e) => {
            void handleSubmit(e);
          }}
        >
          <Flex direction="column" gap="s">
            <RhfTextInput<EditFileDescriptionFormFields>
              name="description"
              trackingId={EditFileDescriptionModalTrackingId.TextInput}
              label="File Description"
            />
            <Flex>
              <Button
                variant="anchor-primary-inline"
                onClick={clearDescription}
                isDisabled={isDescriptionEmpty}
                trackingId={
                  EditFileDescriptionModalTrackingId.ClearDescriptionButton
                }
                label="Clear Description"
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditFileDescriptionModal;
