import {
  IrsTinMatchValidationResult,
  KycTinVerificationStatus,
} from "@taxbit-dashboard/rest";

import BadgeWithoutLabelTransformation from "../../../BadgeWithoutLabelTransformation";

type TinBadgeProps = {
  tinValidationStatus?: KycTinVerificationStatus | IrsTinMatchValidationResult;
};

const TinBadge: React.FC<TinBadgeProps> = ({ tinValidationStatus }) => {
  switch (tinValidationStatus) {
    case IrsTinMatchValidationResult.InvalidData:
    case KycTinVerificationStatus.InvalidData: {
      return (
        <BadgeWithoutLabelTransformation
          label="Invalid Data"
          variant="danger"
        />
      );
    }
    case IrsTinMatchValidationResult.Mismatch:
    case KycTinVerificationStatus.TinMismatch: {
      return (
        <BadgeWithoutLabelTransformation label="Mismatch" variant="danger" />
      );
    }
    case IrsTinMatchValidationResult.Pending:
    case KycTinVerificationStatus.Pending: {
      return (
        <BadgeWithoutLabelTransformation label="Pending" variant="warning" />
      );
    }
    case KycTinVerificationStatus.TinTypeMismatch: {
      return (
        <BadgeWithoutLabelTransformation
          label="Valid - TIN Type Mismatch"
          variant="warning"
        />
      );
    }
    case KycTinVerificationStatus.CountryCodeMismatch: {
      return (
        <BadgeWithoutLabelTransformation
          label="Valid - TIN Country Code Mismatch"
          variant="warning"
        />
      );
    }
    case IrsTinMatchValidationResult.TinNotIssued:
    case KycTinVerificationStatus.TinNotIssued: {
      return (
        <BadgeWithoutLabelTransformation
          label="TIN Not Issued"
          variant="danger"
        />
      );
    }
    case KycTinVerificationStatus.TinMismatchForeignIndicia: {
      return (
        <BadgeWithoutLabelTransformation
          label="Mismatch - Foreign Indicia"
          variant="danger"
        />
      );
    }
    case IrsTinMatchValidationResult.Foreign:
    case KycTinVerificationStatus.ForeignIndicia: {
      return (
        <BadgeWithoutLabelTransformation label="Foreign" variant="secondary" />
      );
    }
    case IrsTinMatchValidationResult.ValidEinMatch:
    case IrsTinMatchValidationResult.ValidSsnMatch:
    case IrsTinMatchValidationResult.ValidSsnEinMatch:
    case KycTinVerificationStatus.Valid: {
      return (
        <BadgeWithoutLabelTransformation label="Valid" variant="success" />
      );
    }
    default: {
      return (
        <BadgeWithoutLabelTransformation
          label="Unprocessed"
          variant="secondary"
        />
      );
    }
  }
};

export default TinBadge;
