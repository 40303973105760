import { DashboardEligibilityFormStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo } from "react";

import { EligibilityFiltersFormFields } from "./useEligibilityFiltersFormFieldSchema";
import { dashboardEligibilityFormStatusLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

export enum EligibilityFormStatusFilterTrackingIds {
  Multiselect = "irEligibilityFormStatusFilterMultiselect",
}

const EligibilityFormStatusFilter = () => {
  const options = useMemo(() => {
    return Object.values(DashboardEligibilityFormStatus).map((formStatus) => ({
      key: formStatus,
      label: dashboardEligibilityFormStatusLabelMap[formStatus],
    }));
  }, []);

  return (
    <RhfMultiselect<
      DashboardEligibilityFormStatus,
      Pick<EligibilityFiltersFormFields, "formStatuses">,
      "formStatuses",
      (typeof options)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="formStatuses"
      options={options}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Form Status"
      placeholder="All"
      trackingId={EligibilityFormStatusFilterTrackingIds.Multiselect}
    />
  );
};

export default EligibilityFormStatusFilter;
