import { z } from "zod";

import camelCaseKeys from "./camelCaseKeys";
import getUnexpectedResponseError from "./getUnexpectedResponseError";
import parseDataWithSchema from "./parseDataWithSchema";
import JsonData from "./types/JsonData";
import JsonDataZodSchema from "./types/JsonDataZodSchema";

type FormatResponseOptions<
  TResponseDataSchema extends JsonDataZodSchema = JsonDataZodSchema,
> = {
  response: Response;
  responseDataSchema?: TResponseDataSchema;
};

// Overload: If no responseDataSchema is given, no response data will be returned.
function prepareResponseData({
  response,
}: Omit<FormatResponseOptions, "responseDataSchema">): Promise<undefined>;

// Overload: If responseDataSchema is given, typed response data will be returned.
function prepareResponseData<TResponseDataSchema extends JsonDataZodSchema>({
  response,
  responseDataSchema,
}: FormatResponseOptions<TResponseDataSchema>): Promise<
  z.infer<TResponseDataSchema>
>;

/**
 * Prepares data returned from a network request to be handed back to the consumer.
 */
async function prepareResponseData<
  TResponseDataSchema extends JsonDataZodSchema,
>({
  response,
  responseDataSchema,
}: FormatResponseOptions<TResponseDataSchema>) {
  // Purposely never return response body data unless a schema has
  // been provided to validate it.
  if (!responseDataSchema) {
    return undefined;
  }

  let parsedBody: JsonData;

  try {
    parsedBody = (await response.json()) as JsonData;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw await getUnexpectedResponseError(response);
  }

  const camelCasedResult =
    typeof parsedBody === "object" ? camelCaseKeys(parsedBody) : parsedBody;

  return parseDataWithSchema(
    camelCasedResult,
    responseDataSchema,
    "Response data did not match expected schema."
  );
}

export default prepareResponseData;
