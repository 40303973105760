import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form1099RLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form1099R),
  grossDistribution: z.string(),
  taxableAmount: z.string().optional(),
  taxableAmountNotDetermined: z.boolean().default(false),
  isTotalDistribution: z.boolean().default(false),
  capitalGain: z.string().optional(),
  federalTaxWithheld: z.string().optional(),
  employeeContributionsDesignatedRothContributionsInsurancePremiums: z
    .string()
    .optional(),
  netUnrealizedAppreciation: z.string().optional(),
  distributionsCode: z.string(),
  other: z.string().optional(),
  otherPercentage: z.union([z.number(), z.string()]).optional(),
  percentageOfTotalDistribution: z.union([z.number(), z.string()]).optional(),
  totalEmployeeContributions: z.string().optional(),
  allocableToIrrWithin5Years: z.string().optional(),
  firstYearDesignatedRothContribution: z.number().optional(),
  dateOfPayment: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateDistribution: z.string().optional(),
  localTaxWithheld: z.string().optional(),
  nameOfLocality: z.string().optional(),
  localDistribution: z.string().optional(),
});

export const form1099RLineItemWithIdsSchema = form1099RLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form1099RLineItem = z.infer<typeof form1099RLineItemWithIdsSchema>;
