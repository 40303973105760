import assertDefinedValue from "./assertDefinedValue";
import { useGetCurrentCompanyUser } from "../api/currentCompanyUserApi";

/**
 * This hook should only be used within authenticated views (i.e. descendants of AuthedRoute.tsx),
 * as we will not load any of them until we have pulled in the current company user.
 */
const useDefinedCurrentCompanyUser = () => {
  const { data } = useGetCurrentCompanyUser();
  return assertDefinedValue(
    "Only descendants of AuthedRoute.tsx may use the useDefinedCurrentCompanyUser hook",
    data
  );
};

export default useDefinedCurrentCompanyUser;
