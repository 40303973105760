import { LineItem, Transaction } from "@taxbit-dashboard/rest";
import { Big } from "big.js";
/**
 * Checks if two LineItem objects represent the same asset by comparing their asset id.
 * @param {LineItem} assetA - The first LineItem object to compare.
 * @param {LineItem} assetB - The second LineItem object to compare.
 * @returns {boolean} - True if the two LineItem objects represent the same asset, false otherwise.
 */
const isSameAsset = (assetA: LineItem, assetB: LineItem) => {
  return assetA.assetAmount.asset.id === assetB.assetAmount.asset.id;
};

/**
 * Calculates the amount of acquired assets in a transaction, taking into account any associated fees.
 * @param {Transaction} transaction - The transaction object to calculate acquired assets for.
 * @returns {<LineItem>} - A LineItem object representing the acquired asset in the transaction.
 * If the received field of the transaction is not defined, returns undefined.
 * If the fees field of the transaction doesn't have a matching asset, returns the line item from the received field of the transaction.
 */
export const calculateTransactionAcquiredAsset = (
  transaction: Transaction
): LineItem | undefined => {
  const { received, fees } = transaction;
  if (!received) return undefined;

  const acquiredAsset: LineItem = received[0];

  const feeWithMatchingAsset = fees?.find((fee) =>
    isSameAsset(acquiredAsset, fee)
  );

  if (feeWithMatchingAsset) {
    const newAcquiredAmount = new Big(acquiredAsset.assetAmount.amount)
      .minus(feeWithMatchingAsset.assetAmount.amount)
      .toString();

    return {
      ...acquiredAsset,
      assetAmount: {
        asset: acquiredAsset.assetAmount.asset,
        amount: newAcquiredAmount,
      },
    };
  } else {
    return acquiredAsset;
  }
};
