import { AppOutlet } from "@taxbit-dashboard/commons";

import {
  navigationItem as accountsNavigationItem,
  route as accountsRoute,
} from "./src/pages/accounts/route";
import {
  route as irRoute,
  navigationItem as irNavigationItem,
} from "./src/pages/information-reporting/route";
import {
  route as ingestionRoute,
  navigationItem as ingestionNavigationItem,
} from "./src/pages/ingestion/route";
import {
  navigationItem as reportsNavigationItem,
  route as reportsRoute,
} from "./src/pages/reports/route";
import {
  route as transferRoute,
  navigationItem as transferActivityNavigationItem,
} from "./src/pages/transfer-activity/route";

// react-smart-masonry uses the global object, even though it doesn't exist in the browser.
// https://stackoverflow.com/questions/72114775/vite-global-is-not-defined/73208485#73208485
window.global = window;

export const route = {
  element: (
    <AppOutlet
      sections={[
        {
          items: [
            accountsNavigationItem,
            ingestionNavigationItem,
            transferActivityNavigationItem,
            irNavigationItem,
            reportsNavigationItem,
          ],
        },
      ]}
    />
  ),
  children: [
    accountsRoute,
    reportsRoute,
    irRoute,
    transferRoute,
    ingestionRoute,
  ],
};

export { DeleteFileUploaderProvider } from "./src/pages/ingestion/context/delete/DeleteFileUploaderContext";
export { IngestFileUploaderProvider } from "./src/pages/ingestion/context/ingest/IngestFileUploaderContext";
