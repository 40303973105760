import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form5498LineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form5498),
  iraContributions: z.string().optional(),
  rolloverContributions: z.string().optional(),
  rothIraConversionAmount: z.string().optional(),
  recharacterizedContributions: z.string().optional(),
  lifeInsuranceCost: z.string().optional(),
  sepContributions: z.string().optional(),
  simpleContributions: z.string().optional(),
  rothIraContributions: z.string().optional(),
  requiredMinimumDistribution: z.boolean().default(false),
  rmdDate: z.string().optional(),
  rmdAmount: z.string().optional(),
  postponedLateContribution: z.string().optional(),
  year13B: z.number().optional(),
  code13C: z.string().optional(),
  repayments: z.string().optional(),
  code14B: z.string().optional(),
  fmvOfCertainSpecifiedAssets: z.string().optional(),
  code15B: z.string().optional(),
});

export const form5498LineItemWithIdsSchema = form5498LineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form5498LineItem = z.infer<typeof form5498LineItemWithIdsSchema>;
