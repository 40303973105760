import { Form1099KLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItem1099KColumns = (): TableColumns<Form1099KLineItem> => {
  const { formatQuantity } = useCosmicLocalizationContext();
  return useMemo(
    (): TableColumns<Form1099KLineItem> => [
      {
        key: "grossAmountOfPaymentsTransactions",
        label: "Gross Amount Of Payments Transactions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (grossAmountOfPaymentsTransactions) =>
          formatQuantity(grossAmountOfPaymentsTransactions),
      },
      {
        key: "cardNotPresentTransactions",
        label: "Card Not Present Transactions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (cardNotPresentTransactions) =>
          formatQuantity(cardNotPresentTransactions),
      },
      {
        key: "merchantCategoryCode",
        label: "Merchant Category Code",
        isContentFullWidth: true,
      },
      {
        key: "numberOfPaymentTransactions",
        label: "Number Of Payment Transactions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (numberOfPaymentTransactions) =>
          formatQuantity(numberOfPaymentTransactions),
      },
      {
        key: "federalTaxWithheld",
        label: "Federal Tax Withheld",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "january5A",
        label: "January 5a",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (january5a) => formatQuantity(january5a),
      },
      {
        key: "february5B",
        label: "February 5b",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (february5b) => formatQuantity(february5b),
      },
      {
        key: "march5C",
        label: "March 5c",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (march5c) => formatQuantity(march5c),
      },
      {
        key: "april5D",
        label: "April 5d",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (april5d) => formatQuantity(april5d),
      },
      {
        key: "may5E",
        label: "May 5e",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (may5e) => formatQuantity(may5e),
      },
      {
        key: "june5F",
        label: "June 5f",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (june5f) => formatQuantity(june5f),
      },
      {
        key: "july5G",
        label: "July 5g",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (july5g) => formatQuantity(july5g),
      },
      {
        key: "august5H",
        label: "August 5h",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (august5h) => formatQuantity(august5h),
      },
      {
        key: "september5I",
        label: "September 5i",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (september5i) => formatQuantity(september5i),
      },
      {
        key: "october5J",
        label: "October 5j",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (october5j) => formatQuantity(october5j),
      },
      {
        key: "november5K",
        label: "November 5k",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (november5k) => formatQuantity(november5k),
      },
      {
        key: "december5L",
        label: "December 5l",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (december5l) => formatQuantity(december5l),
      },
      {
        key: "state",
        label: "State",
        isContentFullWidth: true,
      },
      {
        key: "payersStateNumber",
        label: "Payers State Number",
        isContentFullWidth: true,
      },
      {
        key: "stateTaxWithheld",
        label: "State Tax Withheld",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (stateTaxWithheld) => formatQuantity(stateTaxWithheld),
      },
    ],
    [formatQuantity]
  );
};

export default useFormItem1099KColumns;
