import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import useAccountIdsChip from "./useAccountIdsChip";
import useDateRangeFilterChip from "./useFormDateRangeChip";
import useFormsStatusChips from "./useFormStatusChips";
import useFormsTypeChips from "./useFormTypeChips";
import useSearchFilterChips from "./useSearchFilterChips";
import { useIrFormsContext } from "../../context/useIrForms";

const useFormsFilterChips = () => {
  const { shouldDisableControls } = useIrFormsContext();
  const searchFilterChips = useSearchFilterChips();
  const statusChips = useFormsStatusChips();
  const typeChips = useFormsTypeChips();
  const dateRangeChip = useDateRangeFilterChip();
  const accountIdsChip = useAccountIdsChip();

  return useMemo(
    () =>
      filterToDefinedValues([
        accountIdsChip,
        ...searchFilterChips,
        dateRangeChip,
        ...typeChips,
        ...statusChips,
      ]).map((chip) => ({
        ...chip,
        isDisabled: shouldDisableControls,
        removeButtonProps: {
          ...chip.removeButtonProps,
          label: "Remove filter",
        },
      })),
    [
      accountIdsChip,
      searchFilterChips,
      dateRangeChip,
      typeChips,
      statusChips,
      shouldDisableControls,
    ]
  );
};

export default useFormsFilterChips;
