import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  RowGroup,
} from "@taxbit-private/cosmic";
import { useMemo } from "react";

import DownloadTaxDocumentationFormButton from "./DownloadTaxDocumentationFormButton";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";

export enum DownloadTaxDocumentationTrackingId {
  DownloadTaxDocumentationSpinner = "download-tax-documentation-loading-spinner",
}

const DownloadTaxDocumentation: React.FC = () => {
  const { isLoading, taxDocumentationStatus } =
    useAccountOwnerTaxDocumentContext();

  const kycTaxFormRows = useMemo(() => {
    const wFormStatus = taxDocumentationStatus?.wFormQuestionnaire;
    if (!wFormStatus) {
      return undefined;
    }

    return [
      {
        key: `${wFormStatus.type}-form`,
        avatarProps: { iconName: "file-text" },
        title: `${wFormStatus.type} Form`,
        content: (
          <DownloadTaxDocumentationFormButton
            documentType={wFormStatus.type as KycTaxDocumentType}
          />
        ),
      },
    ];
  }, [taxDocumentationStatus]);

  return (
    <Card title="Download Tax Documentation">
      {isLoading ? (
        <ContentSpinner
          trackingId={
            DownloadTaxDocumentationTrackingId.DownloadTaxDocumentationSpinner
          }
        />
      ) : kycTaxFormRows ? (
        <RowGroup rows={kycTaxFormRows} />
      ) : (
        <ContentErrorEmptyState entity="tax documentation" />
      )}
    </Card>
  );
};

export default DownloadTaxDocumentation;
