import {
  KycTaxDocumentType,
  KycTaxDocumentation,
} from "@taxbit-dashboard/rest";
import {
  W8BenETaxDocumentation,
  W8BenTaxDocumentation,
  W9TaxDocumentation,
} from "@taxbit-dashboard/rest/src/types/api/kycSharedTypes";

export const isWForm = (
  doc?: KycTaxDocumentation
): doc is W9TaxDocumentation | W8BenTaxDocumentation | W8BenETaxDocumentation =>
  doc?.documentType === KycTaxDocumentType.W9 ||
  doc?.documentType === KycTaxDocumentType.W8Ben ||
  doc?.documentType === KycTaxDocumentType.W8BenE;
