import { z } from "zod";

import { assetAmountSchema } from "./assetApiTypes";
import { accountIdSchema } from "./sharedApiTypes";
import {
  dateTimeStringSchema,
  externalIdSchema,
  transactionIdSchema,
} from "./transactionApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum TransferType {
  TransferIn = "TRANSFER_IN",
  TransferOut = "TRANSFER_OUT",
}

export enum TransferStatus {
  Matched = "MATCHED",
  NotMatched = "NOT_MATCHED",
  MissingData = "MISSING_DATA",
}
export const transferStatusSchema = z.nativeEnum(TransferStatus);

export enum TransferIntention {
  Personal = "PERSONAL",
  Other = "OTHER",
}

export const transferSchema = z.object({
  type: z.nativeEnum(TransferType),
  transactionId: transactionIdSchema,
  datetime: z.string(),
  assetAmount: assetAmountSchema,
  status: transferStatusSchema,
  intention: z.nativeEnum(TransferIntention).optional(),
  accountId: accountIdSchema,
  transactionHash: z.string().optional(),
  fromAddress: z.string().optional(),
  toAddress: z.string().optional(),
});
export type Transfer = z.infer<typeof transferSchema>;

export const getTransfersSchema = getPublicApiSuccessSchema(
  transferSchema.array()
);

export const getTransferSchema = getPublicApiSuccessSchema(transferSchema);

export const transferMetadataSchema = z.object({
  totalCount: z.number(),
});
export type TransferMetadata = z.infer<typeof transferMetadataSchema>;

export const getTransfersMetadataSchema = getPublicApiSuccessSchema(
  transferMetadataSchema
);

export type TransfersApiFiltersUrlParams = {
  "filters[datetime][$gte]"?: string;
  "filters[datetime][$lte]"?: string;
  "filters[status]"?: TransferStatus[];
};

export type TransfersApiPaginationUrlParams = {
  "page[limit]": string;
  "page[offset]": string;
};

export type TransfersApiUrlParams = TransfersApiFiltersUrlParams &
  TransfersApiPaginationUrlParams;

export const transferOutLotItemSchema = z.object({
  userId: accountIdSchema,
  index: z.string().optional(),
  acquisitionTransactionId: transactionIdSchema.optional(),
  acquisitionTimestamp: dateTimeStringSchema.optional(),
  dispositionTransactionId: transactionIdSchema,
  dispositionTimestamp: dateTimeStringSchema.optional(),
  cost: assetAmountSchema.optional(),
  rate: assetAmountSchema.optional(),
  amountTransferred: assetAmountSchema,
});

export type TransferOutLotItem = z.infer<typeof transferOutLotItemSchema>;

export const getTransferOutLotsSchema = getPublicApiSuccessSchema(
  transferOutLotItemSchema.array()
);

export type GetTransferOutLots = z.infer<typeof getTransferOutLotsSchema>;

export const transferInLotItemSchema = z.object({
  costBasis: z.string(),
  transactionId: externalIdSchema,
  createdDateTime: dateTimeStringSchema.optional(),
  acquisitionTransactionDatetime: dateTimeStringSchema.optional(),
  modifiedDateTime: dateTimeStringSchema.optional(),
  quantity: z.string().optional(),
});

export type TransferInLotItem = z.infer<typeof transferInLotItemSchema>;

export const getTransferInLotsSchema = getPublicApiSuccessSchema(
  transferInLotItemSchema.array()
);

export type GetTransferInLots = z.infer<typeof getTransferInLotsSchema>;
