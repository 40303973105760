import { StringType } from "@taxbit-private/type-wrappers";
import { z } from "zod";

import { accountIdSchema } from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum TinReportStatus {
  Unprocessed = "UNPROCESSED",
  Pending = "PENDING",
  InvalidData = "INVALID_DATA",
  TinMismatch = "TIN_MISMATCH",
  TinTypeMismatch = "TIN_TYPE_MISMATCH",
  CountryCodeMismatch = "COUNTRY_CODE_MISMATCH",
  TinNotIssued = "TIN_NOT_ISSUED",
  TinMismatchForeignIndicia = "TIN_MISMATCH_FOREIGN_INDICIA",
  ForeignIndicia = "FOREIGN_INDICIA",
  Valid = "VALID",
  Unknown = "UNKNOWN",
}

export enum TaxIdType {
  UsSsn = "US_SSN",
  UsItin = "US_ITIN",
  UsEin = "US_EIN",
  UsAtin = "US_ATIN",
  NonUsTin = "NON_US_TIN",
  Unknown = "UNKNOWN",
}

export enum AccountType {
  Individual = "INDIVIDUAL",
  Business = "BUSINESS",
  Unknown = "UNKNOWN",
}

const addressSchema = z.object({
  firstLine: z.string().optional(),
  secondLine: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
  // This field is normally a string. However, historical bad data exists where it
  // can be either a string or number. We will be able to remove the coercion to string
  // when we have swapped to the new accounts service.
  postalCode: z.coerce.string().optional(),
});

export type AccountAddress = z.infer<typeof addressSchema>;

export type UserId = StringType<"UserId">;

const userIdSchema = z.string().transform((userId) => userId as UserId);

export const accountSchema = z.object({
  accountId: accountIdSchema,
  accountType: z.nativeEnum(AccountType).optional(),
  address: addressSchema.optional(),
  // This field is normally a string. However, historical bad data exists where it
  // can be either a string or number. We will be able to remove the coercion to string
  // when we have swapped to the new accounts service.
  countryCode: z.coerce.string().optional(),
  email: z.string().optional(),
  externalId: z.string().optional(),
  name: z.string().optional(),
  taxId: z.string().optional(),
  taxIdType: z.nativeEnum(TaxIdType).optional(),
  tinReportStatus: z.nativeEnum(TinReportStatus).optional(),
  tinValidationDate: z.string().optional(),
  userId: userIdSchema.optional(),
});

export type Account = z.infer<typeof accountSchema>;

export const getAccountResponseSchema =
  getPublicApiSuccessSchema(accountSchema);
export type GetAccountResponse = z.infer<typeof getAccountResponseSchema>;
