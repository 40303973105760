import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  createQueryMetaObject,
} from "@taxbit-dashboard/commons";

export const useGetRoles = () => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.Roles],
    () => restSdk.roles.get(),
    createQueryMetaObject(restSdk.roles.buildPath())
  );
  return unwrapPublicApiWrappedQuery(query);
};
