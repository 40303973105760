import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

/**
 Formats a gain type string by replacing hyphens with spaces, converting all instances of "uk" to "UK",
 and capitalizing the first letter of each word.
 @param {string} [gainType] - The gain type to format.
 @returns {string} The formatted gain type string.
 */
export const formatGainType = (gainType?: string) => {
  if (!gainType) {
    return COSMIC_VALUE_PLACEHOLDER;
  }

  return gainType
    .replaceAll("-", " ")
    .replaceAll(/uk/gi, "UK")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
