import { useGetDateRangeChipLabel } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import { useIrFormsContext } from "../../context/useIrForms";

export enum IrFormDateRangeChipTrackingId {
  Chip = "ir-forms-date-range-chip",
}

const useDateRangeFilterChip = () => {
  const {
    urlParams: { startDate, endDate },
    setFilterParams,
  } = useIrFormsContext();

  const getChipLabel = useGetDateRangeChipLabel();

  return useMemo(() => {
    if (!startDate) {
      return undefined;
    }

    return {
      label: `Date Generated: ${getChipLabel({ startDate, endDate })}`,
      removeButtonProps: {
        "onClick": () =>
          setFilterParams((draft) => {
            draft.startDate = undefined;
            draft.endDate = undefined;
          }),
        "label": "Remove filter",
        "aria-label": "Remove filter for date generated",
      },
      trackingId: IrFormDateRangeChipTrackingId.Chip,
    };
  }, [endDate, getChipLabel, setFilterParams, startDate]);
};

export default useDateRangeFilterChip;
