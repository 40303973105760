import {
  TableColumns,
  optionalAlphabeticalSort,
  Badge,
  optionalDateSort,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import InvitationStatus from "../utils/InvitationStatus";
import { InvitationRowData } from "../utils/mapInvitationToInvitationRowData";

const useInvitationManagementTableColumns = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  return useMemo(
    (): TableColumns<InvitationRowData> => [
      {
        key: "inviteeEmail",
        label: "Invitee",
        sortMethod: optionalAlphabeticalSort,
        shouldTruncate: true,
      },
      {
        key: "status",
        label: "Status",
        sortMethod: optionalAlphabeticalSort,
        renderCell: (status) => {
          return status === InvitationStatus.Pending ? (
            <Badge label="Pending" variant="success" />
          ) : (
            <Badge label="Expired" variant="danger" />
          );
        },
      },
      {
        key: "createdAt",
        label: "Created",
        sortMethod: optionalDateSort,
        renderCell: (createdAt) => {
          return formatDateTime({ date: createdAt });
        },
      },
      {
        key: "expiresAt",
        label: "Expires",
        sortMethod: optionalDateSort,
        renderCell: (expiresAt) => {
          return formatDateTime({ date: expiresAt });
        },
      },
      {
        key: "inviterName",
        label: "Inviter",
        sortMethod: optionalAlphabeticalSort,
      },
      {
        key: "roles",
        label: "Roles",
        // Per design, there isn't a super logical way to sort the joined list of roles
        // so we will just disable sorting on this column.
        shouldDisableSorting: true,
        renderCell: (roles) => roles.map(({ name }) => name).join(", "),
      },
    ],
    [formatDateTime]
  );
};

export default useInvitationManagementTableColumns;
