import { FileAction } from "@taxbit-dashboard/rest";
import {
  Flex,
  Icon,
  getSharedBorderStyle,
  StyledIconBase,
  withTracking,
  Body,
  invisibleClickableStyles,
  getSharedAtomFocusCss,
  getRgbaForHex,
} from "@taxbit-private/cosmic";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import {
  fileTypeIconMap,
  fileTypeLabelMap,
} from "../../../../api/files/filesApiTypes";
import { FileUploaderFormFields } from "../../context/fileUploaderFormTypes";
import useFileUploaderContext from "../../context/useFileUploaderContext";

type Props = {
  action: FileAction;
};

const RhfFileTypeSelector: React.FC<Props> = ({ action }) => {
  const { formMethods, filteredFileTypes } = useFileUploaderContext(action);

  return (
    <Controller<FileUploaderFormFields>
      name="fileType"
      render={({ field: { value, onChange } }) => (
        <Flex gap="l" growItems={true}>
          {filteredFileTypes.map((type) => {
            const isSelected = value === type;

            return (
              <FileTypeButton
                isSelected={isSelected}
                aria-current={isSelected}
                onClick={() => {
                  onChange(type);

                  /**
                   * This is a workaround to trigger error UI on the uploader when the file type
                   * is changed after the form is initially submitted and validated.
                   * https://github.com/react-hook-form/resolvers/issues/661
                   * https://github.com/react-hook-form/resolvers/issues/671
                   */
                  if (formMethods.formState.isSubmitted) {
                    void formMethods.trigger();
                  }
                }}
                trackingId={`select-file-type-${type}`}
                key={type}
                type="button"
              >
                <Icon name={fileTypeIconMap[type]} />
                <Body>{fileTypeLabelMap[type]}</Body>
              </FileTypeButton>
            );
          })}
        </Flex>
      )}
    />
  );
};

export default RhfFileTypeSelector;

const FileTypeButton = withTracking(
  styled.button.withConfig<{ isSelected: boolean }>({
    shouldForwardProp: (propName) => propName !== "isSelected",
  })(
    ({ theme, isSelected }) => `
    ${invisibleClickableStyles}
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
  align-items: center;
  justify-content: center;

  padding: ${theme.spacing.m};
  border-radius: ${theme.borderRadius.s};
  border: ${getSharedBorderStyle(theme)};
  
  ${StyledIconBase} {
    height: ${theme.sizing.l};
    width: ${theme.sizing.l};
    color: ${theme.color.gray4};
  }

  &:hover {
    border-color: ${theme.color.borderHover};
    background-color: ${theme.color.gray0};
  }

  &:active {
    border-color: ${theme.color.borderHover};
    background-color: ${theme.color.gray1};
  }

  &:focus-visible {
    ${getSharedAtomFocusCss(theme)}
  }

  ${
    isSelected
      ? `
        ${StyledIconBase} {
          color: ${theme.color.primary};
        }

        
        background-color: ${getRgbaForHex(theme.color.primary, 0.05)};

        &, &:hover, &:active {
          border: ${theme.borderWidth.m} ${theme.color.primary} solid;
        }

        &:hover {
          background-color: ${getRgbaForHex(theme.color.primary, 0.1)};
        }

        &:active {
          background-color: ${getRgbaForHex(theme.color.primary, 0.15)};
        }
  
  `
      : ""
  }
`
  )
);
