import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { AccountTaxDocumentationType } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo } from "react";

import { AccountsFilterDrawerFormFields } from "./accountsFilterDrawerFormFieldSchema";
import { taxDocumentationTypeLabelMap } from "../../../../../api/accounts/accountsApiTypes";

const DOCUMENTATION_TYPE_OPTIONS = Object.values(
  AccountTaxDocumentationType
).map((type) => ({
  key: type,
  label: taxDocumentationTypeLabelMap[type],
}));

const AccountsTaxDocumentationTypeFilter: React.FC = () => {
  const { hasW8TaxDocumentationAccess } = useDashboardFeatureFlags();

  const options = useMemo(() => {
    if (hasW8TaxDocumentationAccess) {
      return DOCUMENTATION_TYPE_OPTIONS;
    } else {
      return DOCUMENTATION_TYPE_OPTIONS.filter(
        ({ key }) =>
          key !== AccountTaxDocumentationType.W8Ben &&
          key !== AccountTaxDocumentationType.W8BenE
      );
    }
  }, [hasW8TaxDocumentationAccess]);

  return (
    <RhfMultiselect<
      AccountTaxDocumentationType,
      Pick<AccountsFilterDrawerFormFields, "taxDocumentationTypes">,
      "taxDocumentationTypes",
      (typeof DOCUMENTATION_TYPE_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="taxDocumentationTypes"
      options={options}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Documentation Type"
      placeholder="All"
      trackingId="accounts-filters-drawer-tax-documentation-type-filter"
    />
  );
};

export default AccountsTaxDocumentationTypeFilter;
