import {
  useDashboardFeatureFlags,
  useDashboardStore,
  useGetCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { updateDatadogUser } from "../components/app/vendors/datadog";
import { updateFullStoryUser } from "../components/app/vendors/fullStory";
import { updateLaunchDarklyUser } from "../components/app/vendors/launchDarkly";
import { updatePendoUser } from "../components/app/vendors/pendo/pendo";
import { updateZendeskUser } from "../components/app/vendors/zendesk";

const useInitializeVendorUsers = () => {
  const ldClient = useLDClient();
  const { data: currentCompanyUser } = useGetCurrentCompanyUser({
    useErrorBoundary: true,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const { hasZendeskWidgetAccess } = useDashboardFeatureFlags();

  // using dashboard store directly instead of useOrganizationId hook
  // because organization id might be undefined when we initialize LD
  const organizationId = useDashboardStore((store) => store.organizationId);
  const organizationName = useDashboardStore((store) => store.organizationName);

  useEffect(() => {
    void (async () => {
      if (
        currentCompanyUser &&
        ldClient &&
        organizationId &&
        organizationName
      ) {
        updateFullStoryUser(
          currentCompanyUser,
          organizationId,
          organizationName
        );
        updatePendoUser(currentCompanyUser, organizationId, organizationName);
        updateDatadogUser(currentCompanyUser, organizationId, organizationName);
        await updateLaunchDarklyUser(
          currentCompanyUser,
          organizationId,
          organizationName,
          ldClient
        );
        updateZendeskUser(currentCompanyUser, hasZendeskWidgetAccess);
        setIsInitialized(true);
      }
    })();
  }, [
    currentCompanyUser,
    hasZendeskWidgetAccess,
    ldClient,
    organizationId,
    organizationName,
  ]);

  return isInitialized;
};

export default useInitializeVendorUsers;
