import { Route } from "@tanstack/react-location";
import { FallbackView, logError } from "@taxbit-dashboard/commons";
import { ErrorBoundary } from "@taxbit-private/cosmic";

export const FALLBACK_VIEW_SUBTITLE =
  "The page you are looking for doesn't exist. Check your URL or hit the back button in your browser to try again.";

export const FALLBACK_VIEW_TRACKING_ID = "not-found-fallback-view";

const getRoutesWithErrorAndRoutingFallbacks = (appRoutes: Route[]): Route[] => {
  return appRoutes.map((route) => {
    const fallbackView = route.meta?.shouldIgnoreFallback
      ? []
      : [
          {
            element: (
              <FallbackView
                trackingId={FALLBACK_VIEW_TRACKING_ID}
                subtitle={FALLBACK_VIEW_SUBTITLE}
              />
            ),
          },
        ];

    return {
      ...route,
      element: route.element ? (
        // This `as` is due to a mismatch between React-Location's types that expect
        // React 17 children typing, and our React 18 types. Can likely be removed when
        // we move to the new TanStack router.
        <ErrorBoundary
          logError={logError}
          trackingId={`${route.path}-error-boundary`}
        >
          {route.element as React.ReactElement}
        </ErrorBoundary>
      ) : undefined,
      children: route.children
        ? [
            ...getRoutesWithErrorAndRoutingFallbacks(route.children),
            // Appends a fallback view to the end of every children array. This ensures that
            // we will display a "not found" view in the current Outlet no matter how deeply an
            // error is made in the url. For example, hitting /not-real and /accounts/overview/not-real
            // will both display fallback views in the current content slot.
            ...fallbackView,
          ]
        : undefined,
    };
  });
};

export default getRoutesWithErrorAndRoutingFallbacks;
