import {
  useCopyToClipboard,
  useOrganizationId,
} from "@taxbit-dashboard/commons";
import {
  ActionButton,
  ContentSpinner,
  EmptyState,
  ErrorEmptyState,
  Table,
} from "@taxbit-private/cosmic";
import { useCallback, useMemo } from "react";

import useInvitationManagementTableColumns from "./useInvitationManagementTableColumns";
import { useGetInvitationsWithRoles } from "../../../../api/invitationsApi";
import mapInvitationToInvitationRowData, {
  InvitationRowData,
} from "../utils/mapInvitationToInvitationRowData";

export enum InvitationManagementTableTrackingId {
  CopyInvitationUrlButton = "invitation-management-table-copy-invitation-url-button",
  LoadingSpinner = "invitation-management-table-loading-spinner",
  CosmicTable = "invitation-management-table",
}

const InvitationManagementTable: React.FC = () => {
  const organizationId = useOrganizationId();
  const {
    data: invitations,
    isLoading: isLoadingInvitations,
    isError: isErrorInvitations,
  } = useGetInvitationsWithRoles(organizationId);

  const copyToClipboard = useCopyToClipboard();

  const memoizedRows = useMemo(() => {
    return (invitations ?? []).map((invitation) =>
      mapInvitationToInvitationRowData(invitation)
    );
  }, [invitations]);

  const columns = useInvitationManagementTableColumns();

  const getInvitationActionButtons = useCallback(
    (invitationRowData: InvitationRowData) => {
      return (
        <ActionButton
          iconName="copy"
          trackingId={`${InvitationManagementTableTrackingId.CopyInvitationUrlButton}-${invitationRowData.id}`}
          onClick={() => {
            void copyToClipboard(
              "invitation URL",
              invitationRowData.invitationUrl
            );
          }}
          label="Copy Invitation URL"
        />
      );
    },
    [copyToClipboard]
  );

  if (isLoadingInvitations) {
    return (
      <ContentSpinner
        trackingId={InvitationManagementTableTrackingId.LoadingSpinner}
      />
    );
  } else if (isErrorInvitations) {
    return <ErrorEmptyState entity="invitations" />;
  } else if (invitations?.length) {
    return (
      <Table
        trackingId={InvitationManagementTableTrackingId.CosmicTable}
        rows={memoizedRows}
        columns={columns}
        initialSortColumnKey="expiresAt"
        isInitialSortDescending={true}
        getRowKey={(row) => row.id}
        getRowUtilityElement={getInvitationActionButtons}
        isSortable={true}
      />
    );
  } else {
    return (
      <EmptyState
        avatarProps={{ iconName: "user", variant: "secondary" }}
        title="No invitations found"
      />
    );
  }
};

export default InvitationManagementTable;
