import {
  AuthorizedView,
  DashboardAppRoutePath,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  UserPermission,
} from "@taxbit-dashboard/commons";

import CompanyUserManagement from "./company-user-management/company-user-table/CompanyUserManagement";
import { CompanyUserManagementDataProvider } from "./company-user-management/context/useCompanyUserManagementData";
import DeveloperSettings from "./developer-settings/DeveloperSettings";
import InvitationManagement from "./invitation-management/user-invitation-table/InvitationManagement";

export const userManagementNavigationItem: DashboardNavigationItemChild = {
  href: DashboardAppRoutePath.CompanyUserManagement,
  label: "User Management",
  trackingId: "sidebar-navigation-user-management",
  key: "user-manangement",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadOrganizationMembers],
} as const;

export const invitationsNavigationItem: DashboardNavigationItemChild = {
  href: DashboardAppRoutePath.InvitationManagement,
  label: "Invitation Management",
  trackingId: "sidebar-navigation-invitation-management",
  key: "invitations",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadOrganizationInvitations],
} as const;

export const developerSettingsNavigationItem: DashboardNavigationItemChild = {
  href: DashboardAppRoutePath.DeveloperSettings,
  label: "Developer Settings",
  trackingId: "sidebar-navigation-developer-settings",
  key: "developer-settings",
  shouldShowRoute: ({ permissions, flags }) =>
    flags.shouldShowDeveloperSettings && permissions[UserPermission.ReadTins],
} as const;

export const navigationItem: DashboardNavigationItemWithChildren = {
  iconName: "users",
  label: "My Organization",
  trackingId: "sidebar-navigation-my-organization-anchor",
  children: [
    userManagementNavigationItem,
    invitationsNavigationItem,
    developerSettingsNavigationItem,
  ],
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadOrganizationMembers] ||
    permissions[UserPermission.ReadOrganizationInvitations] ||
    (flags.shouldShowDeveloperSettings && permissions[UserPermission.ReadTins]),
} as const;

export const routes = [
  {
    path: "/user-management",
    element: (
      <AuthorizedView permissions={[UserPermission.ReadOrganizationMembers]}>
        <CompanyUserManagementDataProvider>
          <CompanyUserManagement />
        </CompanyUserManagementDataProvider>
      </AuthorizedView>
    ),
  },
  {
    path: "/invitation-management",
    element: (
      <AuthorizedView
        permissions={[UserPermission.ReadOrganizationInvitations]}
      >
        <InvitationManagement />
      </AuthorizedView>
    ),
  },
  {
    path: "/developer-settings",
    element: (
      <AuthorizedView
        permissions={[UserPermission.ReadTins]}
        flags={["shouldShowDeveloperSettings"]}
      >
        <DeveloperSettings />
      </AuthorizedView>
    ),
  },
];
