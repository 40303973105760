import { zodResolver } from "@hookform/resolvers/zod";
import {
  ToastConfig,
  hasDefinedValues,
  useDashboardFeatureFlags,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { EligibilityStatus } from "@taxbit-dashboard/rest";
import pluralize from "pluralize";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import {
  formGenerationModalFormSchema,
  formGenerationModalFormDefaultValues,
  FormGenerationModalForm,
} from "../../context/formGenerationModalFormTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum GenerateTaxFormsConfirmationModalTrackingId {
  FilteredPopulationCheckbox = "ir-eligibility-generate-tax-forms-confirmation-modal-filtered-population-checkbox",
  DataComparisonCheckbox = "ir-eligibility-generate-tax-forms-confirmation-modal-data-comparison-checkbox",
  CloseButton = "ir-eligibility-generate-tax-forms-confirmation-modal-close-btn",
  CancelButton = "ir-eligibility-generate-tax-forms-confirmation-modal-cancel-btn",
  SubmitButton = "ir-eligibility-generate-tax-forms-confirmation-modal-submit-btn",
  SuccessToast = "ir-eligibility-generate-tax-forms-confirmation-modal-success-toast",
  ErrorToast = "ir-eligibility-generate-tax-forms-confirmation-modal-error-toast",
}

export const makeGenerateTaxFormsSuccessNotifcationsToastContent = (
  formsCount: number
): ToastConfig => ({
  message: `${pluralize("Form", formsCount)} ${pluralize("is", formsCount)} generating. You will receive a notification when the ${pluralize("form", formsCount)} ${pluralize("is", formsCount)} available on the Forms page.`,
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
});

export const makeGenerateTaxFormsSuccessToastContent = (
  formsCount: number
): ToastConfig => ({
  message: `${pluralize("Form", formsCount)} ${pluralize("is", formsCount)} generating.`,
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
});

export const makeGenerateTaxFormsErrorToastContent = (
  formsCount: number
): ToastConfig => ({
  message: `${pluralize("Form", formsCount)} failed to generate. Please try again.`,
  variant: "danger",
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
});

const useGenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  onClose,
}: {
  shouldIgnoreSelectedItems?: boolean;
  onClose: () => void;
}) => {
  const {
    accountExternalIdsFilter,
    urlParams: { formDate, formType, page, limit, ...filterParams },
    selectedItems,
    eligibilityCounts,
    totalCount,
    shouldDisableControls,
    generateTaxForms,
    toggleAllSelectedItems,
  } = useEligibilityDataContext();
  const addToast = useDashboardStore((state) => state.addToast);

  const { completeAccountData = 0, incompleteAccountData = 0 } =
    eligibilityCounts?.eligible || {};
  const totalEligibleAccounts = shouldIgnoreSelectedItems
    ? completeAccountData + incompleteAccountData
    : selectedItems.filter(
        (account) =>
          account.status === EligibilityStatus.Eligible ||
          account.status === EligibilityStatus.Added
      ).length;

  const {
    exempt = 0,
    nonValidTaxCountryCode = 0,
    noReportableTransactions = 0,
    removed = 0,
  } = eligibilityCounts?.ineligible || {};

  const totalIneligibleAccounts = shouldIgnoreSelectedItems
    ? exempt + nonValidTaxCountryCode + noReportableTransactions + removed
    : selectedItems.filter(
        (account) =>
          account.status !== EligibilityStatus.Eligible &&
          account.status !== EligibilityStatus.Added
      ).length;

  const formGenerationFormMethods = useForm<FormGenerationModalForm>({
    resolver: zodResolver(formGenerationModalFormSchema),
    defaultValues: formGenerationModalFormDefaultValues,
  });

  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      void formGenerationFormMethods.handleSubmit(
        ({ shouldIncludeFilters, shouldSkipCompareData }) => {
          const totalForms = (() => {
            if (!shouldIgnoreSelectedItems) {
              return selectedItems.length;
            }

            return totalCount;
          })();

          generateTaxForms(
            {
              shouldIncludeFilters: !!shouldIncludeFilters,
              shouldCompareData: !shouldSkipCompareData,
              eligibilityItems: shouldIgnoreSelectedItems
                ? undefined
                : selectedItems,
              accountExternalIdsFilter,
            },
            {
              onSuccess: () => {
                addToast(
                  hasNotificationCenterAccess
                    ? makeGenerateTaxFormsSuccessNotifcationsToastContent(
                        totalForms
                      )
                    : makeGenerateTaxFormsSuccessToastContent(totalForms)
                );
              },
              onError: () => {
                addToast(makeGenerateTaxFormsErrorToastContent(totalForms));
              },
            }
          );

          formGenerationFormMethods.reset();
          onClose();
          toggleAllSelectedItems(false);
        }
      )(e);
    },
    [
      accountExternalIdsFilter,
      addToast,
      formGenerationFormMethods,
      generateTaxForms,
      onClose,
      selectedItems,
      shouldIgnoreSelectedItems,
      toggleAllSelectedItems,
      hasNotificationCenterAccess,
      totalCount,
    ]
  );

  return {
    handleSubmit,
    totalEligibleAccounts,
    totalIneligibleAccounts,
    totalFilteredAccounts: totalCount,
    isLoading: shouldDisableControls,
    hasFiltersApplied:
      hasDefinedValues(filterParams) || accountExternalIdsFilter.length > 0,
    formGenerationFormMethods,
  };
};

export default useGenerateTaxFormsConfirmationModal;
