import { AccountId, TransactionId, TransferType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import calculateTotalCostBasis from "./calculateTotalCostBasis";
import { useGetTransferOutLots } from "../../../../api/transferOutLotsApi";
import useFormatAsset from "../../../../utils/useFormatAsset";

const LOTS_LIMIT = 100;

const useDispositionTableData = (
  type: TransferType,
  accountId: AccountId,
  transactionId: TransactionId
) => {
  const formatAsset = useFormatAsset();

  const {
    isFetching: isTransferOutLotsLoading,
    data: transferOutLotData = [],
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetTransferOutLots({
    type,
    accountId,
    transactionId,
    limit: LOTS_LIMIT,
    shouldFetchTransferOutLots: type === TransferType.TransferOut,
  });

  const totalCostBasisDisplayValue = useMemo(() => {
    const costBasisAmount = calculateTotalCostBasis(transferOutLotData);
    return formatAsset({
      assetAmount: costBasisAmount,
    });
  }, [formatAsset, transferOutLotData]);

  return {
    isLoading: isTransferOutLotsLoading,
    transferOutLotItems: transferOutLotData,
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    totalCostBasis: totalCostBasisDisplayValue,
  };
};

export default useDispositionTableData;
