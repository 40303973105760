import {
  useDefinedCurrentCompanyUser,
  DashboardPrimaryTemplate,
} from "@taxbit-dashboard/commons";

import IdentityProviderSettingsView from "./IdentityProviderSettingsView";
import UsernamePasswordSettingsView from "./UsernamePasswordSettingsView";
import { AUTH0_USER_PASS_AUTH } from "../../utils/usernamePasswordConnection";

const AccountSettings = () => {
  const { name, email, connectionStrategy } = useDefinedCurrentCompanyUser();

  const isUsernamePasswordAuth0ConnectionType =
    connectionStrategy === AUTH0_USER_PASS_AUTH;

  return (
    <DashboardPrimaryTemplate title="My Account">
      {isUsernamePasswordAuth0ConnectionType ? (
        <UsernamePasswordSettingsView name={name} email={email} />
      ) : (
        <IdentityProviderSettingsView name={name} email={email} />
      )}
    </DashboardPrimaryTemplate>
  );
};

export default AccountSettings;
