import {
  irFormTypeToDisplayNameMap,
  navigateToUrl,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  FileAction,
  FileType,
} from "@taxbit-dashboard/rest";
import { Button, Flex } from "@taxbit-private/cosmic";
import { RhfDropdown } from "@taxbit-private/cosmic-react-hook-form";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useWatch } from "react-hook-form";
import styled from "styled-components";

import { FileUploaderFormFields } from "../../context/fileUploaderFormTypes";
import useFileUploaderContext from "../../context/useFileUploaderContext";
import useTemplatesFeatureFlagData from "../../context/useTemplatesFeatureFlagData";
import { getCsvTemplateDownloadUrl } from "../../csvTemplateData";

export enum FormDataTemplateStepTrackingIds {
  FormType = "form-type-dropdown",
  FormYear = "form-year-dropdown",
  DownloadTemplate = "download-template-button",
}

type Props = {
  action: FileAction;
};

const FormDataTemplateStep: React.FC<Props> = ({ action }) => {
  const { formMethods } = useFileUploaderContext(action);

  const { forms, getYearsForForm, hasYearForForm } =
    useTemplatesFeatureFlagData();

  const [currentType, currentYear] = useWatch<
    FileUploaderFormFields,
    ["formDocumentType", "formTaxYear"]
  >({
    name: ["formDocumentType", "formTaxYear"],
  });

  const handleFormTypeChange = (formType: DashboardFormType) => {
    if (!currentYear || !hasYearForForm(formType, currentYear)) {
      const fallbackYear = getYearsForForm(formType)[0];
      formMethods.setValue("formTaxYear", fallbackYear, {
        shouldValidate: true,
      });
    }
  };

  const templateUrl = getCsvTemplateDownloadUrl({
    fileType:
      action === FileAction.Ingest ? FileType.Forms : FileType.DeleteForms,
    templateType: currentType,
  });

  return (
    <Flex gap="m" growItems={true}>
      <RhfDropdown<
        FileUploaderFormFields,
        "formDocumentType",
        DashboardFormType
      >
        getOptionKey={(value) => value}
        getOptionLabel={(value) => irFormTypeToDisplayNameMap[value]}
        getOptionValue={(value) => value}
        label="Form Type"
        name="formDocumentType"
        options={forms}
        placeholder="Select an option"
        trackingId={FormDataTemplateStepTrackingIds.FormType}
        onChange={handleFormTypeChange}
      />
      <RhfDropdown<FileUploaderFormFields, "formTaxYear", FourDigitYear>
        getOptionKey={(value) => value.toString()}
        getOptionLabel={(value) => value.toString()}
        getOptionValue={(value) => value}
        label="Form Year"
        name="formTaxYear"
        options={getYearsForForm(currentType)}
        isDisabled={!currentType}
        placeholder="Select an option"
        trackingId={FormDataTemplateStepTrackingIds.FormYear}
      />
      {action === FileAction.Ingest && (
        <TemplateButton
          label="Download Template"
          trackingId={FormDataTemplateStepTrackingIds.DownloadTemplate}
          isDisabled={!(currentType && currentYear) || !templateUrl}
          variant="button-secondary"
          onClick={() => {
            if (templateUrl) {
              navigateToUrl(templateUrl);
            }
          }}
        />
      )}
    </Flex>
  );
};

const TemplateButton = styled(Button)`
  // Manually offset to align with the dropdowns regardless of error text.
  margin-top: 32px;
  max-width: max-content;
`;

export default FormDataTemplateStep;
