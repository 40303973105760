import { DashboardFormType, GenericFormItem } from "@taxbit-dashboard/rest";
import {
  Table,
  TrimmedBoxContent,
  getEnUsErrorEmptyStateProps,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";

import useFormItemsColumns from "./useFormItemsColumns";

type FormItemsTableProps = {
  formItems: GenericFormItem[];
  currentForm: DashboardFormType;
  isError: boolean;
  isLoading?: boolean;
};

enum FormItemsTableTrackingId {
  CosmicTable = "form-items-table",
}

const FormItemsTable: React.FC<FormItemsTableProps> = ({
  formItems,
  currentForm,
  isError,
  isLoading,
}) => {
  const formItemsColumns = useFormItemsColumns(currentForm);
  const searchEmptyStateProps = useSearchEmptyStateProps();

  return (
    <TrimmedBoxContent trim="all">
      <Table
        trackingId={FormItemsTableTrackingId.CosmicTable}
        shouldShowAutomaticPlaceholders={true}
        isLoading={isLoading}
        rows={formItems}
        getRowKey={({ id }) => id}
        columns={formItemsColumns}
        emptyStateProps={
          isError ? getEnUsErrorEmptyStateProps() : searchEmptyStateProps
        }
      />
    </TrimmedBoxContent>
  );
};

export default FormItemsTable;
