import {
  useDashboardFeatureFlags,
  useDashboardStore,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { TaxReportStatus } from "@taxbit-dashboard/rest";
import { useCallback, useEffect, useState } from "react";

import {
  useGenerateTaxReport,
  useGetTaxReportStatuses,
} from "../../../api/taxReportsApi";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import { UmcReport } from "../types/umcReportTypes";

export const getGenerateReportErrorToastTrackingId = (key: string) =>
  `umcReportsErrorToast${key}`;

export const getGenerateReportSuccessToastTrackingId = (key: string) =>
  `umcReportsSuccessToast${key}`;

export const useGenerateReport = (report: UmcReport) => {
  const currentCompanyUser = useDefinedCurrentCompanyUser();
  const addToast = useDashboardStore((store) => store.addToast);

  const [currentStatus, setCurrentStatus] = useState<TaxReportStatus>();
  const [prevStatus, setPrevStatus] = useState<TaxReportStatus>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: taxReportStatuses, refetch } = useGetTaxReportStatuses();
  const { mutate: generateTaxReport } = useGenerateTaxReport();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();
  const generateReport = useCallback(() => {
    setCurrentStatus(TaxReportStatus.InProgress);
    generateTaxReport(
      {
        requestData: {
          reportName: report.key,
          generatedBy: currentCompanyUser.id,
        },
      },
      {
        onSuccess: () => {
          setErrorMessage(undefined);
          void refetch().then((res) => {
            const reportGenerationStatus = res?.data?.data.find(
              (reportStatus) => reportStatus.reportName === report.key
            );
            setCurrentStatus(reportGenerationStatus?.status);
          });
          addToast({
            message: `Your ${report.name} report is currently generating. Once complete, you can download it from this page${hasNotificationCenterAccess ? " or the notifications tab" : ""}.`,
            trackingId: getGenerateReportSuccessToastTrackingId(report.key),
            variant: "primary",
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onError: (error) => {
          setCurrentStatus(TaxReportStatus.Failed);
          if (error.detailedErrors) {
            setErrorMessage(error.detailedErrors[0].detail);
          }
        },
      }
    );
  }, [
    generateTaxReport,
    addToast,
    refetch,
    currentCompanyUser.id,
    report,
    hasNotificationCenterAccess,
  ]);

  useEffect(() => {
    const reportGenerationStatus = taxReportStatuses?.find(
      (reportStatus) => reportStatus.reportName === report.key
    );
    setCurrentStatus(reportGenerationStatus?.status);
  }, [taxReportStatuses, report.key]);

  useEffect(() => {
    if (
      prevStatus === TaxReportStatus.InProgress &&
      currentStatus === TaxReportStatus.Completed
    ) {
      addToast({
        message: `Your ${report.name} report has been successfully generated. You can now download it from this page.`,
        trackingId: getGenerateReportSuccessToastTrackingId(report.key),
        variant: "primary",
        timeoutMs: TOAST_TIMEOUT,
      });
    }
    if (
      prevStatus === TaxReportStatus.InProgress &&
      currentStatus === TaxReportStatus.Failed
    ) {
      addToast({
        message:
          errorMessage ??
          `Your ${report.name} report failed to generate. Please try again, or contact Taxbit support if the error persists.`,
        trackingId: getGenerateReportErrorToastTrackingId(report.key),
        variant: "danger",
        timeoutMs: TOAST_TIMEOUT,
      });
    }
    setPrevStatus(currentStatus);
  }, [
    currentStatus,
    errorMessage,
    prevStatus,
    addToast,
    report.key,
    report.name,
  ]);
  return {
    generateReport,
    isGenerating: currentStatus === TaxReportStatus.InProgress,
  };
};
