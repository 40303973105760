import { UseQueryResult } from "@tanstack/react-query";
import { JsonData, PublicApiSuccess } from "@taxbit-dashboard/rest";

/**
 * Method to unwrap the data that uses getPublicApiSuccessSchema wrapper
 * @param queryResult - result of useQuery
 */
const unwrapPublicApiWrappedQuery = <T extends JsonData>(
  queryResult: UseQueryResult<PublicApiSuccess<T> | undefined>
) => {
  return {
    ...queryResult,
    data: queryResult.data?.data,
    meta: queryResult.data?.meta,
  };
};

export default unwrapPublicApiWrappedQuery;
