import { Flex, StatCard } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { useIrFormsContext } from "../context/useIrForms";

export enum TotalsTrackingIds {
  TotalStatCard = "ir-forms-total-stat-card",
  TotalStatCardHelpIcon = "ir-forms-total-stat-card-helpicon",
  TotalStatCardErrorIcon = "ir-forms-total-stat-card-erroricon",
  TotalOriginalStatCard = "ir-forms-total-original-stat-card",
  TotalOriginalStatCardHelpIcon = "ir-forms-total-original-stat-card-helpicon",
  TotalOriginalStatCardErrorIcon = "ir-forms-total-original-stat-card-erroricon",
  TotalCorrectionStatCard = "ir-forms-total-correction-stat-card",
  TotalCorrectionStatCardHelpIcon = "ir-forms-total-correction-stat-card-helpicon",
  TotalCorrectionStatCardErrorIcon = "ir-forms-total-correction-stat-card-erroricon",
  TotalVoidStatCard = "ir-forms-total-void-stat-card",
  TotalVoidStatCardHelpIcon = "ir-forms-total-void-stat-card-helpicon",
  TotalVoidStatCardErrorIcon = "ir-forms-total-void-stat-card-erroricon",
}

export const totalsHelpIconContent = {
  totalStatCard:
    "The total number of unique forms available for download for all accounts. Please note that accounts may have more than one form generated.",
  totalOriginalStatCard:
    "Total number of Original Forms available for download. An Original Form is generated to the extent no previous forms have been filed with the relevant tax authority.",
  totalCorrectionStatCard:
    "Total number of Corrected Forms available for download. A Corrected Form is generated to the extent a previous form was filed with the relevant tax authority that contained information that must be corrected.",
  totalVoidStatCard:
    "Total number of Void Forms available for download. A Void Form is generated to the extent a previous form was incorrectly filed with the relevant tax authority when it should not have been.",
};

export const totalsErrorIconContent =
  "Stat count failed to load. Please refresh the page, or contact TaxBit support if this issue persists.";

const Totals = () => {
  const { aggregates, isErrorAggregates, isLoadingAggregates } =
    useIrFormsContext();

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const statCardProps = [
    {
      title: "Total Forms",
      trackingId: TotalsTrackingIds.TotalStatCard,
      value: formatWholeQuantity(aggregates?.total),
      helpIconProps: {
        content: totalsHelpIconContent.totalStatCard,
        trackingId: TotalsTrackingIds.TotalStatCardHelpIcon,
      },
      errorIconProps: {
        content: totalsErrorIconContent,
        trackingId: TotalsTrackingIds.TotalStatCardErrorIcon,
      },
    },
    {
      title: "Original Forms",
      trackingId: TotalsTrackingIds.TotalOriginalStatCard,
      value: formatWholeQuantity(aggregates?.original),
      helpIconProps: {
        content: totalsHelpIconContent.totalOriginalStatCard,
        trackingId: TotalsTrackingIds.TotalOriginalStatCardHelpIcon,
      },
      errorIconProps: {
        content: totalsErrorIconContent,
        trackingId: TotalsTrackingIds.TotalOriginalStatCardErrorIcon,
      },
    },
    {
      title: "Corrected Forms",
      trackingId: TotalsTrackingIds.TotalCorrectionStatCard,
      value: formatWholeQuantity(aggregates?.correction),
      helpIconProps: {
        content: totalsHelpIconContent.totalCorrectionStatCard,
        trackingId: TotalsTrackingIds.TotalCorrectionStatCardHelpIcon,
      },
      errorIconProps: {
        content: totalsErrorIconContent,
        trackingId: TotalsTrackingIds.TotalCorrectionStatCardErrorIcon,
      },
    },
    {
      title: "Void Forms",
      trackingId: TotalsTrackingIds.TotalVoidStatCard,
      value: formatWholeQuantity(aggregates?.voided),
      helpIconProps: {
        content: totalsHelpIconContent.totalVoidStatCard,
        trackingId: TotalsTrackingIds.TotalVoidStatCardHelpIcon,
      },
      errorIconProps: {
        content: totalsErrorIconContent,
        trackingId: TotalsTrackingIds.TotalVoidStatCardErrorIcon,
      },
    },
  ] as const;

  return (
    <Flex direction="row" gap="l">
      {statCardProps.map(
        ({ title, trackingId, helpIconProps, errorIconProps, value }) => (
          <StatCard
            key={trackingId}
            hasError={isErrorAggregates}
            isLoading={isLoadingAggregates}
            title={title}
            trackingId={trackingId}
            value={value}
            helpIconProps={{
              isDisabled: false,
              tooltipProps: {
                appendTo: "body",
                content: helpIconProps.content,
                isInteractive: false,
              },
              trackingId: helpIconProps.trackingId,
              variant: "secondary",
            }}
            errorIconProps={{
              tooltipProps: {
                appendTo: "body",
                content: errorIconProps.content,
                isInteractive: false,
              },
              trackingId: errorIconProps.trackingId,
            }}
          />
        )
      )}
    </Flex>
  );
};

export default Totals;
