import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
  FormItem1099KAggregateValues,
  EligibilityStatus,
} from "@taxbit-dashboard/rest";
import { Badge, TableColumns } from "@taxbit-private/cosmic";
import {
  COSMIC_VALUE_PLACEHOLDER,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { Big } from "big.js";
import { useMemo } from "react";

import defaultToZeroOrPlaceholder from "./defaultToZeroOrPlaceholder";

// Under the hood we map CESOP to 1099-K for the demo purposes.
// The column is identical to 1099-K except the label.
// We should remove this mapping once we have CESOP support
const calculateFormDataTotalPaymentsAmount = (
  formSpecificFields: FormItem1099KAggregateValues
): string => {
  const {
    januaryAmount,
    februaryAmount,
    marchAmount,
    aprilAmount,
    mayAmount,
    juneAmount,
    julyAmount,
    augustAmount,
    septemberAmount,
    octoberAmount,
    novemberAmount,
    decemberAmount,
  } = formSpecificFields;

  return new Big(januaryAmount)
    .add(februaryAmount)
    .add(marchAmount)
    .add(aprilAmount)
    .add(mayAmount)
    .add(juneAmount)
    .add(julyAmount)
    .add(augustAmount)
    .add(septemberAmount)
    .add(octoberAmount)
    .add(novemberAmount)
    .add(decemberAmount)
    .toString();
};

const accountIdToTransactionValueMap: Record<string, number> = {
  "f11e297e-558d-45ff-8b01-c43a5a88ca2b": 20,
  "e1ea5466-8099-426b-ac7a-e8a91f7fd282": 15,
  "783c7a59-739f-468a-88e7-07eabe185d85": 10,
};

const useFormCesopEligibilityTableColumns = () => {
  const { formatQuantity, formatNumber } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "taxYearTotalTaxableTransactionCount",
        label: "Rep. Trans.",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (taxYearTotalTaxableTransactionCount, eligibilityData) => {
          if (eligibilityData.isFormData) {
            return <Badge variant="secondary" label="Form Data" />;
          } else if (eligibilityData.status === EligibilityStatus.Pending) {
            return COSMIC_VALUE_PLACEHOLDER;
          } else {
            const updatedTransactionCount =
              accountIdToTransactionValueMap[eligibilityData.accountId] ??
              taxYearTotalTaxableTransactionCount ??
              0;

            return formatQuantity(updatedTransactionCount);
          }
        },
      },
      {
        key: "formSpecificFields",
        label: "Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields, eligibilityData) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099kIngested
          ) {
            return formatNumber({
              value: formSpecificFields.taxYearTotalAdjustedReceivedQuantity,
              currency: "EUR",
              format: "FiatCurrency",
            });
          }

          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099kFormData
          ) {
            return formatNumber({
              value: calculateFormDataTotalPaymentsAmount(formSpecificFields),
              currency: "EUR",
              format: "FiatCurrency",
            });
          }

          return defaultToZeroOrPlaceholder(eligibilityData.status);
        },
      },
    ],
    [formatNumber, formatQuantity]
  );
};

export default useFormCesopEligibilityTableColumns;
