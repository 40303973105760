import { logError } from "@taxbit-dashboard/commons";
import { CompanyUser } from "@taxbit-dashboard/rest";

import runPendoSnippet from "./runPendoSnippet";

let isUserIdentified = false;

export const initializePendo = () => {
  try {
    runPendoSnippet();

    if (!window.pendo) {
      throw new Error("window.pendo does not exist");
    }

    window.pendo.initialize({
      // Initialize all users with the `isUmcUser` flag to ensure
      // we don't accidentally show guides for other platforms prior
      // to the user being fully identified.
      visitor: {
        isUmcUser: true,
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    logError({ message: "Unable to initialize Pendo", error });
  }
};

export const updatePendoUser = (
  { id, email, name }: CompanyUser,
  organizationId: string,
  organizationName: string
) => {
  if (!window.pendo) {
    // eslint-disable-next-line no-console
    console.error(
      "Attempting to update Pendo user but Pendo has not been initialized."
    );
    return;
  }

  const visitor = {
    id,
    email,
    fullName: name,
    isUmcUser: true,
    organizationId,
    organizationName,
  };

  const account = {
    id: organizationId,
    organizationName,
  };

  try {
    if (isUserIdentified) {
      window.pendo.updateOptions({
        visitor,
        account,
      });
    } else {
      window.pendo.identify({
        visitor,
        account,
      });
      isUserIdentified = true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Unable to update Pendo user", error);
  }
};
