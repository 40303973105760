import { AppOutlet } from "@taxbit-dashboard/commons";

import {
  navigationItem as accountSettingsNavigationItem,
  route as accountSettingsRoute,
} from "./src/pages/account-settings/route";
import {
  navigationItem as filerSettingsNavigationItem,
  route as filerSettingsRoute,
} from "./src/pages/filer-setup/route";
import {
  navigationItem as orgSettingsNavigationItem,
  routes as orgSettingsRoutes,
} from "./src/pages/org-settings/route";

export const route = {
  path: "settings",
  element: (
    <AppOutlet
      sections={[
        {
          items: [
            accountSettingsNavigationItem,
            orgSettingsNavigationItem,
            filerSettingsNavigationItem,
          ],
        },
      ]}
    />
  ),
  children: [accountSettingsRoute, ...orgSettingsRoutes, filerSettingsRoute],
};
