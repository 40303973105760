import { TransferStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";

import { TransfersFilterDrawerFormFields } from "./transfersFilterDrawerFormFieldTypes";
import { transferStatusesMap } from "../../../../api/transfers/transfersApiTypes";

const TRANSFER_STATUS_OPTIONS = Object.values(TransferStatus).map((status) => ({
  key: status,
  label: transferStatusesMap[status],
}));

export enum TransfersStatusFilterTrackingId {
  MultiselectBtn = "transfers-filters-drawer-transfer-status-filter",
}

const TransfersStatusFilter: React.FC = () => {
  return (
    <RhfMultiselect<
      TransferStatus,
      Pick<TransfersFilterDrawerFormFields, "transferStatuses">,
      "transferStatuses",
      (typeof TRANSFER_STATUS_OPTIONS)[number]
    >
      label="Status"
      placeholder="All"
      name="transferStatuses"
      trackingId={TransfersStatusFilterTrackingId.MultiselectBtn}
      options={TRANSFER_STATUS_OPTIONS}
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
    />
  );
};

export default TransfersStatusFilter;
