import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

import getFormItemsParams, { FormItemsParams } from "./getFormItemsParams";
import useAccountId from "../../utils/useAccountId";

export const useGetFormItems = (params: FormItemsParams) => {
  const accountId = useAccountId();
  const restSdk = useTaxBitRest();
  const apiParams = getFormItemsParams(params);

  const query = useQuery(
    [DashboardQueryKey.FormItems, { ...apiParams }, accountId],
    () =>
      accountId
        ? restSdk.formItems.get({ params: apiParams, accountId })
        : undefined,
    {
      ...createQueryMetaObject(restSdk.formItems.buildPath()),
      enabled: !!accountId,
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
