import { datadogRum } from "@datadog/browser-rum";
import { CompanyUser, getEnvironment } from "@taxbit-dashboard/rest";

export const initializeDatadog = () => {
  datadogRum.init({
    applicationId: "ce7f380a-441c-442e-9bd6-a78fb44ae12d",
    clientToken: "pubd7302bc9e219cb82d280bb14240af312",
    site: "datadoghq.com",
    service: "dashboard",
    env: getEnvironment(),
    version: window.appVersion,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    sessionSampleRate: 100,
    trackLongTasks: true,
    trackResources: true,
    enableExperimentalFeatures: ["feature_flags"],
  });
};

export const updateDatadogUser = (
  { id, email, name }: CompanyUser,
  organizationId: string,
  organizationName: string
) => {
  try {
    datadogRum.setUser({
      id,
      name,
      email,
      organizationId,
      organizationName,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Unable to update Datadog user", error);
  }
};
