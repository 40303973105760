import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import logError from "../error-handling/logError";

/**
 * Helper to invalidate multiple queries at once
 * for a given set of query keys.
 */
const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async (queryKeys: QueryKey[]) => {
      try {
        const queriesToInvalidate = queryKeys.map((queryKey) => {
          return queryClient.invalidateQueries(queryKey);
        });

        await Promise.all(queriesToInvalidate);
      } catch (error) {
        logError({
          message: "Error invalidating queries",
          error,
          additionalProperties: {
            queryKeys,
          },
        });
      }
    },
    [queryClient]
  );
};

export default useInvalidateQueries;
