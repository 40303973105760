import { useMutation } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  isDefined,
  logError,
  useInvalidateQueries,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

export const useCreateAccountOwnerCuringArchive = () => {
  const restSdk = useTaxBitRest();
  const invalidateQueries = useInvalidateQueries();

  return useMutation(
    (params: { accountOwnerCuringId?: number; note?: string }) => {
      if (!isDefined(params.accountOwnerCuringId)) {
        throw new Error(
          "Missing required parameters for creating account owner curing archive."
        );
      }

      return restSdk.accountOwnerCuringArchives.post({
        requestData: {
          accountOwnerCuringId: params.accountOwnerCuringId,
          note: params.note,
        },
      });
    },
    {
      onSuccess: () => {
        void invalidateQueries([
          [DashboardQueryKey.AccountOwnerCuringArchives],
          [DashboardQueryKey.AccountOwnerDetails],
        ]);
      },
      onError: (error) => {
        logError({
          message: "Failed to create account owner curing archive.",
          error,
        });
      },
    }
  );
};
