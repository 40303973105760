import { usePageScroll } from "@taxbit-dashboard/commons";
import { PaginationFooter } from "@taxbit-private/cosmic";

import useAccountsTableData from "./useAccountsTableData";

const AccountsPagination: React.FC = () => {
  const {
    urlParams: { page, limit },
    setPaginationParams,
    totalCount,
    shouldDisableControls,
  } = useAccountsTableData();

  const { scrollPageToTop } = usePageScroll();

  return (
    <PaginationFooter
      isDisabled={shouldDisableControls}
      totalCount={totalCount}
      setCurrentPage={(nextPage) => {
        setPaginationParams((draft) => {
          draft.page = nextPage;
        });
        scrollPageToTop();
      }}
      currentPage={page}
      setPageSize={(nextLimit) =>
        setPaginationParams((draft) => {
          draft.limit = nextLimit;
        })
      }
      pageSize={limit}
      trackingId="accounts-pagination"
    />
  );
};

export default AccountsPagination;
