import { useQuery } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useOrganizationId,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

export const useGetOrganizationDevSettings = () => {
  const restSdk = useTaxBitRest();
  const organizationId = useOrganizationId();
  const query = useQuery(
    [DashboardQueryKey.OrganizationDevSettings],
    () => restSdk.organizations.devSettings.get(organizationId),
    {
      ...createQueryMetaObject(
        restSdk.organizations.devSettings.buildPath(organizationId)
      ),
      // Turn off refetching entirely, as it's unlikely we'll ever
      // have stale organization data during a single session.
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
