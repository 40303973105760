import { IrFormStatus } from "@taxbit-dashboard/rest";

import { useIrFormsContext } from "../../context/useIrForms";

export enum IrFormDeleteConfirmationModalTrackingId {
  CloseButton = "ir-forms-delete-confirmation-modal-close-btn",
  CancelButton = "ir-forms-delete-confirmation-modal-cancel-btn",
  SubmitButton = "ir-forms-delete-confirmation-modal-submit-btn",
}

const useDeleteFormsConfirmationModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const {
    selectedForms,
    shouldDisableControls,
    rescindForms,
    toggleAllSelectedForms,
  } = useIrFormsContext();

  const handleSubmit = () => {
    const keysToRescind = selectedForms
      .filter(({ status }) => status === IrFormStatus.Generated)
      .map(({ accountId, revisionKey }) => ({
        accountId,
        revisionKey,
      }));

    rescindForms(keysToRescind);

    onClose();
    toggleAllSelectedForms(false);
  };

  return {
    handleSubmit,
    selectedForms,
    isLoading: shouldDisableControls,
  };
};

export default useDeleteFormsConfirmationModal;
