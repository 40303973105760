import { Body } from "@taxbit-private/cosmic";

type GridRowProps = {
  /**
   * The label for the row.
   */
  label: string;
  /**
   * The content for the row. This content will be displayed in Body text.
   */
  content: React.ReactNode;
};

/**
 * The `GridRow` molecule displays a label with a text description or element.
 * This should only be used within a `Grid`, and the template should be determined by the parent component.
 */
const GridRow: React.FC<GridRowProps> = ({ label, content }) => {
  return (
    <>
      <Body colorVariant="secondary">{label}</Body>
      <Body shouldBreakWord={true}>{content}</Body>
    </>
  );
};

export default GridRow;
