import { TRACKING_EXCLUDE_CLASS } from "@taxbit-dashboard/commons";
import { AccountV2Address } from "@taxbit-dashboard/rest";
import { Body, Flex } from "@taxbit-private/cosmic";

export enum AccountAddressTrackingId {
  FirstLineAddress = "account-address-first-line",
  SecondLineAddress = "account-address-second-line",
  ThirdLineAddress = "account-address-third-line",
  FourthLineAddress = "account-address-fourth-line",
}

type AccountAddressProps = {
  address: AccountV2Address;
};

const AccountAddress: React.FC<AccountAddressProps> = ({ address }) => {
  const { firstLine, secondLine, stateOrProvince, city, country, postalCode } =
    address;

  const addressLines = [
    { text: firstLine, trackingId: AccountAddressTrackingId.FirstLineAddress },
    {
      text: secondLine,
      trackingId: AccountAddressTrackingId.SecondLineAddress,
    },
    {
      text: [[city, stateOrProvince].filter(Boolean).join(", "), postalCode]
        .filter(Boolean)
        .join(" "),
      trackingId: AccountAddressTrackingId.ThirdLineAddress,
    },
    { text: country, trackingId: AccountAddressTrackingId.FourthLineAddress },
  ];

  return (
    <Flex direction="column" className={TRACKING_EXCLUDE_CLASS}>
      {addressLines.map(
        ({ text, trackingId }) =>
          text && (
            <Body key={trackingId} trackingId={trackingId}>
              {text}
            </Body>
          )
      )}
    </Flex>
  );
};

export default AccountAddress;
