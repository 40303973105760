import { zodResolver } from "@hookform/resolvers/zod";
import {
  useDashboardStore,
  logError,
  useOrganizationId,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import {
  Modal,
  ModalProps,
  Flex,
  Body,
  BodySecondary,
  Alert,
  ContentSpinner,
  ContentErrorEmptyState,
} from "@taxbit-private/cosmic";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";

import {
  OrganizationMemberWithRoles,
  useUpdateUserRoles,
} from "../../../../api/organizationMembersApi";
import { useGetRoles } from "../../../../api/rolesApi";
import RhfCheckboxGroup from "../../../../components/RhfCheckboxGroup";

type Props = Pick<ModalProps, "isOpen" | "onClose"> & {
  user: OrganizationMemberWithRoles;
};

const EDIT_USER_FORM_ID = "edit-user-form";

const organizationMemberSchema = z.object({
  roleIds: z.string().array().nonempty({
    message: "At least one role is required",
  }),
});

export type OrganizationMember = z.infer<typeof organizationMemberSchema>;

const UpdateCompanyUserModal: React.FC<Props> = ({ onClose, isOpen, user }) => {
  const {
    data: roles,
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
  } = useGetRoles();

  const currentCompanyUser = useDefinedCurrentCompanyUser();
  const organizationId = useOrganizationId();

  const { mutate: updateUserRoles, isLoading: isLoadingUpdateUserRoles } =
    useUpdateUserRoles(organizationId, user.id);

  const addToast = useDashboardStore((store) => store.addToast);

  const formMethods = useForm<OrganizationMember>({
    resolver: zodResolver(organizationMemberSchema),
    defaultValues: {
      roleIds: user.roles.map(({ id }) => id),
    },
  });

  const { handleSubmit } = formMethods;

  const roleCheckboxOptions =
    roles?.map((role) => ({
      value: role.id,
      label: role.name,
      trackingId: `edit-user-role-${role.id}`,
    })) ?? [];

  const isEditingCurrentUser = currentCompanyUser.id === user.id;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    void handleSubmit((formData: OrganizationMember) => {
      updateUserRoles(formData.roleIds, {
        onSuccess: () => {
          // If the current company user is editing themselves, we force a refresh to
          // pull in a new token with edited scope from Auth0.
          if (isEditingCurrentUser) {
            window.location.reload();
          } else {
            addToast({
              message: `${user.name} has been updated.`,
              trackingId: "edit-user-success-toast",
              variant: "primary",
              timeoutMs: 10000,
            });
          }
        },
        onError: (error) => {
          logError({ message: "Error editing user", error });
          addToast({
            message:
              "An error occurred and the user was not updated. Please try again.",
            trackingId: "edit-user-error-toast",
            variant: "danger",
            timeoutMs: 10000,
          });
        },
        onSettled: () => onClose(),
      });
    })(event);
  };

  return (
    <Modal
      title="Edit User"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId="edit-user-close-button"
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: "edit-user-cancel-button",
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Save User",
        trackingId: "edit-user-submit-button",
        type: "submit",
        form: EDIT_USER_FORM_ID,
        isLoading: isLoadingUpdateUserRoles,
        loadingText: "Saving",
        isDisabled: isErrorRoles || isLoadingRoles,
      }}
    >
      {isErrorRoles ? (
        <ContentErrorEmptyState entity="form" />
      ) : isLoadingRoles ? (
        <ContentSpinner />
      ) : (
        <FormProvider {...formMethods}>
          <form id={EDIT_USER_FORM_ID} onSubmit={onSubmit}>
            <Flex gap="l" direction="column">
              {isEditingCurrentUser && (
                <Alert variant="warning">
                  You are currently editing your own user info. After saving,
                  the browser will refresh to pull in the changes.
                </Alert>
              )}
              <Flex gap="xs" direction="column">
                <Body>Name</Body>
                <BodySecondary>{user.name}</BodySecondary>
              </Flex>
              <Flex gap="xs" direction="column">
                <Body>Email</Body>
                <BodySecondary>{user.email}</BodySecondary>
              </Flex>
              <RhfCheckboxGroup
                label="Roles"
                name="roleIds"
                options={roleCheckboxOptions}
              />
            </Flex>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default UpdateCompanyUserModal;
