import { isDefined } from "@taxbit-dashboard/commons";
import {
  AssetAmount,
  GetTransferInLots,
  LineItem,
  Transaction,
  TransactionType,
} from "@taxbit-dashboard/rest";
import {
  Card,
  getEnUsEntityEmptyStateProps,
  getEnUsErrorEmptyStateProps,
  Table,
  TrimmedBoxContent,
  TableColumns,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { DateTimeString } from "@taxbit-private/data-models-v2";
import { useCallback, useMemo } from "react";

import { useGetTransferInLots } from "../../../../../api/transferInLotsApi";
import { calculateTransactionAcquiredAsset } from "../../../../../utils/calculateTransactionAcquiredAsset";
import calculateTransactionCostBasis from "../../../../../utils/calculateTransactionCostBasis";
import useFormatAsset from "../../../../../utils/useFormatAsset";
import useFormatUsdValue from "../../../../../utils/useFormatUsdValue";

type TableAsset = {
  received?: LineItem;
  costBasisDate?: DateTimeString;
  cost?: Omit<AssetAmount, "asset"> & { asset?: AssetAmount["asset"] };
  key: string;
};

const mapReceivedAssetsToTable = (transaction: Transaction): TableAsset[] => {
  const { received, datetime, id } = transaction;
  if (!received) return [];
  const acquiredAsset = calculateTransactionAcquiredAsset(transaction);
  const costBasis = calculateTransactionCostBasis(transaction);
  return [
    {
      received: acquiredAsset,
      costBasisDate: datetime,
      cost: costBasis,
      key: `${id}-acquisition`,
    },
  ];
};

const mapTransferInLotsToTable = (
  transferInLots: GetTransferInLots["data"],
  transaction: Transaction
): TableAsset[] => {
  const transactionAsset = transaction.received?.[0]?.assetAmount.asset;
  if (!transactionAsset) return [];

  return transferInLots.map((lot, index) => ({
    received: isDefined(lot.quantity)
      ? {
          assetAmount: {
            amount: lot.quantity,
            asset: transactionAsset,
          },
        }
      : undefined,
    costBasisDate: lot.acquisitionTransactionDatetime,
    cost: {
      amount: lot.costBasis,
    },
    key: `${lot.transactionId}-acquisition-${index}`,
  }));
};

type AcquistionTableProps = {
  transaction: Transaction;
};

enum AcquisitionTableTrackingId {
  CosmicTable = "acquisition-table",
}

const AcquisitionTable: React.FC<AcquistionTableProps> = ({ transaction }) => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();
  const formatUsdValue = useFormatUsdValue();

  const shouldFetchTransferLots =
    transaction?.type === TransactionType.TransferIn &&
    !!transaction?.metadata?.hasTransferLotData;

  const {
    data: transferInLotsResponse = [],
    isError,
    isFetching: isLoading,
  } = useGetTransferInLots({
    externalTransactionId: transaction?.externalId,
    shouldFetchTransferLots,
  });

  const receivedAssets =
    useMemo(() => {
      if (shouldFetchTransferLots) {
        return mapTransferInLotsToTable(transferInLotsResponse, transaction);
      }
      return mapReceivedAssetsToTable(transaction);
    }, [shouldFetchTransferLots, transferInLotsResponse, transaction]) || [];

  const tableTitle = shouldFetchTransferLots
    ? "Acquisition Lots"
    : "Acquisitions";

  const columns: TableColumns<TableAsset> = useMemo(
    () =>
      [
        {
          key: "costBasisDate",
          label: "Date Acquired",
          renderCell: (costBasisDate) => {
            return formatDateTime({ date: costBasisDate, format: "DateTime" });
          },
        },
        {
          key: "received",
          label: "Incoming Asset",
          renderCell: (received) => {
            return formatAsset({ assetAmount: received?.assetAmount });
          },
        },
        {
          key: "cost",
          label: "Cost Basis",
          renderCell: (cost) => {
            if (cost?.asset) {
              return formatAsset({
                assetAmount: { asset: cost.asset, amount: cost.amount },
              });
            } else {
              // TODO: https://taxbit.atlassian.net/browse/TAX-40413 Update Transfer Lots API response to return assets on both cost and quantity
              return formatUsdValue(cost?.amount);
            }
          },
        },
      ] as const,
    [formatAsset, formatDateTime, formatUsdValue]
  );

  const getRowKey = useCallback((row: TableAsset) => row.key, []);

  return (
    <Card
      title={tableTitle}
      helpIconProps={{
        trackingId: "acquisition-lots-help-icon",
        tooltipProps: {
          content:
            "The tax lot(s) added to inventory as a result of this transaction.",
          appendTo: "parent",
          isInteractive: true,
        },
      }}
      badgeProps={
        shouldFetchTransferLots
          ? {
              label: "User Provided",
              variant: "secondary",
            }
          : undefined
      }
    >
      <TrimmedBoxContent trim="all">
        <Table
          trackingId={AcquisitionTableTrackingId.CosmicTable}
          columns={columns}
          rows={receivedAssets}
          getRowKey={getRowKey}
          emptyStateProps={
            isError
              ? getEnUsErrorEmptyStateProps({
                  entity: "transfer in lots",
                })
              : getEnUsEntityEmptyStateProps({
                  entity: "transfer in lots",
                })
          }
          isLoading={isLoading}
        />
      </TrimmedBoxContent>
    </Card>
  );
};

export default AcquisitionTable;
