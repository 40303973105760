import {
  GetFormsSearchKey,
  getFormsSearchKeySchema,
} from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import { formsSearchKeyLabelMap } from "../../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../../context/useIrForms";

export enum FormsSearchChipsTrackingId {
  Chip = "ir-form-search-chip",
}

const useSearchFilterChips = () => {
  const { urlParams, setFilterParams } = useIrFormsContext();

  const getSearchChip = useCallback(
    (key: GetFormsSearchKey, value: string) => {
      if (value) {
        const label = `${formsSearchKeyLabelMap[key]}: "${value}"`;
        return {
          label,
          removeButtonProps: {
            "onClick": () =>
              setFilterParams((draft) => {
                draft[key] = undefined;
              }),
            "aria-label": `Remove filter for ${label}`,
          },
          trackingId: `${FormsSearchChipsTrackingId.Chip}${formsSearchKeyLabelMap[key]}`,
        };
      } else {
        return undefined;
      }
    },
    [setFilterParams]
  );

  const searchChipEntries = Object.entries(urlParams).filter(
    (entry): entry is [GetFormsSearchKey, string] =>
      getFormsSearchKeySchema.options.includes(entry[0] as GetFormsSearchKey) &&
      !!entry[1]
  );

  return searchChipEntries.map(([key, value]) => getSearchChip(key, value));
};

export default useSearchFilterChips;
