import {
  GetAccountsSort,
  GetAccountsSortKey,
  getAccountsSortKeySchema,
  getAccountsSortSchema,
} from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import { AccountsColumnKey } from "./useAccountsTableColumns";
import { DEFAULT_ACCOUNTS_SORT } from "../../../../api/accounts/accountsApiTypes";
import useAccountsTableData from "../useAccountsTableData";

const getSortAttributesFromSort = (sort: GetAccountsSort) => {
  if (sort.startsWith("-")) {
    return {
      isInitialSortDescending: true,
      initialSortColumnKey: sort.slice(1) as GetAccountsSortKey,
    } as const;
  } else {
    return {
      isInitialSortDescending: false,
      initialSortColumnKey: sort as GetAccountsSortKey,
    };
  }
};

const defaultSortAttributes = getSortAttributesFromSort(DEFAULT_ACCOUNTS_SORT);

const isValidSortKey = (key?: string): key is GetAccountsSortKey =>
  !!(key && getAccountsSortKeySchema.safeParse(key).success);

const useAccountsTableSort = () => {
  const {
    setFilterParams,
    urlParams: { sort },
  } = useAccountsTableData();

  const onSortChange = useCallback(
    (key: AccountsColumnKey, isDescending: boolean = false) => {
      const newSort = (() => {
        if (isValidSortKey(key)) {
          return `${isDescending ? "-" : ""}${key}` as const;
        } else {
          return DEFAULT_ACCOUNTS_SORT;
        }
      })();

      if (newSort !== sort) {
        setFilterParams((draft) => {
          draft.sort = newSort;
        });
      }
    },
    [setFilterParams, sort]
  );

  const initialSortProps = getAccountsSortSchema.safeParse(sort).success
    ? getSortAttributesFromSort(sort)
    : defaultSortAttributes;

  return {
    onSortChange,
    ...initialSortProps,
  };
};

export default useAccountsTableSort;
