// There is an issue with the csv-stringify package exports
// https://github.com/adaltas/node-csv/issues/372
// disabling lint until resolved
// eslint-disable-next-line import/no-unresolved
import { stringify } from "csv-stringify/browser/esm/sync";

export type CsvHeader = {
  key: string;
  header?: string;
};

export const generateCsv = <T>(
  columns: CsvHeader[],
  rows: Array<T>
): string => {
  return stringify(rows, {
    delimiter: ",",
    columns,
    header: true,
  });
};
