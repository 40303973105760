import { Form1099MiscLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItem1099MiscColumns = (): TableColumns<Form1099MiscLineItem> => {
  const { formatQuantity, formatBoolean } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form1099MiscLineItem> => [
      {
        key: "rents",
        label: "Rents",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (rents) => formatQuantity(rents),
      },
      {
        key: "royalties",
        label: "Royalties",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (royalties) => formatQuantity(royalties),
      },
      {
        key: "otherIncome",
        label: "Other Income",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (otherIncome) => formatQuantity(otherIncome),
      },
      {
        key: "federalTaxWithheld",
        label: "Federal Tax Withheld",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "fishingBoatProceeds",
        label: "Fishing Boat Proceeds",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (fishingBoatProceeds) =>
          formatQuantity(fishingBoatProceeds),
      },
      {
        key: "medicalAndHealthCarePayments",
        label: "Medical And Healthcare Payments",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (medicalAndHealthCarePayments) =>
          formatQuantity(medicalAndHealthCarePayments),
      },
      {
        key: "directSales5000ConsumerProducts",
        label: "Direct Sales 5000 Consumer Products",
        isContentFullWidth: true,
        renderCell: (directSales5000ConsumerProducts) =>
          formatBoolean(directSales5000ConsumerProducts),
      },
      {
        key: "substitutePayments",
        label: "Substitute Payments",
        isContentFullWidth: true,
        renderCell: (substitutePayments) => formatQuantity(substitutePayments),
      },
      {
        key: "cropInsuranceProceeds",
        label: "Crop Insurance Proceeds",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (cropInsuranceProceeds) =>
          formatQuantity(cropInsuranceProceeds),
      },
      {
        key: "grossProceedsPaidToAnAttorney",
        label: "Gross Proceeds Paid To An Attorney",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (grossProceedsPaidToAnAttorney) =>
          formatQuantity(grossProceedsPaidToAnAttorney),
      },
      {
        key: "fishPurchasedForResale",
        label: "Fish Purchased For Resale",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (fishPurchasedForResale) =>
          formatQuantity(fishPurchasedForResale),
      },
      {
        key: "section409ADeferrals",
        label: "Section 409a Deferrals",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (section409aDeferrals) =>
          formatQuantity(section409aDeferrals),
      },
      {
        key: "excessGoldenParachutePayments",
        label: "Excess Golden Parachute Payments",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (excessGoldenParachutePayments) =>
          formatQuantity(excessGoldenParachutePayments),
      },
      {
        key: "nonqualifiedDeferredComp",
        label: "Nonqualified Deferred Comp",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (nonqualifiedDeferredComp) =>
          formatQuantity(nonqualifiedDeferredComp),
      },
      {
        key: "stateTaxWithheld",
        label: "State Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (stateTaxWithheld) => formatQuantity(stateTaxWithheld),
      },
      {
        key: "state",
        label: "State",
        isContentFullWidth: true,
      },
      {
        key: "payersStateNumber",
        label: "Payers State Number",
        isContentFullWidth: true,
      },
      {
        key: "stateIncome",
        label: "State Income",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (stateIncome) => formatQuantity(stateIncome),
      },
    ],
    [formatQuantity, formatBoolean]
  );
};

export default useFormItem1099MiscColumns;
