import { useQuery } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";

import { AccountsTableParams } from "./accountsApiTypes";
import getAccountsApiParams from "./getAccountsApiParams";

export const useGetAccounts = ({ params }: { params: AccountsTableParams }) => {
  const restSdk = useTaxBitRest();

  const apiParams = getAccountsApiParams(params);

  const query = useQuery(
    [DashboardQueryKey.Accounts, { ...params }],
    () => restSdk.accounts.get(apiParams),
    {
      ...createQueryMetaObject(restSdk.accounts.buildPath()),
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
