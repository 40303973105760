import { DashboardFormType } from "@taxbit-dashboard/rest";
import {
  Box,
  BoxAccent,
  ContentErrorEmptyState,
  Divider,
  Flex,
  H4,
  NumericalRowGroup,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { getEligibilityStatusLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export enum IneligibleUsersCountTrackingIds {
  Exempt = "irIneligibleUsersCountExempt",
  NonValidTaxCountry = "irIneligibleUsersCountNonValidTaxCountry",
  NotReportable = "irIneligibleUsersCountNotReportable",
  Removed = "irIneligibleUsersCountRemoved",
  Total = "irIneligibleUsersCountTotal",
}

const IneligibleUsersCount = () => {
  const {
    eligibilityCounts,
    urlParams,
    isEligibilityCountsError,
    currentForm,
  } = useEligibilityDataContext();
  const {
    exempt = 0,
    nonValidTaxCountryCode = 0,
    noReportableTransactions = 0,
    removed = 0,
  } = eligibilityCounts?.ineligible || {};

  const total =
    exempt + nonValidTaxCountryCode + noReportableTransactions + removed;

  const rows = [
    {
      label: currentForm === DashboardFormType.Dac7 ? "Non-EU" : "Exempt",
      key: "exempt",
      value: exempt,
    },
    {
      label: [DashboardFormType.Cesop, DashboardFormType.Dac7].includes(
        currentForm
      )
        ? "Transactions Under Threshold"
        : getEligibilityStatusLabelMap(urlParams.formType)
            .NON_VALID_TAX_COUNTRY,
      key: "nonValidTaxCountryCode",
      value: nonValidTaxCountryCode,
    },
    {
      label: [DashboardFormType.Cesop, DashboardFormType.Dac7].includes(
        currentForm
      )
        ? "No Reportable Transactions"
        : "Not Reportable",
      key: "notReportable",
      value: noReportableTransactions,
    },
    {
      label: "Removed From Scope",
      key: "removed",
      value: removed,
    },
  ];

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <Box headElement={<BoxAccent background="primary" />}>
      <Flex direction="column" padding="contentPadding" gap="m">
        <Flex justifyContent="space-between">
          <H4>Ineligible Accounts</H4>
          {!isEligibilityCountsError && (
            <H4 trackingId={IneligibleUsersCountTrackingIds.Total}>
              {formatWholeQuantity(total)}
            </H4>
          )}
        </Flex>
        <Divider />
        {isEligibilityCountsError ? (
          <ContentErrorEmptyState entity="ineligible accounts" />
        ) : (
          <NumericalRowGroup rows={rows} />
        )}
      </Flex>
    </Box>
  );
};

export default IneligibleUsersCount;
