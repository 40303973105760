import { zodResolver } from "@hookform/resolvers/zod";
import {
  getBrowserDateFromUtcDateString,
  getUtcDateStringFromBrowserDate,
  hasDefinedValues,
} from "@taxbit-dashboard/commons";
import { useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  AccountsFilterDrawerFormFields,
  accountsFilterDrawerFormFieldSchema,
} from "./accountsFilterDrawerFormFieldSchema";
import { AccountsTableParams } from "../../../../../api/accounts/accountsApiTypes";
import useAccountsTableData from "../../useAccountsTableData";

const getFormDataFromUrlParams = ({
  endDate,
  startDate,
  taxDocumentationStatuses,
  taxDocumentationTypes,
  tinValidationStatuses,
  taxDocumentationOpenIssues,
}: AccountsTableParams): AccountsFilterDrawerFormFields => ({
  dateRange: [
    getBrowserDateFromUtcDateString(startDate),
    getBrowserDateFromUtcDateString(endDate),
  ],
  taxDocumentationStatuses: taxDocumentationStatuses ?? [],
  taxDocumentationTypes: taxDocumentationTypes ?? [],
  tinValidationStatuses: tinValidationStatuses ?? [],
  taxDocumentationOpenIssues: taxDocumentationOpenIssues ?? [],
});

const formDataEmptyValues: AccountsFilterDrawerFormFields = {
  dateRange: [undefined, undefined],
  tinValidationStatuses: [],
  taxDocumentationStatuses: [],
  taxDocumentationTypes: [],
  taxDocumentationOpenIssues: [],
};

const useAccountsFilterDrawerForm = () => {
  const { urlParams, setFilterParams } = useAccountsTableData();

  const defaultValues = useMemo(
    () => getFormDataFromUrlParams(urlParams),
    [urlParams]
  );

  const formMethods = useForm<AccountsFilterDrawerFormFields>({
    resolver: zodResolver(accountsFilterDrawerFormFieldSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(
        ({
          dateRange: [startDate, endDate],
          tinValidationStatuses,
          taxDocumentationStatuses,
          taxDocumentationTypes,
          taxDocumentationOpenIssues,
        }) => {
          setFilterParams((draft) => {
            draft.startDate =
              startDate && getUtcDateStringFromBrowserDate(startDate, true);
            draft.endDate =
              endDate && getUtcDateStringFromBrowserDate(endDate, false);
            draft.tinValidationStatuses = tinValidationStatuses;
            draft.taxDocumentationStatuses = taxDocumentationStatuses;
            draft.taxDocumentationTypes = taxDocumentationTypes;
            draft.taxDocumentationOpenIssues = taxDocumentationOpenIssues;
          });
        }
      )(e);
    },
    [formMethods, setFilterParams]
  );

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset(getFormDataFromUrlParams(urlParams));
  }, [urlParams, formMethods]);

  /**
   * Clears all form filters. We must use the `setValue` form methods to individually
   * mark each state as dirty, in order to not mess with RHF's state handling.
   */
  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof AccountsFilterDrawerFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useAccountsFilterDrawerForm;
