import { useDashboardStore } from "@taxbit-dashboard/commons";
import { taxReportTypeSchema } from "@taxbit-dashboard/rest";

import { useGetTaxReportUrl } from "../../../api/taxReportsApi";
import { useGetReportsUrl as useGetUmcReportsUrl } from "../../../api/useGetReportsUrl";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import { UmcReport } from "../types/umcReportTypes";

export const getDownloadReportErrorToastTrackingId = (key: string) =>
  `umcReportsErrorToast${key}`;

export const useDownloadReport = (report: UmcReport) => {
  const addToast = useDashboardStore((store) => store.addToast);

  const onError = () => {
    addToast({
      message: `${report.name} failed to download. Please try again.`,
      trackingId: getDownloadReportErrorToastTrackingId(report.key),
      variant: "danger",
      timeoutMs: TOAST_TIMEOUT,
    });
  };

  /**
   * UMCReport type loses the branding we introduced at the API client level.
   * Once we've cut off the UMC endpoints entirely, this won't be necessary.
   */
  const parsedReportType = taxReportTypeSchema.safeParse(report.key);
  const reportType = parsedReportType.success
    ? parsedReportType.data
    : undefined;
  const { isFetching: isTaxReportUrlFetching, refetch: refetchTaxReportUrl } =
    useGetTaxReportUrl({
      reportType,
      onError,
    });

  const { isFetching: isUmcReportUrlFetching, refetch: refetchUmc } =
    useGetUmcReportsUrl({
      key: report.key,
      onError,
    });

  /**
   * The type of report determines which endpoint we hit. This is temporary until
   * we fully cut off the UMC endpoints.
   * https://taxbit.atlassian.net/browse/TAX-33489
   */
  const isFetching = parsedReportType.success
    ? isTaxReportUrlFetching
    : isUmcReportUrlFetching;
  const refetch = parsedReportType.success ? refetchTaxReportUrl : refetchUmc;

  const onReportClick = () => {
    void refetch().then((res) => {
      if (res && res.data?.data.url) window.open(res.data.data.url, "_blank");
    });
  };

  return {
    onReportClick,
    isLoading: isFetching,
  };
};
