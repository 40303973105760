import { useNavigate, useSearch } from "@tanstack/react-location";
import { produce } from "immer";
import { useCallback, useMemo } from "react";

import validateFormItemsParams, {
  FormItemsTableGenerics,
} from "./table/validateFormItemsParams";
import { useGetFormItems } from "../../api/form-items/formItemsApi";
import { FormItemsTableParams } from "../../api/form-items/formItemsApiTypes";
import useIrDropdowns from "../information-reporting/shared/useIrDropdowns";

const useFormItems = (page?: number, limit?: number) => {
  const shouldUseFormItemsForms = true;
  const formDropdownData = useIrDropdowns(shouldUseFormItemsForms);

  const formParams = useMemo(
    () => ({
      formDate: formDropdownData.currentYear,
      formType: formDropdownData.currentForm,
    }),
    [formDropdownData.currentForm, formDropdownData.currentYear]
  );

  const rawUrlParams = useSearch<FormItemsTableGenerics>();
  const navigate = useNavigate();

  const setUrlParams = useCallback(
    (setter: (draft: FormItemsTableParams) => void) => {
      navigate({
        search: (params) => {
          const validatedParams = validateFormItemsParams(params ?? {});
          return produce({ ...validatedParams, ...formParams }, setter);
        },
      });
    },
    [navigate, formParams]
  );

  const urlParams = useMemo(
    () => validateFormItemsParams(rawUrlParams),
    [rawUrlParams]
  );

  const query = useGetFormItems({
    ...urlParams,
    ...formParams,
    limit: limit ?? urlParams.limit,
    page: page ?? urlParams.page,
  });

  return {
    ...formDropdownData,
    ...query,
    formItems: query.data?.formItems,
    isNewDataLoading: query.isPreviousData,
    setPaginationParams: setUrlParams,
    shouldDisableControls: query.isLoading || query.isPreviousData,
    totalCount: query.meta?.page?.totalCount ?? 0,
    urlParams,
  };
};

export default useFormItems;
