import {
  UserPermission,
  useGetPayers,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { Payer } from "@taxbit-dashboard/rest";
import {
  Table,
  TrimmedBoxContent,
  ContentSpinner,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
} from "@taxbit-private/cosmic";
import { useCallback, useMemo } from "react";

import FilerSetupTableRowActionMenu from "./FilerSetupTableRowActionMenu";
import useFilerSetupTableColumns, {
  FilerSetupTableTrackingId,
} from "./useFilerSetupTableColumns";

const FilerSetupTable: React.FC = () => {
  const { data: payersData = [], isLoading, isError } = useGetPayers();
  const canUpdatePayers = useUserPermission(UserPermission.UpdatePayers);

  const sortedPayers = useMemo(
    () =>
      [...payersData].sort((a, b) => {
        const comparison = a.payerName.localeCompare(b.payerName);
        if (comparison === 0) {
          return a.payerId.localeCompare(b.payerId);
        }
        return comparison;
      }),
    [payersData]
  );

  const columns = useFilerSetupTableColumns();

  const getRowKey = useCallback((payer: Payer) => payer.payerId, []);

  const getRowUtilityElement = useCallback(
    (payer: Payer) =>
      canUpdatePayers ? (
        <FilerSetupTableRowActionMenu payer={payer} />
      ) : undefined,
    [canUpdatePayers]
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <ContentSpinner trackingId={FilerSetupTableTrackingId.LoadingSpinner} />
      );
    }

    if (isError) {
      return <ContentErrorEmptyState entity="Filers" />;
    } else if (payersData?.length) {
      return (
        <Table
          rows={sortedPayers}
          trackingId={FilerSetupTableTrackingId.Table}
          columns={columns}
          getRowKey={getRowKey}
          getRowUtilityElement={getRowUtilityElement}
          isUtilityColumnSticky={true}
          isSortable={true}
          initialSortColumnKey="isDefault"
          isInitialSortDescending={true}
        />
      );
    } else {
      return <ContentEntityEmptyState entity="Filers" />;
    }
  };

  return <TrimmedBoxContent trim="all">{renderContent()}</TrimmedBoxContent>;
};

export default FilerSetupTable;
