import {
  accountTaxDocumentationStatusSchema,
  accountTaxDocumentationTypeSchema,
  kycTaxDocumentationIssueTypeSchema,
  tinValidationStatusSchema,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

export const accountsFilterDrawerFormFieldSchema = z.object({
  dateRange: z.tuple([z.date().optional(), z.date().optional()]),
  taxDocumentationStatuses: z.array(accountTaxDocumentationStatusSchema),
  taxDocumentationTypes: z.array(accountTaxDocumentationTypeSchema),
  taxDocumentationOpenIssues: z.array(kycTaxDocumentationIssueTypeSchema),
  tinValidationStatuses: z.array(tinValidationStatusSchema),
});

export type AccountsFilterDrawerFormFields = z.infer<
  typeof accountsFilterDrawerFormFieldSchema
>;
