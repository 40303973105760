import { UrlFilterParams } from "@taxbit-dashboard/commons";

import {
  TransfersTableParams,
  transfersTableParamsSchema,
} from "../../../api/transfers/transfersApiTypes";

const validateTransfersUrlParams = (
  params: UrlFilterParams<TransfersTableParams>
): TransfersTableParams => {
  return transfersTableParamsSchema.parse(params);
};

export default validateTransfersUrlParams;
