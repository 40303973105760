import TimedToast from "./TimedToast";
import FixedPositionNotificationsWrapper from "../components/FixedPositionNotificationsWrapper";
import useDashboardStore from "../store/useDashboardStore";

const ToastNotifications: React.FC = () => {
  const toasts = useDashboardStore((state) => state.toasts);

  if (toasts.length === 0) {
    return undefined;
  }

  return (
    <FixedPositionNotificationsWrapper
      aria-live="polite"
      aria-atomic="true"
      padding="none l l l"
      gap="s"
      direction="column"
    >
      {toasts.map((toast) => (
        <TimedToast key={toast.uuid} {...toast} />
      ))}
    </FixedPositionNotificationsWrapper>
  );
};

export default ToastNotifications;
