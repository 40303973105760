import { useAuth0 } from "@auth0/auth0-react";
import {
  DashboardAppRoutePath,
  useOrganizationPrefixedNavigate,
} from "@taxbit-dashboard/commons";
import { Flex } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import TopNavigationActionMenuDivider from "./TopNavigationActionMenuDivider";
import TopNavigationActionMenuItem from "./TopNavigationActionMenuItem";

export enum TopNavigationActionMenuItemsTrackingId {
  HelpCenterButton = "top-navigation-help-center-button",
  SettingsButton = "top-navigation-settings-button",
  LogOutButton = "top-navigation-log-out-button",
}

export const HELP_CENTER_LINK =
  "https://taxbitsupport.zendesk.com/hc/en-us/categories/25452765315085-Enterprise-Tax";

const TopNavigationActionMenuItems: React.FC = () => {
  const { prefixedNavigate } = useOrganizationPrefixedNavigate();
  const { logout } = useAuth0();

  const openHelpCenter = useCallback(() => {
    window.open(HELP_CENTER_LINK, "_blank");
  }, []);

  return (
    <>
      <TopNavigationActionMenuItem
        onClick={openHelpCenter}
        trackingId={TopNavigationActionMenuItemsTrackingId.HelpCenterButton}
        label="Help Center"
        rightIcon="external-link"
        leftIcon="help-circle"
      />
      <TopNavigationActionMenuItem
        label="Settings"
        leftIcon="settings"
        trackingId={TopNavigationActionMenuItemsTrackingId.SettingsButton}
        onClick={() =>
          prefixedNavigate({ to: DashboardAppRoutePath.AccountSettings })
        }
      />
      <TopNavigationActionMenuDivider />
      <Flex padding="none none xs none">
        <TopNavigationActionMenuItem
          onClick={() => {
            void logout({
              logoutParams: {
                federated: true,
                returnTo: window.location.origin,
              },
            });
          }}
          trackingId={TopNavigationActionMenuItemsTrackingId.LogOutButton}
          label="Log Out"
          leftIcon="log-out"
        />
      </Flex>
    </>
  );
};

export default TopNavigationActionMenuItems;
