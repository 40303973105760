/**
 * Returns a new object with all of the key/value pairs of the given object that match the given filter function.
 *
 * @param object An object to map over
 * @param filterFn Predicate check for each key/value pair. Arguments are (value, key).
 *
 * @returns A new object with all the keys/value pairs from the original object that result in a `true` value from the filter fn
 */
function filterObject<
  TKey extends PropertyKey,
  TInput extends Record<TKey, unknown>,
  TValue extends TInput[keyof TInput],
  TOutput extends Partial<TInput>,
>(object: TInput, filterFn: (value: TValue, key: TKey) => boolean) {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) =>
      filterFn(value as TValue, key as TKey)
    )
  ) as TOutput;
}

export default filterObject;
