import { Dropdown } from "@taxbit-private/cosmic";
import styled from "styled-components";

export enum YearDropdownTrackingId {
  YearDropdown = "year-dropdown-tax-forms",
}

type YearDropdownProps = {
  years: string[];
  currentYear: string;
  setCurrentYear: (year: string) => void;
};

const YearDropdown: React.FC<YearDropdownProps> = ({
  years,
  currentYear,
  setCurrentYear,
}) => {
  return (
    <ContainerWithMaxWidth>
      <Dropdown
        onChange={setCurrentYear}
        options={years}
        value={currentYear}
        getOptionLabel={(value: string) => value}
        getOptionKey={(value: string) => value}
        trackingId={YearDropdownTrackingId.YearDropdown}
        size="small"
      />
    </ContainerWithMaxWidth>
  );
};

const ContainerWithMaxWidth = styled.div(
  ({ theme }) => `
  max-width: ${theme.measure.xxs};
`
);

export default YearDropdown;
