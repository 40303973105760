import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

const renderStringOrPlaceholder = (
  value?: string,
  placeholder = COSMIC_VALUE_PLACEHOLDER
) => {
  return value || placeholder;
};

export default renderStringOrPlaceholder;
