import { OAuthError, useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";
import { useCallback } from "react";

import useLoginWithReturnTo from "./navigation/hooks/useLoginWithReturnTo";
import { UserPermissions } from "./store/auth/authSliceModels";
import useDashboardStore from "./store/useDashboardStore";
import UserPermission from "./UserPermission";
import { deserializeScopeClaim } from "./utils/scopeClaimUtils";

const allPermissions = Object.values(UserPermission);

const useGetTokenDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const loginWithReturnTo = useLoginWithReturnTo();

  return useCallback(async () => {
    try {
      return await getAccessTokenSilently({
        detailedResponse: true,
      });
    } catch (e) {
      if ((e as OAuthError)?.error === "login_required") {
        /**
         * While we only have single-tenant environments, redirect directly to
         * the Auth0 login process instead of the login screen. This just saves
         * us the trouble of typing in an email when the input doesn't
         * actually matter.
         */
        void loginWithReturnTo();

        // The user isn't authenticated, and we're now navigating the user
        // to the login view. We'll never resolve the promise returned from this
        // function since we can't provide a valid access token and don't want to throw
        // an error since that would likely trigger an error boundary. We know
        // the user is on their way to the log in again, which should be sufficient.
        return new Promise<GetTokenSilentlyVerboseResponse>(() => {});
      }
      throw e;
    }
  }, [getAccessTokenSilently, loginWithReturnTo]);
};

export enum CustomClaimName {
  /**
   * The key we use to store our own internal company key on the access token.
   * This is configured via Auth0, and this string must be updated if it is ever changed.
   */
  CompanyId = "https://taxbit.com/company-id",
  /**
   * The key we use to store our own internal organization key on the access token.
   * This is configured via Auth0, and this string must be updated if it is ever changed.
   */
  OrganizationId = "https://taxbit.com/organization-id",
  OrganizationName = "https://taxbit.com/organization-name",
}

/**
 * This retrieves an access token (a JWT), decodes the access token, and stores parsed information from the
 * token in the global store for use throughout the app. The access token is then returned. This hook must
 * be used within a descendant of an Auth0Provider.
 *
 * Auth0's getAccessTokenSilently will return the access token from its cache if it already exists and
 * hasn't expired. If the access token expires, it will try to retrieve a new one
 * by using refresh tokens. Because we may receive a new access token with an updated
 * scope string (permissions), it's important to use this module whenever trying to retrieve
 * an access token so that the user permissions we hold in the store always reflect the latest reality.
 *
 * Ideally, we wouldn't have to decode the access token since the frontend should treat
 * the access token as an opaque string (access tokens are intended for the backend). Instead,
 * we would be able to access all information on the identity token, which is intended for
 * frontend usage. Unfortunately, Auth0 makes it burdensome to add user permissions
 * within an organization* to the identity token. Ongoing discussion is happeni ng on the Auth0
 * discussion board (https://community.auth0.com/t/how-to-add-user-permissions-to-id-token/88714/7)
 * and in emails with an Auth0 technical account manager.
 */
const useGetAccessTokenAndUpdateAuthState = () => {
  const getTokenDetails = useGetTokenDetails();
  const setUserPermissions = useDashboardStore(
    (store) => store.setUserPermissions
  );
  const setCompanyId = useDashboardStore((store) => store.setCompanyId);
  const setOrganizationId = useDashboardStore(
    (store) => store.setOrganizationId
  );
  const setOrganizationName = useDashboardStore(
    (store) => store.setOrganizationName
  );
  const setAuthOrganizationId = useDashboardStore(
    (store) => store.setAuthOrganizationId
  );

  return useCallback(async () => {
    const { access_token: accessToken, id_token: idToken } =
      await getTokenDetails();
    const decodedAccessToken = jwtDecode<{
      scope: string;
      org_id: string;
      [CustomClaimName.OrganizationId]: string;
      [CustomClaimName.CompanyId]: string;
    }>(accessToken);
    const scopes = deserializeScopeClaim(decodedAccessToken.scope);
    const decodedIdToken = jwtDecode<{
      [CustomClaimName.OrganizationName]: string;
    }>(idToken);
    const userPermissions = Object.fromEntries(
      allPermissions.map((permission) => {
        return [permission, scopes.includes(permission)];
      })
    ) as UserPermissions;

    setUserPermissions(userPermissions);
    setCompanyId(decodedAccessToken[CustomClaimName.CompanyId]);
    setOrganizationId(decodedAccessToken[CustomClaimName.OrganizationId]);
    setOrganizationName(decodedIdToken[CustomClaimName.OrganizationName]);
    setAuthOrganizationId(decodedAccessToken.org_id);

    return accessToken;
  }, [
    getTokenDetails,
    setAuthOrganizationId,
    setCompanyId,
    setOrganizationId,
    setOrganizationName,
    setUserPermissions,
  ]);
};

export default useGetAccessTokenAndUpdateAuthState;
