import {
  cosmicPaginationLimitSchema,
  utcDateStringSchema,
  pageNumberSchema,
  withOptionalCatch,
} from "@taxbit-dashboard/commons";
import { TransferStatus, transferStatusSchema } from "@taxbit-dashboard/rest";
import { z } from "zod";

export const transfersTableParamsSchema = z.object({
  limit: cosmicPaginationLimitSchema,
  page: pageNumberSchema,
  startDate: withOptionalCatch(utcDateStringSchema.optional()),
  endDate: withOptionalCatch(utcDateStringSchema.optional()),
  transferStatuses: withOptionalCatch(z.array(transferStatusSchema).optional()),
});

export type TransfersTableParams = z.infer<typeof transfersTableParamsSchema>;

export const transferStatusesMap: Record<TransferStatus, string> = {
  [TransferStatus.Matched]: "Matched",
  [TransferStatus.MissingData]: "Missing Transfer Data",
  [TransferStatus.NotMatched]: "Not Matched",
};
