import { EligibilityDataItem, EligibilityStatus } from "@taxbit-dashboard/rest";
import { Body, Modal } from "@taxbit-private/cosmic";
import pluralize from "pluralize";
import { useMemo } from "react";

import useEligibilityOverrideConfirmationModal, {
  getEligibilityOverrideConfirmationModalTrackingIds,
} from "./useEligibilityOverrideConfirmationModal";
import { OverrideEligibilityAction } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

type AddToScopeConfirmationModalProps = {
  onClose: () => void;
  isOpen: boolean;
  eligibilityItems: EligibilityDataItem[];
};

const AddToScopeConfirmationModal = ({
  onClose,
  isOpen,
  eligibilityItems,
}: AddToScopeConfirmationModalProps) => {
  const action = OverrideEligibilityAction.Add;
  const accountIds = eligibilityItems.map((item) => item.accountId);
  const { isLoading, handleSubmit } = useEligibilityOverrideConfirmationModal({
    onClose,
    action,
    items: eligibilityItems,
  });

  const { eligibleAccounts, noDataAccounts } = useMemo(() => {
    return eligibilityItems.reduce(
      (acc, item) => {
        if (
          item.status === EligibilityStatus.Added ||
          item.status === EligibilityStatus.Eligible
        ) {
          return { ...acc, eligibleAccounts: acc.eligibleAccounts + 1 };
        } else if (
          (!item.taxYearTotalTaxableTransactionCount ||
            item.taxYearTotalTaxableTransactionCount === 0) &&
          !item.isFormData
        ) {
          return { ...acc, noDataAccounts: acc.noDataAccounts + 1 };
        }

        return acc;
      },
      { eligibleAccounts: 0, noDataAccounts: 0 }
    );
  }, [eligibilityItems]);

  return (
    <Modal
      title="Add To Scope"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        getEligibilityOverrideConfirmationModalTrackingIds(action).CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId:
          getEligibilityOverrideConfirmationModalTrackingIds(action)
            .CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Continue",
        trackingId:
          getEligibilityOverrideConfirmationModalTrackingIds(action)
            .SubmitButton,
        isLoading,
        loadingText: "Adding",
        variant: "button-primary",
        onClick: handleSubmit,
      }}
    >
      <Body>{`You have selected ${accountIds.length} ${pluralize(
        "account",
        accountIds.length
      )} to add to scope, making them eligible. Please confirm you want to continue.`}</Body>
      {(eligibleAccounts > 0 || noDataAccounts > 0) && (
        <Body>
          <br />
          Please note:
          <ul>
            {eligibleAccounts > 0 && (
              <li>
                {eligibleAccounts} selected{" "}
                {pluralize("account", eligibleAccounts)}{" "}
                {pluralize("is", eligibleAccounts)} already eligible
              </li>
            )}
            {noDataAccounts > 0 && (
              <li>
                {noDataAccounts} selected {pluralize("account", noDataAccounts)}{" "}
                {pluralize("has", noDataAccounts)} no data to report
              </li>
            )}
          </ul>
        </Body>
      )}
    </Modal>
  );
};

export default AddToScopeConfirmationModal;
