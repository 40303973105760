import {
  AccountOwnerIssue,
  KycTaxDocumentType,
  KycTaxDocumentationIssueStatus,
} from "@taxbit-dashboard/rest";
import { Button, ContentSpinner, Flex } from "@taxbit-private/cosmic";
import { useState } from "react";

import { accountOwnerIssueTypeToDisplayNameMap } from "./accountOwnerIssueTypeToDisplayNameMap";
import { IssueDetailsModal } from "./IssueDetailsModal";
import KycBadge from "./KycBadge";
import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";

type IssueProps = {
  issue: AccountOwnerIssue;
  taxDocumentType: KycTaxDocumentType;
  addressString?: string;
};

export enum IssueDetailsTrackingId {
  IssueDetailsButton = "detailsBtn",
  IssueDetailsLoadingSpinner = "detailsLoadingSpinner",
}

export const IssueDetails: React.FC<IssueProps> = ({
  issue,
  taxDocumentType,
  addressString,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const issueTypeDescription =
    accountOwnerIssueTypeToDisplayNameMap[issue.issueType];
  const { isLoading } = useAccountOwnerTaxDocumentContext();
  const issueStatus = issue.resolvedAt
    ? KycTaxDocumentationIssueStatus.Resolved
    : KycTaxDocumentationIssueStatus.Open;

  return isLoading ? (
    <ContentSpinner
      trackingId={IssueDetailsTrackingId.IssueDetailsLoadingSpinner}
    />
  ) : (
    <Flex direction="row" gap="s">
      <Button
        variant="anchor-primary-inline"
        trackingId={IssueDetailsTrackingId.IssueDetailsButton}
        label={issueTypeDescription}
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <KycBadge taxStatus={issueStatus} />
      <IssueDetailsModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        issue={issue}
        taxDocumentType={taxDocumentType}
        addressString={addressString}
      />
    </Flex>
  );
};
