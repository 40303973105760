import { PageContextSlice, PageContextPageKey } from "./pageContextSliceModel";
import { DashboardStateSlice } from "../dashboardStoreTypes";

const createPageContextSlice: DashboardStateSlice<PageContextSlice> = (
  set
) => ({
  scrollPositions: {},
  setScrollPosition: (key, position) =>
    set(({ scrollPositions }) => ({
      scrollPositions: { ...scrollPositions, [key]: position },
    })),
  backHrefs: {},
  setBackHref: (key, href) =>
    set(({ backHrefs }) => ({
      backHrefs: { ...backHrefs, [key]: href },
    })),
  resetPageContext: (keys) =>
    set(({ backHrefs, scrollPositions }) => {
      const resetMap = keys
        ? (Object.fromEntries(keys.map((key) => [key, undefined])) as Partial<
            Record<PageContextPageKey, undefined>
          >)
        : undefined;

      return {
        backHrefs: resetMap
          ? {
              ...backHrefs,
              ...resetMap,
            }
          : {},
        scrollPositions: resetMap
          ? {
              ...scrollPositions,
              ...resetMap,
            }
          : {},
      };
    }),
});

export default createPageContextSlice;
