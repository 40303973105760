import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const companyUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  connectionStrategy: z.string().min(1),
});

export type CompanyUser = z.infer<typeof companyUserSchema>;

export const getCurrentCompanyUserResponseSchema =
  getPublicApiSuccessSchema(companyUserSchema);

export type GetCurrentCompanyUserResponse = z.infer<
  typeof getCurrentCompanyUserResponseSchema
>;

export const resetMfaForCompanyUserSchema = z.object({
  companyUserId: z.string(),
});

export type ResetMfaForCompanyUser = z.infer<
  typeof resetMfaForCompanyUserSchema
>;

export const updateCurrentCompanyUserSchema = z.object({
  oldName: z.string(),
  newName: z.string().min(1),
});

export type UpdateCurrentCompanyUserName = z.infer<
  typeof updateCurrentCompanyUserSchema
>;

export const updateCurrentCompanyUserResponseSchema =
  getPublicApiSuccessSchema(companyUserSchema);

export type UpdateCurrentCompanyUserNameResponse = z.infer<
  typeof updateCurrentCompanyUserResponseSchema
>;

export const getCompanyUsersResponseSchema = getPublicApiSuccessSchema(
  z.array(companyUserSchema)
);

const roleSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  description: z.string().min(1),
});

export type Role = z.infer<typeof roleSchema>;

export const getRolesResponseSchema = getPublicApiSuccessSchema(
  z.array(roleSchema)
);

export type GetRolesResponse = z.infer<typeof getRolesResponseSchema>;

export const organizationMemberSchema = z.object({
  companyUser: companyUserSchema,
  roleIds: z.array(roleSchema.shape.id),
});

export type OrganizationMember = z.infer<typeof organizationMemberSchema>;

export const getOrganizationMembersResponseSchema = getPublicApiSuccessSchema(
  z.array(organizationMemberSchema)
);

export type GetOrganizationMembersResponse = z.infer<
  typeof getOrganizationMembersResponseSchema
>;

export const inviterSchema = z.object({
  name: z.string(),
});
export type Inviter = z.infer<typeof inviterSchema>;

export const inviteeSchema = z.object({
  email: z.string().email(),
});
export type Invitee = z.infer<typeof inviteeSchema>;

export const invitationResponseSchema = z.object({
  id: z.string().min(1),
  inviter: inviterSchema,
  invitee: inviteeSchema,
  invitationUrl: z.string().url(),
  createdAt: z.string().datetime(),
  expiresAt: z.string().datetime(),
  // Ideally the data should always have at least one role and, if users were managed through
  // the TaxBit dashboard only, that would be the case. However, there is a possibility that a TaxBit
  // employee incorrectly adds a user through the Auth0 management dashboard and fails to assign
  // roles to the user. In this case, we'd rather not break the user experience.
  roles: z.array(roleSchema.shape.id),
});

export type InvitationResponse = z.infer<typeof invitationResponseSchema>;

export const getInvitationsResponseSchema = getPublicApiSuccessSchema(
  z.array(invitationResponseSchema)
);

export type GetInvitationsResponse = z.infer<
  typeof getInvitationsResponseSchema
>;

export type Invitation = Omit<
  z.infer<typeof invitationResponseSchema>,
  "createdAt" | "expiresAt"
> & {
  createdAt: Date;
  expiresAt: Date;
};

export const inviteUserSchema = invitationResponseSchema
  .pick({
    inviter: true,
    invitee: true,
  })
  .extend({
    roles: invitationResponseSchema.shape.roles.nonempty(),
  });

export type InviteUser = z.infer<typeof inviteUserSchema>;

const inviteUserResponseSchema = invitationResponseSchema.pick({ id: true });

export const inviteUserToOrganizationResponseSchema = getPublicApiSuccessSchema(
  inviteUserResponseSchema
);

export const updateOrganizationMemberRolesSchema = z.string().array();

export type UpdateOrganizationMemberRoles = z.infer<
  typeof updateOrganizationMemberRolesSchema
>;

export const removeUserSchema = z.object({
  remove: z.string().array(),
});

export type RemoveUser = z.infer<typeof removeUserSchema>;

export const organizationSchema = z.object({
  authOrganizationId: z.string().min(1),
  companyId: uuidSchema,
  id: uuidSchema,
  name: z.string().min(1),
  companyName: z.string(),
});

export type Organization = z.infer<typeof organizationSchema>;

export const companySchema = z.object({
  companyId: uuidSchema,
  companyName: z.string().min(1),
  authOrganizationId: z.string().min(1),
});

export type Company = z.infer<typeof companySchema>;

export const publicOrganizationSchema = organizationSchema.pick({
  authOrganizationId: true,
  name: true,
});

/**
 * This is a slimmed down organization type that is available through
 * our unauthenticated endpoints. Authenticated endpoints will return
 * a more robust set of data for each organization.
 */
export type UnauthenticatedOrganization = z.infer<
  typeof publicOrganizationSchema
>;

export const getOrganizationsByCompanyUserEmailResponseSchema =
  getPublicApiSuccessSchema(z.array(publicOrganizationSchema));

export type GetOrganizationsByCompanyUserEmailResponse = z.infer<
  typeof getOrganizationsByCompanyUserEmailResponseSchema
>;

export const getCurrentCompanyUserOrganizationsResponseSchema =
  getPublicApiSuccessSchema(z.array(organizationSchema));
