import { useQuery } from "@tanstack/react-query";

import createQueryMetaObject from "./createQueryMetaObject";
import DashboardQueryKey from "./DashboardQueryKey";
import unwrapPublicApiWrappedQuery from "./unwrapPublicApiWrappedQuery";
import useTaxBitRest from "../useTaxBitRest";

export const useGetCurrentCompanyUserOrganizations = () => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.CurrentCompanyUserOrganizations],
    () => restSdk.currentCompanyUserOrganizations.get(),
    {
      ...createQueryMetaObject(
        restSdk.currentCompanyUserOrganizations.buildPath()
      ),
      // Turn off refetching entirely, as it's unlikely we'll ever
      // have stale organization data during a single session.
      staleTime: Number.POSITIVE_INFINITY,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
