import { UrlFilterParams } from "@taxbit-dashboard/commons";
import { O } from "ts-toolbelt";

import {
  FormsTableParams,
  formsTableParams,
} from "../../../../api/information-reporting/forms/formsApiTypes";
import { IrFormTypeDateParams } from "../../../../api/information-reporting/irApiTypes";

const validateFormsTableUrlParams = (
  params: UrlFilterParams<FormsTableParams>,
  formsParams: O.Optional<IrFormTypeDateParams>
): FormsTableParams & O.Optional<IrFormTypeDateParams> => {
  return { ...formsTableParams.parse(params), ...formsParams };
};

export default validateFormsTableUrlParams;
