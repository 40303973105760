import { TransfersTableParams } from "./transfersApiTypes";

/**
 * This function is used to build API url params for transfers table
 * from local query params. It returns `transfersMetadataApiParams`
 * which are used to build metadata request url params and
 * `transfersApiParams` which are used to build transfers request url params.
 */
const getTransfersApiUrlParams = ({
  limit,
  page,
  startDate,
  endDate,
  transferStatuses,
}: TransfersTableParams) => {
  const filtersApiUrlParams = {
    "filters[datetime][$gte]": startDate,
    "filters[datetime][$lte]": endDate,
    "filters[status]": transferStatuses,
  };

  return {
    transfersApiParams: {
      "page[limit]": `${limit}`,
      "page[offset]": `${(page - 1) * limit}`,
      ...filtersApiUrlParams,
    },
    transfersMetadataApiParams: filtersApiUrlParams,
  };
};

export default getTransfersApiUrlParams;
