import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
  FormItem1099KAggregateValues,
} from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { Big } from "big.js";
import { useMemo } from "react";

import useFormatUsdValue from "../../../../utils/useFormatUsdValue";

const calculateFormDataTotalPaymentsAmount = (
  formSpecificFields: FormItem1099KAggregateValues
): string => {
  const {
    januaryAmount,
    februaryAmount,
    marchAmount,
    aprilAmount,
    mayAmount,
    juneAmount,
    julyAmount,
    augustAmount,
    septemberAmount,
    octoberAmount,
    novemberAmount,
    decemberAmount,
  } = formSpecificFields;

  return new Big(januaryAmount)
    .add(februaryAmount)
    .add(marchAmount)
    .add(aprilAmount)
    .add(mayAmount)
    .add(juneAmount)
    .add(julyAmount)
    .add(augustAmount)
    .add(septemberAmount)
    .add(octoberAmount)
    .add(novemberAmount)
    .add(decemberAmount)
    .toString();
};

const useForm1099KEligibilityTableColumns = () => {
  const formatUsdValue = useFormatUsdValue();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        label: "Payments Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099kIngested
          ) {
            return formatUsdValue(
              formSpecificFields.taxYearTotalAdjustedReceivedQuantity.toString()
            );
          }

          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs1099kFormData
          ) {
            return formatUsdValue(
              calculateFormDataTotalPaymentsAmount(formSpecificFields)
            );
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
    ],
    [formatUsdValue]
  );
};

export default useForm1099KEligibilityTableColumns;
