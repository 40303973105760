import React, { PropsWithChildren, createContext, useContext } from "react";

import useDeleteFileUploader from "./useDeleteFileUploader";

type DeleteFileUploaderContext =
  | ReturnType<typeof useDeleteFileUploader>
  | undefined;

const DeleteFileUploaderContext =
  createContext<DeleteFileUploaderContext>(undefined);

export const useDeleteFileUploaderContext = () => {
  const context = useContext(DeleteFileUploaderContext);
  if (!context) {
    throw new Error(
      "useDeleteFileUploaderContext must be used within a DeleteFileUploaderProvider"
    );
  }
  return context;
};

export const DeleteFileUploaderProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const deleteFileUploader = useDeleteFileUploader();

  return (
    <DeleteFileUploaderContext.Provider value={deleteFileUploader}>
      {children}
    </DeleteFileUploaderContext.Provider>
  );
};
