import { UrlFilterParams } from "@taxbit-dashboard/commons";

import {
  NotificationsPageParams,
  notificationsPageParamsSchema,
} from "../../../api/notifications/notificationsApiTypes";

const validateNotificationsPageUrlParams = (
  params: UrlFilterParams<NotificationsPageParams>
): NotificationsPageParams => {
  return notificationsPageParamsSchema.parse(params);
};

export default validateNotificationsPageUrlParams;
