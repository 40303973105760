import { getPageLimitApiParams, mapObject } from "@taxbit-dashboard/commons";
import { GetEligibilityDataParams } from "@taxbit-dashboard/rest";

import { EligibilityDataParams } from "./eligibleUsersApiTypes";
import { mapDashboardFormTypeToApiFormType } from "../irApiTypes";

const getEligibilityParams = ({
  limit,
  page,
  formDate,
  formType,
  statuses,
  formStatuses,
  accountValidationsEligibilityStatuses,
  ...searches
}: EligibilityDataParams): GetEligibilityDataParams => {
  const searchParams = mapObject(searches, (key, value) => [
    `filters[${key}]`,
    value,
  ]);

  return {
    "filters[document_date]": formDate,
    "filters[document_type]": mapDashboardFormTypeToApiFormType(formType),
    "filters[status]": statuses,
    "filters[form_status]": formStatuses,
    "filters[account_validations_eligibility_status]":
      accountValidationsEligibilityStatuses,
    ...getPageLimitApiParams({ page, limit }),
    ...searchParams,
  };
};

export default getEligibilityParams;
