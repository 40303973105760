import { useMutation } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  isDefined,
  logError,
  useInvalidateQueries,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  AccountOwnerCuringsIssueType,
  AccountOwnerId,
} from "@taxbit-dashboard/rest";

export const useCreateAccountOwnerCuring = () => {
  const restSdk = useTaxBitRest();
  const invalidateQueries = useInvalidateQueries();

  return useMutation(
    (params: {
      accountOwnerId?: AccountOwnerId;
      issueType: AccountOwnerCuringsIssueType;
      target?: string;
      note?: string;
    }) => {
      if (!isDefined(params.accountOwnerId) || !isDefined(params.target)) {
        throw new Error(
          "Missing required parameters for creating account owner curing."
        );
      }

      return restSdk.accountOwnerCurings.post({
        requestData: {
          accountOwnerId: params.accountOwnerId,
          issueType: params.issueType,
          target: params.target,
          note: params.note,
        },
      });
    },
    {
      onSuccess: (res) => {
        void invalidateQueries([
          [DashboardQueryKey.AccountOwnerCurings],
          [DashboardQueryKey.AccountOwnerDetails, res.data.accountOwnerId],
        ]);
      },
      onError: (error) => {
        logError({ message: "Failed to create account owner curing.", error });
      },
    }
  );
};
