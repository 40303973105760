import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItemGainLossAggregateValuesSchema = z.object({
  taxYearTotalGainsItems: z.number(),
  taxYearTotalProceeds: z.number(),
  taxYearTotalCostBasis: z.number(),
  taxYearTotalMissingCostBasisItems: z.number(),
});

export const formItemGainLossSummaryAggregateValuesSchema =
  formItemGainLossAggregateValuesSchema.extend({
    documentTypeDataSource: z.literal(
      EligibilityDocumentTypeDataSource.GainLossSummaryFormData
    ),
  });

export type FormItemGainLossSummaryAggregateValues = z.infer<
  typeof formItemGainLossSummaryAggregateValuesSchema
>;

export const formItemGainLossPdfValuesSchema =
  formItemGainLossAggregateValuesSchema.extend({
    documentTypeDataSource: z.literal(
      EligibilityDocumentTypeDataSource.GainLossPdfFormData
    ),
  });
export type FormItemGainLossPdfAggregateValues = z.infer<
  typeof formItemGainLossPdfValuesSchema
>;
