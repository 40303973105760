import {
  useGetCurrentCompanyUserOrganizations,
  useOrganizationId,
} from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import { useGetOrganizationDevSettings } from "../../../api/devSettingsApi";

const useDeveloperSettings = () => {
  const {
    data: organizations = [],
    isLoading: isUsersOrgsLoading,
    isError: isUsersOrgsError,
  } = useGetCurrentCompanyUserOrganizations();
  const currentOrganizationId = useOrganizationId();
  const {
    data: devSettings,
    isLoading: isDevSettingsLoading,
    isError: isDevSettingsError,
  } = useGetOrganizationDevSettings();

  const currentOrganization = useMemo(
    () =>
      organizations.find(
        (organization) => organization.id === currentOrganizationId
      ),
    [organizations, currentOrganizationId]
  );
  return {
    devSettings,
    currentOrganization,
    currentOrganizationId,
    isError: isUsersOrgsError || isDevSettingsError,
    isLoading: isUsersOrgsLoading || isDevSettingsLoading,
  };
};

export default useDeveloperSettings;
