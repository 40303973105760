import { zodResolver } from "@hookform/resolvers/zod";
import {
  getBrowserDateFromUtcDateString,
  getUtcDateStringFromBrowserDate,
  hasDefinedValues,
} from "@taxbit-dashboard/commons";
import { useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import useFormsFiltersFormFieldSchema, {
  FormsFiltersFormFields,
} from "./useFormsFiltersFormFieldSchema";
import { FormsTableParams } from "../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../context/useIrForms";

const getFormDataFromUrlParams = ({
  statuses,
  types,
  startDate,
  endDate,
}: FormsTableParams): Omit<
  FormsFiltersFormFields,
  "accountExternalIdsFilter"
> => ({
  statuses: statuses ?? [],
  types: types ?? [],
  dateRange: [
    getBrowserDateFromUtcDateString(startDate),
    getBrowserDateFromUtcDateString(endDate),
  ],
});

const formDataEmptyValues: FormsFiltersFormFields = {
  statuses: [],
  types: [],
  dateRange: [undefined, undefined],
  accountExternalIdsFilter: "",
};

const useFormsFiltersDrawerForm = () => {
  const {
    urlParams,
    setFilterParams,
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
  } = useIrFormsContext();

  const defaultValues = useMemo(
    () => ({
      ...getFormDataFromUrlParams(urlParams),
      accountExternalIdsFilter,
    }),
    [urlParams, accountExternalIdsFilter]
  );

  const formsFiltersFormFieldSchema = useFormsFiltersFormFieldSchema();

  const formMethods = useForm<FormsFiltersFormFields>({
    resolver: zodResolver(formsFiltersFormFieldSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>, callback: () => void) => {
      void formMethods.handleSubmit(
        ({
          accountExternalIdsFilter: newAccountExternalIdsFilter,
          dateRange: [startDate, endDate],
          statuses,
          types,
        }) => {
          setFilterParams((draft) => {
            draft.statuses = statuses;
            draft.types = types;
            draft.startDate =
              startDate && getUtcDateStringFromBrowserDate(startDate, true);
            draft.endDate =
              endDate && getUtcDateStringFromBrowserDate(endDate, false);
          });

          setAccountExternalIdsFilter(newAccountExternalIdsFilter);
          callback();
        }
      )(e);
    },
    [formMethods, setFilterParams, setAccountExternalIdsFilter]
  );

  useEffect(() => {
    formMethods.reset({
      ...getFormDataFromUrlParams(urlParams),
      accountExternalIdsFilter,
    });
  }, [urlParams, formMethods, accountExternalIdsFilter]);

  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof FormsFiltersFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useFormsFiltersDrawerForm;
