import { MakeGenerics } from "@tanstack/react-location";
import {
  DashboardFormType,
  dashboardFormTypeSchema,
} from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";

import {
  toUpdatedDashboardFormType,
  supportedFormItemTypesFromDashboardFormTypes,
} from "../../../api/form-items/formItemsApiTypes";
import { IrFormTypeDateParams } from "../../../api/information-reporting/irApiTypes";

export type IrSearchLocationParams = MakeGenerics<{
  Search: {
    // All keys become strings because we use URLSearchParams
    // to deserialize url query params.
    [key in keyof IrFormTypeDateParams]?: string;
  };
}>;

/**
 * @deprecated This hook provides parsed data from the shared `irFormTypesByYear` feature flag. All
 * new form flagging should use individual feature flags and `validateFormFeatureFlagParams` instead.
 */
const validateIrParams = (
  params: IrSearchLocationParams["Search"],
  irFormTypesByYear: Record<string, DashboardFormType[]>,
  shouldUseFormItemsForms = false
): IrFormTypeDateParams | undefined => {
  const validatedFormType = dashboardFormTypeSchema.safeParse(params.formType);
  const validatedFormDate = fourDigitYearSchema.safeParse(params.formDate);
  if (
    validatedFormType.success &&
    validatedFormDate.success &&
    irFormTypesByYear[validatedFormDate.data]?.includes(
      validatedFormType.data
    ) &&
    (!shouldUseFormItemsForms ||
      supportedFormItemTypesFromDashboardFormTypes.has(validatedFormType.data))
  ) {
    return {
      formType: shouldUseFormItemsForms
        ? toUpdatedDashboardFormType(validatedFormType.data)
        : validatedFormType.data,
      formDate: validatedFormDate.data,
    };
  }

  return undefined;
};

export default validateIrParams;
