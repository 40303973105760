import {
  usePageScroll,
  calculatePageNumberForUpdatedPageSize,
} from "@taxbit-dashboard/commons";
import { PaginationFooter, CosmicPageSize } from "@taxbit-private/cosmic";

import useTransfersTableData from "./table/useTransfersTableData";

export enum TransfersPaginationFooterTrackingIds {
  Pagination = "transfer-activity-pagination",
}

const TransfersPaginationFooter: React.FC = () => {
  const { scrollPageToTop } = usePageScroll();
  const { urlParams, setPaginationParams, metadata, shouldDisableControls } =
    useTransfersTableData();

  const currentPageSize = urlParams.limit;
  const currentPage = urlParams.page;

  const setCurrentPage = (nextPage: number) => {
    setPaginationParams((draft) => {
      draft.page = nextPage;
    });

    scrollPageToTop();
  };

  const setPageSize = (nextPageSize: CosmicPageSize) => {
    const page = calculatePageNumberForUpdatedPageSize({
      currentPageSize,
      currentPage,
      nextPageSize,
    });

    setPaginationParams((draft) => {
      draft.page = page;
      draft.limit = nextPageSize;
    });
  };

  return (
    <PaginationFooter
      isDisabled={shouldDisableControls}
      currentPage={currentPage}
      pageSize={currentPageSize}
      totalCount={metadata?.totalCount ?? 0}
      setCurrentPage={setCurrentPage}
      setPageSize={setPageSize}
      trackingId={TransfersPaginationFooterTrackingIds.Pagination}
    />
  );
};

export default TransfersPaginationFooter;
