import { useMatch, MakeGenerics } from "@tanstack/react-location";
import { AccountId } from "@taxbit-dashboard/rest";

type RouteMatchGeneric = MakeGenerics<{
  Params: {
    accountId?: AccountId;
  };
}>;

/**
 * Pulls the `accountId` param from the current account details route. If used
 * outside of an account details route, the `accountId` will be undefined.
 */
const useAccountId = () => {
  const {
    params: { accountId },
  } = useMatch<RouteMatchGeneric>();

  return accountId;
};

export default useAccountId;
