import {
  Flex,
  NAVIGATION_SIDEBAR_WIDTH,
  getMediaQueryForBelowBreakpoint,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

const getSidebarWidth = () => {
  const currentZendeskView = window.zE?.("webWidget:get", "display");
  const isZendeskOpen =
    currentZendeskView && !["hidden", "launcher"].includes(currentZendeskView);

  // Zendesk widget is 342px wide + 16px from the left
  return isZendeskOpen ? "358px" : NAVIGATION_SIDEBAR_WIDTH;
};

/**
 * Fixed position flex container for toasts and `BulkActionBar`.
 * This wrapper is used to ensure that components are always displayed at the bottom right of the Nav Bar.
 */
const FixedPositionNotificationsWrapper = styled(Flex)(
  ({ theme }) => `
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: calc(100% - ${getSidebarWidth()});
  z-index: ${theme.zIndex.toast};

  ${getMediaQueryForBelowBreakpoint(theme, "mobile")} {
    width: 100%;
  }
`
);

export default FixedPositionNotificationsWrapper;
