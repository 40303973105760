import { isDefined } from "@taxbit-dashboard/commons";
import {
  EligibilityDocumentTypeDataSource,
  EligibilityDataItem,
} from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useFormatUsdValue from "../../../../utils/useFormatUsdValue";

const useFormRmdStatementEligibilityTableColumns = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const formatUsdValue = useFormatUsdValue();

  return useMemo(
    (): TableColumns<EligibilityDataItem> => [
      {
        key: "formSpecificFields",
        id: "formSpecificFieldsRmdDate",
        label: "RMD Date",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs5498FormData
          ) {
            return formatDateTime({ date: formSpecificFields.rmdDate });
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
      {
        key: "formSpecificFields",
        id: "formSpecificFieldsRmdAmount",
        label: "RMD Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (formSpecificFields) => {
          if (
            formSpecificFields &&
            formSpecificFields.documentTypeDataSource ===
              EligibilityDocumentTypeDataSource.Irs5498FormData &&
            isDefined(formSpecificFields.rmdAmount)
          ) {
            return formatUsdValue(formSpecificFields.rmdAmount.toString());
          }

          return COSMIC_VALUE_PLACEHOLDER;
        },
      },
      {
        key: "fmvAmount",
        label: "FMV Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (fmvAmount) => {
          return formatUsdValue(fmvAmount);
        },
      },
    ],
    [formatDateTime, formatUsdValue]
  );
};

export default useFormRmdStatementEligibilityTableColumns;
