import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import useAccountIdsChip from "./useAccountIdsChip";
import useAccountValidationsEligibilityStatusChip from "./useAccountValidationsEligibilityStatusChip";
import useEligibilityFormStatusChip from "./useEligibilityFormStatusChip";
import useEligibilityStatusChip from "./useEligibilityStatusChip";
import useSearchFilterChips from "./useSearchFilterChips";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

const useEligibilityFilterChips = () => {
  const { shouldDisableControls } = useEligibilityDataContext();
  const searchFilterChips = useSearchFilterChips();
  const statusChips = useEligibilityStatusChip();
  const accountValidationsEligibilityStatusChips =
    useAccountValidationsEligibilityStatusChip();
  const formStatusChips = useEligibilityFormStatusChip();
  const accountIdsChip = useAccountIdsChip();

  return useMemo(
    () =>
      filterToDefinedValues([
        ...searchFilterChips,
        ...statusChips,
        ...accountValidationsEligibilityStatusChips,
        ...formStatusChips,
        accountIdsChip,
      ]).map((chip) => ({
        ...chip,
        isDisabled: shouldDisableControls,
        removeButtonProps: {
          ...chip.removeButtonProps,
          label: "Remove filter",
        },
      })),
    [
      accountIdsChip,
      accountValidationsEligibilityStatusChips,
      formStatusChips,
      searchFilterChips,
      shouldDisableControls,
      statusChips,
    ]
  );
};

export default useEligibilityFilterChips;
