import { useEffect } from "react";

import { BannerConfig } from "../store/notification/notificationSliceModels";
import useDashboardStore from "../store/useDashboardStore";

/**
 * This hook sets a page banner in global state when the component mounts,
 * and clears it when the component unmounts.
 */
const useLocalPageBanner = ({
  shouldShow,
  bannerConfig,
}: {
  shouldShow: boolean;
  bannerConfig: BannerConfig;
}) => {
  const setBanner = useDashboardStore((store) => store.setBanner);
  const clearBanner = useDashboardStore((store) => store.clearBanner);

  useEffect(() => {
    if (shouldShow) {
      setBanner(bannerConfig);
    }

    return () => clearBanner();
  }, [shouldShow, bannerConfig, clearBanner, setBanner]);
};

export default useLocalPageBanner;
