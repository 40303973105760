import {
  useDashboardFeatureFlags,
  useGetPayers,
} from "@taxbit-dashboard/commons";
import { KycTaxDocumentType, PayerId } from "@taxbit-dashboard/rest";
import { useCallback, useMemo } from "react";

import { AccountOwnerInformation } from "../../../../../api/account/accountApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { isWForm } from "../../../../../utils/isWForm";
import maskData from "../../../../../utils/maskData";

const useGetGeneralInformation = () => {
  const {
    hasTaxDocumentationAccess,
    hasDac7AccountAccess,
    hasRetirementAccountAccess,
  } = useDashboardFeatureFlags();

  const { data: payers, isFetching: isPayersLoading } = useGetPayers();

  const {
    shouldUnmaskValue,
    account,
    accountOwner,
    isLoading: isTaxDocumentationLoading,
    taxDocumentations,
    isValidTaxDocumentationStatus,
  } = useAccountOwnerTaxDocumentContext();

  const getPayerInfo = useCallback(
    (payerId?: PayerId) => {
      if (payerId) {
        const payer = payers?.find((p) => p.payerId === payerId);
        return { payerName: payer?.payerName };
      }

      const payer = payers?.find((p) => p.isDefault);
      return { payerName: payer?.payerName };
    },
    [payers]
  );

  const getMostRecentFairMarketValue = useCallback(() => {
    if (!account?.yearEndFairMarketValue) {
      return undefined;
    }

    const sortedFairMarketValues = account.yearEndFairMarketValue.sort(
      (a, b) => b.yearEnd - a.yearEnd
    );

    return sortedFairMarketValues[0].fairMarketValue;
  }, [account]);

  const generalInformation: AccountOwnerInformation | undefined =
    useMemo(() => {
      if (!account || !accountOwner) {
        return undefined;
      }

      const { payerName } = getPayerInfo(account.payerId);
      const fairMarketValue = hasRetirementAccountAccess
        ? getMostRecentFairMarketValue()
        : undefined;

      // If the user does not has access to retirement accounts, we want to nullify the retirement account data
      // otherwise, we want to keep the data as is
      const retirementAccountData = hasRetirementAccountAccess
        ? {}
        : {
            accountType: undefined,
            establishmentDate: undefined,
            yearEndFairMarketValue: undefined,
          };

      const filteredAccount = {
        ...account,
        ...retirementAccountData,
      };

      const accountOwnerWithFilteredAccount = {
        accountOwner: {
          ...accountOwner,
          accounts: [filteredAccount],
        },
        fairMarketValue,
        payerName,
      };

      if (!hasTaxDocumentationAccess) {
        return accountOwnerWithFilteredAccount;
      }

      const wForm = taxDocumentations?.find((doc) => isWForm(doc));

      if (isValidTaxDocumentationStatus && isWForm(wForm)) {
        return {
          accountOwner: {
            ...accountOwner,
            pii: {
              ...accountOwner.pii,
              name: wForm.name,
              address:
                wForm.documentType === KycTaxDocumentType.W9
                  ? wForm.address
                  : wForm.permanentAddress,
            },
            accounts: [filteredAccount],
          },
          taxClassification: wForm.taxClassification,
          fairMarketValue,
          payerName,
        };
      }

      const dps = taxDocumentations?.find(
        (doc) => doc.documentType === KycTaxDocumentType.Dps
      );

      if (
        hasDac7AccountAccess &&
        dps?.documentType === KycTaxDocumentType.Dps
      ) {
        return {
          accountOwner: {
            ...accountOwner,
            pii: {
              ...accountOwner.pii,
              name: dps.name,
              address: dps.address,
              birthDate: dps.dateOfBirth,
              birthCity: dps.cityOfBirth,
              birthCountry: dps.countryOfBirth,
              financialAccountId: shouldUnmaskValue
                ? dps.financialAccountIdentifier
                : undefined,
              financialAccountIdMasked: maskData(
                dps.financialAccountIdentifier
              ),
              financialAccountHolderName: dps.financialAccountName,
              businessRegistrationNumber: shouldUnmaskValue
                ? dps.businessRegistrationNumber
                : undefined,
              businessRegistrationNumberMasked: maskData(
                dps.businessRegistrationNumber
              ),
              businessRegistrationCountry: dps.businessRegistrationCountry,
            },
            accounts: [filteredAccount],
          },
          documentType: KycTaxDocumentType.Dps,
          fairMarketValue,
          payerName,
        };
      }

      return accountOwnerWithFilteredAccount;
    }, [
      account,
      accountOwner,
      getPayerInfo,
      getMostRecentFairMarketValue,
      hasRetirementAccountAccess,
      hasTaxDocumentationAccess,
      isValidTaxDocumentationStatus,
      taxDocumentations,
      hasDac7AccountAccess,
      shouldUnmaskValue,
    ]);

  const isLoading = isTaxDocumentationLoading || isPayersLoading;

  return {
    isLoading,
    generalInformation,
  };
};

export default useGetGeneralInformation;
