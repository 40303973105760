import { AssetCode } from "@taxbit-private/data-models-v2";
import { StringType } from "@taxbit-private/type-wrappers";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const assetCodeSchema = z
  .string()
  .transform((assetCode) => assetCode as AssetCode);

export type AssetId = StringType<"AssetId">;
export const assetIdSchema = z
  .string()
  .transform((assetId) => assetId as AssetId);

export enum AssetType {
  Crypto = "Crypto",
  Fiat = "Fiat",
  Unknown = "Unknown",
}

export const assetSchema = z.object({
  id: assetIdSchema,
  code: assetCodeSchema,
  name: z.string().optional(),
  type: z.nativeEnum(AssetType),
});
export type Asset = z.infer<typeof assetSchema>;

export const getAssetSchema = getPublicApiSuccessSchema(assetSchema);

export const assetAmountSchema = z.object({
  amount: z.string(),
  asset: assetSchema,
});
export type AssetAmount = z.infer<typeof assetAmountSchema>;

export const lineItemSchema = z.object({
  assetAmount: assetAmountSchema,
  rates: assetAmountSchema.array().optional(),
});
export type LineItem = z.infer<typeof lineItemSchema>;

export const inventorySummaryItemSchema = z.object({
  totalAmount: assetAmountSchema.optional(),
  totalCost: assetAmountSchema.optional(),
});
export type InventorySummaryItem = z.infer<typeof inventorySummaryItemSchema>;

export const getInventorySummaryResponseSchema = getPublicApiSuccessSchema(
  inventorySummaryItemSchema.array()
);
export type GetInventorySummaryResponse = z.infer<
  typeof getInventorySummaryResponseSchema
>;
