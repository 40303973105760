import { UserPermission, useUserPermission } from "@taxbit-dashboard/commons";
import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { ActionButton, Flex } from "@taxbit-private/cosmic";

import useGenerateTaxDocumentationForm from "./useGenerateTaxDocumentationForm";

type Props = {
  documentType?: KycTaxDocumentType;
};

export enum DownloadTaxDocumentationFormTrackingId {
  DownloadButton = "download-tax-documentation-form-download-button",
  GenerateButton = "download-tax-documentation-form-generate-button",
}

const DownloadTaxDocumentationFormButton: React.FC<Props> = ({
  documentType,
}) => {
  const {
    shouldShowDownloadButton,
    onDownloadButtonClick,
    onGenerateButtonClick,
    isDisabled,
    isLoading,
  } = useGenerateTaxDocumentationForm(documentType ?? KycTaxDocumentType.W9);

  const canReadTins = useUserPermission(UserPermission.ReadTins);
  return (
    <Flex direction="column">
      {shouldShowDownloadButton ? (
        <ActionButton
          iconName="download"
          label="Download"
          trackingId={DownloadTaxDocumentationFormTrackingId.DownloadButton}
          onClick={onDownloadButtonClick}
        />
      ) : (
        <ActionButton
          iconName="file-plus"
          label="Generate"
          onClick={onGenerateButtonClick}
          isLoading={isLoading}
          isDisabled={isDisabled}
          trackingId={DownloadTaxDocumentationFormTrackingId.GenerateButton}
          {...(!canReadTins && {
            tooltipProps: {
              content:
                "Not enabled for your role. Contact your Admin for more information.",
              appendTo: "body",
              isInteractive: false,
            },
          })}
        />
      )}
    </Flex>
  );
};

export default DownloadTaxDocumentationFormButton;
