import { irFormStatusSchema, irFormTypeSchema } from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";
import { z } from "zod";

import {
  accountExternalIdsFilterSchema,
  refineAccountExternalIdsFilterSchema,
} from "../../shared/accountExternalIdsFilterSchema";

const formsFiltersFormFieldSchema = z.object({
  statuses: z.array(irFormStatusSchema),
  types: z.array(irFormTypeSchema),
  dateRange: z.tuple([z.date().optional(), z.date().optional()]),
  accountExternalIdsFilter: accountExternalIdsFilterSchema,
});

export type FormsFiltersFormFields = z.infer<
  typeof formsFiltersFormFieldSchema
>;

const useFormsFiltersFormFieldSchema = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();
  return useMemo(
    () =>
      formsFiltersFormFieldSchema.superRefine(
        ({ accountExternalIdsFilter }, ctx) => {
          const refineIssues = refineAccountExternalIdsFilterSchema({
            accountExternalIdsFilter,
            formatValue: formatWholeQuantity,
          });

          for (const issue of refineIssues) {
            ctx.addIssue(issue);
          }
        }
      ),
    [formatWholeQuantity]
  );
};

export default useFormsFiltersFormFieldSchema;
