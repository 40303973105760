import InvitationStatus from "./InvitationStatus";
import { InvitationWithRoles } from "../../../../api/invitationsApi";

const mapInvitationToInvitationRowData = (invitation: InvitationWithRoles) => {
  const {
    inviter: { name: inviterName },
    invitee: { email: inviteeEmail },
    expiresAt,
    ...otherData
  } = invitation;

  return {
    ...otherData,
    inviterName,
    inviteeEmail,
    expiresAt,
    status:
      expiresAt > new Date()
        ? InvitationStatus.Pending
        : InvitationStatus.Expired,
  };
};

export type InvitationRowData = ReturnType<
  typeof mapInvitationToInvitationRowData
>;

export default mapInvitationToInvitationRowData;
