import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import useTransfersDateRangeFilterChip from "./useTransfersDateRangeFilterChip";
import useTransferStatusFilterChips from "./useTransferStatusFilterChips";
import useTransfersTableData from "../../table/useTransfersTableData";

const useTransfersFilterChips = () => {
  const { shouldDisableControls } = useTransfersTableData();
  const dateRangeFilterChip = useTransfersDateRangeFilterChip();
  const transfersStatusFilterChip = useTransferStatusFilterChips();

  return useMemo(
    () =>
      filterToDefinedValues([
        dateRangeFilterChip,
        ...transfersStatusFilterChip,
      ]).map((chip) => ({
        ...chip,
        // Disable all chips along with other controls
        isDisabled: shouldDisableControls,
        // Give all chips a consistent removal label
        removeButtonProps: {
          ...chip.removeButtonProps,
          label: "Remove filter",
        },
      })),
    [dateRangeFilterChip, transfersStatusFilterChip, shouldDisableControls]
  );
};

export default useTransfersFilterChips;
