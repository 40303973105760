import AccountDetails from "./AccountDetails";
import { AccountOwnerTaxDocumentProvider } from "../../../hooks/useGetAccountOwnerTaxDocumentData";

const AccountDetailsPage: React.FC = () => {
  return (
    <AccountOwnerTaxDocumentProvider>
      <AccountDetails />
    </AccountOwnerTaxDocumentProvider>
  );
};

export default AccountDetailsPage;
