import { navigateToUrl } from "@taxbit-dashboard/commons";
import { TransactionsTemplateType, FileType } from "@taxbit-dashboard/rest";
import { Button, Flex } from "@taxbit-private/cosmic";
import { RhfDropdown } from "@taxbit-private/cosmic-react-hook-form";
import { useWatch } from "react-hook-form";
import styled from "styled-components";

import { transactionsTemplateTypeLabelMap } from "../../../../api/files/filesApiTypes";
import { FileUploaderFormFields } from "../../context/fileUploaderFormTypes";
import useTemplatesFeatureFlagData from "../../context/useTemplatesFeatureFlagData";
import { getCsvTemplateDownloadUrl } from "../../csvTemplateData";

export enum TransactionsTemplateStepTrackingIds {
  TemplateDropdown = "transactions-template-dropdown",
  DownloadTemplate = "transactions-template-download-button",
}

const TransactionsTemplateStep: React.FC = () => {
  const { transactionsTemplates } = useTemplatesFeatureFlagData();

  const [currentTemplate] = useWatch<
    FileUploaderFormFields,
    ["transactionsTemplateType"]
  >({
    name: ["transactionsTemplateType"],
  });

  const templateUrl = getCsvTemplateDownloadUrl({
    fileType: FileType.Transactions,
    templateType: currentTemplate,
  });

  return (
    <Flex gap="m" growItems={true}>
      <RhfDropdown<
        FileUploaderFormFields,
        "transactionsTemplateType",
        TransactionsTemplateType
      >
        getOptionKey={(value) => value}
        getOptionLabel={(value) => transactionsTemplateTypeLabelMap[value]}
        getOptionValue={(value) => value}
        label="Template"
        isLabelHidden={true}
        name="transactionsTemplateType"
        options={transactionsTemplates}
        placeholder="Select an option"
        trackingId={TransactionsTemplateStepTrackingIds.TemplateDropdown}
      />
      <TemplateButton
        label="Download Template"
        trackingId={TransactionsTemplateStepTrackingIds.DownloadTemplate}
        isDisabled={!templateUrl}
        variant="button-secondary"
        onClick={() => {
          if (templateUrl) {
            navigateToUrl(templateUrl);
          }
        }}
      />
    </Flex>
  );
};

const TemplateButton = styled(Button)`
  max-width: max-content;
`;

export default TransactionsTemplateStep;
