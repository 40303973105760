import { useCallback, createRef } from "react";

/**
 * Creates a single ref for the top-level scroll container used
 * for all page scrolling behavior. We can't do this inside a hook
 * because then new refs would be created with every hook instance.
 */
export const pageScrollElementRef = createRef<HTMLDivElement>();

/**
 * All standard pages (those that live alongside the left navigation sidebar) shared a single
 * scroll container. This hook provides element for this scroll container and a helper to
 * scroll it to a given top offset and reset the left offset to 0.
 */
const usePageScroll = () => {
  const scrollPageToOffset = useCallback((offset: number) => {
    pageScrollElementRef.current?.scrollTo({
      top: offset,
      left: 0,
    });
  }, []);

  return {
    pageScrollElementRef,
    scrollPageToOffset,
    scrollPageToTop: () => scrollPageToOffset(0),
  };
};

export default usePageScroll;
