import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import isSameUtcDay from "./date/isSameUtcDay";

const useGetDateRangeChipLabel = () => {
  const { formatDateRange, formatDateTime } = useCosmicLocalizationContext();
  return useCallback(
    ({ startDate, endDate }: { startDate?: string; endDate?: string }) => {
      const isSameDay = isSameUtcDay(startDate, endDate);

      return isSameDay
        ? formatDateTime({ date: startDate })
        : formatDateRange({ startDate, endDate });
    },
    [formatDateRange, formatDateTime]
  );
};

export default useGetDateRangeChipLabel;
