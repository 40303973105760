import {
  ActionBarWrapper,
  useDashboardFeatureFlags,
  UserPermission,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { BulkActionBar } from "@taxbit-private/cosmic";
import { useState } from "react";

import DeleteFormsConfirmationModal from "./DeleteFormsConfirmationModal";
import DownloadFormsConfirmationModal from "./DownloadFormsConfirmationModal";
import { useIrFormsContext } from "../../context/useIrForms";

export enum IrFormsActionBarTrackingId {
  Delete = "ir-forms-delete-action-btn",
  Download = "ir-forms-download-action-btn",
  ActionBar = "ir-forms-action-bar",
}

const FormsActionBar = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const { selectedForms, toggleAllSelectedForms, shouldDisableControls } =
    useIrFormsContext();

  const { hasDeleteFormsAccess } = useDashboardFeatureFlags();

  const hasDeleteTaxFormsPermission = useUserPermission(
    UserPermission.DeleteTaxForms
  );

  const canDeleteForms = hasDeleteFormsAccess && hasDeleteTaxFormsPermission;

  const buttonProps = [
    ...(canDeleteForms
      ? [
          {
            label: "Delete Forms",
            iconName: "trash",
            onClick: () => setIsDeleteModalOpen(true),
            trackingId: IrFormsActionBarTrackingId.Delete,
            key: "delete",
          },
        ]
      : []),
    {
      label: "Download Forms",
      iconName: "download",
      onClick: () => setIsDownloadModalOpen(true),
      trackingId: IrFormsActionBarTrackingId.Download,
      key: "download",
    },
  ];

  return (
    <>
      {selectedForms.length > 0 && !shouldDisableControls && (
        <ActionBarWrapper>
          <BulkActionBar
            selectedCount={selectedForms.length}
            trackingId={IrFormsActionBarTrackingId.ActionBar}
            onClear={() => toggleAllSelectedForms(false)}
            buttonsProps={buttonProps}
          />
        </ActionBarWrapper>
      )}
      {isDeleteModalOpen && (
        <DeleteFormsConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
      {isDownloadModalOpen && (
        <DownloadFormsConfirmationModal
          isOpen={isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
        />
      )}
    </>
  );
};

export default FormsActionBar;
