import { AssetAmount, LineItem } from "@taxbit-dashboard/rest";
import { Big } from "big.js";

import sumUpFees from "./sumUpFees";

const calculateFeeFiatValue = (
  fees: LineItem[] | undefined
): AssetAmount | undefined => {
  const feeRates = fees?.[0]?.rates?.[0];

  const feeSummed = sumUpFees(fees)?.amount;

  if (feeSummed && feeRates) {
    return {
      asset: feeRates.asset,
      amount: new Big(feeRates.amount).times(feeSummed).toString(),
    };
  }

  return undefined;
};

export default calculateFeeFiatValue;
