import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "@tanstack/react-location";
import { FallbackView, useDashboardStore } from "@taxbit-dashboard/commons";
import { useEffect } from "react";

import useCallbackAuth0Error from "./useCallbackAuth0Error";
import AppLevelSpinner from "../app/navigation/AppLevelSpinner";

/**
 * This component receives callback state from Auth0 and redirects appropriately.
 * This allows users to return to their intended page when they are prompted to login
 * after following a bookedmarked URL.
 */
const Callback: React.FC = () => {
  const returnTo = useDashboardStore((store) => store.returnTo);
  const { error } = useAuth0();
  const setReturnTo = useDashboardStore((store) => store.setReturnTo);
  const navigate = useNavigate();
  const errorFallbackUiProps = useCallbackAuth0Error();

  useEffect(() => {
    if (returnTo && !error) {
      setReturnTo(undefined);
      navigate({ to: returnTo });
    }
  }, [error, navigate, returnTo, setReturnTo]);

  return errorFallbackUiProps ? (
    <FallbackView {...errorFallbackUiProps} />
  ) : (
    <AppLevelSpinner trackingId="callback-spinner" />
  );
};

export default Callback;
