import { MakeGenerics } from "@tanstack/react-location";
import { YearsByIrFormType } from "@taxbit-dashboard/commons";
import { dashboardFormTypeSchema } from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";

import { IrFormTypeDateParams } from "../../../api/information-reporting/irApiTypes";

export type IrFormFeatureFlagSearchLocationParams = MakeGenerics<{
  Search: {
    // All keys become strings because we use URLSearchParams
    // to deserialize url query params.
    [key in keyof IrFormTypeDateParams]?: string;
  };
}>;

const validateFormFeatureFlagParams = (
  params: IrFormFeatureFlagSearchLocationParams["Search"],
  yearsByIrFormType: YearsByIrFormType
): IrFormTypeDateParams | undefined => {
  const validatedFormType = dashboardFormTypeSchema.safeParse(params.formType);
  const validatedFormDate = fourDigitYearSchema.safeParse(params.formDate);
  if (
    validatedFormType.success &&
    validatedFormDate.success &&
    yearsByIrFormType[validatedFormType.data]?.includes(validatedFormDate.data)
  ) {
    return {
      formType: validatedFormType.data,
      formDate: validatedFormDate.data,
    };
  }

  return undefined;
};

export default validateFormFeatureFlagParams;
