import {
  renderExcludedSpan,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  Account,
  AccountTaxDocumentationStatus,
  TinValidationStatus,
} from "@taxbit-dashboard/rest";
import {
  Anchor,
  Badge,
  ColumnKey,
  Flex,
  TableColumns,
} from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  taxClassificationLabelMap,
  taxDocumentationStatusLabelMap,
  taxDocumentationTypeLabelMap,
  tinStatusLabelMap,
} from "../../../../api/accounts/accountsApiTypes";

const TIN_STATUS_HELP_LINK =
  "https://taxbitsupport.zendesk.com/hc/en-us/articles/15216115742605";

enum AccountsTableColumnsTrackingIds {
  TinStatusHelpIcon = "tin-validation-help-icon",
  TinStatusHelpIconLink = "tin-validation-help-icon-learn-more-link",
  DocTypeHelpIcon = "doc-type-help-icon",
  DocStatusHelpIcon = "doc-status-help-icon",
  DocIssuesHelpIcon = "doc-issues-help-icon",
  TaxClassHelpIcon = "tax-class-help-icon",
}

const renderTinStatus = (status: TinValidationStatus) => {
  const variant = (() => {
    switch (status) {
      case TinValidationStatus.InvalidData:
      case TinValidationStatus.TinMismatch:
      case TinValidationStatus.TinNotIssued: {
        return "danger";
      }
      case TinValidationStatus.Pending:
      case TinValidationStatus.CountryCodeMismatch:
      case TinValidationStatus.TinMismatchForeignIndicia:
      case TinValidationStatus.TinTypeMismatch: {
        return "warning";
      }
      case TinValidationStatus.Valid: {
        return "success";
      }
      case TinValidationStatus.Unprocessed:
      case TinValidationStatus.ForeignIndicia: {
        return "secondary";
      }
      default: {
        return undefined;
      }
    }
  })();

  return variant ? (
    <Badge
      variant={variant}
      label={tinStatusLabelMap[status]}
      shouldTransformLabel={false}
    />
  ) : (
    COSMIC_VALUE_PLACEHOLDER
  );
};

export type AccountsColumnKey = ColumnKey<Account>;

const useBaseAccountsTableColumns = () => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Account> => [
      {
        key: "name",
        label: "Name",
        shouldTruncate: true,
        minWidth: 128,
        renderCell: renderExcludedSpan,
      },
      {
        key: "created",
        label: "Account Created",
        isContentFullWidth: true,
        renderCell: (date) =>
          formatDateTime({
            date,
          }),
      },
      {
        key: "externalId",
        label: "Account ID",
        shouldDisableSorting: true,
        shouldTruncate: true,
        // 261px = approximately 33 chars, the average length.
        minWidth: 261,
        renderCell: renderExcludedSpan,
      },
      {
        key: "email",
        label: "Email",
        shouldDisableSorting: true,
        shouldTruncate: true,
        minWidth: 180,
        renderCell: renderExcludedSpan,
      },
    ],
    [formatDateTime]
  );
};

const tinColumns: TableColumns<Account> = [
  {
    key: "tinValidationStatus",
    label: "TIN Status",
    renderCell: renderTinStatus,
    shouldDisableSorting: true,
    isContentFullWidth: true,
    helpIconProps: {
      trackingId: AccountsTableColumnsTrackingIds.TinStatusHelpIcon,
      tooltipProps: {
        content: (
          <>
            For more info, learn more{" "}
            <Anchor
              variant="anchor-primary-inline"
              label="here"
              href={TIN_STATUS_HELP_LINK}
              trackingId={AccountsTableColumnsTrackingIds.TinStatusHelpIconLink}
            />
            .
          </>
        ),
      },
    },
  },
  {
    key: "maskedTaxId",
    label: "TIN",
    shouldDisableSorting: true,
    isContentFullWidth: true,
    renderCell: renderExcludedSpan,
  },
];

const renderTaxDocumentationStatus = (
  status: AccountTaxDocumentationStatus
) => {
  const variant = (() => {
    switch (status) {
      case AccountTaxDocumentationStatus.Valid: {
        return "success";
      }
      case AccountTaxDocumentationStatus.Invalid: {
        return "danger";
      }
      case AccountTaxDocumentationStatus.Undocumented: {
        return "secondary";
      }
      default: {
        return undefined;
      }
    }
  })();

  return variant ? (
    <Badge variant={variant} label={taxDocumentationStatusLabelMap[status]} />
  ) : (
    COSMIC_VALUE_PLACEHOLDER
  );
};

/**
 * The tooltips for the tax documentation columns are long enough they don't always fit
 * within the table. We can turn on body positioning because they are text-only, and we
 * ensure they default to a bottom placement to be consistent with the interactive tooltips.
 */
const documentationTooltipProps = {
  appendTo: "body",
  isInteractive: false,
  placement: "bottom",
} as const;

const useTaxDocumentationAccountsTableColumns = (): TableColumns<Account> => {
  const taxDocAccountsTableColumns: TableColumns<Account> = [
    {
      key: "taxDocumentationType",
      label: "Doc. Type",
      shouldDisableSorting: true,
      renderCell: (type) =>
        type ? taxDocumentationTypeLabelMap[type] : COSMIC_VALUE_PLACEHOLDER,
      isContentFullWidth: true,
      helpIconProps: {
        trackingId: AccountsTableColumnsTrackingIds.DocTypeHelpIcon,
        tooltipProps: {
          ...documentationTooltipProps,
          content: (
            <Flex direction="column" gap="s">
              <div>
                W-9: Tax Payer Identification information for U.S. Individuals
                and Entities.
              </div>
              <div>
                W-8BEN: Tax Payer Identification information for Foreign
                Individuals.
              </div>
              <div>
                W-8BEN-E: Tax Payer Identification information for Foreign
                Entities.
              </div>
            </Flex>
          ),
        },
      },
    },
    {
      key: "taxDocumentationStatus",
      label: "Doc. Status",
      shouldDisableSorting: true,
      renderCell: renderTaxDocumentationStatus,
      isContentFullWidth: true,
      helpIconProps: {
        trackingId: AccountsTableColumnsTrackingIds.DocStatusHelpIcon,
        tooltipProps: {
          ...documentationTooltipProps,
          content: (
            <Flex direction="column" gap="s">
              <div>
                Valid: There is a Valid W-9, W-8BEN or W-8BEN-E on file for this
                Account.
              </div>
              <div>
                Invalid: There is a W-9, W-8BEN, or W-8BEN-E on file, but it has
                issues present that need to be cured or recollected for this to
                be considered valid.
              </div>
              <div>
                Undocumented: There is no W-9, W-8BEN, or W-8BEN-E on file for
                this Account.
              </div>
            </Flex>
          ),
        },
      },
    },
    {
      key: "taxClassification",
      label: "Tax Class",
      shouldDisableSorting: true,
      renderCell: (classification) =>
        classification && taxClassificationLabelMap[classification],
      isContentFullWidth: true,
      helpIconProps: {
        trackingId: AccountsTableColumnsTrackingIds.TaxClassHelpIcon,
        tooltipProps: {
          ...documentationTooltipProps,
          content:
            "Tax Classification represents the type of entity this Account is owned by. This information is typically collected via a W-9, W-8BEN, or W-8BEN-E.",
        },
      },
    },
  ];

  return taxDocAccountsTableColumns;
};

const useAccountsTableColumns = () => {
  const { hasTaxDocumentationAccess, shouldHideAccountsTableTinUi } =
    useDashboardFeatureFlags();

  const baseAccountsTableColumns = useBaseAccountsTableColumns();
  const taxDocAccountsTableColumns = useTaxDocumentationAccountsTableColumns();

  return useMemo(
    () => [
      ...baseAccountsTableColumns,
      ...(shouldHideAccountsTableTinUi ? [] : tinColumns),
      ...(hasTaxDocumentationAccess ? taxDocAccountsTableColumns : []),
    ],
    [
      baseAccountsTableColumns,
      taxDocAccountsTableColumns,
      hasTaxDocumentationAccess,
      shouldHideAccountsTableTinUi,
    ]
  );
};

export default useAccountsTableColumns;
