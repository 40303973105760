import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form1099MiscLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form1099Misc),
  rents: z.string().optional(),
  royalties: z.string().optional(),
  otherIncome: z.string().optional(),
  federalTaxWithheld: z.string().optional(),
  fishingBoatProceeds: z.string().optional(),
  medicalAndHealthCarePayments: z.string().optional(),
  directSales5000ConsumerProducts: z.boolean().default(false),
  substitutePayments: z.string().optional(),
  cropInsuranceProceeds: z.string().optional(),
  grossProceedsPaidToAnAttorney: z.string().optional(),
  fishPurchasedForResale: z.string().optional(),
  section409ADeferrals: z.string().optional(),
  excessGoldenParachutePayments: z.string().optional(),
  nonqualifiedDeferredComp: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateIncome: z.string().optional(),
});

export const form1099MiscLineItemWithIdsSchema =
  form1099MiscLineItemSchema.merge(baseFormLineItemWithIdsSchema);
export type Form1099MiscLineItem = z.infer<
  typeof form1099MiscLineItemWithIdsSchema
>;
