import { mapObject } from "@taxbit-dashboard/commons";
import { GetAccountsSort, GetAccountsParams } from "@taxbit-dashboard/rest";

import { AccountsTableParams } from "./accountsApiTypes";

const getAccountsApiParams = ({
  limit,
  page,
  sort,
  startDate,
  endDate,
  tinValidationStatuses,
  taxDocumentationStatuses,
  taxDocumentationTypes,
  taxDocumentationOpenIssues,
  ...searches
}: AccountsTableParams): GetAccountsParams => {
  const searchParams = mapObject(searches, (key, value) => [
    `filters[${key}]`,
    value,
  ]);

  const offset = (page - 1) * limit;

  return {
    "page[limit]": limit,
    "page[offset]": offset,
    "filters[created][$gte]": startDate,
    "filters[created][$lte]": endDate,
    "filters[tin_validation_status]": tinValidationStatuses,
    "filters[tax_documentation_status]": taxDocumentationStatuses,
    "filters[tax_documentation_type]": taxDocumentationTypes,
    "filters[tax_documentation_open_issue]": taxDocumentationOpenIssues,
    // Typescript is struggling to infer this enum type at compile time, even though
    // it works fine in VSCode. TODO: https://taxbit.atlassian.net/browse/TAX-28283
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    "sort": sort as GetAccountsSort,
    ...searchParams,
  };
};

export default getAccountsApiParams;
