import { Outlet, ReactLocation, Router } from "@tanstack/react-location";
import { parse, stringify } from "qs";

import routes from "./routes";

const reactLocation = new ReactLocation({
  /**
   * Uses the qs library to parse all search param values as strings rather than the primitives
   * supported by JSON.parse. Unfortunately, this config must live at the top level. Any usage of
   * other primitives (boolean and number params) will need to be manually parsed, but this seemed
   * like a safer alternative to numerical ids (ex: TINs) being incorrectly parsed into numbers.
   *
   * https://github.com/TanStack/router/discussions/553
   */
  parseSearch: (searchStr) => parse(searchStr, { ignoreQueryPrefix: true }),
  stringifySearch: (searchParams) => stringify(searchParams),
});

const AppRouter: React.FC = () => {
  return (
    <Router location={reactLocation} routes={routes}>
      <Outlet />
    </Router>
  );
};

export default AppRouter;
