import { Form5498LineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItems5498Columns = () => {
  const { formatDateTime, formatQuantity, formatBoolean } =
    useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form5498LineItem> => [
      {
        key: "iraContributions",
        label: "IRA Contributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (iraContributions) => formatQuantity(iraContributions),
      },
      {
        key: "rolloverContributions",
        label: "Rollover Contributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (rolloverContributions) =>
          formatQuantity(rolloverContributions),
      },
      {
        key: "rothIraConversionAmount",
        label: "Roth IRA Conversion Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (rothIraConversionAmount) =>
          formatQuantity(rothIraConversionAmount),
      },
      {
        key: "recharacterizedContributions",
        label: "Recharacterized Contributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (recharacterizedContributions) =>
          formatQuantity(recharacterizedContributions),
      },
      {
        key: "lifeInsuranceCost",
        label: "Life Insurance Cost",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (lifeInsuranceCost) => formatQuantity(lifeInsuranceCost),
      },
      {
        key: "sepContributions",
        label: "SEP Contributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (sepContributions) => formatQuantity(sepContributions),
      },
      {
        key: "simpleContributions",
        label: "Simple Contributions",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (simpleContributions) =>
          formatQuantity(simpleContributions),
      },
      {
        key: "rothIraContributions",
        textAlign: "right",
        label: "Roth IRA Contributions",
        isContentFullWidth: true,
        renderCell: (rothIraContributions) =>
          formatQuantity(rothIraContributions),
      },
      {
        key: "requiredMinimumDistribution",
        label: "Required Minimum Distribution",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (requiredMinimumDistribution) =>
          formatBoolean(requiredMinimumDistribution),
      },
      {
        key: "rmdDate",
        label: "RMD Date",
        isContentFullWidth: true,
        renderCell: (rmdDate) => formatDateTime({ date: rmdDate }),
      },
      {
        key: "rmdAmount",
        label: "RMD Amount",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (rmdAmount) => formatQuantity(rmdAmount),
      },
      {
        key: "postponedLateContribution",
        label: "Postponed Late Contribution",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (postponedLateContribution) =>
          formatQuantity(postponedLateContribution),
      },
      {
        key: "year13B",
        label: "Year 13b",
        isContentFullWidth: true,
      },
      {
        key: "code13C",
        label: "Code 13c",
        isContentFullWidth: true,
      },
      {
        key: "repayments",
        label: "Repayments",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (repayments) => formatQuantity(repayments),
      },
      {
        key: "code14B",
        label: "Code 14b",
        isContentFullWidth: true,
      },
      {
        key: "fmvOfCertainSpecifiedAssets",
        label: "FMV Of Certain Specified Assets",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (fmvOfCertainSpecifiedAssets) =>
          formatQuantity(fmvOfCertainSpecifiedAssets),
      },
      {
        key: "code15B",
        label: "Code 15b",
        isContentFullWidth: true,
      },
    ],
    [formatDateTime, formatQuantity, formatBoolean]
  );
};

export default useFormItems5498Columns;
