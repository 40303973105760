import { useMatch } from "@tanstack/react-location";
import { TransferType } from "@taxbit-dashboard/rest";

import {
  useGetTransferIn,
  useGetTransferOut,
} from "../../../api/transfers/transfersApi";
import { TransfersRouteMeta } from "../routeTypes";

/**
 * Hook to return transfer details query data based on the current route's
 * transfer type meta property.
 */
const useTransferQuery = () => {
  const {
    route: { meta },
  } = useMatch<TransfersRouteMeta>();

  const transferType = meta?.transferType;
  const isTransferIn = transferType === TransferType.TransferIn;
  const isTransferOut = transferType === TransferType.TransferOut;

  const transferInQuery = useGetTransferIn(isTransferIn);
  const transferOutQuery = useGetTransferOut(isTransferOut);

  if (isTransferIn) {
    return transferInQuery;
  }

  if (isTransferOut) {
    return transferOutQuery;
  }

  throw new Error(`Unrecognized transfer type: ${transferType}`);
};

export default useTransferQuery;
