import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { Divider, Box, Flex } from "@taxbit-private/cosmic";

import CbiInformationBanner from "./CbiInformationBanner";
import TransfersFiltersChips from "./filters/chips/TransfersFiltersChips";
import TransfersFilterDrawerToggle from "./filters/TransfersFilterDrawerToggle";
import TransfersTable from "./table/TransfersTable";
import TransfersPaginationFooter from "./TransfersPaginationFooter";

const TransfersView: React.FC = () => {
  const { isCbiIntegratedMember, hasCbiTransfersFilters } =
    useDashboardFeatureFlags();

  return (
    <>
      {/* Removing this part until we enable cost basis movement and tax lots are available in the dashboard  */}
      {/* {!isCbiMember && <CbiWarningBanner />} */}
      {isCbiIntegratedMember && <CbiInformationBanner />}
      <Box>
        <Flex direction="column">
          {hasCbiTransfersFilters && (
            <>
              <Flex padding="m" direction="column" gap="m">
                <Flex gap="m">
                  <TransfersFilterDrawerToggle />
                </Flex>
                <TransfersFiltersChips />
              </Flex>
              <Divider />
            </>
          )}
          <TransfersTable />
          <Divider />
          <TransfersPaginationFooter />
        </Flex>
      </Box>
    </>
  );
};

export default TransfersView;
