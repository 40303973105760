import { Chip, ChipGroup, Button } from "@taxbit-private/cosmic";

import useAccountsFilterChips from "./useAccountsFilterChips";
import useAccountsTableData from "../../useAccountsTableData";

enum AccountsFilterChipsTrackingId {
  ClearAllButton = "clear-all-button",
}

const AccountsFilterChips: React.FC = () => {
  const filterChipProps = useAccountsFilterChips();
  const { clearAllFilters } = useAccountsTableData();

  return filterChipProps.length > 0 ? (
    <ChipGroup>
      {filterChipProps.map((props) => (
        <Chip key={props.trackingId} {...props} />
      ))}
      <Button
        onClick={clearAllFilters}
        label="Clear All"
        trackingId={AccountsFilterChipsTrackingId.ClearAllButton}
        variant="anchor-primary-inline"
      />
    </ChipGroup>
  ) : undefined;
};

export default AccountsFilterChips;
