import { EligibilityStatus } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { getEligibilityStatusLabelMap } from "../../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export const getEligibilityStatusChipTrackingId = (
  status: EligibilityStatus
) => {
  return `irEligibilityFilters-${status}-filter-chip`;
};

const useEligibilityStatusChip = () => {
  const { urlParams, setFilterParams } = useEligibilityDataContext();

  return useMemo(() => {
    return (urlParams.statuses ?? []).map((status) => {
      const documentType = urlParams.formType;
      const statusLabel = getEligibilityStatusLabelMap(documentType)[status];

      return {
        label: `Status: ${statusLabel}`,
        removeButtonProps: {
          "onClick": () => {
            setFilterParams((draft) => {
              const { statuses } = draft;
              draft.statuses = statuses?.filter((s) => s !== status);
            });
          },
          "aria-label": `Remove filter for ${statusLabel}`,
        },
        trackingId: getEligibilityStatusChipTrackingId(status),
      };
    });
  }, [urlParams, setFilterParams]);
};

export default useEligibilityStatusChip;
