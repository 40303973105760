import {
  AccountOwner,
  AccountType,
  KycTaxDocumentType,
  TaxDocumentType,
  TaxDocumentationStatus,
} from "@taxbit-dashboard/rest";

export type AccountOwnerInformation = { accountOwner: AccountOwner } & {
  taxClassification?: string;
  documentType?: KycTaxDocumentType;
  unmaskedTaxId?: string;
  taxDocumentType?: TaxDocumentType;
  kycTaxDocumentationSummary?: TaxDocumentationStatus;
  payerName?: string;
  fairMarketValue?: string;
};

export const accountTypeMap: Record<AccountType, string> = {
  US_IRA_TRADITIONAL: "Traditional",
  US_IRA_ROTH: "Roth",
  US_IRA_SIMPLE: "SIMPLE",
  US_IRA_SEP: "SEP",
};
