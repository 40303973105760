import { datadogRum } from "@datadog/browser-rum";
import {
  defaultDashboardFeatureFlagSet,
  logError,
} from "@taxbit-dashboard/commons";
import { CompanyUser, isProdEnvironment } from "@taxbit-dashboard/rest";
import { LDClientBase, LDEvaluationDetail } from "launchdarkly-js-sdk-common";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

export const initializeLaunchDarkly = () =>
  asyncWithLDProvider({
    clientSideID: isProdEnvironment()
      ? "62fa7f71fa9b0111cb64d7c4"
      : "62fa7f71fa9b0111cb64d7c3",
    flags: defaultDashboardFeatureFlagSet,
    context: {
      kind: "user",
      /**
       * This key is important, it ensurse that each initialized LaunchDarkly session does not increment our
       * total count for any unauthenticated user who opens the app. Following best practice outlined here
       * in the docs: https://docs.launchdarkly.com/sdk/features/user-config#designating-anonymous-users
       */
      key: "unique-anonymous-user-key",
    },
    options: {
      /**
       * Sets up a connection between LaunchDarkly & Datadog RUM so that we can use the RUM flag analysis tools.
       * https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=npm#launchdarkly-integration
       */
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

export const updateLaunchDarklyUser = async (
  { id, email, name }: CompanyUser,
  organizationId: string,
  organizationName: string,
  ldClient: LDClientBase
) => {
  try {
    // LaunchDarkly is supposed to be intelligent enough
    // that if this call fails, it can still operate in a reasonable manner,
    // which is why we don't throw an error.
    // https://launchdarkly.com/blog/designing-for-failure-to-avoid-disaster/
    await ldClient.identify({
      kind: "user",
      key: id,
      email,
      name,
      organizationId,
      organizationName,
    });
  } catch (error) {
    logError({ message: "Unable to update LaunchDarkly user", error });
  }
};
