import { useGetCurrentCompanyUserDownloads } from "@taxbit-dashboard/commons";
import { useEffect, useMemo, useState } from "react";

import DownloadList from "./DownloadList";
import TopNavigationPopover from "../../shared/TopNavigationPopover";

export const DownloadsCenter: React.FC = () => {
  const [seenDownloadHashes, setSeenDownloadHashes] = useState<
    Set<string> | undefined
  >();
  const [hasUnseenDownload, setHasUnseenDownload] = useState(false);

  const { data: downloadData } = useGetCurrentCompanyUserDownloads();

  const currentDownloadHashes = useMemo(
    () =>
      downloadData?.items.map(
        (item) => item.properties.fileDownloadName + item.lastModified
      ),
    [downloadData]
  );

  useEffect(() => {
    if (currentDownloadHashes === undefined) {
      return;
    }

    if (seenDownloadHashes === undefined) {
      setSeenDownloadHashes(new Set(currentDownloadHashes));
    } else {
      const unseenDownloadHashes = currentDownloadHashes.filter(
        (name) => !seenDownloadHashes.has(name)
      );
      if (unseenDownloadHashes.length > 0) {
        setHasUnseenDownload(true);
        setSeenDownloadHashes(
          new Set([...seenDownloadHashes, ...unseenDownloadHashes])
        );
      }
    }
  }, [currentDownloadHashes, seenDownloadHashes]);

  return (
    <TopNavigationPopover
      targetButtonProps={{
        iconName: "download",
        trackingId: "downloads-toggle-button",
        label: "Files",
        onClick: () => setHasUnseenDownload(false),
      }}
      contents={<DownloadList />}
      hasDot={hasUnseenDownload}
    />
  );
};
