import { z } from "zod";

import withOptionalCatch from "../../types/withOptionalCatch";

export const MAX_SEARCH_VALUE_LENGTH = 100;

const searchValueStringSchema = withOptionalCatch(
  z.string().min(1).max(MAX_SEARCH_VALUE_LENGTH).optional()
);

export default searchValueStringSchema;
