import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { AccountTaxDocumentationStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo } from "react";

import { AccountsFilterDrawerFormFields } from "./accountsFilterDrawerFormFieldSchema";
import { taxDocumentationStatusLabelMap } from "../../../../../api/accounts/accountsApiTypes";

const DOCUMENTATION_STATUS_OPTIONS = Object.values(
  AccountTaxDocumentationStatus
).map((status) => ({
  key: status,
  label: taxDocumentationStatusLabelMap[status],
}));

const AccountsTaxDocumentationStatusFilter: React.FC = () => {
  const { hasW8TaxDocumentationAccess } = useDashboardFeatureFlags();

  const options = useMemo(() => {
    if (hasW8TaxDocumentationAccess) {
      return DOCUMENTATION_STATUS_OPTIONS;
    } else {
      return DOCUMENTATION_STATUS_OPTIONS.filter(
        ({ key }) => key !== AccountTaxDocumentationStatus.Invalid
      );
    }
  }, [hasW8TaxDocumentationAccess]);

  return (
    <RhfMultiselect<
      AccountTaxDocumentationStatus,
      Pick<AccountsFilterDrawerFormFields, "taxDocumentationStatuses">,
      "taxDocumentationStatuses",
      (typeof DOCUMENTATION_STATUS_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="taxDocumentationStatuses"
      options={options}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Documentation Status"
      placeholder="All"
      trackingId="accounts-filters-drawer-tax-documentation-status-filter"
    />
  );
};

export default AccountsTaxDocumentationStatusFilter;
