import {
  DashboardAppRoutePath,
  useOrganizationPrefixedNavigate,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  TopNavigation as CosmicTopNavigation,
  TopNavigationActionButtonGroup,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import TopNavigationActionMenu from "./action-menu/TopNavigationActionMenu";
import { TopNavigationActionMenuContextProvider } from "./action-menu/useTopNavigationActionMenu";
import { DownloadsCenter } from "./downloads/download-list/DownloadsCenter";
import useTenantBrandingOverrides from "../../../../hooks/useTenantBrandingOverrides";
import NotificationsCenter from "../../../notifications/center/NotificationsCenter";

const TopNavigation: React.FC = () => {
  const { prefixedNavigate } = useOrganizationPrefixedNavigate();

  const { hasDownloadsAccess, hasNotificationCenterAccess } =
    useDashboardFeatureFlags();

  const { logoElement } = useTenantBrandingOverrides();

  const utilityElements = (() => {
    const actionMenu = (
      <TopNavigationActionMenuContextProvider>
        <TopNavigationActionMenu />
      </TopNavigationActionMenuContextProvider>
    );

    if (hasDownloadsAccess || hasNotificationCenterAccess) {
      return [
        actionMenu,
        <TopNavigationActionButtonGroup>
          {hasDownloadsAccess && !hasNotificationCenterAccess && (
            <DownloadsCenter />
          )}
          {hasNotificationCenterAccess && <NotificationsCenter />}
        </TopNavigationActionButtonGroup>,
      ];
    } else {
      return actionMenu;
    }
  })();

  return (
    <TopBar
      trackingId="top-navigation"
      appTitle="Information Reporting"
      logoElement={logoElement}
      onLogoClick={() => prefixedNavigate({ to: DashboardAppRoutePath.Launch })}
      utilityElements={utilityElements}
    />
  );
};

const TopBar = styled(CosmicTopNavigation)(
  ({ theme }) => `
  position: fixed;
  z-index: ${theme.zIndex.sideBarNav};
`
);

export default TopNavigation;
