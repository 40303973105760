import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

import renderExcludedSpan from "./renderExcludedSpan";

const renderExcludedSpanDefaultToCosmicPlaceholder = (
  contents?: string | number
): string | number | JSX.Element | undefined =>
  contents ? renderExcludedSpan(contents) : COSMIC_VALUE_PLACEHOLDER;

export default renderExcludedSpanDefaultToCosmicPlaceholder;
