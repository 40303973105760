import { useGetDateRangeChipLabel } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import useTransfersTableData from "../../table/useTransfersTableData";

export enum TransferDateRangeFilterChipTrackingIds {
  DateRangeChip = "transfers-table-date-range-filter-chip",
}

const useTransfersDateRangeFilterChip = () => {
  const {
    urlParams: { startDate, endDate },
    setFilterParams,
  } = useTransfersTableData();

  const getChipLabel = useGetDateRangeChipLabel();

  return useMemo(() => {
    if (!startDate && !endDate) {
      return undefined;
    }

    return {
      label: getChipLabel({ startDate, endDate }),
      removeButtonProps: {
        "onClick": () =>
          setFilterParams((draft) => {
            draft.startDate = undefined;
            draft.endDate = undefined;
          }),
        "aria-label": "Remove filter for transfer date",
      },
      trackingId: TransferDateRangeFilterChipTrackingIds.DateRangeChip,
    };
  }, [endDate, getChipLabel, setFilterParams, startDate]);
};

export default useTransfersDateRangeFilterChip;
