import * as React from "react";
const SvgZeroHashLogo = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 1417.3 273", style: {
  enableBackground: "new 0 0 1417.3 273"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{clip-path:url(#SVGID_00000049190597783602880310000000139402863646922653_);}\n	.st1{clip-path:url(#SVGID_00000173874059413967503920000018339260869393371043_);fill:#FFFFFF;}\n	.st2{clip-path:url(#SVGID_00000049190597783602880310000000139402863646922653_);fill:#FFFFFF;}\n"), /* @__PURE__ */ React.createElement("g", { id: "ZeroHash_Wordmark_Logo_Black_RGB_00000038412753010448911060000007897653141836474269_" }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("rect", { id: "SVGID_1_", x: 19.1, y: 19, width: 1379, height: 234.9 })), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_00000126284734569544023250000011855365774440731025_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_1_", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#SVGID_00000126284734569544023250000011855365774440731025_)"
} }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("rect", { id: "SVGID_00000041296231731962921840000013157299892752681101_", x: 19.1, y: 19, width: 1379, height: 234.9 })), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_00000086666901204624343750000001938410196014358155_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_00000041296231731962921840000013157299892752681101_", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M219.7,152.8h99.5 c-1.8-28.5-22.2-45.7-48.1-45.7C244.1,107.1,224.6,126.4,219.7,152.8 M348.1,177.8H219.7c4.2,24.6,20.7,46,56.5,46 c19.2,0,41.5-7.2,56.2-18l12.3,21.9c-14.4,11.4-40.6,22.6-69.1,22.6c-61,0-86-42.7-86-84.8c0-47.8,32.8-83.9,81.5-83.9 c44.8,0,77.9,30.4,77.9,81.5C349,169.6,348.4,173.9,348.1,177.8" }), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M165.7,221.7H62.5 L182.3,46.5v-19H24.6v28.3h115.6l-121,175.5V250h163C175.3,241.3,169.9,231.8,165.7,221.7" }), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M481.5,163.5 c0,0.6-0.1,1.1-0.1,1.7c0,47.2,34.9,84.8,84.8,84.8c49.6,0,84.4-37.6,84.4-84.8c0-46.3-34.9-84.5-84.4-84.5c0,0,0,0,0,0v27h0 c31.1,0,55.3,26.3,55.3,57.4c0,31.7-24.2,58.1-55.3,58.1s-55.4-26.3-55.4-58.1v-1.7H481.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M454.5,80.7 c-40.1,0-72.6,32.5-72.6,72.6V250h27.2v-98c0-24.3,19.7-44.1,44.1-44.1h57.7V80.7H454.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M1092.3,215.3 l20.7-15.9c9.9,18,26.1,25.5,40,25.5c17.7,0,28.6-10.5,28.6-22.2c0-9.6-6.3-15.9-14.7-20.4c-9.9-5.4-29.8-12.6-40-18 c-17.1-9.3-24.6-24.4-24.6-39.1c0-25.6,20.1-45.1,52.6-45.1c19.5,0,40,8.1,51.1,25.6l-18.3,17.4c-9.3-13.5-22.5-18.6-34.6-18.6 c-15.3,0-23.1,9.3-23.1,19.5c0,6.6,3.3,14.1,13.5,18.9c10.8,5.1,26.7,11.1,39.4,17.7c16.8,8.7,27.3,21.3,27.3,40.6 c0,27.1-23.4,49-58,49C1129.5,250.2,1105.5,239.7,1092.3,215.3" }), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M1319.5,80.7 c-19.7,0-37.7,7.3-51.5,19.3V19h-27.2V250h27.2v-19.6v-71c0-28.4,23-51.5,51.5-51.5c28.4,0,51.5,23,51.5,51.5v90.8h27.2v-90.8 C1398.2,115.9,1363,80.7,1319.5,80.7" }), /* @__PURE__ */ React.createElement("path", { style: {
  clipPath: "url(#SVGID_00000086666901204624343750000001938410196014358155_)",
  fill: "#FFFFFF"
}, d: "M1032.8,167.7 c0-31.8-22-58.8-56.3-58.8c-34.3,0-55.7,26.9-55.7,59.1c0,32.1,21.4,59.1,55.7,59.1C1010.8,227.1,1032.8,200.1,1032.8,167.7  M1063,86V250h-28.2v-23.3c-14.1,17.4-34,27.2-57.9,27.2c-47.1,0-82-38-82-87.2c0-49,34.9-84.8,82-84.8 c23.9,0,43.5,10.4,57.9,27.8V86H1063z" })), /* @__PURE__ */ React.createElement("polygon", { style: {
  clipPath: "url(#SVGID_00000126284734569544023250000011855365774440731025_)",
  fill: "#FFFFFF"
}, points: "834.7,27.6  834.7,121.5 723.1,121.5 723.1,27.6 692.7,27.6 692.7,250 723.1,250 723.1,150.3 834.7,150.3 834.7,250 864.8,250 864.8,27.6  " }))));
export default SvgZeroHashLogo;
