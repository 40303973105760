import { Button, Card, Flex, Snippet } from "@taxbit-private/cosmic";
import { useState } from "react";

import EditPersonalInfoModal from "./modals/EditPersonalInfoModal";
import PasswordChangeButton from "./PasswordChangeButton";
import { UsernamePasswordSettingsTrackingId } from "./usernamePasswordSettingsTrackingId";

type Props = {
  name: string;
  email: string;
};

const UsernamePasswordSettingsView: React.FC<Props> = ({ name, email }) => {
  const [
    shouldShowEditPersonalInformationModal,
    setShouldShowEditPersonalInformationModal,
  ] = useState(false);

  return (
    <Flex alignItems="flex-start" padding="l" gap="l" growItems={true}>
      <Card
        title="Personal Information"
        utilityElement={
          <Button
            label="Edit"
            variant="button-secondary"
            size="small"
            trackingId={
              UsernamePasswordSettingsTrackingId.EditPersonalInformationButton
            }
            onClick={() => setShouldShowEditPersonalInformationModal(true)}
          />
        }
      >
        <Flex padding="l" direction="column" gap="xl">
          <Snippet label="Name" content={name} />
          <EditPersonalInfoModal
            isOpen={shouldShowEditPersonalInformationModal}
            onClose={() => setShouldShowEditPersonalInformationModal(false)}
          />
        </Flex>
      </Card>
      <Card title="Security">
        <Flex padding="l" direction="column" gap="xl">
          <Snippet label="Email" content={email} />
          <Snippet label="Password" content={<PasswordChangeButton />} />
          <Snippet label="Multi-factor Authentication" content="Enabled" />
        </Flex>
      </Card>
    </Flex>
  );
};

export default UsernamePasswordSettingsView;
