import { MAX_SEARCH_VALUE_LENGTH } from "@taxbit-dashboard/commons";
import { getEligibilitySearchKeySchema } from "@taxbit-dashboard/rest";
import { Button, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useState } from "react";
import styled from "styled-components";

import { eligibilitySearchKeyLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

const SEARCH_KEY_OPTIONS = getEligibilitySearchKeySchema.options.map((key) => ({
  key,
  label: eligibilitySearchKeyLabelMap[key],
}));
const DEFAULT_OPTION = SEARCH_KEY_OPTIONS[0];

type SearchKeyOption = (typeof SEARCH_KEY_OPTIONS)[number];

export enum EligibilityScopedSearchBarTrackingId {
  SearchBar = "irEligibilityScopedSearchBar",
  SearchButton = "irEligibilityScopedSearchButton",
}

const EligibilityScopedSearchBar = () => {
  const { setFilterParams, shouldDisableControls, urlParams } =
    useEligibilityDataContext();

  const [searchKeyOption, setSearchKeyOption] =
    useState<SearchKeyOption>(DEFAULT_OPTION);
  const searchKey = searchKeyOption.key;

  const [searchValue, setSearchValue] = useState("");

  const trimmedValue = searchValue.trim();
  const isValueEmpty = !trimmedValue;
  const isValueTooLong = trimmedValue.length > MAX_SEARCH_VALUE_LENGTH;
  const hasAlreadyAddedFilter = !!urlParams[searchKey];

  const isSubmitDisabled =
    isValueEmpty ||
    hasAlreadyAddedFilter ||
    shouldDisableControls ||
    isValueTooLong;

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setFilterParams((draft) => {
      draft[searchKey] = trimmedValue;
    });
    setSearchValue("");
  };

  const tooltipProps = (() => {
    const label = eligibilitySearchKeyLabelMap[searchKey];
    const casedKey = label[0].toLocaleLowerCase() + label.slice(1);

    if (hasAlreadyAddedFilter) {
      return {
        content: `You can only search for one ${casedKey} at a time. Please clear your existing search to search by another ${casedKey}.`,
      };
    } else if (isValueEmpty) {
      return {
        content: `The ${casedKey} should not be empty.`,
      };
    } else if (isValueTooLong) {
      return {
        content: `The ${casedKey} should not exceed 100 characters.`,
      };
    } else {
      return undefined;
    }
  })();

  return (
    <FlexForm
      onSubmit={handleSearchSubmit}
      aria-label="eligibility-search-form"
    >
      <FullWidthScopedSearchBar
        trackingId={EligibilityScopedSearchBarTrackingId.SearchBar}
        label="Search by name or external ID"
        isLabelHidden={true}
        size="small"
        dropdownProps={{
          options: SEARCH_KEY_OPTIONS,
          onChange: setSearchKeyOption,
          value: searchKeyOption,
          getOptionKey: ({ key }: SearchKeyOption) => key,
          getOptionLabel: ({ label }: SearchKeyOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={shouldDisableControls}
      />
      <Button
        size="small"
        trackingId={EligibilityScopedSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        isDisabled={isSubmitDisabled}
        tooltipProps={tooltipProps}
      />
    </FlexForm>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.s};
  align-items: center;
  flex-grow: 1;
`
);

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchKeyOption>)`
  flex-grow: 1;
`;

export default EligibilityScopedSearchBar;
