import {
  FilesApiFile,
  FileType,
  TransactionsFileMetadata,
  fileStatusesWithMetadata,
  AccountsFileMetadata,
  FormsFileMetadata,
  FileProcessingStatus,
  GetFilesResponse,
  DeleteTransactionsFileMetadata,
  DeleteAccountsFileMetadata,
  DeleteAccountOwnersFileMetadata,
  DeleteFormsFileMetadata,
  AccountsTemplateType,
  DashboardFormType,
  TransactionsTemplateType,
} from "@taxbit-dashboard/rest";

const hasTransactionsMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: TransactionsFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.Transactions
  );
};

const hasAccountsMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: AccountsFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.Accounts
  );
};

const hasFormsMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: FormsFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.Forms
  );
};

const hasDeleteTransactionsMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: DeleteTransactionsFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.DeleteTransactions
  );
};

const hasDeleteAccountsMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: DeleteAccountsFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.DeleteAccounts
  );
};

const hasDeleteAccountOwnersMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: DeleteAccountOwnersFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.DeleteAccountOwners
  );
};

const hasDeleteFormsMetadata = (
  file: FilesApiFile
): file is FilesApiFile & { metadata: DeleteFormsFileMetadata } => {
  return (
    fileStatusesWithMetadata.includes(file.status) &&
    file.fileType === FileType.DeleteForms
  );
};

export const getFileMetadataTotal = (file: FilesApiFile) => {
  if (hasTransactionsMetadata(file)) {
    return file.metadata?.totalTransactions;
  } else if (hasAccountsMetadata(file)) {
    return file.metadata?.totalAccounts;
  } else if (hasFormsMetadata(file)) {
    return file.metadata?.totalRows;
  } else if (hasDeleteTransactionsMetadata(file)) {
    return file.metadata?.totalTransactions;
  } else if (hasDeleteAccountsMetadata(file)) {
    return (
      (file.metadata?.totalAccounts ?? 0) +
      (file.metadata?.failedAccounts ?? 0) +
      (file.metadata?.invalidAccounts ?? 0)
    );
  } else if (hasDeleteAccountOwnersMetadata(file)) {
    return (
      (file.metadata?.totalAccountOwners ?? 0) +
      (file.metadata?.failedAccountOwners ?? 0) +
      (file.metadata?.invalidAccountOwners ?? 0)
    );
  } else if (hasDeleteFormsMetadata(file)) {
    return (
      // Forms metadata provide total accounts as total VALID accounts
      (file.metadata?.totalAccounts ?? 0) +
      (file.metadata?.invalidAccounts ?? 0) +
      (file.metadata?.invalidAccountIds ?? 0)
    );
  }

  return undefined;
};

export const getFileMetadataFailed = (file: FilesApiFile) => {
  if (hasTransactionsMetadata(file)) {
    return file.metadata?.failedTransactions;
  } else if (hasAccountsMetadata(file)) {
    return file.metadata?.failedAccounts;
  } else if (hasFormsMetadata(file)) {
    return file.metadata?.failedRows;
  } else if (hasDeleteTransactionsMetadata(file)) {
    return file.metadata?.invalidTransactions;
  } else if (hasDeleteAccountsMetadata(file)) {
    return (
      (file.metadata?.failedAccounts ?? 0) +
      (file.metadata?.invalidAccounts ?? 0)
    );
  } else if (hasDeleteAccountOwnersMetadata(file)) {
    return (
      (file.metadata?.failedAccountOwners ?? 0) +
      (file.metadata?.invalidAccountOwners ?? 0)
    );
  } else if (hasDeleteFormsMetadata(file)) {
    return (
      (file.metadata?.invalidAccounts ?? 0) +
      (file.metadata?.invalidAccountIds ?? 0)
    );
  }

  return undefined;
};

export const getFileMetadataSucceeded = (file: FilesApiFile) => {
  if (hasTransactionsMetadata(file)) {
    return file.metadata?.succeededTransactions;
  } else if (hasAccountsMetadata(file)) {
    return file.metadata?.succeededAccounts;
  } else if (hasFormsMetadata(file)) {
    return file.metadata?.succeededRows;
  } else if (hasDeleteTransactionsMetadata(file)) {
    return (
      (file.metadata?.totalTransactions ?? 0) -
      (file.metadata?.invalidTransactions ?? 0)
    );
  } else if (hasDeleteAccountsMetadata(file)) {
    return file.metadata?.totalAccounts;
  } else if (hasDeleteAccountOwnersMetadata(file)) {
    return file.metadata?.totalAccountOwners;
  } else if (hasDeleteFormsMetadata(file)) {
    return (
      // Forms metadata provide total accounts as total VALID accounts
      file.metadata?.totalAccounts ?? 0
    );
  }

  return undefined;
};

export const getFileMetadataWarnings = (file: FilesApiFile) => {
  if (hasTransactionsMetadata(file)) {
    return file.metadata?.warningTransactions ?? 0;
  } else if (hasAccountsMetadata(file)) {
    return file.metadata?.warningAccounts;
  } else if (hasFormsMetadata(file)) {
    return file.metadata?.warningRows ?? 0;
  }

  return undefined;
};

export const getFileMetadataNewAndUpdated = (file: FilesApiFile) => {
  if (hasTransactionsMetadata(file)) {
    return {
      new: file.metadata?.newSucceededTransactions,
      updated: file.metadata?.updatedSucceededTransactions,
    };
  } else if (hasAccountsMetadata(file)) {
    return {
      new: file.metadata?.newAccounts,
      updated: file.metadata?.updatedAccounts,
    };
  } else if (hasFormsMetadata(file)) {
    return {
      new: file.metadata?.newFormDataAccounts,
      updated: file.metadata?.updatedFormDataAccounts,
    };
  }

  return undefined;
};

export const getFileMetadataSecondaryTotal = (file: FilesApiFile) => {
  if (hasDeleteTransactionsMetadata(file)) {
    return file.metadata?.totalAccounts;
  } else if (hasDeleteAccountOwnersMetadata(file)) {
    return file.metadata?.totalAccounts;
  } else if (hasDeleteFormsMetadata(file)) {
    return file.metadata?.totalRows;
  }

  return undefined;
};

const THIRTY_MINUTES_MS = 30 * 60 * 1000;

export const shouldPollForFiles = (response?: GetFilesResponse) => {
  const validatingFiles = response?.data.filter(
    ({ status }) => status === FileProcessingStatus.Validating
  );

  const hasRecentlyValidatingFile = validatingFiles?.some(
    ({ dateUploaded }) => {
      const uploadedBrowserDate = new Date(dateUploaded);
      const nowBrowserDate = new Date();

      return (
        nowBrowserDate.getTime() - uploadedBrowserDate.getTime() <
        THIRTY_MINUTES_MS
      );
    }
  );

  return hasRecentlyValidatingFile;
};

const CHUNK_SIZE = 1e7; // 10 MB

export const getTotalParts = (size: number) => Math.ceil(size / CHUNK_SIZE);

export const getFileChunks = (file: File) => {
  const chunks = [];
  let offset = 0;
  let chunkNumber = 1;

  while (offset < file.size) {
    const fileChunk = file.slice(offset, offset + CHUNK_SIZE);
    chunks.push({ fileChunk, chunkNumber });
    offset += CHUNK_SIZE;
    chunkNumber += 1;
  }

  return chunks;
};

export const getTemplateTypeForFile = ({
  fileType,
  accountsTemplateType,
  formDocumentType,
  transactionsTemplateType,
}: {
  fileType: FileType;
  accountsTemplateType?: AccountsTemplateType;
  formDocumentType?: DashboardFormType;
  transactionsTemplateType?: TransactionsTemplateType;
}) => {
  switch (fileType) {
    case FileType.Accounts: {
      return accountsTemplateType;
    }
    case FileType.Transactions: {
      return transactionsTemplateType;
    }
    case FileType.Forms: {
      return formDocumentType;
    }
    default: {
      return undefined;
    }
  }
};
