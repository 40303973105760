import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const gainLossLotLineItemSchema = z.object({
  boxOn8949: z.string().optional(),
  assetName: z.string(),
  assetSymbol: z.string(),
  assetQuantity: z.string(),
  acquiredDate: z.string().optional(),
  disposedDate: z.string(),
  proceeds: z.string(),
  costBasis: z.string().optional(),
  gainLoss: z.string().optional(),
  accruedMarketDiscount: z.string().optional(),
  washSale: z.string().optional(),
  gainLossClassification: z.string(),
  collectiblesQofClassification: z.string().optional(),
  federalTaxWithheld: z.string().optional(),
  isNoncoveredSecurity: z.boolean(),
  hasFatcaFilingRequirement: z.boolean().optional(),
  hasSecondTinNotice: z.boolean().optional(),
  cusipNumber: z.string().optional(),
  proceedsType: z.string(),
  isLossNotAllowedBasedOnProceeds: z.boolean().optional(),
  isBasisReported: z.boolean().optional(),
  bartering: z.string().optional(),
  stateCode: z.string().optional(),
  stateId: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
  isDigitalAsset: z.boolean().optional(),
});

const aggregateLineItemSchema = z.object({
  gainsOnClosedContracts: z.string(),
  unrealizedGainsOnOpenContractsPriorYear: z.string(),
  unrealizedGainsOnOpenContractsCurrentYear: z.string(),
  aggregateGainsOnContracts: z.string().optional(),
});

export const form1099BLineItemSchema = baseFormLineItemSchema
  .extend({
    documentType: z.literal(FormItemDocumentType.Form1099B),
  })
  .merge(gainLossLotLineItemSchema.partial())
  .merge(aggregateLineItemSchema.partial());

export const form1099BLineItemWithIdsSchema = form1099BLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form1099BLineItem = z.infer<typeof form1099BLineItemWithIdsSchema>;
