import {
  pageLimitPaginationParamsSchema,
  withOptionalCatch,
} from "@taxbit-dashboard/commons";
import {
  AccountsTemplateType,
  FileAction,
  FileProcessingStatus,
  FileType,
  TransactionsTemplateType,
  deleteFileTypes,
  ingestFileTypes,
} from "@taxbit-dashboard/rest";
import { IconName } from "@taxbit-private/cosmic";
import { z } from "zod";

export const fileActionFileTypesMap: Record<FileAction, FileType[]> = {
  [FileAction.Ingest]: ingestFileTypes,
  [FileAction.Delete]: deleteFileTypes,
};

export const fileProcessingStatusLabelMap: Record<
  FileProcessingStatus,
  string
> = {
  [FileProcessingStatus.Uploading]: "Uploading",
  [FileProcessingStatus.Validating]: "Validating",
  [FileProcessingStatus.Invalid]: "Invalid",
  [FileProcessingStatus.PendingApproval]: "Needs Review",
  [FileProcessingStatus.Ingesting]: "Processing",
  [FileProcessingStatus.Completed]: "Complete",
  [FileProcessingStatus.Rejected]: "Rejected",
  [FileProcessingStatus.ContactSupport]: "Contact Support",
};

export const fileTypeIconMap: Record<FileType, IconName> = {
  [FileType.Transactions]: "book-open",
  [FileType.Accounts]: "user",
  [FileType.Forms]: "file-text",
  [FileType.DeleteTransactions]: "book-open",
  [FileType.DeleteAccounts]: "user",
  [FileType.DeleteAccountOwners]: "users",
  [FileType.DeleteForms]: "file-text",
};

export const fileTypeLabelMap: Record<FileType, string> = {
  [FileType.Transactions]: "Transactions",
  [FileType.Accounts]: "Accounts",
  [FileType.Forms]: "Form Data",
  [FileType.DeleteTransactions]: "Transactions",
  [FileType.DeleteAccounts]: "Accounts",
  [FileType.DeleteAccountOwners]: "Account Owners",
  [FileType.DeleteForms]: "Form Data",
};

export const filesTableParamsSchema = z
  .object({
    fileType: withOptionalCatch(z.array(z.nativeEnum(FileType)).optional()),
  })
  .merge(pageLimitPaginationParamsSchema);

export type FilesTableParams = z.infer<typeof filesTableParamsSchema>;

export const accountsTemplateTypeLabelMap: Record<
  AccountsTemplateType,
  string
> = {
  [AccountsTemplateType.GlobalSingle]: "Global",
  [AccountsTemplateType.Us]: "Standard",
  [AccountsTemplateType.UsIra]: "IRA",
};

export const transactionsTemplateTypeLabelMap: Record<
  TransactionsTemplateType,
  string
> = {
  [TransactionsTemplateType.Adjustments]: "Standard - Adjustments",
  [TransactionsTemplateType.Cesop]: "CESOP",
  [TransactionsTemplateType.Dac7]: "Payments",
  [TransactionsTemplateType.Default]: "Standard",
};
