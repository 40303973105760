import { useMemo } from "react";

import { getEligibilityStatusLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { PieSection } from "../../eligible-users/eligibility-counts/EligibleUsersCountRows";
import { useIrSummaryDataContext } from "../context/IrSummaryContext";

const useEligibilityBreakdown = () => {
  const { currentForm, eligibilityCounts } = useIrSummaryDataContext();

  const { completeAccountData = 0, incompleteAccountData = 0 } =
    eligibilityCounts?.eligible || {};

  const totalEligible = completeAccountData + incompleteAccountData;
  const shouldShowPieChart = totalEligible > 0;

  const {
    exempt = 0,
    nonValidTaxCountryCode = 0,
    noReportableTransactions = 0,
    removed = 0,
  } = eligibilityCounts?.ineligible || {};

  const totalIneligible =
    exempt + nonValidTaxCountryCode + noReportableTransactions + removed;

  const pieSections: PieSection[] = useMemo(
    () => [
      {
        key: "CompleteAccountData",
        label: "Complete Account Data",
        value: completeAccountData,
        color: "green",
      },
      {
        key: "IncompleteAccountData",
        label: "Incomplete Account Data",
        value: incompleteAccountData,
        color: "red",
      },
    ],
    [completeAccountData, incompleteAccountData]
  );

  const eligibilityRows = useMemo(
    () =>
      [
        {
          label: "Eligible Accounts",
          value: totalEligible,
          variant: "bold",
          key: "eligibleAccounts",
        },
        {
          label: "Complete Account Data",
          value: completeAccountData,
          variant: "indented",
          key: "completeAccountData",
        },
        {
          label: "Incomplete Account Data",
          value: incompleteAccountData,
          variant: incompleteAccountData ? "indented-danger" : "indented",
          key: "incompleteAccountData",
          hasDivider: true,
        },
        {
          label: "Ineligible Accounts",
          value: totalIneligible,
          variant: "bold",
          key: "ineligibleAccounts",
        },
        {
          label: "Exempt",
          value: exempt,
          variant: "indented",
          key: "exempt",
        },
        {
          label:
            getEligibilityStatusLabelMap(currentForm).NON_VALID_TAX_COUNTRY,
          value: nonValidTaxCountryCode,
          variant: "indented",
          key: "nonValidtaxCountryCode",
        },
        {
          label: "Not Reportable",
          value: noReportableTransactions,
          variant: "indented",
          key: "noReportableTransactions",
        },
        {
          label: "Removed From Scope",
          value: removed,
          variant: "indented",
          key: "removed",
          hasDivider: true,
        },
        {
          label: "Total Accounts",
          value: totalEligible + totalIneligible,
          variant: "bold",
          key: "total",
        },
      ] as const,
    [
      completeAccountData,
      currentForm,
      exempt,
      incompleteAccountData,
      nonValidTaxCountryCode,
      noReportableTransactions,
      removed,
      totalEligible,
      totalIneligible,
    ]
  );

  return {
    eligibilityRows,
    pieSections,
    shouldShowPieChart,
  };
};

export default useEligibilityBreakdown;
