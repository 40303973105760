import { Form1099RLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItem1099RColumns = (): TableColumns<Form1099RLineItem> => {
  const { formatDateTime, formatQuantity, formatBoolean } =
    useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form1099RLineItem> => [
      {
        key: "grossDistribution",
        label: "Gross Distribution",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (grossDistribution) => formatQuantity(grossDistribution),
      },
      {
        key: "taxableAmount",
        label: "Taxable Amount",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (taxableAmount) => formatQuantity(taxableAmount),
      },
      {
        key: "taxableAmountNotDetermined",
        label: "Taxable Amount Not Determined",
        isContentFullWidth: true,
        renderCell: (taxableAmountNotDetermined) =>
          formatBoolean(taxableAmountNotDetermined),
      },
      {
        key: "isTotalDistribution",
        label: "Total Distribution",
        isContentFullWidth: true,
        renderCell: (isTotalDistribution) => formatBoolean(isTotalDistribution),
      },
      {
        key: "capitalGain",
        label: "Capital Gain",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (capitalGain) => formatQuantity(capitalGain),
      },
      {
        key: "federalTaxWithheld",
        label: "Federal Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "employeeContributionsDesignatedRothContributionsInsurancePremiums",
        label:
          "Employee Contributions/Designated Roth Contributions/Insurance Premiums",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (
          employeeContributionsDesignatedRothContributionsInsurancePremiums
        ) =>
          formatQuantity(
            employeeContributionsDesignatedRothContributionsInsurancePremiums
          ),
      },
      {
        key: "netUnrealizedAppreciation",
        label: "Net Unrealized Appreciation",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (netUnrealizedAppreciation) =>
          formatQuantity(netUnrealizedAppreciation),
      },
      {
        key: "distributionsCode",
        label: "Distributions Code",
        isContentFullWidth: true,
      },
      {
        key: "other",
        label: "Other",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (other) => formatQuantity(other),
      },
      {
        key: "otherPercentage",
        label: "Other Percentage",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (otherPercentage) => formatQuantity(otherPercentage),
      },
      {
        key: "percentageOfTotalDistribution",
        label: "Percentage Of Total Distribution",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (percentageOfTotalDistribution) =>
          formatQuantity(percentageOfTotalDistribution),
      },
      {
        key: "totalEmployeeContributions",
        label: "Total Employee Contributions",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (totalEmployeeContributions) =>
          formatQuantity(totalEmployeeContributions),
      },
      {
        key: "allocableToIrrWithin5Years",
        label: "Allocable To IRR Within 5 Years",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (allocableToIrrWithin5Years) =>
          formatQuantity(allocableToIrrWithin5Years),
      },
      {
        key: "firstYearDesignatedRothContribution",
        label: "First Year Designated Roth Contribution",
        textAlign: "right",
        isContentFullWidth: true,
        renderCell: (firstYearDesignatedRothContribution) =>
          formatQuantity(firstYearDesignatedRothContribution),
      },
      {
        key: "dateOfPayment",
        label: "Date Of Payment",
        isContentFullWidth: true,
        renderCell: (dateOfPayment) =>
          formatDateTime({
            date: dateOfPayment,
          }),
      },
      {
        key: "stateTaxWithheld",
        label: "State Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (stateTaxWithheld) => formatQuantity(stateTaxWithheld),
      },
      {
        key: "state",
        label: "State",
        isContentFullWidth: true,
      },
      {
        key: "payersStateNumber",
        label: "Payers State Number",
        isContentFullWidth: true,
      },
      {
        key: "stateDistribution",
        label: "State Distribution",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (stateDistribution) => formatQuantity(stateDistribution),
      },
      {
        key: "localTaxWithheld",
        label: "Local Tax Withheld",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (localTaxWithheld) => formatQuantity(localTaxWithheld),
      },
      {
        key: "nameOfLocality",
        label: "Name Of Locality",
        isContentFullWidth: true,
      },
      {
        key: "localDistribution",
        label: "Local Distribution",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (localDistribution) => formatQuantity(localDistribution),
      },
    ],
    [formatDateTime, formatQuantity, formatBoolean]
  );
};

export default useFormItem1099RColumns;
