/**
 * The default typing for ReadonlyArray makes it burdensome to check if an arbitrary
 * string value is in a potential list of options. This helper accomplishes the check
 * without type assertions, and narrows the type of the input key for use in subsequent
 * logic.
 */
const readonlyIncludes = <
  TUnknownElement extends unknown,
  TArrayElement extends TUnknownElement,
>(
  arr: ReadonlyArray<TArrayElement>,
  el: TUnknownElement
): el is TArrayElement => {
  return arr.includes(el as TArrayElement);
};

export default readonlyIncludes;
