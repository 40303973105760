import { QueryClient, QueryCache, MutationCache } from "@tanstack/react-query";
import { UnexpectedResponseError } from "@taxbit-dashboard/rest";

import { DashboardQueryMeta } from "./createQueryMetaObject";
import logError from "../error-handling/logError";

export const POLLING_REFETCH_INTERVAL = 5 * 1000;

/**
 * Some backend APIs return the following error codes along with additional information
 * about why these requests failed. We do not need to log these errors or alert on them,
 * because these particular failures are typically just a means of communicating information
 * back to the requestor (ex: a user is trying to upload a duplicate file).
 */
const KNOWN_ERROR_STATUS_CODES = new Set([419]);

export const dashboardQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Data is considered stale after 10 minutes.
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const shouldRetry = failureCount < 3;

        if (error instanceof UnexpectedResponseError) {
          // Retry on 5xx Server errors.
          return error.status >= 500 && shouldRetry;
        }

        // Retry on network errors.
        return shouldRetry;
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const meta = query.meta as DashboardQueryMeta;

      logError({
        message: `Failed query.\nURL: ${meta.endpointUrl}`,
        error,
        additionalProperties: {
          ...meta,
          ...(error instanceof UnexpectedResponseError
            ? { errorCode: error.status }
            : {}),
        },
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (
        error instanceof UnexpectedResponseError &&
        KNOWN_ERROR_STATUS_CODES.has(error.status)
      ) {
        return;
      }

      logError({ message: `Failed mutation.`, error });
    },
  }),
});

export default dashboardQueryClient;
