import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem5498AggregateValuesSchema = z.object({
  totalTaxYearIraContributions: z.number().optional(),
  totalTaxYearRolloverContributions: z.number().optional(),
  totalTaxYearRothIraConversionAmount: z.number().optional(),
  totalTaxYearRecharacterizedContributions: z.number().optional(),
  totalTaxYearLifeInsuranceCost: z.number().optional(),
  totalTaxYearSepContributions: z.number().optional(),
  totalTaxYearSimpleContributions: z.number().optional(),
  totalTaxYearRothIraContributions: z.number().optional(),
  requiredMinimumDistribution: z.boolean().optional(),
  taxYearRmdDate: z.string().optional(),
  rmdDate: z.string().optional(),
  totalTaxYearRmdAmount: z.number().optional(),
  totalTaxYearPostponedLateContribution: z.number().optional(),
  taxYearPostponedLateContribution: z.string().optional(),
  totalTaxYearYear13b: z.string().optional(),
  taxYearYear13b: z.string().optional(),
  totalTaxYearCode13c: z.string().optional(),
  taxYearCode13c: z.string().optional(),
  totalTaxYearRepayments: z.number().optional(),
  taxYearCode14b: z.string().optional(),
  totalTaxYearCode14b: z.string().optional(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs5498MayFormData
  ),
});

export type FormItem5498AggregateValues = z.infer<
  typeof formItem5498AggregateValuesSchema
>;
