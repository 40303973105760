/**
 * Checks if value is an empty entity.
 */
const isEmpty = <T extends string | unknown[] | object>(entity: T): boolean => {
  switch (typeof entity) {
    case "object": {
      return Object.keys(entity).length === 0;
    }
    case "string": {
      return entity === "";
    }
    default: {
      return false;
    }
  }
};

export default isEmpty;
