import { isDefined } from "@taxbit-dashboard/commons";
import { Body, Button, Divider, Flex, Tooltip } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import styled from "styled-components";

import { useIrSummaryDataContext } from "../context/IrSummaryContext";

const TimestampsTooltip: React.FC = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const { timestamps } = useIrSummaryDataContext();

  const {
    accountsSyncTimestamp,
    transactionsSyncTimestamp,
    formItemsSyncTimestamp,
  } = timestamps || {};

  const areSomeTimestampsDefined =
    isDefined(timestamps) &&
    (isDefined(accountsSyncTimestamp) ||
      isDefined(transactionsSyncTimestamp) ||
      isDefined(formItemsSyncTimestamp));

  if (!areSomeTimestampsDefined) {
    return undefined;
  }

  return (
    <Tooltip
      trigger="click"
      arrow={false}
      maxWidth="400px"
      content={
        <PaddedFlex direction="column">
          <Flex direction="column" gap="xs">
            <BodyBold>Account Data</BodyBold>
            <Body>
              Last Ingested:{" "}
              {formatDateTime({
                date: accountsSyncTimestamp,
                format: "DateTime",
              })}
            </Body>
          </Flex>
          <Divider />
          <Flex direction="column" gap="xs">
            <BodyBold>Transaction Data</BodyBold>
            <Body>
              Last Ingested:{" "}
              {formatDateTime({
                date: transactionsSyncTimestamp,
                format: "DateTime",
              })}
            </Body>
          </Flex>
          <Divider />
          <Flex direction="column" gap="xs">
            <BodyBold>Form Data</BodyBold>
            <Body>
              Last Ingested:{" "}
              {formatDateTime({
                date: formItemsSyncTimestamp,
                format: "DateTime",
              })}
            </Body>
          </Flex>
        </PaddedFlex>
      }
      target={
        <Button
          trackingId="ir-summary-timestamps-tooltip"
          label="View Details"
          variant="anchor-primary-inline"
        />
      }
    />
  );
};

const PaddedFlex = styled(Flex)(
  ({ theme }) => `
  padding: ${theme.spacing.s} 0;
  gap: ${theme.spacing.m};
`
);

const BodyBold = styled(Body)(
  ({ theme }) => `
  font-weight: ${theme.fontWeight.bold}; 
`
);

export default TimestampsTooltip;
