import { useAuth0 } from "@auth0/auth0-react";
import {
  useDashboardStore,
  useGetReturnTo,
  useLoginWithReturnTo,
  useOrganizationRouteMatch,
} from "@taxbit-dashboard/commons";
import { useEffect } from "react";

import useHandleArrivalFromInvitation from "./useHandleArrivalFromInvitation";
import useInitializeUserPermissions from "./useInitializeUserPermissions";

const useRouteAuthentication = () => {
  useInitializeUserPermissions();

  const { error, isAuthenticated, isLoading } = useAuth0();
  const getReturnTo = useGetReturnTo();
  const loginWithReturnTo = useLoginWithReturnTo();

  const setReturnTo = useDashboardStore((store) => store.setReturnTo);
  const userPermissions = useDashboardStore((store) => store.userPermissions);
  const authOrganizationId = useDashboardStore(
    (store) => store.authOrganizationId
  );

  const { authOrganizationId: routeAuthOrganizationId } =
    useOrganizationRouteMatch();

  const isRedirectingForInvitationAcceptance = useHandleArrivalFromInvitation();

  if (error) {
    throw error;
  }

  useEffect(() => {
    if (
      !isLoading &&
      !isAuthenticated &&
      !isRedirectingForInvitationAcceptance
    ) {
      setReturnTo(getReturnTo());

      void loginWithReturnTo({
        authorizationParams: { organization: routeAuthOrganizationId },
      });
    }
  }, [
    getReturnTo,
    isAuthenticated,
    isLoading,
    isRedirectingForInvitationAcceptance,
    loginWithReturnTo,
    routeAuthOrganizationId,
    setReturnTo,
  ]);

  // If we're fully authenticated into a different organization than the hardcoded URL we are
  // trying to load, we must redirect through the login flow for the appropriate organization.
  const mustSwitchOrganizations =
    isAuthenticated &&
    authOrganizationId &&
    routeAuthOrganizationId &&
    authOrganizationId !== routeAuthOrganizationId;

  useEffect(() => {
    if (mustSwitchOrganizations) {
      // Create a returnTo with the new URL so we arrive there when we are authed
      setReturnTo(getReturnTo(routeAuthOrganizationId));

      void loginWithReturnTo({
        authorizationParams: { organization: routeAuthOrganizationId },
      });
    }
  }, [
    getReturnTo,
    loginWithReturnTo,
    mustSwitchOrganizations,
    routeAuthOrganizationId,
    setReturnTo,
  ]);

  // If we're currently loading the authentication status, show a spinner.
  if (isLoading) {
    return false;
  }

  // If we're in the process of redirecting to the invitation flow, show a spinner.
  if (isRedirectingForInvitationAcceptance) {
    return false;
  }

  // If we're in the process of redirecting to switch organizations, show a spinner.
  if (mustSwitchOrganizations) {
    return false;
  }

  // Because it's likely authenticated routes will want to access user permissions,
  // we'll wait for those to be initialized as well.
  if (isAuthenticated && !!userPermissions) {
    return true;
  }

  return false;
};

export default useRouteAuthentication;
