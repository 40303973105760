import { Anchor, Body, H4 } from "@taxbit-private/cosmic";

export enum ResolutionTextsTrackingId {
  CuringTextLink = "curing-text-link",
}

export const curingDocumentationObtainedText = (
  <>
    <H4>Curing Documentation Obtained</H4>
    <Body>
      It was previously indicated that curing documentation has been obtained to
      resolve this issue. If this is incorrect, click below to re-open the
      issue.
    </Body>
    <Body>
      See{" "}
      <Anchor
        label="here"
        href="https://taxbitsupport.zendesk.com/hc/en-us/articles/28876526910861-Curing-Documentation-Review"
        trackingId={ResolutionTextsTrackingId.CuringTextLink}
      />{" "}
      for a checklist on what to look for before accepting curing documentation.
    </Body>
  </>
);

export const newTaxDocumentationSubmittedText = (
  <>
    <H4>New Tax Documentation Submitted</H4>
    <Body>
      This issue has been resolved by the account owner re-submitting new tax
      documentation.
    </Body>
  </>
);
