import { uuidSchema, newUuidV4 } from "@taxbit-private/uuids";

import {
  BannerConfig,
  NotificationSlice,
  ToastConfig,
} from "./notificationSliceModels";
import { DashboardStateSlice } from "../dashboardStoreTypes";

const createNotificationSlice: DashboardStateSlice<NotificationSlice> = (
  set
) => ({
  toasts: [],
  addToast: (toast: ToastConfig) => {
    set(({ toasts }) => ({
      toasts: [
        ...toasts,
        {
          uuid: uuidSchema.parse(newUuidV4()),
          variant: "primary",
          ...toast,
        },
      ],
    }));
  },
  removeToast: (uuidToRemove: string) =>
    set(({ toasts }) => ({
      toasts: toasts.filter(({ uuid }) => uuid !== uuidToRemove),
    })),
  clearToasts: () =>
    set(() => ({
      toasts: [],
    })),
  banner: undefined,
  setBanner: (banner: BannerConfig) =>
    set(() => ({
      banner,
    })),
  clearBanner: () =>
    set(() => ({
      banner: undefined,
    })),
});

export default createNotificationSlice;
