import {
  useDefinedCurrentCompanyUser,
  useOrganizationId,
  useGetCurrentCompanyUserOrganizations,
} from "@taxbit-dashboard/commons";
import { Company, Organization } from "@taxbit-dashboard/rest";
import { filterDropdownOptions } from "@taxbit-private/cosmic";
import { Uuid } from "@taxbit-private/uuids";
import { useMemo, useState } from "react";

const useOrganizationAndCompanyPickerData = () => {
  const { data: organizations = [], isLoading } =
    useGetCurrentCompanyUserOrganizations();

  const currentOrganizationId = useOrganizationId();
  const currentCompanyUser = useDefinedCurrentCompanyUser();

  const [isSwitchingOrg, setIsSwitchingOrg] = useState<boolean>(false);
  const [searchCompany, setSearchCompany] = useState<string>("");
  const [searchOrganization, setSearchOrganization] = useState<string>("");
  const [isOrganizationDropdownVisible, setIsOrganizationDropdownVisible] =
    useState<boolean>(false);
  const [organizationsForSelectedCompany, setOrganizationsForSelectedCompany] =
    useState<Organization[]>([]);
  const [isOrgAndCompanyPickerOpen, setIsOrgAndCompanyPickerOpen] =
    useState<boolean>(false);

  const currentCompany = useMemo(
    () =>
      organizations.find(
        (organization) => organization.id === currentOrganizationId
      ),
    [organizations, currentOrganizationId]
  );

  const currentOrganization = useMemo(
    () =>
      organizations.find(
        (organization) => organization.id === currentOrganizationId
      ),
    [organizations, currentOrganizationId]
  );

  const companyMap: Map<Uuid, Company> = useMemo(
    () =>
      organizations
        .map((organization) => ({
          companyId: organization.companyId,
          companyName: organization.companyName,
          authOrganizationId: organization.authOrganizationId,
        }))
        .reduce((acc, company) => {
          if (!acc.has(company.companyId)) {
            acc.set(company.companyId, company);
          }
          return acc;
        }, new Map<Uuid, Company>()),
    [organizations]
  );

  const filteredCompanies = useMemo(
    () =>
      filterDropdownOptions(
        [...companyMap.values()].sort((a, b) =>
          a.companyName.localeCompare(b.companyName)
        ),
        (company) => company.companyName,
        searchCompany
      ),
    [companyMap, searchCompany]
  );

  const filteredOrganizations = useMemo(
    () =>
      filterDropdownOptions(
        organizationsForSelectedCompany.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        (org) => org.name,
        searchOrganization
      ),
    [organizationsForSelectedCompany, searchOrganization]
  );

  const hasMoreThanOneOrganizationForCurrentCompany = useMemo(
    () =>
      organizations.filter((org) => org.companyId === currentCompany?.companyId)
        .length > 1,
    [organizations, currentCompany]
  );

  const hasAccessToMoreThanOneOrganization = useMemo(
    () => organizations.length > 1,
    [organizations]
  );

  return {
    organizations,
    isLoading,
    currentCompany,
    currentOrganization,
    currentCompanyUser,
    isSwitchingOrg,
    isOrganizationDropdownVisible,
    organizationsForSelectedCompany,
    setIsOrganizationDropdownVisible,
    setIsSwitchingOrg,
    setSearchCompany,
    setSearchOrganization,
    setOrganizationsForSelectedCompany,
    isOrgAndCompanyPickerOpen,
    setIsOrgAndCompanyPickerOpen,
    hasMoreThanOneOrganizationForCurrentCompany,
    hasAccessToMoreThanOneOrganization,
    filteredCompanies,
    filteredOrganizations,
    searchCompany,
    searchOrganization,
  };
};

export default useOrganizationAndCompanyPickerData;
