import { useDashboardStore } from "@taxbit-dashboard/commons";
import { FilesApiFile } from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import { useGetFileUrl } from "../../../../api/files/filesApi";
import { fileTypeLabelMap } from "../../../../api/files/filesApiTypes";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";

export enum DownloadFileTrackingId {
  DownloadFileFailed = "download-file-failed",
}

const useDownloadFile = (file: FilesApiFile) => {
  const addToast = useDashboardStore((store) => store.addToast);
  const { getFileUrl } = useGetFileUrl({
    fileId: file.fileId,
  });

  const languageForFormType = fileTypeLabelMap[file.fileType].toLowerCase();

  return useCallback(async () => {
    const { data, isError } = await getFileUrl();

    if (data) {
      window.open(data.s3Url, "_blank");
    } else if (isError) {
      addToast({
        message: `Failed to download the ${languageForFormType} file ${file.fileName}. Please try again later.`,
        trackingId: DownloadFileTrackingId.DownloadFileFailed,
        variant: "danger",
        timeoutMs: TOAST_TIMEOUT,
      });
    }
  }, [addToast, file.fileName, getFileUrl, languageForFormType]);
};

export default useDownloadFile;
