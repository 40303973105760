import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const form1099NecAggregateValuesSchema = z.object({
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099NecIngested
  ),
  taxYearTotalTransactionCount: z.number(),
  taxYearTotalFeesQuantity: z.number(),
  taxYearTotalReceivedQuantity: z.number(),
  taxYearTotalAdjustedReceivedQuantity: z.number(),
  taxYearTotalSentQuantity: z.number(),
});

export type Form1099NecAggregateValues = z.infer<
  typeof form1099NecAggregateValuesSchema
>;
