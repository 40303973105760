import { Flex, Table } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import ReportDateWithErrorIcon from "./ReportDateWithErrorIcon";
import DownloadReportButton from "../download-report-button/DownloadReportButton";
import GenerateReportButton from "../generate-report-button/GenerateReportButton";
import { UmcReport } from "../types/umcReportTypes";

type ReportTableProps = {
  reports: UmcReport[];
};

const reportsTableColumns = [
  { key: "name", label: "Report Name" },
  { key: "type", label: "Type" },
  { key: "description", label: "Description" },
  {
    key: "date",
    label: "Last Generated",
    renderCell: (_: string, report: UmcReport) => (
      <ReportDateWithErrorIcon report={report} />
    ),
    isContentFullWidth: true,
  },
] as const;

enum ReportsTableTrackingId {
  CosmicTable = "reports-table",
}

const ReportsTable: React.FC<ReportTableProps> = ({ reports }) => {
  const getRowUtilityElement = useCallback((report: UmcReport) => {
    return (
      <Flex alignItems="center">
        {report.type !== "Custom" && (
          <Flex padding="none xs">
            <GenerateReportButton report={report} />
          </Flex>
        )}
        <Flex padding="none xs">
          <DownloadReportButton report={report} />
        </Flex>
      </Flex>
    );
  }, []);

  return (
    <Table
      trackingId={ReportsTableTrackingId.CosmicTable}
      columns={reportsTableColumns}
      getRowUtilityElement={getRowUtilityElement}
      rows={reports}
      getRowKey={(row) => row.key}
    />
  );
};

export default ReportsTable;
