import { z } from "zod";

import { gainsAggregateFieldsSchema } from "./formGainLossAggregateValues";
import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const form1099BAggregateValuesSchema = gainsAggregateFieldsSchema.extend(
  {
    documentTypeDataSource: z.literal(
      EligibilityDocumentTypeDataSource.Irs1099bIngested
    ),
  }
);

export type Form1099BAggregateValues = z.infer<
  typeof form1099BAggregateValuesSchema
>;
