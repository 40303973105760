import { useCallback } from "react";

import logError from "./error-handling/logError";
import useDashboardStore from "./store/useDashboardStore";

/**
 * Creates a function that, when called, will copy text to the clipboard
 * and show an alert.
 * TODO: Replace with CopyButton from Cosmic once it's build: https://taxbit.atlassian.net/browse/TAX-27157
 */
const useCopyToClipboard = () => {
  const addToast = useDashboardStore((store) => store.addToast);
  return useCallback(
    async (subjectName: string, textToCopy: string) => {
      try {
        await navigator.clipboard.writeText(textToCopy);
      } catch (error) {
        logError({
          message: `Error copying the ${subjectName} to the clipboard.`,
          error,
        });
        addToast({
          message: `Failed to copy the ${subjectName} to your clipboard.`,
          trackingId: "copy-to-clipboard-error-toast",
          timeoutMs: 5000,
          variant: "danger",
        });
        return;
      }
      addToast({
        message: `The ${subjectName} has been copied to your clipboard.`,
        trackingId: "copy-to-clipboard-success-toast",
        timeoutMs: 5000,
      });
    },
    [addToast]
  );
};

export default useCopyToClipboard;
