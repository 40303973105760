import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { useMemo } from "react";

import useDateRangeFilterChip from "./useDateRangeFilterChip";
import useEnumFilterChips from "./useEnumFilterChips";
import useSearchFilterChips from "./useSearchFilterChips";
import {
  tinStatusLabelMap,
  taxDocumentationStatusLabelMap,
  taxDocumentationTypeLabelMap,
} from "../../../../../api/accounts/accountsApiTypes";
import { issueTypesMap } from "../../../account-details/kyc-tax-documentation/issuesTypesMap";
import useAccountsTableData from "../../useAccountsTableData";

const useAccountsFilterChips = () => {
  const { shouldDisableControls } = useAccountsTableData();
  const searchFilterChips = useSearchFilterChips();
  const dateRangeFilterChip = useDateRangeFilterChip();
  const tinValidationStatusChips = useEnumFilterChips(
    "tinValidationStatuses",
    tinStatusLabelMap,
    "TIN Validation Status"
  );
  const taxDocumentationStatusChips = useEnumFilterChips(
    "taxDocumentationStatuses",
    taxDocumentationStatusLabelMap,
    "Documentation Status"
  );
  const taxDocumentationTypeChips = useEnumFilterChips(
    "taxDocumentationTypes",
    taxDocumentationTypeLabelMap,
    "Documentation Type"
  );
  const taxDocumentationOpenIssueChips = useEnumFilterChips(
    "taxDocumentationOpenIssues",
    issueTypesMap,
    "Open Documentation Issues"
  );

  return useMemo(
    () =>
      filterToDefinedValues([
        ...searchFilterChips,
        dateRangeFilterChip,
        ...tinValidationStatusChips,
        ...taxDocumentationStatusChips,
        ...taxDocumentationTypeChips,
        ...taxDocumentationOpenIssueChips,
      ]).map((chip) => ({
        ...chip,
        // Disable all chips along with other controls
        isDisabled: shouldDisableControls,
        // Give all chips a consistent removal label
        removeButtonProps: {
          ...chip.removeButtonProps,
          label: "Remove filter",
        },
      })),
    [
      searchFilterChips,
      dateRangeFilterChip,
      tinValidationStatusChips,
      taxDocumentationStatusChips,
      taxDocumentationTypeChips,
      shouldDisableControls,
      taxDocumentationOpenIssueChips,
    ]
  );
};

export default useAccountsFilterChips;
