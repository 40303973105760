import { Flex, Spinner, TrackingProps } from "@taxbit-private/cosmic";

/**
 * We show an app-level spinner through a few different phases of logging in and
 * initializing vendors. This component just standardizes the sizing/spacing to
 * ensure transitions are smooth, and attaches a trackingId for easier debugging
 * and unit testing.
 */
const AppLevelSpinner: React.FC<TrackingProps> = ({ trackingId }) => (
  <Flex padding="xxl" justifyContent="center" trackingId={trackingId}>
    <Spinner />
  </Flex>
);

export default AppLevelSpinner;
