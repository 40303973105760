import {
  formatTaxId,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  AccountServiceTinType,
  KycTaxDocumentType,
  KycTinVerificationStatus,
  UsTaxIdType,
  W9KycTaxDocumentationSummary,
  TaxIdType,
  IrsTinMatchValidationResult,
} from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { AccountOwnerInformation } from "../../../../../api/account/accountApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import maskTaxId from "../../../../../utils/maskTaxId";

export type TinInformation = {
  rawTin?: string;
  maskedTin?: string;
  unmaskedTin?: string;
  tinVerificationStatus?:
    | KycTinVerificationStatus
    | IrsTinMatchValidationResult;
  tinVerificationDate?:
    | W9KycTaxDocumentationSummary["tinVerificationDate"]
    | string;
  country?: string;
  isDac7: false;
  id: string;
};

export type Dac7TinInformation = {
  country?: string;
  tin: AccountOwnerInformation["accountOwner"]["pii"]["tin"];
  unmaskedTin?: string;
  tinNotRequired?: boolean;
  isDac7: true;
  id: string;
};

export type TinInformationResult = TinInformation | Dac7TinInformation;

const getUnmaskedTaxId = ({
  tinInformation,
  tinType,
  shouldUnmaskTin,
}: {
  tinInformation?: TinInformation;
  tinType?: UsTaxIdType | AccountServiceTinType | TaxIdType;
  shouldUnmaskTin: boolean;
}): TinInformation | undefined => {
  if (!shouldUnmaskTin || !tinInformation?.rawTin) {
    return tinInformation
      ? {
          ...tinInformation,
          maskedTin:
            tinInformation.maskedTin ??
            maskTaxId(tinInformation.rawTin, tinType),
        }
      : undefined;
  }

  return {
    ...tinInformation,
    maskedTin: maskTaxId(tinInformation.rawTin, tinType),
    unmaskedTin: formatTaxId(tinInformation.rawTin, tinType),
  };
};

const getUnmaskedDac7TaxId = ({
  tinInformation,
  shouldUnmaskTin,
}: {
  tinInformation: Dac7TinInformation;
  shouldUnmaskTin: boolean;
}): Dac7TinInformation => {
  if (!shouldUnmaskTin || !tinInformation.country) {
    return tinInformation;
  }

  return {
    ...tinInformation,
    country: tinInformation.country,
    tin: maskTaxId(tinInformation.tin),
    unmaskedTin: formatTaxId(tinInformation.tin),
  };
};

const useGetTinInformationCards = () => {
  const { hasDac7AccountAccess } = useDashboardFeatureFlags();

  const {
    shouldUnmaskValue,
    isLoading,
    taxDocumentations,
    taxDocumentationStatus,
    accountOwner,
    isError,
  } = useAccountOwnerTaxDocumentContext();

  const tinInformation: TinInformationResult[] | undefined = useMemo(() => {
    if (!accountOwner) {
      return undefined;
    }

    const w9 = taxDocumentations?.find(
      (doc) => doc.documentType === KycTaxDocumentType.W9
    );
    // get W-9 US TIN
    if (w9?.documentType === KycTaxDocumentType.W9) {
      const tinInfo = getUnmaskedTaxId({
        tinInformation: {
          rawTin: w9.tin,
          tinVerificationStatus:
            taxDocumentationStatus?.wFormQuestionnaire?.tinStatus,
          tinVerificationDate:
            taxDocumentationStatus?.wFormQuestionnaire?.tinValidationDate,
          country: "US",
          isDac7: false,
          id: "us-tin",
        },
        tinType: w9.tinType,
        shouldUnmaskTin: shouldUnmaskValue,
      });

      if (tinInfo) {
        return [tinInfo];
      }

      return undefined;
    }

    let result: TinInformationResult[] = [];

    const w8 = taxDocumentations?.find(
      (doc) =>
        doc.documentType === KycTaxDocumentType.W8Ben ||
        doc.documentType === KycTaxDocumentType.W8BenE
    );

    // get W-8 TIN/FTIN
    if (
      w8?.documentType === KycTaxDocumentType.W8Ben ||
      w8?.documentType === KycTaxDocumentType.W8BenE
    ) {
      if (w8.tin) {
        const tinInfo = getUnmaskedTaxId({
          tinInformation: {
            rawTin: w8.tin,
            tinVerificationStatus: KycTinVerificationStatus.ForeignIndicia,
            country: "US",
            isDac7: false,
            id: "w8-tin",
          },
          tinType:
            w8.documentType === KycTaxDocumentType.W8Ben
              ? TaxIdType.UsSsn
              : TaxIdType.UsEin,
          shouldUnmaskTin: shouldUnmaskValue,
        });

        if (tinInfo) {
          result.push(tinInfo);
        }
      }

      if (w8.ftin) {
        const tinInfo = getUnmaskedTaxId({
          tinInformation: {
            rawTin: w8.ftin,
            country: w8.permanentAddress?.country,
            isDac7: false,
            id: "w8-ftin",
          },
          shouldUnmaskTin: shouldUnmaskValue,
        });

        if (tinInfo) {
          result.push(tinInfo);
        }
      }
    }

    const tinInfo = getUnmaskedTaxId({
      tinInformation: {
        rawTin: accountOwner.pii.tin,
        maskedTin: accountOwner.pii.maskedTin,
        tinVerificationStatus:
          accountOwner.validation.irsTinMatch?.validationResult,
        tinVerificationDate:
          accountOwner.validation?.irsTinMatch?.validationResponseDate,
        country: accountOwner.pii.taxCountryCode,
        isDac7: false,
        id: "account-tin",
      },
      tinType: accountOwner.pii.tinType,
      shouldUnmaskTin: shouldUnmaskValue,
    });

    if (
      tinInfo &&
      (tinInfo.tinVerificationStatus || tinInfo.country === "US") &&
      result[0]?.country !== "US"
    ) {
      result.unshift(tinInfo);
    } else if (
      tinInfo &&
      !tinInfo.tinVerificationStatus &&
      tinInfo.country !== "US"
    ) {
      result.push(tinInfo);
    }

    const dps = taxDocumentations?.find(
      (doc) => doc.documentType === KycTaxDocumentType.Dps
    );

    if (
      dps?.documentType === KycTaxDocumentType.Dps &&
      dps?.taxResidences?.length &&
      dps.taxResidences.length > 0 &&
      hasDac7AccountAccess
    ) {
      const residenceTinInfo = dps.taxResidences?.map(
        ({ tin, country, tinNotRequired }) =>
          getUnmaskedDac7TaxId({
            tinInformation: {
              tin,
              country,
              tinNotRequired,
              isDac7: true,
              id: `residence-tin-${country}`,
            },
            shouldUnmaskTin: shouldUnmaskValue,
          })
      );

      if (tinInfo) {
        result = [...result, ...residenceTinInfo];
      }
    }

    if (result.length === 0) {
      return undefined;
    }

    return result;
  }, [
    accountOwner,
    taxDocumentationStatus,
    taxDocumentations,
    shouldUnmaskValue,
    hasDac7AccountAccess,
  ]);

  return {
    isLoading,
    isError,
    tinInformation,
  };
};

export default useGetTinInformationCards;
