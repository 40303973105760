import { z } from "zod";

import { accountV2Schema, addressSchema } from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export type AccountV2 = z.infer<typeof accountV2Schema>;

export const getAccountV2ResponseSchema =
  getPublicApiSuccessSchema(accountV2Schema);

export type GetAccountV2Response = z.infer<typeof getAccountV2ResponseSchema>;

export type AccountV2Address = z.infer<typeof addressSchema>;
