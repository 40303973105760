import { Row, isDefined, renderExcludedSpan } from "@taxbit-dashboard/commons";
import { Badge } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  Dac7Details,
  kycTaxDocumentationDac7StatusToBadgePropsMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";

const useDac7InterviewDetailsRows = ({
  dac7Details,
}: {
  dac7Details?: Dac7Details;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!dac7Details) {
      return undefined;
    }

    const rows: Row[] = [
      {
        label: "Interview Status",
        content: (
          <Badge
            {...kycTaxDocumentationDac7StatusToBadgePropsMap[
              dac7Details.interviewStatus
            ]}
          />
        ),
      },
    ];

    if (isDefined(dac7Details.name)) {
      rows.push({
        label: "Name",
        content: renderExcludedSpan(dac7Details.name),
      });
    }

    if (isDefined(dac7Details.isIndividual)) {
      rows.push({
        label: "Classification",
        content: dac7Details.isIndividual ? "Individual" : "Entity",
      });
    }

    if (isDefined(dac7Details.isEuResident)) {
      rows.push({
        label: "EU Resident",
        content: formatBoolean(dac7Details.isEuResident),
      });
    }

    if (isDefined(dac7Details.submissionDate)) {
      rows.push({
        label: "Submission Date",
        content: formatDateTime({ date: dac7Details.submissionDate }),
      });
    }

    if (isDefined(dac7Details.expirationDate)) {
      rows.push({
        label: "Expiration Date",
        content: formatDateTime({ date: dac7Details.expirationDate }),
      });
    }

    return [rows];
  }, [dac7Details, formatDateTime, formatBoolean]);
};

export default useDac7InterviewDetailsRows;
