import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { z } from "zod";

export enum FormItemDocumentType {
  Form1099B = "IRS_1099_B",
  Form1099Misc = "IRS_1099_MISC",
  Form1099Int = "IRS_1099_INT",
  Form1099K = "IRS_1099_K",
  Form5498 = "IRS_5498",
  Form1099Nec = "IRS_1099_NEC",
  Form1099R = "IRS_1099_R",
  Form1099Div = "IRS_1099_DIV",
  FormRmdStatement = "IRS_RMD_STATEMENT",
  FormGainLossStatement = "GENERIC_GAIN_LOSS",
  Cesop = "CESOP",
}

export const baseFormLineItemSchema = z.object({
  taxYear: z.number().transform((year) => fourDigitYearSchema.parse(year)),
});

export const baseFormLineItemWithIdsSchema = baseFormLineItemSchema.extend({
  id: z.string(),
  userId: z.string(),
  externalId: z.string().optional(),
});
