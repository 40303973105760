import { isDefined } from "@taxbit-dashboard/commons";
import {
  AccountOwner,
  AccountOwnerDetailsTaxDocumentationIssueType,
  AccountOwnerIssue,
  KycTaxDocumentType,
  KycTaxDocumentation,
  TaxDocumentStatusValidationResult,
} from "@taxbit-dashboard/rest";

import { TaxDocDetails } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { isWForm } from "../../../../../utils/isWForm";

const getFormDetailsName = (name: string, dbaName?: string) =>
  `${name}${dbaName ? ` (${dbaName})` : ""}`;

export const getIssues = (
  issues?: AccountOwnerIssue[],
  latestTaxDocumentationGroupNumber?: number,
  latestTaxDocumentation?: KycTaxDocumentation
) => {
  if (!issues) {
    return [];
  }

  const latestGroupIssues = issues.filter(
    (issue) =>
      issue.groupNumber === latestTaxDocumentationGroupNumber ||
      issue.groupNumber === 0
  );

  const unresolvedIssues = latestGroupIssues.filter(
    (issue) => !issue.resolvedAt
  );

  const existingIssueTypes = new Set(
    unresolvedIssues.map((issue) => issue.issueType)
  );

  const taxDocUsIndiciaIssue = (() => {
    if (
      !existingIssueTypes.has(
        AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
      )
    ) {
      const usIndiciaIssue = [...issues]
        .reverse()
        .find(
          (issue) =>
            issue.issueType ===
              AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia &&
            issue.accountOwnerCuring
        );

      if (isDefined(usIndiciaIssue)) {
        return [usIndiciaIssue];
      }
    }

    return [];
  })();

  existingIssueTypes.add(
    AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
  );

  const taxDocRelatedIssues = [...latestGroupIssues]
    .reverse()
    .filter((issue) => {
      const isResolvedByLatestTaxDocumentation =
        issue.resolvedAt && latestTaxDocumentation
          ? issue.resolvedAt === latestTaxDocumentation.createdAt
          : false;

      if (
        !existingIssueTypes.has(issue.issueType) &&
        // we don't show resolved Expired Form issues
        issue.issueType !==
          AccountOwnerDetailsTaxDocumentationIssueType.ExpiredForm &&
        isResolvedByLatestTaxDocumentation
      ) {
        existingIssueTypes.add(issue.issueType);

        return true;
      }

      return false;
    });

  const resolvedIssues = [...taxDocRelatedIssues, ...taxDocUsIndiciaIssue].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return [...unresolvedIssues, ...resolvedIssues];
};

const getExpirationDate = (signatureTimestamp: string) => {
  const expirationYear = new Date(signatureTimestamp).getUTCFullYear() + 3;
  return new Date(`${expirationYear}-12-31T23:59:59.999Z`).toISOString();
};

const getFormDetails = (
  issues?: AccountOwnerIssue[],
  taxDocumentations?: KycTaxDocumentation[],
  accountOwner?: AccountOwner,
  latestTaxDocumentationGroupNumber?: number
): TaxDocDetails | undefined => {
  const wForm = taxDocumentations?.find((doc) => isWForm(doc));

  if (!isWForm(wForm)) {
    return undefined;
  }

  const baseData = {
    documentType: wForm.documentType,
    taxClassification: wForm.taxClassification,
    submissionDate: wForm.signatureTimestamp,
    documentStatus:
      accountOwner?.validation.taxDocumentStatus?.validationResult ??
      TaxDocumentStatusValidationResult.Undocumented,
  };

  if (wForm.documentType === KycTaxDocumentType.W9) {
    return {
      ...baseData,
      name: getFormDetailsName(wForm.name, wForm.dbaName),
      subjectToBackupWithholding: !wForm.isNotSubjectBackupWithholding,
    };
  }

  const currentIssues = getIssues(
    issues,
    latestTaxDocumentationGroupNumber,
    wForm
  );

  return {
    ...baseData,
    name: getFormDetailsName(wForm.name),
    expirationDate: getExpirationDate(wForm.signatureTimestamp),
    issues: currentIssues,
    permanentAddress: wForm.permanentAddress,
  };
};

const useGetFormDetails = () => {
  const {
    isLoading,
    taxDocumentations,
    issues,
    accountOwner,
    latestTaxDocumentationGroupNumber,
  } = useAccountOwnerTaxDocumentContext();

  return {
    isLoading,
    formDetails: getFormDetails(
      issues,
      taxDocumentations,
      accountOwner,
      latestTaxDocumentationGroupNumber
    ),
  };
};

export default useGetFormDetails;
