import { ReleasedDocument } from "@taxbit-dashboard/rest";
import { Card, RowGroup } from "@taxbit-private/cosmic";
import { useMemo } from "react";

import DownloadTaxFormsButton from "./DownloadTaxFormsButton";
import { documentTitleMap } from "../../../../../api/tax-forms/taxFormsApiTypes";

type DownloadTaxFormsProps = {
  currentForms: ReleasedDocument[];
};

export enum DownloadTaxFormsTrackingId {
  DownloadTaxFormsSpinner = "download-tax-forms-spinner",
}

const DownloadTaxForms: React.FC<DownloadTaxFormsProps> = ({
  currentForms,
}) => {
  const taxFormRows = useMemo(() => {
    return currentForms.map((document) => {
      const formTitle = documentTitleMap[document.type];
      return {
        key: document.id,
        avatarProps: { iconName: "file-text" },
        title: formTitle,
        content: (
          <DownloadTaxFormsButton
            documentId={document.id}
            formTitle={formTitle}
          />
        ),
      };
    });
  }, [currentForms]);

  return (
    <Card title="Download Tax Forms">
      <RowGroup rows={taxFormRows} />
    </Card>
  );
};

export default DownloadTaxForms;
