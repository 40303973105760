import { useCallback } from "react";

import useOrganizationPrefixedNavigation from "./useOrganizationPrefixedNavigation";
import { PageContextPageKey } from "../../store/page-context/pageContextSliceModel";
import useSetCurrentPageAsBackHref from "../../store/page-context/useSetCurrentPageAsBackHref";
import useDashboardStore from "../../store/useDashboardStore";

/**
 * Helper to reset required page context, save the current href to global state
 * for the back button, and navigate to the given path.
 * @param to The path to navigate to
 * @param backHrefKey The page context key of the page we're navigating
 * to. This will be used to store the current href in global state for the back button.
 * @param reset An optional array of page context keys to reset if we need to reset
 * nested views of the page we're navigating to.
 */
const useSetBackHrefAndNavigate = () => {
  const setCurrentPageAsBackHref = useSetCurrentPageAsBackHref();
  const resetPageContext = useDashboardStore((store) => store.resetPageContext);
  const { prefixedNavigate } = useOrganizationPrefixedNavigation();

  return useCallback(
    ({
      to,
      backHrefKey,
      reset,
    }: {
      to: string;
      backHrefKey: PageContextPageKey;
      reset?: PageContextPageKey[];
    }) => {
      if (reset) {
        resetPageContext(reset);
      }

      setCurrentPageAsBackHref(backHrefKey);
      prefixedNavigate({ to });
    },
    [setCurrentPageAsBackHref, resetPageContext, prefixedNavigate]
  );
};

export default useSetBackHrefAndNavigate;
