import { renderStringOrPlaceholder } from "@taxbit-dashboard/commons";
import { Transaction } from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import calculateFeeFiatValue from "../../../../../utils/calculateFeeFiatValue";
import calculateTransactionInFiatValue from "../../../../../utils/calculateTransactionInFiatValue";
import formatTransactionType from "../../../../../utils/formatTransactionType";
import sumUpFees from "../../../../../utils/sumUpFees";

export const CSV_PLACEHOLDER = "-";

type Row = {
  dateTime: string;
  transactionType: string;
  assetInQuantity: string;
  assetInCurrency: string;
  assetOutQuantity: string;
  assetOutCurrency: string;
  transactionFeeQuantity: string;
  transactionFeeCurrency: string;
  transactionValueFiat: string;
  feeValueFiat: string;
};

const useGetTransactionsCsvRow = () => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useCallback(
    (transaction: Transaction): Row => {
      const feeSummed = sumUpFees(transaction.fees);

      return {
        dateTime: formatDateTime({
          date: transaction.datetime,
          format: "DateTime",
        }),
        transactionType: formatTransactionType(
          transaction.type,
          CSV_PLACEHOLDER
        ),
        assetInQuantity: renderStringOrPlaceholder(
          transaction.received?.[0]?.assetAmount.amount,
          CSV_PLACEHOLDER
        ),
        assetInCurrency: renderStringOrPlaceholder(
          transaction.received?.[0]?.assetAmount.asset.code,
          CSV_PLACEHOLDER
        ),
        assetOutQuantity: renderStringOrPlaceholder(
          transaction.sent?.[0]?.assetAmount.amount,
          CSV_PLACEHOLDER
        ),
        assetOutCurrency: renderStringOrPlaceholder(
          transaction.sent?.[0]?.assetAmount.asset.code,
          CSV_PLACEHOLDER
        ),
        transactionFeeQuantity: renderStringOrPlaceholder(
          feeSummed?.amount,
          CSV_PLACEHOLDER
        ),
        transactionFeeCurrency: renderStringOrPlaceholder(
          feeSummed?.asset.code,
          CSV_PLACEHOLDER
        ),
        transactionValueFiat: renderStringOrPlaceholder(
          calculateTransactionInFiatValue(transaction)?.amount,
          CSV_PLACEHOLDER
        ),
        feeValueFiat: renderStringOrPlaceholder(
          calculateFeeFiatValue(transaction.fees)?.amount,
          CSV_PLACEHOLDER
        ),
      };
    },
    [formatDateTime]
  );
};

export default useGetTransactionsCsvRow;
