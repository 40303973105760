import {
  CosmicPageSize,
  PAGINATION_PAGE_SIZE_OPTIONS,
} from "@taxbit-private/cosmic";
import { z } from "zod";

export const DEFAULT_PAGINATION_LIMIT = 25;

/**
 * Coerces a primitive into a number and validate that it aligns with the
 * default Cosmic page size options. If it does not, the value will default
 * to 25.
 */
const cosmicPaginationLimitSchema = z.coerce
  .number()
  // TODO: Replace this array with the one from Cosmic.
  .refine((val): val is CosmicPageSize =>
    (PAGINATION_PAGE_SIZE_OPTIONS as readonly number[]).includes(val)
  )
  .catch(DEFAULT_PAGINATION_LIMIT);

export default cosmicPaginationLimitSchema;
