import { EligibilityDataItem } from "@taxbit-dashboard/rest";

import { OverrideEligibilityAction } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export const getEligibilityOverrideConfirmationModalTrackingIds = (
  action: OverrideEligibilityAction
) => ({
  CloseButton: `irEligibilityOverride${action}ConfirmationModalCloseButton`,
  CancelButton: `irEligibilityOverride${action}ConfirmationModalCancelButton`,
  SubmitButton: `irEligibilityOverride${action}ConfirmationModalSubmitButton`,
});

const useEligibilityOverrideConfirmationModal = ({
  onClose,
  action,
  items,
}: {
  onClose: () => void;
  action: OverrideEligibilityAction;
  items: EligibilityDataItem[];
}) => {
  const {
    handleAddAccountsToScope,
    handleRemoveAccountsFromScope,
    shouldDisableControls,
    toggleAllSelectedItems,
  } = useEligibilityDataContext();

  return {
    handleSubmit: () => {
      toggleAllSelectedItems(false);
      onClose();

      if (action === OverrideEligibilityAction.Add) {
        handleAddAccountsToScope(items);
      } else {
        handleRemoveAccountsFromScope(items);
      }
    },
    isLoading: shouldDisableControls,
  };
};

export default useEligibilityOverrideConfirmationModal;
