import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { GetCurrentCompanyUserResponse } from "@taxbit-dashboard/rest";

import createQueryMetaObject from "./createQueryMetaObject";
import DashboardQueryKey from "./DashboardQueryKey";
import unwrapPublicApiWrappedQuery from "./unwrapPublicApiWrappedQuery";
import useTaxBitRest from "../useTaxBitRest";

export const useGetCurrentCompanyUser = (
  options: Pick<
    UseQueryOptions<
      GetCurrentCompanyUserResponse,
      unknown,
      GetCurrentCompanyUserResponse,
      DashboardQueryKey[]
    >,
    "useErrorBoundary"
  > = {}
) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.CurrentCompanyUser],
    () => restSdk.currentCompanyUser.get(),
    {
      // Auto-refetching the current company user shouldn't cause problems,
      // but we're considering it unnecessary since the company user data should
      // rarely actually become stale, so we've turned it off.
      staleTime: Number.POSITIVE_INFINITY,
      ...createQueryMetaObject(restSdk.currentCompanyUser.buildPath()),
      ...options,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};
