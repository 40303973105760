import { GridGroup } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import useGeneralInformationRows from "./useGeneralInformationRows";
import useGetGeneralInformation from "./useGetGeneralInformation";

export enum GeneralInformationTrackingId {
  GeneralInformationSpinner = "general-information-spinner",
}

const GeneralInformation: React.FC = () => {
  const { isLoading, generalInformation } = useGetGeneralInformation();

  const generalInfoRows = useGeneralInformationRows({
    generalInformation,
  });

  return (
    <Card title="General Information">
      {isLoading ? (
        <ContentSpinner
          trackingId={GeneralInformationTrackingId.GeneralInformationSpinner}
        />
      ) : generalInfoRows ? (
        <GridGroup data={generalInfoRows} />
      ) : (
        <ContentErrorEmptyState entity="general information" />
      )}
    </Card>
  );
};

export default GeneralInformation;
