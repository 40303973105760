import { Button } from "@taxbit-private/cosmic";
import { useState } from "react";

import AccountsCsvExportModal from "./AccountsCsvExportModal";
import useGetAccountsCsv from "./useGetAccountsCsv";
import useAccountsTableData from "../useAccountsTableData";

export enum DownloadAccountsCsvButtonTrackingId {
  ExportCsvButton = "exportAccountsCsvButton",
}

const DownloadAccountsCsvButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoading, generateAccountsCsv } = useGetAccountsCsv();

  const { filterCount, totalCount } = useAccountsTableData();

  const handleSubmit = (exportAll: boolean) => {
    setIsModalOpen(false);
    generateAccountsCsv(exportAll);
  };

  const isDisabled = isLoading || totalCount === 0;

  return (
    <>
      <Button
        label="Export CSV"
        trackingId={DownloadAccountsCsvButtonTrackingId.ExportCsvButton}
        variant="button-secondary"
        isLoading={isLoading}
        loadingText="Exporting"
        isDisabled={isDisabled}
        onClick={() =>
          filterCount > 0 ? setIsModalOpen(true) : handleSubmit(true)
        }
      />
      <AccountsCsvExportModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default DownloadAccountsCsvButton;
