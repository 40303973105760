import {
  accountValidationsEligibilityStatusSchema,
  dashboardEligibilityFormStatusSchema,
  eligibilityStatusSchema,
} from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";
import { z } from "zod";

import {
  accountExternalIdsFilterSchema,
  refineAccountExternalIdsFilterSchema,
} from "../../shared/accountExternalIdsFilterSchema";

const eligibilityFiltersFormFieldsSchema = z.object({
  statuses: z.array(eligibilityStatusSchema),
  accountValidationsEligibilityStatuses: z.array(
    accountValidationsEligibilityStatusSchema
  ),
  formStatuses: z.array(dashboardEligibilityFormStatusSchema),
  accountExternalIdsFilter: accountExternalIdsFilterSchema,
});

export type EligibilityFiltersFormFields = z.infer<
  typeof eligibilityFiltersFormFieldsSchema
>;

const useEligibilityFiltersFormFieldSchema = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return useMemo(
    () =>
      eligibilityFiltersFormFieldsSchema.superRefine(
        ({ accountExternalIdsFilter }, ctx) => {
          const refineIssues = refineAccountExternalIdsFilterSchema({
            accountExternalIdsFilter,
            formatValue: formatWholeQuantity,
          });

          for (const issue of refineIssues) {
            ctx.addIssue(issue);
          }
        }
      ),
    [formatWholeQuantity]
  );
};

export default useEligibilityFiltersFormFieldSchema;
