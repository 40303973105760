import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const gainsAggregateFieldsSchema = z.object({
  taxYearTotalGainsItems: z.number(),
  taxYearTotalProceeds: z.number(),
  taxYearTotalCostBasis: z.number(),
  taxYearTotalMissingCostBasisItems: z.number(),
});

export const formGainLossSummaryAggregateValuesSchema =
  gainsAggregateFieldsSchema.extend({
    documentTypeDataSource: z.literal(
      EligibilityDocumentTypeDataSource.GainLossSummaryIngested
    ),
  });

export type FormGainLossSummaryAggregateValues = z.infer<
  typeof formGainLossSummaryAggregateValuesSchema
>;

export const formGainLossPdfAggregateValuesSchema =
  gainsAggregateFieldsSchema.extend({
    documentTypeDataSource: z.literal(
      EligibilityDocumentTypeDataSource.GainLossPdfIngested
    ),
  });

export type FormGainLossPdfAggregateValues = z.infer<
  typeof formGainLossPdfAggregateValuesSchema
>;
