import { Button } from "@taxbit-private/cosmic";

import { useExportAccountsTable } from "./useExportAccountsTable";

enum AccountsTableExportButtonTrackingIds {
  ExportButton = "AccountsTableExportButton",
}

type Props = {
  isAnchor?: boolean;
  label: string;
};

const AccountsTableExportButton: React.FC<Props> = ({ label, isAnchor }) => {
  const { startExport, isLoading } = useExportAccountsTable();

  const variantProps = isAnchor
    ? ({
        variant: "anchor-primary",
      } as const)
    : ({
        size: "small",
        variant: "button-tertiary",
        iconName: "download",
        loadingText: "Exporting",
      } as const);
  return (
    <Button
      trackingId={AccountsTableExportButtonTrackingIds.ExportButton}
      label={label}
      isLoading={isLoading}
      onClick={() => startExport()}
      {...variantProps}
    />
  );
};

export default AccountsTableExportButton;
