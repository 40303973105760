import {
  ToastConfig,
  navigateToUrl,
  useDashboardFeatureFlags,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import pluralize from "pluralize";
import { useState } from "react";

import {
  useGenerateEligibilityExport,
  useGetEligibilityExport,
} from "../../../../api/information-reporting/eligible-users/eligibilityExportApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import {
  ExportEligibilityTableModalForm,
  exportEligibilityTableModalFormDefaultValues,
} from "../context/exportEligibilityTableModalFormTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export enum DownloadEligibilityTableTrackingId {
  ErrorToast = "irEligibilityTableExportErrorToast",
  SuccessToast = "irEligibilityTableExportSuccessToast",
  InfoToast = "irEligibilityTableExportInfoToast",
  CloseButton = "irEligibilityTableExportCloseButton",
  CancelButton = "irEligibilityTableExportCancelButton",
  SubmitButton = "irEligibilityTableExportSubmitButton",
  DataValidationReportCheckbox = "irEligibilityTableExportDataValidationReportCheckbox",
  FormDataReportCheckbox = "irEligibilityTableExportFormDataReportCheckbox",
  AccountDataReportCheckbox = "irEligibilityTableExportAccountDataReportCheckbox",
}

const exportErroredToastContent = {
  message: "Eligibility report failed to export. Please try again.",
  trackingId: DownloadEligibilityTableTrackingId.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
} as const;

const exportSucceededToastContent = {
  message: "Your eligibility report has been exported.",
  trackingId: DownloadEligibilityTableTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
} as const;

const getExportStartedToastContent = (reportCount: number): ToastConfig => ({
  message: `Your eligibility ${pluralize("report", reportCount)} ${pluralize("is", reportCount)} being exported.`,
  trackingId: DownloadEligibilityTableTrackingId.InfoToast,
  timeoutMs: TOAST_TIMEOUT,
});

const useEligibilityTableExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useDashboardStore((store) => store.addToast);
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const {
    urlParams,
    currentForm,
    currentYear,
    shouldDisableControls,
    isEligibilityDataError,
    accountExternalIdsFilter,
  } = useEligibilityDataContext();

  const { mutate: generateEligibilityExport, data: eligibilityExportResponse } =
    useGenerateEligibilityExport();

  useGetEligibilityExport({
    exportId: eligibilityExportResponse?.data?.exportId,
    onSuccess: (response) => {
      const { data } = response;

      if (data.presignedUrl && !hasNotificationCenterAccess) {
        setIsLoading(false);
        addToast(exportSucceededToastContent);
        navigateToUrl(data.presignedUrl);
      }
    },
    onError: hasNotificationCenterAccess
      ? undefined
      : () => {
          setIsLoading(false);
          addToast(exportErroredToastContent);
        },
  });

  const handleSubmit = (
    formData: ExportEligibilityTableModalForm = exportEligibilityTableModalFormDefaultValues
  ) => {
    const reportCount = 1 + Object.values(formData).filter(Boolean).length;

    setIsLoading(true);
    addToast(getExportStartedToastContent(reportCount));

    generateEligibilityExport(
      {
        ...formData,
        ...urlParams,
        formDate: currentYear,
        formType: currentForm,
        accountExternalIdsFilter,
      },
      {
        onError: () => {
          setIsLoading(false);
          addToast(exportErroredToastContent);
        },
        onSuccess: hasNotificationCenterAccess
          ? () => {
              setIsLoading(false);
            }
          : undefined,
      }
    );
  };

  return {
    isLoading,
    isDisabled: isLoading || shouldDisableControls || isEligibilityDataError,
    onClick: handleSubmit,
  };
};

export default useEligibilityTableExport;
