import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
  createQueryMetaObject,
} from "@taxbit-dashboard/commons";

const getReportUrlQueryKey = (key: DashboardQueryKey, reportKey: string) => {
  return [{ key, reportKey }] as const;
};

export const useGetReportsUrl = ({
  key,
  onError,
}: {
  key: string;
  onError: () => void;
}) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    getReportUrlQueryKey(DashboardQueryKey.UmcReportUrl, key),
    () => restSdk.umcReports.url.get({ key }),
    {
      ...createQueryMetaObject(restSdk.umcReports.url.buildPath()),
      // disabling query to fetch new URL each time we click
      // since we pass pre signed URLs that will expire after ~3 min
      // https://stackoverflow.com/questions/62340697/react-query-how-to-usequery-when-button-is-clicked/62529349#62529349
      enabled: false,
      onError,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};
