import { createContext, PropsWithChildren, useContext } from "react";

/**
 * Creates a React context + access hook for the given hook so that
 * an entire page or feature can share a single hook instance.
 */
const createSingleInstanceHookContext = <TReturn extends unknown>(
  hook: () => TReturn,
  hookName: string
) => {
  const Context = createContext<TReturn | undefined>(undefined);

  const Provider: React.FC<PropsWithChildren> = ({ children }) => {
    const hookInstance = hook();

    return <Context.Provider value={hookInstance}>{children}</Context.Provider>;
  };

  const useContextHook: () => TReturn = () => {
    const context = useContext(Context);
    if (!context) {
      throw new Error(
        `Single instance hook used without provider: ${hookName}`
      );
    }
    return context;
  };

  return { Context, Provider, useContextHook };
};

export default createSingleInstanceHookContext;
