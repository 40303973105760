import { Flex, Modal, Body, RadioGroup } from "@taxbit-private/cosmic";
import { useState } from "react";

import useAccountsTableData from "../useAccountsTableData";

export enum AccountsCsvModalTrackingId {
  CloseBtn = "accountsCSVExportModalCloseBtn",
  PrimaryBtn = "accountsCSVExportModalPrimaryBtn",
  SecondaryBtn = "accountsCSVExportModalSecondaryBtn",
  ModalRadioGroup = "accountsCSVExportRadioGroup",
  MatchingAccounts = "accountsCSVExportMatchingAccountsOption",
  AllAccounts = "accountsCSVExportAllAccountsOption",
}

type AccountsCsvExportModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  handleSubmit: (shouldExportAll: boolean) => void;
};

const AccountsCsvExportModal: React.FC<AccountsCsvExportModalProps> = ({
  onModalClose,
  handleSubmit,
  isModalOpen,
}) => {
  const { filterCount, totalCount } = useAccountsTableData();
  const [shouldExportAll, setShouldExportAll] = useState(false);

  const matchingTransactionsMessage = `You currently have ${filterCount} ${
    totalCount > 1 ? "filter" : "filters"
  } applied with ${totalCount} matching accounts`;

  return (
    <Modal
      title="Export Accounts"
      isOpen={isModalOpen}
      onClose={onModalClose}
      primaryButtonProps={{
        label: "Export",
        variant: "button-primary",
        trackingId: AccountsCsvModalTrackingId.PrimaryBtn,
        onClick: () => handleSubmit(shouldExportAll),
      }}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: AccountsCsvModalTrackingId.SecondaryBtn,
        onClick: onModalClose,
      }}
      closeButtonTrackingId={AccountsCsvModalTrackingId.CloseBtn}
    >
      <Flex direction="column" gap="m">
        <Body>{matchingTransactionsMessage}</Body>
        <RadioGroup
          onChange={setShouldExportAll}
          options={[
            {
              label: "Export matching accounts",
              trackingId: AccountsCsvModalTrackingId.MatchingAccounts,
              value: false,
            },
            {
              label: "Export all accounts",
              trackingId: AccountsCsvModalTrackingId.AllAccounts,
              value: true,
            },
          ]}
          trackingId={AccountsCsvModalTrackingId.ModalRadioGroup}
          value={shouldExportAll}
        />
      </Flex>
    </Modal>
  );
};

export default AccountsCsvExportModal;
