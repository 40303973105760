import snakeCaseKeys from "snakecase-keys";

const options = {
  parsingOptions: {
    // Ensures that nameLine2 converts to name_line_2 rather than name_line2
    splitRegexp: /([a-z])([A-Z0-9])/g,
  },
};

/**
 * Recursively snake cases keys of an object. Returns a new object.
 */
export default (data: Parameters<typeof snakeCaseKeys>[0]) => {
  return snakeCaseKeys(data, options);
};
