import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form1099NecLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form1099Nec),
  nonemployeeComp: z.string().optional(),
  directSales5000ConsumerProducts: z.boolean().default(false),
  federalTaxWithheld: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateIncome: z.string().optional(),
});

export const form1099NecLineItemWithIdsSchema = form1099NecLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form1099NecLineItem = z.infer<
  typeof form1099NecLineItemWithIdsSchema
>;
