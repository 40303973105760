import { OAuthError, useAuth0 } from "@auth0/auth0-react";
import { logError } from "@taxbit-dashboard/commons";
import { useEffect } from "react";

const wrongOrgRegex = /user .*? is not part of the .*? organization/i;
const usedInvitationMessage = "invitation not found or already used";
const incorrectEmailSetupMessage =
  "the specified account is not allowed to accept the current invitation";
const noIdpAccessMessage = "User is not assigned to the client application.";
const invalidStateError = "Invalid state";

const shouldLogAuth0Error = (error?: unknown) => {
  // If there's no error, there is nothing to log.
  if (!error) {
    return false;
  }

  // Do not log the intermittent invalid state errors, because they aren't actionable on our side.
  // Inexplicably, these particular errors aren't instances of the OAuthError provided by Auth0 but
  // do have a message field attached.
  if ((error as { message?: string }).message === invalidStateError) {
    return false;
  }

  // Log all errors that are not Auth0 errors.
  if (!(error instanceof OAuthError)) {
    return true;
  }

  // Do not log when a company user enters the wrong organization code
  // or navigates directly to an organization URL they do not have access to.
  if (wrongOrgRegex.test(error.message)) {
    return false;
  }

  // Do not log when a company users tries to access the dashboard via an old
  // email invitation link.
  if (error.message === usedInvitationMessage) {
    return false;
  }

  // Do not log when a company user tries to accept an invitation and it fails due to
  // (1) the email address of the user from the identity provider not matching the email
  // of the invitee or (2) an email attribute is missing for the user from the identity
  // provider (eg., incorrect SAML assertion mappings).
  if (error.message === incorrectEmailSetupMessage) {
    return false;
  }

  // Do not log when a company user tries to access the dashboard via their identity provider
  // but has not been granted access to the TaxBit app in that identity provider.
  if (error.message === noIdpAccessMessage) {
    return false;
  }

  return true;
};

/**
 * Checks an error provided from Auth0 for a few specific edge cases and returns the
 * appropriate props for the `FallbackView` component. Logs the error unless it falls
 * under a few specific cases we have eliminated to reduce noise. If no props for the
 * FallvackView are returned, there is no current Auth0 error.
 */
const useCallbackAuth0Error = () => {
  const { error, logout } = useAuth0();

  useEffect(() => {
    if (shouldLogAuth0Error(error)) {
      logError({
        message: "Error from Auth0 encountered during authentication callback",
        error,
      });
    }
  }, [error]);

  if (error instanceof OAuthError) {
    if (wrongOrgRegex.test(error.message)) {
      return {
        title: "Not a Member of the Organization",
        subtitle:
          "Ask an administrator of the organization to add you as a member or log out and log back into a different organization.",
        ctaProps: {
          label: "Log Out",
          onClick: () =>
            logout({
              logoutParams: {
                federated: true,
                returnTo: window.location.origin,
              },
            }),
        },
        trackingId: "callback-organization-not-found-fallback-view",
      };
    } else if (error.message === incorrectEmailSetupMessage) {
      return {
        title: "Incorrect Email",
        subtitle:
          "You are not allowed to accept the invitation because the email address tied to this user account is different than the email address that received the invitation. If you believe this is not the case, please advise your administrator to verify that the email attribute has been properly mapped in the SAML assertion if a SAML identity provider is being used.",
        trackingId: "callback-incorrect-email-fallback-view",
      };
    } else if (error.message === noIdpAccessMessage) {
      return {
        title: "Access Denied by Identity Provider",
        subtitle:
          "Ask your administrator to grant you access to this application through your identity provider.",
        trackingId: "callback-no-idp-access-fallback-view",
      };
    } else {
      return {
        subtitle: `Sorry, something went wrong. Error: ${error.error_description}`,
        trackingId: "callback-error-message-fallback-view",
      };
    }
  } else if (error) {
    return {
      subtitle: "Sorry, something went wrong.",
      trackingId: "callback-generic-fallback-view",
    };
  } else {
    return undefined;
  }
};

export default useCallbackAuth0Error;
