import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { RhfTextArea } from "@taxbit-private/cosmic-react-hook-form";

import { EligibilityFiltersFormFields } from "./useEligibilityFiltersFormFieldSchema";
import { getAccountExternalIdsFilterMaxIdsLength } from "../../shared/accountExternalIdsFilterSchema";

export enum EligibilityAccountIdsFilterTrackingId {
  TextArea = "ir-eligibility-account-ids-filter-textarea",
  HelpIcon = "ir-eligibility-account-ids-filter-help-icon",
}
const EligibilityAccountIdsFilter = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <RhfTextArea<Pick<EligibilityFiltersFormFields, "accountExternalIdsFilter">>
      name="accountExternalIdsFilter"
      label="Filter by Account ID"
      trackingId={EligibilityAccountIdsFilterTrackingId.TextArea}
      descriptionText="Enter account IDs on separate lines"
      helpIconProps={{
        tooltipProps: {
          content: `Manually enter up to ${formatWholeQuantity(getAccountExternalIdsFilterMaxIdsLength())} account IDs, with each ID on a separate line.`,
        },
        trackingId: EligibilityAccountIdsFilterTrackingId.HelpIcon,
      }}
    />
  );
};

export default EligibilityAccountIdsFilter;
