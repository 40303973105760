import isDefined from "./isDefined";

/**
 * Filters the given array down to defined values using the `isDefined` typeguard.
 * We have pulled this into a helper to avoid needing to eslint-ignore the direct
 * usage of an array callback every time we want to complete this operation.
 */
function filterToDefinedValues<T extends unknown>(
  values: (T | undefined)[]
): T[];
function filterToDefinedValues<T extends unknown>(
  values?: (T | undefined)[]
): T[] | undefined;
function filterToDefinedValues<T extends unknown>(
  values?: (T | undefined)[]
): T[] | undefined {
  return values?.filter(isDefined);
}

export default filterToDefinedValues;
