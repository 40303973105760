import { useLocation } from "@tanstack/react-location";
import { useCallback } from "react";

import { PageContextPageKey } from "./pageContextSliceModel";
import useDashboardStore from "../useDashboardStore";

const useSetCurrentPageAsBackHref = () => {
  const location = useLocation();
  const setBackHref = useDashboardStore((store) => store.setBackHref);

  return useCallback(
    (key: PageContextPageKey) => {
      setBackHref(key, location.current.href);
    },
    [location, setBackHref]
  );
};

export default useSetCurrentPageAsBackHref;
