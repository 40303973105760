import { FileAction } from "@taxbit-dashboard/rest";

import { useDeleteFileUploaderContext } from "./delete/DeleteFileUploaderContext";
import { useIngestFileUploaderContext } from "./ingest/IngestFileUploaderContext";

const useFileUploaderContext = (action: FileAction) => {
  const ingestContext = useIngestFileUploaderContext();
  const deleteContext = useDeleteFileUploaderContext();

  return action === FileAction.Ingest ? ingestContext : deleteContext;
};

export default useFileUploaderContext;
