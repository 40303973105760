import { TRACKING_EXCLUDE_CLASS, isDefined } from "@taxbit-dashboard/commons";
import { Body, Flex, HelpIcon } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

export enum InvalidCountryTrackingId {
  InvalidCountryCodeAlertIcon = "invalid-country-code-alert-icon",
}

type InvalidCountryProps = {
  country?: string;
};

const InvalidCountry: React.FC<InvalidCountryProps> = ({ country }) => {
  return isDefined(country) ? (
    <Flex gap="s">
      <Body className={TRACKING_EXCLUDE_CLASS}>{country}</Body>
      <HelpIcon
        trackingId={InvalidCountryTrackingId.InvalidCountryCodeAlertIcon}
        variant="danger"
        tooltipProps={{
          content: "Country is not recognized as a valid ISO name or code.",
          appendTo: "body",
          isInteractive: false,
        }}
      />
    </Flex>
  ) : (
    COSMIC_VALUE_PLACEHOLDER
  );
};

export default InvalidCountry;
