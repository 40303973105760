/**
 * Map over the values of an object, returning a new object.
 *
 * @param obj An object to map over
 * @param mapFn Transform function to apply to each value. Arguments are (value, key)
 *
 * @returns A new object with all the keys from the original object, and values returned by the transform method.
 */
function mapObject<
  TExistingObject extends Record<PropertyKey, unknown>,
  TExistingValue extends TExistingObject[keyof TExistingObject],
  TKey extends keyof TExistingObject,
  TNewValue,
  TNewKey extends PropertyKey,
>(
  obj: TExistingObject,
  mapFn: (key: TKey, value: TExistingValue) => [TNewKey, TNewValue]
) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      mapFn(key as TKey, value as TExistingValue)
    )
  ) as Record<TKey, TNewValue>;
}

export default mapObject;
