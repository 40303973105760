import { Button, NumericalBadge } from "@taxbit-private/cosmic";
import { useState } from "react";

import TransfersFilterDrawer from "./TransfersFilterDrawer";
import useTransfersTableData from "../table/useTransfersTableData";

export enum TransfersFilterDrawerToggleTrackingId {
  ToggleButton = "transfers-filter-toggle-button",
  FilterCountBadge = "transfers-filter-count-badge",
}

const TransfersFilterDrawerToggle: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { shouldDisableControls, filterDrawerFilterCount } =
    useTransfersTableData();

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        trackingId={TransfersFilterDrawerToggleTrackingId.ToggleButton}
        onClick={() => setIsDrawerOpen(true)}
        utilityElement={
          filterDrawerFilterCount > 0 ? (
            <NumericalBadge
              trackingId={
                TransfersFilterDrawerToggleTrackingId.FilterCountBadge
              }
              value={filterDrawerFilterCount}
            />
          ) : undefined
        }
        isDisabled={shouldDisableControls}
      />
      <TransfersFilterDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default TransfersFilterDrawerToggle;
