import { isSameDay } from "date-fns";

import getBrowserDateFromUtcDateString from "./getBrowserDateFromUtcDateString";
import { UtcDateStringSchema } from "../../api/common/utcDateStringSchema";

/**
 * Converts two UTC date strings into browser dates and uses the date-fns
 * `isSameDay` method to determine if they are the same day.
 */
const isSameUtcDay = (
  dateA?: UtcDateStringSchema,
  dateB?: UtcDateStringSchema
): boolean => {
  const browserDateA = getBrowserDateFromUtcDateString(dateA);
  const browserDateB = getBrowserDateFromUtcDateString(dateB);

  return (
    !!browserDateA && !!browserDateB && isSameDay(browserDateA, browserDateB)
  );
};

export default isSameUtcDay;
