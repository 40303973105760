import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { Button, Drawer, DrawerProps } from "@taxbit-private/cosmic";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import { O } from "ts-toolbelt";

import AccountsTaxDocumentationOpenIssueFilter from "./AccountsTaxDocumentationOpenIssueFilter";
import AccountsTaxDocumentationStatusFilter from "./AccountsTaxDocumentationStatusFilter";
import AccountsTaxDocumentationTypeFilter from "./AccountsTaxDocumentationTypeFilter";
import AccountsTinValidationStatusFilter from "./AccountsTinValidationStatusFilter";
import AccountsDateRangeFilter from "./date-range-filter/AccountsDateRangeFilter";
import useAccountsFilterDrawerForm from "./useAccountsFilterDrawerForm";

type Props = O.Pick<DrawerProps, "onClose" | "isOpen">;

enum AccountsFilterDrawerTrackingId {
  CloseButton = "account-filters-drawer-close-button",
  ApplyButton = "account-filters-drawer-apply-button",
  CancelButton = "account-filters-drawer-cancel-button",
  ClearAllFiltersButton = "account-filters-drawer-clear-filters-button",
}

const FILTER_DRAWER_FORM_ID = "account-filters-drawer-form";

const AccountsFilterDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const {
    hasTaxDocumentationAccess,
    shouldHideAccountsTableTinUi,
    hasW8TaxDocumentationAccess,
    hasIssueFilterAccess,
  } = useDashboardFeatureFlags();

  const {
    clearAllFormFilters,
    formMethods,
    handleSubmit,
    hasFiltersApplied,
    isFormDirty,
    resetForm,
  } = useAccountsFilterDrawerForm();

  const closeAndReset = () => {
    onClose();
    resetForm();
  };

  return (
    <Drawer
      onClose={closeAndReset}
      isOpen={isOpen}
      title="Filter Accounts"
      closeButtonTrackingId={AccountsFilterDrawerTrackingId.CloseButton}
      primaryButtonProps={{
        label: "Apply",
        trackingId: AccountsFilterDrawerTrackingId.ApplyButton,
        isDisabled: !isFormDirty,
        type: "submit",
        form: FILTER_DRAWER_FORM_ID,
      }}
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: AccountsFilterDrawerTrackingId.CancelButton,
        onClick: closeAndReset,
      }}
    >
      <FormProvider {...formMethods}>
        <FlexForm
          id={FILTER_DRAWER_FORM_ID}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(e);
            onClose();
          }}
        >
          <AccountsDateRangeFilter />
          {!shouldHideAccountsTableTinUi && (
            <AccountsTinValidationStatusFilter />
          )}
          {hasTaxDocumentationAccess && (
            <>
              <AccountsTaxDocumentationStatusFilter />
              <AccountsTaxDocumentationTypeFilter />
            </>
          )}
          {hasW8TaxDocumentationAccess && hasIssueFilterAccess && (
            <AccountsTaxDocumentationOpenIssueFilter />
          )}
          {hasFiltersApplied && (
            <Button
              onClick={clearAllFormFilters}
              label="Clear All Filters"
              variant="anchor-primary-inline"
              trackingId={AccountsFilterDrawerTrackingId.ClearAllFiltersButton}
            />
          )}
        </FlexForm>
      </FormProvider>
    </Drawer>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
`
);

export default AccountsFilterDrawer;
