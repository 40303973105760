import { Anchor, Box, ContentEmptyState } from "@taxbit-private/cosmic";

const SUPPORT_LINK =
  "https://taxbitsupport.zendesk.com/hc/en-us/requests/new?ticket_form_id=26300100611981";

const MissingDashboardConfigEmptyState = () => {
  return (
    <Box>
      <ContentEmptyState
        title="Dashboard configuration missing"
        subtitleElement={
          <>
            Contact{" "}
            <Anchor
              label="TaxBit Support"
              variant="anchor-primary-inline"
              href={SUPPORT_LINK}
              trackingId="no-feature-flag-data-empty-state-support-link"
            />{" "}
            for assistance.
          </>
        }
        avatarProps={{ iconName: "file-text", variant: "secondary" }}
      />
    </Box>
  );
};

export default MissingDashboardConfigEmptyState;
