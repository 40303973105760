import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItemRmdStatementAggregateValuesSchema = z.object({
  rmdDate: z.string().optional(),
  rmdAmount: z.number().optional(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs5498FormData
  ),
});

export type FormItemRmdStatementAggregateValues = z.infer<
  typeof formItemRmdStatementAggregateValuesSchema
>;
