import {
  TRACKING_EXCLUDE_CLASS,
  renderExcludedSpanDefaultToCosmicPlaceholder,
} from "@taxbit-dashboard/commons";
import { ActionButton, Body, Flex } from "@taxbit-private/cosmic";
import { useState } from "react";

export enum MaskedDataTrackingId {
  MaskedDataButton = "maskedDataBtn",
}

type MaskedDataProps = {
  data?: string;
  unmaskedData?: string;
  isLoading?: boolean;
  dataLabel: string;
};

const MaskedData: React.FC<MaskedDataProps> = ({
  data,
  unmaskedData,
  dataLabel,
}) => {
  const [isDataUnmasked, setIsDataUnmasked] = useState(false);
  return data && unmaskedData ? (
    <Flex gap="s">
      <Body className={TRACKING_EXCLUDE_CLASS}>
        {isDataUnmasked ? unmaskedData : data}
      </Body>
      <ActionButton
        iconName={isDataUnmasked ? "eye-off" : "eye"}
        label={isDataUnmasked ? `Hide ${dataLabel}` : `Show ${dataLabel}`}
        trackingId={MaskedDataTrackingId.MaskedDataButton}
        onClick={() => setIsDataUnmasked(!isDataUnmasked)}
      />
    </Flex>
  ) : data ? (
    <Body className={TRACKING_EXCLUDE_CLASS}>{data}</Body>
  ) : (
    renderExcludedSpanDefaultToCosmicPlaceholder(data)
  );
};

export default MaskedData;
