import { logError } from "@taxbit-dashboard/commons";
import { TransferStatus } from "@taxbit-dashboard/rest";
import { Badge } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

import { transferStatusesMap } from "../../../api/transfers/transfersApiTypes";

type TransferStatusBadgeProps = {
  status: TransferStatus;
};

const TransferStatusBadge: React.FC<TransferStatusBadgeProps> = ({
  status,
}) => {
  const variant = (() => {
    switch (status) {
      case TransferStatus.Matched: {
        return "success";
      }
      case TransferStatus.NotMatched:
      case TransferStatus.MissingData: {
        return "secondary";
      }
      default: {
        logError({ message: `Unknown transfer status: ${status}` });
        return undefined;
      }
    }
  })();

  return variant ? (
    <Badge variant={variant} label={transferStatusesMap[status]} />
  ) : (
    <>{COSMIC_VALUE_PLACEHOLDER}</>
  );
};

export default TransferStatusBadge;
