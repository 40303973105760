import { Alert } from "@taxbit-private/cosmic";

export const INFORMATION_BANNER_MESSAGE =
  "The transfer matching functionality is being rolled out. You will notice changes in the Status field as the roll-out progresses.";

const CbiInformationBanner: React.FC = () => {
  return <Alert variant="primary">{INFORMATION_BANNER_MESSAGE}</Alert>;
};

export default CbiInformationBanner;
