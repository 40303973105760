import { QueryClientProvider } from "@tanstack/react-query";
import { dashboardQueryClient, logError } from "@taxbit-dashboard/commons";
import {
  GlobalStyles,
  theme as cosmicTheme,
  ErrorBoundary,
} from "@taxbit-private/cosmic";
import { CosmicLocalizationContextProvider } from "@taxbit-private/cosmic-localization";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import App from "./components/app/App";
import AuthProvider from "./components/app/AuthProvider";
import initializeAppVendors from "./components/app/initializeAppVendors";

import "@taxbit-private/cosmic/dist/style.css";

window.appVersion = APP_VERSION;

/**
 * Inject the global background color we want for the dashboard in addition
 * to the other global styles that Cosmic offers. This has to live at the top
 * to avoid a Typescript error about declaration ordering.
 */
const DashboardGlobalStyles = createGlobalStyle(
  ({ theme }) => `
  html, body, #root {
    height: 100%;

  }

  body {
    background-color: ${theme.color.gray0};
  }
`
);

void (async () => {
  const LaunchDarklyProvider = await initializeAppVendors();

  const root = createRoot(document.querySelector("#root") as HTMLElement);

  root.render(
    <StrictMode>
      <LaunchDarklyProvider>
        <ThemeProvider theme={cosmicTheme}>
          <GlobalStyles />
          <DashboardGlobalStyles />
          <ErrorBoundary logError={logError} trackingId="root-error-boundary">
            <QueryClientProvider client={dashboardQueryClient}>
              <AuthProvider>
                <CosmicLocalizationContextProvider
                  initialData={{ locale: "en-US", currency: "USD" }}
                >
                  <App />
                </CosmicLocalizationContextProvider>
              </AuthProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </LaunchDarklyProvider>
    </StrictMode>
  );
})();
