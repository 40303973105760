import { AuthSlice, UserPermissions } from "./authSliceModels";
import { DashboardStateSlice } from "../dashboardStoreTypes";

const createAuthSlice: DashboardStateSlice<AuthSlice> = (set) => ({
  userPermissions: undefined,
  setUserPermissions: (userPermissions: UserPermissions) =>
    set(() => ({ userPermissions })),
  companyId: undefined,
  setCompanyId: (companyId: string) => set(() => ({ companyId })),
  organizationId: undefined,
  setOrganizationId: (organizationId: string) =>
    set(() => ({ organizationId })),
  organizationName: undefined,
  setOrganizationName: (organizationName: string) =>
    set(() => ({ organizationName })),
  authOrganizationId: undefined,
  setAuthOrganizationId: (authOrganizationId: string) =>
    set(() => ({ authOrganizationId })),
  returnTo: undefined,
  setReturnTo: (returnTo?: string) => set(() => ({ returnTo })),
});

export default createAuthSlice;
