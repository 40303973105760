import { useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  createQueryMetaObject,
} from "@taxbit-dashboard/commons";
import { AccountId, AssetType } from "@taxbit-dashboard/rest";

export const useGetInventorySummary = (accountId?: AccountId) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.InventorySummary, accountId],
    () =>
      accountId
        ? restSdk.inventory.summary.get({
            accountId,
            assetTypes: [AssetType.Crypto, AssetType.Fiat],
          })
        : undefined,
    {
      ...createQueryMetaObject(restSdk.inventory.summary.buildPath(accountId)),
      enabled: !!accountId,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};
