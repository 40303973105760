import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

import useGetReturnTo from "./useGetReturnTo";
import useDashboardStore from "../../store/useDashboardStore";

/**
 * A login hook that preloads the Auth0 `appState` with a returnTo path.
 * This `returnTo` path is then loaded into global state for use in the
 * `Callback` component so that users are redirected to their original
 * intended URL
 */
const useLoginWithReturnTo = () => {
  const returnTo = useDashboardStore((store) => store.returnTo);

  const { loginWithRedirect } = useAuth0();
  const getReturnTo = useGetReturnTo();

  return useCallback(
    (opts: RedirectLoginOptions = {}) => {
      return loginWithRedirect({
        ...opts,
        appState: {
          returnTo:
            returnTo ?? getReturnTo(opts.authorizationParams?.organization),
        },
      });
    },
    [getReturnTo, loginWithRedirect, returnTo]
  );
};

export default useLoginWithReturnTo;
