import assertDefinedValue from "../../utils/assertDefinedValue";
import useDashboardStore from "../useDashboardStore";

/**
 * Returns the current internal organizationId. If the id has not yet been set, this
 * will throw an error. This would be unusual since most content isn't rendered until
 * the organization has been set.
 */
const useOrganizationId = () => {
  return useDashboardStore((store) =>
    assertDefinedValue(
      "A usage of useOrganizationId is expecting organizationId to be defined, but it has not yet been set.",
      store.organizationId
    )
  );
};

export default useOrganizationId;
