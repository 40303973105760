import { GridGroup } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { useMemo } from "react";

import useGetTinInformation from "./useGetTinInformation";
import useTinInformationRows from "./useTinInformationRows";

export enum TinCardsTrackingId {
  TinCardsSpinner = "tin-cards-spinner",
}

const TinInformationCards: React.FC = () => {
  const { isLoading, tinInformation, isError } = useGetTinInformation();

  const tinInfoRows = useTinInformationRows({
    tinInformation,
  });

  const tinCards = useMemo(() => {
    if (tinInfoRows && tinInfoRows.length > 0) {
      return tinInfoRows.map((tinInfoRow) => (
        <Card
          title="Tax Residence and TIN Information"
          key={`tinInformation${tinInfoRow[0].id}`}
        >
          <GridGroup data={[tinInfoRow]} />
        </Card>
      ));
    }

    return undefined;
  }, [tinInfoRows]);

  if (isLoading) {
    return (
      <Card title="Tax Residence and TIN Information">
        <ContentSpinner trackingId={TinCardsTrackingId.TinCardsSpinner} />
      </Card>
    );
  } else if (tinCards) {
    return tinCards;
  } else if (isError) {
    return (
      <Card title="Tax Residence and TIN Information">
        <ContentErrorEmptyState entity="TIN data" />
      </Card>
    );
  } else {
    return (
      <Card title="Tax Residence and TIN Information">
        <ContentEntityEmptyState entity="TIN data" />
      </Card>
    );
  }
};

export default TinInformationCards;
