import {
  FileType,
  DashboardFormType,
  AccountsTemplateType,
  TransactionsTemplateType,
} from "@taxbit-dashboard/rest";

import formData1099BGuide from "../../assets/csv-guides/TaxBit_1099-B_Ingest_Guide.xlsx?url";
import formData1099DivGuide from "../../assets/csv-guides/TaxBit_1099-DIV_Ingest_Guide.xlsx?url";
import formData1099IntGuide from "../../assets/csv-guides/TaxBit_1099-INT_Ingest_Guide.xlsx?url";
import formData1099KGuide from "../../assets/csv-guides/TaxBit_1099-K_Ingest_Guide.xlsx?url";
import formData1099MiscGuide from "../../assets/csv-guides/TaxBit_1099-MISC_Ingest_Guide.xlsx?url";
import formData1099NecGuide from "../../assets/csv-guides/TaxBit_1099-NEC_Ingest_Guide.xlsx?url";
import formData1099RGuide from "../../assets/csv-guides/TaxBit_1099-R_Ingest_Guide.xlsx?url";
import formData5498Guide from "../../assets/csv-guides/TaxBit_5498_Ingest_Guide.xlsx?url";
import cesopGuide from "../../assets/csv-guides/TaxBit_CESOP_Ingest_Guide.xlsx?url";
import accountsEuGuide from "../../assets/csv-guides/TaxBit_EU_Accounts_Ingest_Guide.xlsx?url";
import transactionsCesopGuide from "../../assets/csv-guides/TaxBit_EU_CESOP_Transactions_Ingest_Guide.xlsx?url";
import transactionsDac7Guide from "../../assets/csv-guides/TaxBit_EU_DAC7_Transactions_Ingest_Guide.xlsx?url";
import gainLossSummaryGuide from "../../assets/csv-guides/TaxBit_Gain_Loss_Summary_Ingest_Guide.xlsx?url";
import rmdStatementGuide from "../../assets/csv-guides/TaxBit_RMD_Statement_Ingest_Guide.xlsx?url";
import transactionsGuide from "../../assets/csv-guides/TaxBit_Transactions_Ingest_Guide.xls?url";
import accountsGuide from "../../assets/csv-guides/TaxBit_US_Accounts_Ingest_Guide.xlsx?url";
import accountsIraGuide from "../../assets/csv-guides/TaxBit_US_IRA_Accounts_Ingest_Guide.xlsx?url";
import transactionsAdjustmentsGuide from "../../assets/csv-guides/TaxBit_US_Transactions_Adjustments_Guide.xls?url";

const csvGuideDownloadUrlMapByFileType = {
  [FileType.Accounts]: {
    [AccountsTemplateType.GlobalSingle]: accountsEuGuide,
    [AccountsTemplateType.Us]: accountsGuide,
    [AccountsTemplateType.UsIra]: accountsIraGuide,
  },
  [FileType.Forms]: {
    [DashboardFormType.Cesop]: cesopGuide,
    [DashboardFormType.GainLossSummary]: gainLossSummaryGuide,
    [DashboardFormType.Irs1099B]: formData1099BGuide,
    [DashboardFormType.Irs1099Div]: formData1099DivGuide,
    [DashboardFormType.Irs1099Int]: formData1099IntGuide,
    [DashboardFormType.Irs1099K]: formData1099KGuide,
    [DashboardFormType.Irs1099Misc]: formData1099MiscGuide,
    [DashboardFormType.Irs1099Nec]: formData1099NecGuide,
    [DashboardFormType.Irs1099R]: formData1099RGuide,
    [DashboardFormType.Irs5498]: formData5498Guide,
    [DashboardFormType.RmdStatement]: rmdStatementGuide,
  },
  [FileType.Transactions]: {
    [TransactionsTemplateType.Cesop]: transactionsCesopGuide,
    [TransactionsTemplateType.Default]: transactionsGuide,
    [TransactionsTemplateType.Adjustments]: transactionsAdjustmentsGuide,
    [TransactionsTemplateType.Dac7]: transactionsDac7Guide,
  },
};

export const getCsvGuideDownloadUrl = ({
  fileType,
  templateType,
}: {
  fileType: FileType;
  templateType?:
    | AccountsTemplateType
    | DashboardFormType
    | TransactionsTemplateType;
}): string | undefined => {
  const mapForFileType =
    csvGuideDownloadUrlMapByFileType[
      fileType as keyof typeof csvGuideDownloadUrlMapByFileType
    ];

  return (
    mapForFileType &&
    mapForFileType[templateType as keyof typeof mapForFileType]
  );
};
