import { Navigate } from "@tanstack/react-location";
import {
  UserPermission,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  AuthorizedView,
} from "@taxbit-dashboard/commons";
import { TransferType } from "@taxbit-dashboard/rest";

import TransferDetails from "./transfer-details/TransferDetails";
import Transfers from "./transfers/Transfers";

export const incomingNavigationItem: DashboardNavigationItemChild = {
  href: "/transfer-activity/incoming",
  label: "Incoming",
  trackingId: "sidebar-navigation-transfer-activity-incoming-anchor",
  key: "incoming",
} as const;

export const outgoingNavigationItem: DashboardNavigationItemChild = {
  href: "/transfer-activity/outgoing",
  label: "Outgoing",
  trackingId: "sidebar-navigation-transfer-activity-outgoing-anchor",
  key: "outgoing",
} as const;

export const navigationItem: DashboardNavigationItemWithChildren = {
  iconName: "refresh",
  label: "Transfer Activity",
  trackingId: "sidebar-navigation-transfer-activity-anchor",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountTransactions] &&
    flags.hasCbiTransfersPage,
  children: [incomingNavigationItem, outgoingNavigationItem],
} as const;

const authorizedViewProps = {
  flags: ["hasCbiTransfersPage"],
  permissions: [UserPermission.ReadAccountTransactions],
} as const;

export const route = {
  path: "transfer-activity",
  children: [
    {
      path: "/",
      element: <Navigate to="./incoming" />,
    },
    {
      path: "/incoming",
      children: [
        {
          path: "/",
          element: (
            <AuthorizedView {...authorizedViewProps}>
              <Transfers />
            </AuthorizedView>
          ),
          meta: {
            transferType: TransferType.TransferIn,
          },
        },
        {
          path: ":transactionId/accounts/:accountId",
          element: (
            <AuthorizedView {...authorizedViewProps}>
              <TransferDetails />
            </AuthorizedView>
          ),
          meta: {
            transferType: TransferType.TransferIn,
          },
        },
      ],
    },
    {
      path: "/outgoing",
      children: [
        {
          path: "/",
          element: (
            <AuthorizedView {...authorizedViewProps}>
              <Transfers />
            </AuthorizedView>
          ),
          meta: {
            transferType: TransferType.TransferOut,
          },
        },
        {
          path: ":transactionId/accounts/:accountId",
          element: (
            <AuthorizedView {...authorizedViewProps}>
              <TransferDetails />
            </AuthorizedView>
          ),
          meta: {
            transferType: TransferType.TransferOut,
          },
        },
      ],
    },
  ],
};
