import { Modal } from "@taxbit-private/cosmic";
import { FormProvider } from "react-hook-form";

import {
  useFilerSetupModalContext,
  FILER_SETUP_FORM_ID,
} from "./useFilerSetupModalContext";

const FilerSetupModal: React.FC = () => {
  const { modalProps, formMethods, formFields, handleSubmit } =
    useFilerSetupModalContext();

  return (
    <Modal {...modalProps}>
      <FormProvider {...formMethods}>
        <form id={FILER_SETUP_FORM_ID} onSubmit={handleSubmit}>
          {formFields}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default FilerSetupModal;
