import { UtcDateStringSchema } from "../../api/common/utcDateStringSchema";

/**
 * We convert from browser dates to UTC ISO datestrings with the correct start
 * and end timestamps when we apply the date range filter. To convert them
 * back, we remove the "Z" suffix associated with UTC which forces the browser
 * to create these dates as the given timestamps in browser time. This ensures
 * they appear correctly in the DateRangePicker, which only runs in browser dates.
 */
function getBrowserDateFromUtcDateString(dateString: UtcDateStringSchema): Date;
function getBrowserDateFromUtcDateString(
  dateString?: UtcDateStringSchema
): Date | undefined;
function getBrowserDateFromUtcDateString(dateString?: UtcDateStringSchema) {
  return dateString ? new Date(dateString.slice(0, -1)) : undefined;
}

export default getBrowserDateFromUtcDateString;
