import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form1099DivLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form1099Div),
  totalOrdinaryDividends: z.string().optional(),
  qualifiedDividends: z.string().optional(),
  totalCapitalGainDistribution: z.string().optional(),
  unrecapSection1250Gains: z.string().optional(),
  section1202Gain: z.string().optional(),
  collectiblesGain: z.string().optional(),
  section897OrdinaryDividends: z.string().optional(),
  section897CapitalGain: z.string().optional(),
  nondividendDistributions: z.string().optional(),
  federalTaxWithheld: z.string().optional(),
  section199ADividends: z.string().optional(),
  investmentExpense: z.string().optional(),
  foreignTaxPaid: z.string().optional(),
  foreignCountryOrUsPossession: z.string().optional(),
  cashLiquidationDistributions: z.string().optional(),
  noncashLiquidationDistributions: z.string().optional(),
  exemptInterestDividends: z.string().optional(),
  specifiedPrivateActivityBondInterestDividends: z.string().optional(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
});

export const form1099DivLineItemWithIdsSchema = form1099DivLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form1099DivLineItem = z.infer<
  typeof form1099DivLineItemWithIdsSchema
>;
