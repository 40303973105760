import { InlineSpinner, Flex, Banner } from "@taxbit-private/cosmic";

import useDashboardStore from "../store/useDashboardStore";

const BannerNotification: React.FC = () => {
  const banner = useDashboardStore((state) => state.banner);

  return banner ? (
    <Banner variant="primary" trackingId={banner.trackingId}>
      <Flex alignItems="center" gap="s">
        {banner.shouldShowSpinner && <InlineSpinner />}
        {banner.message}
      </Flex>
    </Banner>
  ) : undefined;
};

export default BannerNotification;
