import { Anchor, Body, H4 } from "@taxbit-private/cosmic";

export enum ActionRequiredTextsTrackingId {
  CuringTextLink = "curing-text-link",
}

export const resubmissionText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This issue requires the account owner to submit new tax documentation.
      After submission, the tax document will be reviewed to determine whether
      there are any outstanding issues that need to be resolved.
    </Body>
  </>
);

export const usIndiciaText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This issue requires curing documentation to be obtained. Alternatively,
      the account owner can submit a Form W-9.
    </Body>
    <Body>
      If you have obtained acceptable curing documentation, click below to
      resolve the issue. See{" "}
      <Anchor
        label="here"
        href="https://taxbitsupport.zendesk.com/hc/en-us/articles/28876526910861-Curing-Documentation-Review"
        trackingId={ActionRequiredTextsTrackingId.CuringTextLink}
      />{" "}
      for a checklist on what to look for before accepting curing documentation.
    </Body>
  </>
);

export const resubmissionOrCuringText = (
  <>
    <H4>Action Required</H4>
    <Body>
      This issue requires curing documentation to be obtained. Alternatively,
      the account owner can submit new tax documentation to resolve the issue.
    </Body>
    <Body>
      If you have obtained acceptable curing documentation, click below to
      resolve the issue. See{" "}
      <Anchor
        label="here"
        href="https://taxbitsupport.zendesk.com/hc/en-us/articles/28876526910861-Curing-Documentation-Review"
        trackingId={ActionRequiredTextsTrackingId.CuringTextLink}
      />{" "}
      for a checklist on what to look for before accepting curing documentation.
    </Body>
    <Body>
      If the account owner chooses to submit new tax documentation, the new tax
      document will be reviewed to determine whether the issue has been
      resolved.
    </Body>
  </>
);
