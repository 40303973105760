const maskData = (data?: string): string | undefined => {
  if (!data) {
    return undefined;
  }

  const maskLength = Math.max(0, data.length - 4);
  return `${"*".repeat(maskLength)}${data.slice(maskLength - data.length)}`;
};

export default maskData;
