import { useQuery } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { UmcApiReportType } from "@taxbit-dashboard/rest";

export const useGetUmcReports = (type: UmcApiReportType, enabled: boolean) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.UmcReports, type],
    () => restSdk.umcReports.get({ type }),
    { ...createQueryMetaObject(restSdk.umcReports.buildPath()), enabled }
  );
  return unwrapPublicApiWrappedQuery(query);
};
