import Papa, { parse } from "papaparse";
import { L, O } from "ts-toolbelt";

/**
 * The Papaparse `parse` method utilizes a callback to asynchronously
 * return results. We typically use async/await to handle asynchronous
 * operations, so this helper just wraps `parse` in a Promise to better
 * fit into our other async operations.
 */
const parseAsPromise = async <T = string[] | undefined>(
  ...[file, opts]: L.Update<
    Parameters<typeof Papa.parse<T>>,
    "1",
    O.Omit<Parameters<typeof Papa.parse<T>>[1], "complete">
  >
): Promise<Papa.ParseResult<T>> => {
  return new Promise((resolve, reject) => {
    parse(file, {
      ...opts,
      complete: (results: Papa.ParseResult<T>) => {
        resolve(results);
      },
      error: (e) => {
        reject(e);
      },
    });
  });
};

export default parseAsPromise;
