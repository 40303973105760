/**
 * A typeguard that will return the value more narrowly typed (the type
 * will no longer include undefined) if the value is defined. If the value
 * is not defined, an error will be thrown.
 */
const assertDefinedValue = <T>(errorMessage: string, value?: T) => {
  if (value === undefined) {
    throw new Error(errorMessage);
  }

  return value;
};

export default assertDefinedValue;
