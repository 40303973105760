import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  POLLING_REFETCH_INTERVAL,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { GetEligibilityExportResponse } from "@taxbit-dashboard/rest";
import { Uuid } from "@taxbit-private/uuids";

import {
  EligibilityDataParams,
  getEligibilityExportRequest,
} from "./eligibleUsersApiTypes";
import getEligibilityParams from "./getEligibilityParams";
import { ExportEligibilityTableModalForm } from "../../../pages/information-reporting/eligible-users/context/exportEligibilityTableModalFormTypes";
import { AccountExternalIdsFilter } from "../irApiTypes";

export const useGetEligibilityExport = ({
  exportId,
  onError,
  onSuccess,
}: {
  exportId?: Uuid;
} & Pick<
  UseQueryOptions<
    GetEligibilityExportResponse | undefined,
    unknown,
    GetEligibilityExportResponse,
    [DashboardQueryKey, { exportId?: Uuid }]
  >,
  "onError" | "onSuccess"
>) => {
  const restSdk = useTaxBitRest();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  const query = useQuery(
    [DashboardQueryKey.EligibilityExport, { exportId }],
    () => exportId && restSdk.eligibility.export.get({ exportId }),
    {
      ...createQueryMetaObject(restSdk.eligibility.export.buildPath(exportId)),
      enabled: !!exportId,
      onError,
      onSuccess,
      refetchInterval: hasNotificationCenterAccess
        ? false
        : (data, queryData) =>
            data?.data.presignedUrl || queryData.state.error
              ? false
              : POLLING_REFETCH_INTERVAL,
      refetchIntervalInBackground: !hasNotificationCenterAccess,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateEligibilityExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    [DashboardQueryKey.EligibilityExport],
    (
      params: EligibilityDataParams &
        ExportEligibilityTableModalForm &
        AccountExternalIdsFilter
    ) => {
      const apiParams = getEligibilityParams(params);
      const {
        "page[offset]": offset,
        "page[limit]": limit,
        ...filterParams
      } = apiParams;
      const requestData = getEligibilityExportRequest(params);

      return restSdk.eligibility.exports.post(filterParams, requestData);
    },
    {
      ...createQueryMetaObject(restSdk.eligibility.exports.buildPath()),
    }
  );
};
