import { Alert } from "@taxbit-private/cosmic";
import { useEffect, useMemo, useState } from "react";

import { NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export const GAIN_LOSS_STATEMENT_ALERT_MESSAGE =
  "Complete Account Data is not required to generate Gain/Loss Statements.";

const GainLossStatementAlert = () => {
  const { currentForm } = useEligibilityDataContext();
  const shouldShowAlert = useMemo(
    () => NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES.has(currentForm),
    [currentForm]
  );
  const [isWarningPresent, setIsWarningPresent] = useState(shouldShowAlert);

  useEffect(() => {
    setIsWarningPresent(shouldShowAlert);
  }, [shouldShowAlert]);

  if (isWarningPresent) {
    return (
      <Alert
        onClose={() => setIsWarningPresent(false)}
        variant="warning"
        trackingId="irEligibleUsersGainLossAlert"
      >
        {GAIN_LOSS_STATEMENT_ALERT_MESSAGE}
      </Alert>
    );
  } else {
    return undefined;
  }
};

export default GainLossStatementAlert;
