import { irFormTypeToDisplayNameMap } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";

const sortFormsByDisplayedName = (
  a: DashboardFormType,
  b: DashboardFormType
) => {
  return irFormTypeToDisplayNameMap[a].localeCompare(
    irFormTypeToDisplayNameMap[b]
  );
};

// Separate IRS forms from other forms and excluding 1042S to sort them separately
const partitionDashboardFormTypes = (forms: DashboardFormType[]) => {
  return forms.reduce<[DashboardFormType[], DashboardFormType[]]>(
    (acc, form) => {
      if (form === DashboardFormType.Irs1042S) {
        return acc;
      } else if (
        form.startsWith("IRS") ||
        form === DashboardFormType.RmdStatement
      ) {
        acc[0].push(form);
      } else {
        acc[1].push(form);
      }

      return acc;
    },
    [[], []]
  );
};

/**
 * Sorting forms in alphabetical order with the exception of 1042S which is always last
 * of the IRS forms. Non IRS forms will always follow IRS forms.
 */
const sortDashboardForms = (forms: DashboardFormType[]) => {
  const is1042SFormPresent = forms.includes(DashboardFormType.Irs1042S);
  const [irsForms, otherForms] = partitionDashboardFormTypes(forms);
  return [
    ...irsForms.sort(sortFormsByDisplayedName),
    ...(is1042SFormPresent ? [DashboardFormType.Irs1042S] : []),
    ...otherForms.sort(sortFormsByDisplayedName),
  ];
};

export default sortDashboardForms;
