import { useQuery } from "@tanstack/react-query";

import createQueryMetaObject from "./createQueryMetaObject";
import { POLLING_REFETCH_INTERVAL } from "./dashboardQueryClient";
import DashboardQueryKey from "./DashboardQueryKey";
import unwrapPublicApiWrappedQuery from "./unwrapPublicApiWrappedQuery";
import useDashboardFeatureFlags from "../feature-flags/useDashboardFeatureFlags";
import useTaxBitRest from "../useTaxBitRest";

export const useGetCurrentCompanyUserDownloads = () => {
  const restSdk = useTaxBitRest();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();
  return unwrapPublicApiWrappedQuery(
    useQuery(
      [DashboardQueryKey.CurrentCompanyUserDownloads],
      () => restSdk.currentCompanyUser.downloads.get(),
      {
        ...createQueryMetaObject(
          restSdk.currentCompanyUser.downloads.buildPath()
        ),
        refetchInterval: hasNotificationCenterAccess
          ? false
          : POLLING_REFETCH_INTERVAL,
        refetchIntervalInBackground: !hasNotificationCenterAccess,
      }
    )
  );
};
