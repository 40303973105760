import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import accountExternalIdsFilterToAccountExternalIds from "../../../../../utils/accountExternalIdsFilterToAccountExternalIds";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum EligibilityAccountIdsChipTrackingId {
  Chip = "ir-eligibility-account-ids-chip",
}

const useAccountIdsChip = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();
  const { accountExternalIdsFilter, setAccountExternalIdsFilter } =
    useEligibilityDataContext();

  return useMemo(() => {
    if (accountExternalIdsFilter === "") return undefined;

    const accountExternalIdsLength =
      accountExternalIdsFilterToAccountExternalIds(
        accountExternalIdsFilter
      ).length;

    return {
      label: `Account IDs: ${formatWholeQuantity(accountExternalIdsLength)}`,
      removeButtonProps: {
        "onClick": () => {
          setAccountExternalIdsFilter("");
        },
        "aria-label": "Remove filter for account IDs",
      },
      trackingId: EligibilityAccountIdsChipTrackingId.Chip,
    };
  }, [
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
    formatWholeQuantity,
  ]);
};

export default useAccountIdsChip;
