import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import {
  Box,
  Button,
  Divider,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";
import { useState } from "react";

import CompanyUserManagementTable from "./CompanyUserManagementTable";
import CompanyUserManagementTablePagination from "./CompanyUserManagementTablePagination";
import InviteCompanyUserModal from "../../../../components/InviteCompanyUserModal";

enum UserManagementTrackingId {
  InviteUserButton = "invite-user-button",
}

const CompanyUserManagement = () => {
  const [shouldShowInviteUserModal, setShouldShowInviteUserModal] =
    useState(false);

  return (
    <DashboardPrimaryTemplate
      title="User Management"
      utilityElement={
        <Button
          label="Invite User"
          trackingId={UserManagementTrackingId.InviteUserButton}
          onClick={() => setShouldShowInviteUserModal(true)}
        />
      }
    >
      <Box>
        <TrimmedBoxContent trim="all">
          <CompanyUserManagementTable />
          <Divider />
          <CompanyUserManagementTablePagination />
        </TrimmedBoxContent>
      </Box>
      <InviteCompanyUserModal
        isOpen={shouldShowInviteUserModal}
        onClose={() => setShouldShowInviteUserModal(false)}
      />
    </DashboardPrimaryTemplate>
  );
};

export default CompanyUserManagement;
