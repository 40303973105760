import { navigateToUrl } from "@taxbit-dashboard/commons";
import { AccountsTemplateType, FileType } from "@taxbit-dashboard/rest";
import { Button, Flex } from "@taxbit-private/cosmic";
import { RhfDropdown } from "@taxbit-private/cosmic-react-hook-form";
import { useWatch } from "react-hook-form";
import styled from "styled-components";

import { accountsTemplateTypeLabelMap } from "../../../../api/files/filesApiTypes";
import { FileUploaderFormFields } from "../../context/fileUploaderFormTypes";
import useTemplatesFeatureFlagData from "../../context/useTemplatesFeatureFlagData";
import { getCsvTemplateDownloadUrl } from "../../csvTemplateData";

export enum AccountsTemplateStepTrackingIds {
  TemplateDropdown = "accounts-template-dropdown",
  DownloadTemplate = "accounts-template-download-button",
}

const AccountsTemplateStep: React.FC = () => {
  const { accountsTemplates } = useTemplatesFeatureFlagData();

  const [currentTemplate] = useWatch<
    FileUploaderFormFields,
    ["accountsTemplateType"]
  >({
    name: ["accountsTemplateType"],
  });

  const templateUrl = getCsvTemplateDownloadUrl({
    fileType: FileType.Accounts,
    templateType: currentTemplate,
  });

  return (
    <Flex gap="m" growItems={true}>
      <RhfDropdown<
        FileUploaderFormFields,
        "accountsTemplateType",
        AccountsTemplateType
      >
        getOptionKey={(value) => value}
        getOptionLabel={(value) => accountsTemplateTypeLabelMap[value]}
        getOptionValue={(value) => value}
        label="Template"
        isLabelHidden={true}
        name="accountsTemplateType"
        options={accountsTemplates}
        placeholder="Select an option"
        trackingId={AccountsTemplateStepTrackingIds.TemplateDropdown}
      />
      <TemplateButton
        label="Download Template"
        trackingId={AccountsTemplateStepTrackingIds.DownloadTemplate}
        isDisabled={!templateUrl}
        variant="button-secondary"
        onClick={() => {
          if (templateUrl) {
            navigateToUrl(templateUrl);
          }
        }}
      />
    </Flex>
  );
};

const TemplateButton = styled(Button)`
  max-width: max-content;
`;

export default AccountsTemplateStep;
