import {
  ReleasedDocumentRevisionState,
  ReleasedDocumentRevisionType,
  ReleasedDocumentType,
} from "@taxbit-dashboard/rest";
import { BadgeProps } from "@taxbit-private/cosmic";

export const revisionTypesMap: Record<ReleasedDocumentRevisionType, string> = {
  [ReleasedDocumentRevisionType.Original]: "Original",
  [ReleasedDocumentRevisionType.Correction]: "Correction",
  [ReleasedDocumentRevisionType.Void]: "Void",
};

export const revisionStatesMap: Record<ReleasedDocumentRevisionState, string> =
  {
    [ReleasedDocumentRevisionState.Delivered]: "Delivered",
    [ReleasedDocumentRevisionState.Filed]: "Filed",
  };

export const documentTitleMap: Record<ReleasedDocumentType, string> = {
  [ReleasedDocumentType.GainLossSummary]: "Gain Loss Statement (CSV)",
  [ReleasedDocumentType.GainLossPdf]: "Gain Loss Statement (PDF)",
  [ReleasedDocumentType.Irs1099B]: "1099-B",
  [ReleasedDocumentType.Irs1099Misc]: "1099-MISC",
  [ReleasedDocumentType.Irs1099Div]: "1099-DIV",
  [ReleasedDocumentType.Irs1099Int]: "1099-INT",
  [ReleasedDocumentType.Irs1099K]: "1099-K",
  [ReleasedDocumentType.Irs1099R]: "1099-R",
  [ReleasedDocumentType.Irs1099Nec]: "1099-NEC",
  [ReleasedDocumentType.Irs5498]: "5498",
  [ReleasedDocumentType.RmdStatement]: "RMD Statement",
  [ReleasedDocumentType.Irs1042S]: "1042-S",
  [ReleasedDocumentType.Irs8949]: "8949",
  [ReleasedDocumentType.UkGainLossSummary]: "UK Gain Loss Statement",
  [ReleasedDocumentType.TransactionSummary]: "Transaction Summary (CSV)",
  [ReleasedDocumentType.TransactionSummaryPdf]: "Transaction Summary (PDF)",
  [ReleasedDocumentType.Dac7]: "DAC7",
  [ReleasedDocumentType.Unknown]: "Other form",
};

export const taxFormStatusToBadgePropsMap: Record<
  ReleasedDocumentRevisionState,
  BadgeProps
> = {
  [ReleasedDocumentRevisionState.Delivered]: {
    label: "Generated",
    variant: "success",
  },
  [ReleasedDocumentRevisionState.Filed]: {
    label: "Filed",
    variant: "success",
  },
};
