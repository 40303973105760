import {
  useDashboardStore,
  useDefinedCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { FileAction, FilesApiFile } from "@taxbit-dashboard/rest";
import {
  Alert,
  Body,
  Button,
  Divider,
  Flex,
  Modal,
  ModalProps,
} from "@taxbit-private/cosmic";
import { useCallback, useState } from "react";

import FileMetadataGrid from "./FileMetadataGrid";
import { useConfirmFileIngestion } from "../../../../../api/files/filesApi";
import { fileTypeLabelMap } from "../../../../../api/files/filesApiTypes";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import useDownloadErrorReports from "../useDownloadErrorReports";

type Props = Pick<ModalProps, "isOpen" | "onClose"> & {
  action: FileAction;
  file: FilesApiFile;
};

export enum ReviewIngestionModalTrackingId {
  CloseButton = "review-ingestion-modal-close-button",
  RejectButton = "review-ingestion-modal-reject-button",
  ApproveButton = "review-ingestion-modal-approve-button",
  SuccessToast = "review-ingestion-modal-success-toast",
  ErrorReportSummaryDownload = "review-ingestion-modal-error-report-summary-download",
}

const ReviewIngestionModal: React.FC<Props> = ({
  onClose,
  isOpen,
  action,
  file,
}) => {
  const [isApprovingFile, setIsApprovingFile] = useState(false);
  const {
    mutate: confirmIngestion,
    isLoading: isConfirmingIngestion,
    isError: didConfirmationFail,
  } = useConfirmFileIngestion();
  const currentCompanyUser = useDefinedCurrentCompanyUser();

  const addToast = useDashboardStore((store) => store.addToast);

  const downloadErrorReports = useDownloadErrorReports(file);

  const languageForFormType = fileTypeLabelMap[file.fileType].toLowerCase();
  const languageForAction =
    action === FileAction.Ingest ? "ingested" : "deleted";

  const confirmFileIngestion = useCallback(
    (isIngestionApproved: boolean) => {
      confirmIngestion(
        {
          fileId: file.fileId,
          requestData: {
            isIngestionApproved,
            username: currentCompanyUser.name,
          },
        },
        {
          onSuccess: () => {
            addToast({
              message: isIngestionApproved
                ? `File ${file.fileName} has been successfully approved, and we are processing your ${languageForFormType}. This may take a while. You may now leave this page.`
                : `File ${file.fileName} has been rejected. You may remove this file at any time by clicking on the 3-dot menu in the table above.`,
              timeoutMs: TOAST_TIMEOUT,
              trackingId: ReviewIngestionModalTrackingId.SuccessToast,
            });
            onClose();
          },
        }
      );
    },
    [
      addToast,
      confirmIngestion,
      currentCompanyUser.name,
      file.fileId,
      file.fileName,
      onClose,
      languageForFormType,
    ]
  );

  return (
    <Modal
      title="Review File"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={ReviewIngestionModalTrackingId.CloseButton}
      secondaryButtonProps={{
        label: "Reject File",
        trackingId: ReviewIngestionModalTrackingId.RejectButton,
        onClick: () => {
          setIsApprovingFile(false);
          confirmFileIngestion(false);
        },
        isLoading: isConfirmingIngestion && !isApprovingFile,
        loadingText: "Rejecting",
      }}
      primaryButtonProps={{
        label: "Approve File",
        trackingId: ReviewIngestionModalTrackingId.ApproveButton,
        onClick: () => {
          setIsApprovingFile(true);
          confirmFileIngestion(true);
        },
        isLoading: isConfirmingIngestion && isApprovingFile,
        loadingText: "Approving",
      }}
    >
      <Flex direction="column" gap="m">
        <Body>
          Please review the information below for the file {file.fileName}.
        </Body>
        <Body>
          Here is what will happen based on the action you take:
          <ul>
            <li>
              <b>Reject File:</b> None of the {languageForFormType} in your file
              will be {languageForAction}.
            </li>
            <li>
              <b>Approve File:</b> Valid {languageForFormType} will be{" "}
              {languageForAction}. Invalid {languageForFormType} will NOT be{" "}
              {languageForAction}.
            </li>
          </ul>
        </Body>
        {file.hasErrorReport && (
          <Body>
            For more details on the status of your records, you can download a
            report for this file{" "}
            <Button
              variant="anchor-primary-inline"
              label="here"
              trackingId={
                ReviewIngestionModalTrackingId.ErrorReportSummaryDownload
              }
              onClick={() => {
                void downloadErrorReports();
              }}
            />
            .
          </Body>
        )}
        <Divider />
        <FileMetadataGrid action={action} file={file} />
        {didConfirmationFail && (
          <Alert variant="danger">
            Failed to {isApprovingFile ? "approve" : "reject"} file{" "}
            {file.fileName}. Please try again later.
          </Alert>
        )}
      </Flex>
    </Modal>
  );
};

export default ReviewIngestionModal;
