import AppLevelSpinner from "./AppLevelSpinner";
import AuthedRoute from "./AuthedRoute";
import useRouteAuthentication from "../../../hooks/useRouteAuthentication";
import { NotificationsContextProvider } from "../../notifications/useNotificationsContext";

const AuthProtectedRoute: React.FC = () => {
  const isAuthenticated = useRouteAuthentication();

  return isAuthenticated ? (
    <NotificationsContextProvider>
      <AuthedRoute />
    </NotificationsContextProvider>
  ) : (
    <AppLevelSpinner trackingId="auth-protected-route-spinner" />
  );
};

export default AuthProtectedRoute;
