import { ReleasedDocumentId } from "@taxbit-dashboard/rest";
import { ActionButton } from "@taxbit-private/cosmic";

import useDownloadTaxFormsButton from "./useDownloadTaxFormsButton";

type DownloadTaxFormsButtonProps = {
  documentId: ReleasedDocumentId;
  formTitle: string;
};

export const DownloadTaxFormButtonTrackingId = "download-tax-form-btn";

const DownloadTaxFormsButton: React.FC<DownloadTaxFormsButtonProps> = ({
  documentId,
  formTitle,
}) => {
  const { isLoading, onClick } = useDownloadTaxFormsButton(
    documentId,
    formTitle
  );

  return (
    <ActionButton
      isDisabled={isLoading}
      iconName="download"
      label={isLoading ? undefined : "Download"}
      trackingId={DownloadTaxFormButtonTrackingId}
      onClick={onClick}
      tooltipProps={{
        content: "Preparing download",
        appendTo: "body",
        isInteractive: false,
        isDisabled: !isLoading,
      }}
    />
  );
};

export default DownloadTaxFormsButton;
