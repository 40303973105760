import { useMatch } from "@tanstack/react-location";
import { TransferType } from "@taxbit-dashboard/rest";

import {
  useGetTransfersIn,
  useGetTransfersOut,
} from "../../../api/transfers/transfersApi";
import { TransfersTableParams } from "../../../api/transfers/transfersApiTypes";
import { TransfersRouteMeta } from "../routeTypes";

/**
 * Hook to return transfers query data based on the current route's
 * transfer type meta property.
 */
const useTransfersQuery = ({
  urlParams,
}: {
  urlParams: TransfersTableParams;
}) => {
  const {
    route: { meta },
  } = useMatch<TransfersRouteMeta>();

  const transferType = meta?.transferType;
  const isTransfersIn = transferType === TransferType.TransferIn;
  const isTransfersOut = transferType === TransferType.TransferOut;

  const transfersInQuery = useGetTransfersIn(urlParams, isTransfersIn);
  const transfersOutQuery = useGetTransfersOut(urlParams, isTransfersOut);

  if (isTransfersIn) {
    return { ...transfersInQuery, transferType };
  }

  if (isTransfersOut) {
    return { ...transfersOutQuery, transferType };
  }

  throw new Error(`Unrecognized transfer type: ${transferType}`);
};

export default useTransfersQuery;
