import { UnexpectedResponseError } from "@taxbit-dashboard/rest";

const doesResponseErrorMatchStatusCode = (
  error: unknown,
  statusCode: number
): boolean => {
  return error instanceof UnexpectedResponseError
    ? error.status === statusCode
    : false;
};

export default doesResponseErrorMatchStatusCode;
