import { Form1099IntLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItem1099IntColumns = (): TableColumns<Form1099IntLineItem> => {
  const { formatQuantity } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Form1099IntLineItem> => [
      {
        key: "interestIncome",
        textAlign: "right",
        label: "Interest Income",
        isContentFullWidth: true,
        renderCell: (interestIncome) => formatQuantity(interestIncome),
      },
      {
        key: "earlyWithdrawalPenalty",
        textAlign: "right",
        label: "Early Withdrawal Penalty",
        isContentFullWidth: true,
        renderCell: (earlyWithdrawalPenalty) =>
          formatQuantity(earlyWithdrawalPenalty),
      },
      {
        key: "interestOnUsSavingsBondsAndTreasuryObligations",
        textAlign: "right",
        label: "Interest On US Savings Bonds And Treasury Obligations",
        isContentFullWidth: true,
        renderCell: (interestOnUsSavingsBondsAndTreasuryObligations) =>
          formatQuantity(interestOnUsSavingsBondsAndTreasuryObligations),
      },
      {
        key: "federalTaxWithheld",
        textAlign: "right",
        label: "Federal Tax Withheld",
        isContentFullWidth: true,
        renderCell: (federalTaxWithheld) => formatQuantity(federalTaxWithheld),
      },
      {
        key: "investmentExpense",
        textAlign: "right",
        label: "Investment Expense",
        isContentFullWidth: true,
        renderCell: (investmentExpense) => formatQuantity(investmentExpense),
      },
      {
        key: "foreignTaxPaid",
        textAlign: "right",
        label: "Foreign Tax Paid",
        isContentFullWidth: true,
        renderCell: (foreignTaxPaid) => formatQuantity(foreignTaxPaid),
      },
      {
        key: "foreignCountryOrUsPossession",
        label: "Foreign Country Or US Possession",
        isContentFullWidth: true,
      },
      {
        key: "taxExemptInterest",
        textAlign: "right",
        label: "Tax Exempt Interest",
        isContentFullWidth: true,
        renderCell: (taxExemptInterest) => formatQuantity(taxExemptInterest),
      },
      {
        key: "specifiedPrivateActivityBondInterest",
        textAlign: "right",
        label: "Specified Private Activity Bond Interest",
        isContentFullWidth: true,
        renderCell: (specifiedPrivateActivityBondInterest) =>
          formatQuantity(specifiedPrivateActivityBondInterest),
      },
      {
        key: "marketDiscount",
        textAlign: "right",
        label: "Market Discount",
        isContentFullWidth: true,
        renderCell: (marketDiscount) => formatQuantity(marketDiscount),
      },
      {
        key: "bondPremium",
        textAlign: "right",
        label: "Bond Premium",
        isContentFullWidth: true,
        renderCell: (bondPremium) => formatQuantity(bondPremium),
      },
      {
        key: "bondPremiumOnTreasuryObligations",
        textAlign: "right",
        label: "Bond Premium On Treasury Obligations",
        isContentFullWidth: true,
        renderCell: (bondPremiumOnTreasuryObligations) =>
          formatQuantity(bondPremiumOnTreasuryObligations),
      },
      {
        key: "bondPremiumOnTaxExemptBond",
        textAlign: "right",
        label: "Bond Premium On Tax Exempt Bond",
        isContentFullWidth: true,
        renderCell: (bondPremiumOnTaxExemptBond) =>
          formatQuantity(bondPremiumOnTaxExemptBond),
      },
      {
        key: "taxExemptAndTaxCreditBondCusip",
        label: "Tax Exempt And Tax Credit Bond CUSIP",
        isContentFullWidth: true,
      },
      {
        key: "state",
        label: "State",
        isContentFullWidth: true,
      },
      {
        key: "payersStateNumber",
        label: "Payers State Number",
        isContentFullWidth: true,
      },
      {
        key: "stateTaxWithheld",
        textAlign: "right",
        label: "State Tax Withheld",
        isContentFullWidth: true,
        renderCell: (stateTaxWithheld) => formatQuantity(stateTaxWithheld),
      },
    ],
    [formatQuantity]
  );
};

export default useFormItem1099IntColumns;
