import { useGetCurrentCompanyUserDownloads } from "@taxbit-dashboard/commons";
import {
  ContentEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
  Flex,
  H4,
} from "@taxbit-private/cosmic";
import { Fragment } from "react";
import styled from "styled-components";

import DownloadListItem from "./DownloadListItem";

export enum DownloadListTrackingId {
  LoadingSpinner = "downloads-loading-spinner",
}

const DownloadList: React.FC = () => {
  const { isLoading, data, isError } = useGetCurrentCompanyUserDownloads();

  const panelContent = (() => {
    if (isLoading) {
      return (
        <ContentSpinner trackingId={DownloadListTrackingId.LoadingSpinner} />
      );
    }

    if (isError) {
      return <ContentErrorEmptyState />;
    }

    if (!data || data.items.length === 0) {
      return (
        <ContentEmptyState
          title="No recent files"
          avatarProps={{
            iconName: "file",
            variant: "secondary",
          }}
        />
      );
    }

    return (
      <ItemsWrapper direction="column">
        {data.items.map((download, index) => (
          <Fragment key={download.downloadUrl}>
            <DownloadListItem downloadItem={download} />
            {index !== data.items.length - 1 && <Divider />}
          </Fragment>
        ))}
      </ItemsWrapper>
    );
  })();

  return (
    <PanelWrapper direction="column">
      <Flex padding="m l">
        <H4 as="div">Files</H4>
      </Flex>
      <Divider />
      {panelContent}
    </PanelWrapper>
  );
};

const PanelWrapper = styled(Flex)(
  ({ theme }) => `
  width: ${theme.measure.l};
`
);

const ItemsWrapper = styled(Flex)`
  overflow-y: auto;
  max-height: 250px;
`;

export default DownloadList;
