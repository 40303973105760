import { useDashboardStore } from "@taxbit-dashboard/commons";
import { useMemo, useEffect, useCallback } from "react";

import {
  useGetNotificationsByCategory,
  useMarkAllNotificationsAsRead,
  useNotificationsWebSocket,
} from "../../api/notifications/notificationsApi";
import {
  NotificationsPageParams,
  NotificationsTab,
} from "../../api/notifications/notificationsApiTypes";

export enum NotificationToastTrackingId {
  MarkAsReadError = "mark-as-read-error-toast",
  MarkAllAsReadError = "mark-all-as-read-error-toast",
}

export const notificationsTabTitleMap = {
  [NotificationsTab.All]: "All",
  [NotificationsTab.Read]: "Read",
  [NotificationsTab.Unread]: "Unread",
};

const useNotifications = (params: NotificationsPageParams) => {
  const {
    isLoading: isLoadingNotifications,
    allNotifications,
    unreadNotifications,
    readNotifications,
    unreadCount,
    readCount,
    allCount,
    isError: isErrorLoadingNotifications,
  } = useGetNotificationsByCategory(params);

  const { isConnectingWebSocket, isErrorWebSocket } =
    useNotificationsWebSocket();

  const { mutate: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMarkAllNotificationsAsRead();

  const addToast = useDashboardStore((store) => store.addToast);

  const isError = useMemo(
    () => isErrorLoadingNotifications || isErrorWebSocket,
    [isErrorLoadingNotifications, isErrorWebSocket]
  );

  const isLoading = useMemo(
    () => isLoadingNotifications || isConnectingWebSocket,
    [isConnectingWebSocket, isLoadingNotifications]
  );

  const shouldShowUnreadNotifications = useMemo(
    () => unreadCount > 0 && !isError && !isLoading,
    [isError, isLoading, unreadCount]
  );

  useEffect(() => {
    if (shouldShowUnreadNotifications) {
      document.title = `Taxbit (${unreadCount})`;
    } else {
      document.title = `Taxbit`;
    }
  }, [shouldShowUnreadNotifications, unreadCount]);

  const [filteredNotifications, totalCount] = useMemo(() => {
    switch (params.tab) {
      case NotificationsTab.Read: {
        return [readNotifications, readCount];
      }
      case NotificationsTab.Unread: {
        return [unreadNotifications, unreadCount];
      }
      default: {
        return [allNotifications, allCount];
      }
    }
  }, [
    params.tab,
    readNotifications,
    readCount,
    unreadNotifications,
    unreadCount,
    allNotifications,
    allCount,
  ]);

  const markAllNotificationsAsRead = useCallback(() => {
    markAllAsRead(undefined, {
      onError: () => {
        addToast({
          message:
            "Failed to mark all notifications as read. Please try again.",
          trackingId: NotificationToastTrackingId.MarkAllAsReadError,
          timeoutMs: 5000,
          variant: "danger",
        });
      },
    });
  }, [addToast, markAllAsRead]);

  return {
    filteredNotifications,
    shouldShowUnreadNotifications,
    isLoading,
    unreadCount,
    totalCount,
    isError,
    markAllNotificationsAsRead,
    isMarkingAllAsRead,
  };
};

export default useNotifications;
