import { useDefinedCurrentCompanyUser } from "@taxbit-dashboard/commons";
import { FileType, CreateMultipartUploadRequest } from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import { FileUploaderFormFields } from "./fileUploaderFormTypes";
import { getFilenameParts } from "../file-uploader/validateFile";
import isFormsFileType from "../utils/isFormsFileType";

const useGetFileData = () => {
  const currentCompanyUser = useDefinedCurrentCompanyUser();

  return useCallback(
    ({
      file,
      fileType,
      formDocumentType,
      formTaxYear,
      accountsTemplateType,
      transactionsTemplateType,
    }: {
      file: File;
    } & Omit<FileUploaderFormFields, "files">):
      | Omit<CreateMultipartUploadRequest, "totalParts">
      | undefined => {
      const { extension } = getFilenameParts(file.name);

      if (!extension) {
        return undefined;
      }

      const metadata = (() => {
        if (isFormsFileType(fileType) && formDocumentType && formTaxYear) {
          return {
            formDocumentType,
            formTaxYear: formTaxYear.toString(),
          };
        } else if (fileType === FileType.Accounts && accountsTemplateType) {
          return {
            accountsTemplateType,
          };
        } else if (
          fileType === FileType.Transactions &&
          transactionsTemplateType
        ) {
          return { transactionsTemplateType };
        } else {
          return undefined;
        }
      })();

      return {
        fileName: file.name,
        fileExtension: extension,
        fileType,
        username: currentCompanyUser.name,
        metadata,
      };
    },
    [currentCompanyUser.name]
  );
};

export default useGetFileData;
