import { AssetAmount, LineItem } from "@taxbit-dashboard/rest";
import { Big } from "big.js";

const calculateAssetFiatValue = (
  lineItem?: LineItem
): AssetAmount | undefined => {
  const assetAmount = lineItem?.assetAmount;
  const assetRate = lineItem?.rates?.[0];

  if (assetAmount && assetRate) {
    return {
      asset: assetRate.asset,
      amount: new Big(assetAmount.amount).times(assetRate.amount).toString(),
    };
  } else {
    return undefined;
  }
};

export default calculateAssetFiatValue;
