import { MakeGenerics } from "@tanstack/react-location";
import { filterObject, isDefined } from "@taxbit-dashboard/commons";
import { EligibilityStatus } from "@taxbit-dashboard/rest";

import {
  EligibilityTableParams,
  eligibilityTableParams,
} from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { irFormTypeDateParamsSchema } from "../../../../api/information-reporting/irApiTypes";

export type EligibilityTableGenerics = MakeGenerics<{
  Search: {
    // All keys become strings or string arrays because
    // we use qs to deserialize url query params.
    [key in keyof EligibilityTableParams]?: string | string[];
  };
}>;

/**
 * Represents the URL params for the Eligibility page when initially loaded or
 * when the user navigates to a different form type or year. We use this to
 * determine if we are in a "fresh" state and should apply default filters.
 */
const baseEligibilityTableUrlParamsSchema = irFormTypeDateParamsSchema
  .partial()
  .strict();

/**
 * URL params can be manually edited or out of date according
 * to the current data if from a saved link. To simplify the number
 * of edge cases we need to dive into, we use a zod schema that coerces
 * the given params into a set that could be used to fetch data, i.e. it
 * strips out incorrect optional params and defaults required ones.
 */
const validateEligibilityTableUrlParams = (
  rawParams: EligibilityTableGenerics["Search"]
): EligibilityTableParams => {
  const shouldUseDefaultFilters =
    baseEligibilityTableUrlParamsSchema.safeParse(rawParams).success;

  const params = eligibilityTableParams.parse(rawParams);

  return filterObject(
    {
      ...params,
      statuses: shouldUseDefaultFilters
        ? [EligibilityStatus.Eligible, EligibilityStatus.Added]
        : params.statuses,
    },
    (val) => isDefined(val)
  );
};

export default validateEligibilityTableUrlParams;
