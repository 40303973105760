import { GridGroup, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { useMemo } from "react";

import useDac7InterviewDetailsRows from "./useDac7InterviewDetailsRows";
import useGetDac7InterviewDetails from "./useGetDac7InterviewDetails";

export enum Dac7InterviewDetailsTrackingId {
  Dac7InterviewDetailsSpinner = "dac7-interview--details-spinner",
}

const Dac7InterviewDetails: React.FC = () => {
  const { hasDac7AccountAccess } = useDashboardFeatureFlags();
  const { isLoading, dac7Details } = useGetDac7InterviewDetails();

  const dac7InterviewDetailsRows = useDac7InterviewDetailsRows({
    dac7Details,
  });

  const cardContent = useMemo(() => {
    if (isLoading) {
      return (
        <ContentSpinner
          trackingId={
            Dac7InterviewDetailsTrackingId.Dac7InterviewDetailsSpinner
          }
        />
      );
    }

    if (!hasDac7AccountAccess) {
      return <ContentEntityEmptyState entity="DAC7 interview details" />;
    }

    if (dac7InterviewDetailsRows) {
      return <GridGroup data={dac7InterviewDetailsRows} />;
    }

    return <ContentErrorEmptyState entity="DAC7 interview details" />;
  }, [dac7InterviewDetailsRows, hasDac7AccountAccess, isLoading]);

  return <Card title="DAC7 Interview Details">{cardContent}</Card>;
};

export default Dac7InterviewDetails;
