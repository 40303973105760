import { KycTaxDocumentationIssueType } from "@taxbit-dashboard/rest";

export const issueTypesMap: Record<KycTaxDocumentationIssueType, string> = {
  [KycTaxDocumentationIssueType.UsPermanentAddress]: "US Permanent Address",
  [KycTaxDocumentationIssueType.PoBoxPermanentAddress]:
    "PO Box Permanent Address",
  [KycTaxDocumentationIssueType.CareOf]: "Care Of Permanent Address",
  [KycTaxDocumentationIssueType.UsMailingAddress]: "US Mailing Address",
  [KycTaxDocumentationIssueType.ExpiredForm]: "Expired Form",
};
