import { FormRmdLineItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

const useFormItemRmdColumns = () => {
  const { formatQuantity } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<FormRmdLineItem> => [
      {
        key: "rmdDate",
        label: "RMD Date",
        isContentFullWidth: true,
      },
      {
        key: "rmdAmount",
        label: "RMD Amount",
        isContentFullWidth: true,
        textAlign: "right",
        renderCell: (rmdAmount) => formatQuantity(rmdAmount),
      },
    ],
    [formatQuantity]
  );
};

export default useFormItemRmdColumns;
