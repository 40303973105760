import { FileType } from "@taxbit-dashboard/rest";
import { z } from "zod";

import { createFileUploaderFormFieldsSchema } from "./fileUploaderFormFieldsSchema";

export type FileUploaderFormFields = z.infer<
  ReturnType<typeof createFileUploaderFormFieldsSchema>
>;

export const FILE_UPLOADER_FORM_ID = "file-uploader-form";

export const fileTypesWithTemplates = new Set([
  FileType.Accounts,
  FileType.DeleteForms,
  FileType.Forms,
  FileType.Transactions,
]);
