import { z } from "zod";

import { EligibilityDocumentTypeDataSource } from "./sharedEligibilityRestApiTypes";

export const formItem1099NecAggregateValuesSchema = z.object({
  state: z.string().optional(),
  stateIncome: z.number(),
  nonemployeeComp: z.number(),
  stateTaxWithheld: z.number(),
  payersStateNumber: z.string().optional(),
  federalTaxWithheld: z.number(),
  directSales5000ConsumerProducts: z.boolean(),
  documentTypeDataSource: z.literal(
    EligibilityDocumentTypeDataSource.Irs1099NecFormData
  ),
});

export type FormItem1099NecAggregateValues = z.infer<
  typeof formItem1099NecAggregateValuesSchema
>;
