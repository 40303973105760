import { KycTaxdocumentationVatStatus } from "@taxbit-dashboard/rest";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";

import BadgeWithoutLabelTransformation from "../../../BadgeWithoutLabelTransformation";

type VatBadgeProps = {
  vatValidationStatus?: KycTaxdocumentationVatStatus;
};

const VatBadge: React.FC<VatBadgeProps> = ({ vatValidationStatus }) => {
  switch (vatValidationStatus) {
    case KycTaxdocumentationVatStatus.Invalid: {
      return (
        <BadgeWithoutLabelTransformation
          label="Invalid Data"
          variant="danger"
        />
      );
    }

    case KycTaxdocumentationVatStatus.InsufficientData: {
      return (
        <BadgeWithoutLabelTransformation
          label="Insufficient Data"
          variant="danger"
        />
      );
    }

    case KycTaxdocumentationVatStatus.Pending: {
      return (
        <BadgeWithoutLabelTransformation label="Pending" variant="warning" />
      );
    }

    case KycTaxdocumentationVatStatus.NotRequired: {
      return (
        <BadgeWithoutLabelTransformation
          label="Not Required"
          variant="secondary"
        />
      );
    }

    case KycTaxdocumentationVatStatus.Valid: {
      return (
        <BadgeWithoutLabelTransformation label="Valid" variant="success" />
      );
    }
    default: {
      return COSMIC_VALUE_PLACEHOLDER;
    }
  }
};

export default VatBadge;
