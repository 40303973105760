import { Body } from "@taxbit-private/cosmic";
import { Fragment } from "react";
import styled from "styled-components";

type GridRow = {
  /**
   * The label for the row. This content will be displayed in Body text
   * by default and will be aligned to the left side of the column.
   */
  label: string | number | React.ReactElement;
  /**
   * The content for the row. This content will be displayed in Body
   * text by default, and will be aligned to the right side of the column.
   */
  content: string | number | React.ReactElement;
  /**
   * The render key for the step. Required so that React does not complain
   * about missing keys while rendering each row.
   */
  key: string;
};

type GridProps = {
  rows: GridRow[];
};

/**
 * The `Grid` component displays a label with a text description or element.
 * The description and content will be displayed in Body text by default.
 */
const Grid = ({ rows }: GridProps) => {
  return (
    <BaseGrid>
      {rows.map(({ label, content, key }) => (
        <Fragment key={key}>
          <Body>{label}</Body>
          <ContentSlot>
            <Body>{content}</Body>
          </ContentSlot>
        </Fragment>
      ))}
    </BaseGrid>
  );
};

const ContentSlot = styled.div`
  justify-self: end;
`;

const BaseGrid = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${theme.spacing.m};
  column-gap: ${theme.spacing.m};
  word-wrap: break-word;
  align-items: center;
  grid-auto-rows: max-content;
`
);

export default Grid;
