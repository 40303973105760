import { Table } from "@taxbit-private/cosmic";

import { useEligibilityDataContext } from "../context/useEligibilityData";

const EligibilityTable = () => {
  const { irEligibilityTableProps } = useEligibilityDataContext();

  return <Table {...irEligibilityTableProps} />;
};

export default EligibilityTable;
