import { ActionButton } from "@taxbit-private/cosmic";

import { useGenerateReport } from "./useGenerateReport";
import { UmcReport } from "../types/umcReportTypes";

export const GenerateReportTrackingId = "generateTaxReportBtn";

type GenerateReportButtonProps = {
  report: UmcReport;
};

const GenerateReportButton: React.FC<GenerateReportButtonProps> = ({
  report,
}) => {
  const { isGenerating, generateReport } = useGenerateReport(report);
  return (
    <ActionButton
      isLoading={isGenerating}
      label={isGenerating ? undefined : "Generate New Report"}
      iconName="refresh"
      trackingId={GenerateReportTrackingId}
      onClick={generateReport}
    />
  );
};

export default GenerateReportButton;
